import { ApiErrorMappingLabels } from './CommonLabels';

interface ErrorCategory {
	[customCode: string]: string;
}

interface ErrorMapping {
	[status: number]: ErrorCategory;
}

export const errorMapping: ErrorMapping = {
	400: {
		BR1000: ApiErrorMappingLabels.issueProcessingRequest,
		BR1001: ApiErrorMappingLabels.issueProcessingRequest,
		BR1002: ApiErrorMappingLabels.issueProcessingRequest,
		BR1003: ApiErrorMappingLabels.issueProcessingRequest,
	},
	401: {
		UA1000: ApiErrorMappingLabels.issueValidatingIdentity,
		UA1001: ApiErrorMappingLabels.issueValidatingIdentity,
		UA1002: ApiErrorMappingLabels.issueValidatingIdentity,
	},
	403: {
		F1000: ApiErrorMappingLabels.permissionLevelRequired,
		F1001: ApiErrorMappingLabels.permissionLevelRequired,
	},
	404: {
		NF1000: ApiErrorMappingLabels.itemNotExist,
		NF1001: ApiErrorMappingLabels.linkNotExist,
	},
	413: {
		PL1000: ApiErrorMappingLabels.issueProcessingRequest,
	},
	503: {
		SU1000: ApiErrorMappingLabels.serviceUnavailable,
		SU1001: ApiErrorMappingLabels.serviceUnavailable,
	},
	500: {
		IE1000: ApiErrorMappingLabels.serverError,
		IE1001: ApiErrorMappingLabels.serverError,
		IED1000: ApiErrorMappingLabels.databaseConnectionError,
		IED1001: ApiErrorMappingLabels.databaseTimeoutError,
		IED1002: ApiErrorMappingLabels.issueProcessingRequest,
		IED1003: ApiErrorMappingLabels.recordNotFound,
		IED1004: ApiErrorMappingLabels.duplicateRecord,
		IED1005: ApiErrorMappingLabels.invalidRequest,
		IED1006: ApiErrorMappingLabels.invalidRequest,
		IED1007: ApiErrorMappingLabels.invalidRequest,
		IED1008: ApiErrorMappingLabels.processingRequestIssue,
		IED1009: ApiErrorMappingLabels.processingRequestIssue,
	},
};
