import {
	Box,
	Button,
	Grid,
	Paper,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from '@mui/material';
import { orderBy, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react/jsx-runtime';
import { useParams } from 'react-router-dom';
import { ReactComponent as DownArrow } from '../../../assets/images/Doarrow-narrow-up.svg';
import { useAppDispatch, useAppSelector } from '../../../app/Hooks';
import {
	IEmployeeDetailsCollection,
	IEmployeeDetailsCollectionData,
	IListViewReq,
} from '../../../types/QuestionnaireResultsType';
import {
	getListViewResultsRecords,
	listViewResultAPICompletionStatus,
	resultListViewRecord,
	setModifiedListResultResponse,
	setResultAPICompletionStatus,
	setPageIndex,
	setRowsPerPageValue,
	pageIndex,
	pageSize,
} from '../../../redux/QuestionnaireResultsSlice';
import { filter, tableHead } from '../../../utils/Filter';
import { STEPTheme } from '../../../utils/Theme';
import {
	AudienceFilterLabels,
	initialFilterLabelJson,
	initialFilterValue,
} from '../../../utils/SurveyAudience';
import SelectedFilters from '../../../components/SelectedFilters';
import {
	configurationData,
	resetAppliedFilterData,
	selectedFilterRequestPayloadData,
	setSelectedFilterRequestPayload,
	setSurveyAudienceBlobName,
	setSurveyAudienceExcludeClause,
	setSurveyAudienceFilterLabelJson,
	setSurveyAudienceIncludeClause,
} from '../../../redux/SurveySlice';
import { getGroupDropDownList } from '../../../utils/CommonMethods';
import ChipGroup from '../../../components/common/ChipGroup';
import { groupsList } from '../../../redux/MappingSlice';
import CustomDropdown from '../../../components/common/CustomDropdown';
import StyledTooltip from '../../../components/StyledTooltip';
import { Numbers, PageRows } from '../../../utils/Enum';
import { FilterButtonLabel } from '../../../utils/QuestionnaireResult';
import {
	ChipsLabels,
	MappingScreenLabels,
	questionnaireResultsLabels,
	resultTableHead,
} from '../../../utils/CommonLabels';
import AudiencePopup from '../../../components/mapping/AudiencePopup';
import {
	IAudienceListingRequest,
	IChipGroup,
} from '../../../types/CommonTypes';
import { getTimeInLastRefreshFormat } from '../../../utils/DateFormatting';
import NoResultContentFound from '../../../components/questionnaire-results/NoResultContentFound';
import Loader from '../../../components/Loader';
import { API_STATUS_CONSTANT } from '../../../constants/StepConstants';

const ListTableView: React.FC<{ preFilterValue: string }> = ({
	preFilterValue,
}) => {
	const HeadText = styled('div')({
		width: 'auto',
		minHeight: '22px',
		fontFamily: 'JohnsonText-Regular',
		fontSize: '16px',
		fontWeight: '700',
		lineHeight: '16px',
		letterSpacing: '1px',
		textAlign: 'left',
		display: 'flex',
		cursor: 'pointer',
		svg: {
			cursor: 'pointer',
			width: '22px',
			height: '18px',
		},
		'.up-arrow': {
			svg: {
				transform: 'rotate(180deg)',
			},
		},
	});
	const StyledDropdownLabel = styled(Typography)({
		fontSize: '14px',
		fontWeight: 500,
	});
	const FilterButton = styled(Button)({
		width: '100px',
		height: '24px',
		borderRadius: '8px',
		backgroundColor: STEPTheme.colors.text,
		color: STEPTheme.colors.white,
		fontFamily: 'JohnsonText-Regular',
		fontSize: '14px',
		fontWeight: 500,
		lineHeight: '16px',
		align: 'center',
		display: 'flex',
		marginLeft: 'auto',
		'&:hover': {
			backgroundColor: STEPTheme.colors.text,
			textDecoration: 'none !important',
			border: `1px solid ${STEPTheme.colors.text} !important`,
			filter: 'blur(1px)',
		},
	});
	const Reset = styled(Button)({
		display: 'flex',
		flexWrap: 'wrap',
		width: '55px',
		fontFamily: 'JohnsonText-Regular',
		fontSize: '14px',
		fontWeight: 400,
		lineHeight: '16px',
		letterSpacing: '0px',
		textAlign: 'left',
		color: STEPTheme.colors.blue,
		textTransform: 'none',
		'&:hover': {
			backgroundColor: 'initial',
			textDecoration: 'none',
		},
	});
	const FilterButtonsBox = styled(Box)({
		display: 'flex',
		gap: '10px',
		marginLeft: 'auto',
		marginBottom: 'auto',
		marginTop: '10px',
	});

	const groupListFetched = useAppSelector(groupsList);
	const groupListDropDownOptions = getGroupDropDownList(groupListFetched);
	const [groupSelected, setGroupSelected] = useState('');
	const { questionnaireID } = useParams<{ questionnaireID: string }>();
	// Convert questionnaireID to string and store in questionnaireId
	const questionnaireId = `${questionnaireID}`;
	/**Filter state variable Code Start */
	const [filterSelectionOpen, setFilterSelectionOpen] = useState(false);
	const filterRequestPayload = useAppSelector(selectedFilterRequestPayloadData);
	/**Filter state variable Code end */
	/**Table variables */
	const configs = useAppSelector(configurationData);
	const resultTablePageIndex: number = useAppSelector(pageIndex);
	const resultTablePageSize: number = useAppSelector(pageSize);
	const [page, setPage] = React.useState(Numbers.zero);
	const rowPage = configs?.rows_per_page?.map((i: number) => Number(i));
	const defaultRowsPerPage = rowPage
		? rowPage[Numbers.zero]
		: PageRows.firstOption;
	const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
	const [resultNameSortObj, setResultNameSortObj] = useState('desc');
	const [resultEmailSortObj, setResultEmailSortObj] = useState('desc');
	const [resultWwidSortObj, setResultWwidSortObj] = useState('desc');
	const [resultSectorSortObj, setResultSectorSortObj] = useState('desc');
	const [resultRegionSortObj, setResultRegionSortObj] = useState('desc');
	const [resultRespondedSortObj, setResultRespondedSortObj] = useState('desc');
	const [resultCompletionDateSortObj, setResultCompletionDateSortObj] =
		useState('desc');
	const listViewDataArray: IEmployeeDetailsCollectionData =
		useAppSelector(resultListViewRecord);
	/**Table variables */
	const dispatch = useAppDispatch();
	const includedFilters = filterRequestPayload.includeFilters
		? JSON.parse(filterRequestPayload.includeFilters)
		: (initialFilterValue as unknown as IAudienceListingRequest);
	const excludedFilters = filterRequestPayload.excludeFilters
		? JSON.parse(filterRequestPayload.excludeFilters)
		: (initialFilterValue as unknown as IAudienceListingRequest);
	const [filtersApplied, setFiltersApplied] = useState(false);
	const [showLoader, setShowLoader] = React.useState(true);
	const APICompletionStatus = useAppSelector(listViewResultAPICompletionStatus);

	useEffect(() => {
		handleResetAllFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (groupListFetched.length > 0 && groupSelected !== '') {
			transformChips(
				groupSelected !== '',
				groupListFetched.find((item) => item.groupName === groupSelected)
					?.groupId
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupSelected, groupListFetched]);

	useEffect(() => {
		if (
			APICompletionStatus === API_STATUS_CONSTANT.SUCCESS ||
			APICompletionStatus === API_STATUS_CONSTANT.FAILED
		) {
			setShowLoader(false);
			dispatch(setResultAPICompletionStatus(API_STATUS_CONSTANT.IDLE));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [APICompletionStatus]);

	useEffect(() => {
		setPage(resultTablePageIndex);
		setRowsPerPage(resultTablePageSize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listViewDataArray]);

	useEffect(() => {
		if (preFilterValue !== '') {
			handleSelect(preFilterValue);
		} else {
			handleListViewFilter(chips, page, rowsPerPage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [preFilterValue]);

	useEffect(() => {
		setFiltersApplied(
			!isEqual(includedFilters, initialFilterValue) ||
				!isEqual(excludedFilters, initialFilterValue)
		);
		if (filtersApplied) {
			handleListViewFilter(chips, page, rowsPerPage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersApplied, filterRequestPayload]);

	const handleResetAllFilter = () => {
		dispatch(resetAppliedFilterData([]));
		dispatch(
			setSelectedFilterRequestPayload({
				includeFilters: JSON.stringify(initialFilterValue),
				excludeFilters: JSON.stringify(initialFilterValue),
				includeClause: '',
				excludeClause: '',
				filterLabelJson: initialFilterLabelJson,
			})
		);
		dispatch(setSurveyAudienceBlobName(JSON.stringify(initialFilterValue)));
		dispatch(setSurveyAudienceIncludeClause(''));
		dispatch(setSurveyAudienceExcludeClause(''));
		dispatch(setSurveyAudienceFilterLabelJson(initialFilterLabelJson));
	};
	/**Filter state varriable Code Start */
	const handleFilterModalOpen = () => {
		setFilterSelectionOpen(true);
	};
	/**Filter state varriable Code end */
	const [chips, setChips] = useState<IChipGroup['chips']>([
		{ text: ChipsLabels.RESPONDED, selected: true, visible: true },
		{ text: ChipsLabels.NOTRESPONDED, selected: false, visible: true },
		{ text: ChipsLabels.DEFAULTER, selected: false, visible: true },
		{ text: ChipsLabels.RISKOVERDUE, selected: false, visible: true },
		{ text: ChipsLabels.ALREADYOVERDUE, selected: false, visible: true },
		{ text: ChipsLabels.MULTIPLESURVEYGROUPS, selected: false, visible: true },
		{ text: ChipsLabels.NOTINSUMMIT, selected: false, visible: false },
	]);

	const setDefaultChipVisibility = () => {
		return chips.map((chip) => {
			return chip.text === ChipsLabels.NOTINSUMMIT
				? { ...chip, selected: false, visible: false }
				: { ...chip, selected: false, visible: true };
		});
	};

	const transformSelectedChip = (
		chipText: string,
		modifiedChips: IChipGroup['chips']
	) => {
		const updatedChips = modifiedChips.map((chip) => {
			return chip.text === chipText
				? { ...chip, selected: !chip.selected }
				: { ...chip, selected: false };
		});
		setChips([...updatedChips]);
		return updatedChips;
	};

	const handleSelect = (chipText: string) => {
		const updatedChips = transformSelectedChip(chipText, chips);
		setRowsPerPage(defaultRowsPerPage);
		dispatch(setRowsPerPageValue(defaultRowsPerPage));
		setPage(Numbers.zero);
		dispatch(setPageIndex(Numbers.zero));
		handleListViewFilter(updatedChips, Numbers.zero, defaultRowsPerPage);
	};

	const createChipsPayload = (selectedChipText: string | undefined) => {
		const metricObj = {
			metricName: '',
			metricSubType: '',
		};
		const EMPLOYEE = 'employees';
		if (
			groupSelected === 'None' &&
			selectedChipText === ChipsLabels.NOTINSUMMIT
		) {
			selectedChipText = ChipsLabels.RESPONDED;
			const updatedChips = setDefaultChipVisibility();
			transformSelectedChip(selectedChipText, updatedChips);
		} else if (!selectedChipText && groupSelected !== 'None') {
			metricObj.metricName = `${EMPLOYEE}summitgroupstatus`;
			metricObj.metricSubType = ``;
			return metricObj;
		} else {
			/* empty */
		}
		switch (selectedChipText) {
			case ChipsLabels.RESPONDED:
				metricObj.metricSubType = `responded`;
				break;
			case ChipsLabels.NOTRESPONDED:
				metricObj.metricSubType = 'not responded';
				break;
			case ChipsLabels.EMPLOYEE:
				metricObj.metricName = `${EMPLOYEE}`;
				break;
			case ChipsLabels.CONTIGENTWORKER:
				metricObj.metricName = `${EMPLOYEE}ContigentWorker`;
				break;
			case ChipsLabels.DEFAULTER:
				metricObj.metricName = `${EMPLOYEE}Defaulter`;
				break;
			case ChipsLabels.RISKOVERDUE:
				metricObj.metricName = `${EMPLOYEE}RiskOverDue`;
				break;
			case ChipsLabels.ALREADYOVERDUE:
				metricObj.metricName = `${EMPLOYEE}AlreadyOverDue`;
				break;
			case ChipsLabels.MULTIPLESURVEYGROUPS:
				metricObj.metricName = `${EMPLOYEE}MultiPageSurveyGroup`;
				break;
			case ChipsLabels.NOTINSUMMIT:
				metricObj.metricName = `${EMPLOYEE}summitgroupstatus`;
				metricObj.metricSubType = `notInSummit`;
				break;
			default:
				metricObj.metricSubType = `responded`;
				break;
		}
		return metricObj;
	};

	const handleGroupSelect = (group: string) => {
		setGroupSelected(group);
	};

	const transformChips = (
		isGrpupSelected: boolean,
		groupId: string | undefined
	) => {
		let updatedChips;
		if (!isGrpupSelected) {
			updatedChips = setDefaultChipVisibility();
		} else {
			updatedChips = chips.map((chip) => {
				return chip.text !== ChipsLabels.NOTINSUMMIT
					? { ...chip, selected: false, visible: false }
					: { ...chip, selected: true, visible: true };
			});
		}
		setChips([...updatedChips]);
		handleListViewFilter(updatedChips, page, rowsPerPage, groupId);
	};

	const handleListViewFilter = (
		updatedChips: IChipGroup['chips'],
		pageNum: number,
		pageSz: number,
		groupId?: string | undefined
	) => {
		const selectedChipText = updatedChips?.find(
			(chipsItem) => chipsItem.selected
		)?.text;
		const payload = {
			requestFilterPayload: createRequestPayload(
				createChipsPayload(selectedChipText),
				pageNum,
				pageSz,
				groupId
			),
			questionniareId: questionnaireId,
		};
		setShowLoader(true);
		dispatch(getListViewResultsRecords(payload));
	};
	const createRequestPayload = (
		metricObj: Record<string, string>,
		pageNum: number,
		pageSz: number,
		groupId?: string | undefined
	) => {
		const listRequestFilterPayload: IListViewReq = {
			includeFilter: JSON.stringify({ ...includedFilters }),
			excludeFilter: JSON.stringify({ ...excludedFilters }),
			days: Numbers.one,
			pageNumber: Number(pageNum + Numbers.one),
			pageSize: pageSz,
			metricName: metricObj?.metricName || '',
			metricSubType: metricObj?.metricSubType || '',
		};
		if (groupId) {
			listRequestFilterPayload['groupId'] = groupId;
		} else if (groupSelected) {
			listRequestFilterPayload['groupId'] = groupListFetched.find(
				(item) => item.groupName === groupSelected
			)?.groupId;
		} else {
			/* empty */
		}
		return listRequestFilterPayload;
	};

	/**Table Pagination Methods */
	const handleChangePage = (_event: unknown, newPage: number) => {
		setPage(newPage);
		dispatch(setPageIndex(newPage));
		handleListViewFilter(chips, newPage, rowsPerPage);
	};
	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(Number(event.target.value));
		dispatch(setRowsPerPageValue(Number(event.target.value)));
		setPage(Numbers.zero);
		dispatch(setPageIndex(Numbers.zero));
		handleListViewFilter(chips, Numbers.zero, Number(event.target.value));
	};
	/**Table Pagination Methods */

	/**
	 * @description Sorting arrow making and functionality.
	 * @param stateName
	 * @param columnName
	 * @param setStateMethod
	 * @returns
	 */
	const renderAscDesArraow = (
		stateName: string,
		columnName: string,
		setStateMethod: (value: React.SetStateAction<string>) => void
	) => {
		return (
			<Fragment>
				{stateName === 'desc' && (
					<div className="up-arrow">
						<DownArrow
							onClick={() => {
								arrowClick(stateName, columnName, setStateMethod);
							}}
						/>
					</div>
				)}
				{stateName === 'asc' && (
					<DownArrow
						onClick={() => {
							arrowClick(stateName, columnName, setStateMethod);
						}}
					/>
				)}
			</Fragment>
		);
	};
	/**arrow click common method */
	const arrowClick = (
		stateValue: string,
		columnName: string,
		setStateMethod: (value: React.SetStateAction<string>) => void
	) => {
		const ascDescVal =
			stateValue === 'desc'
				? 'asc'
				: stateValue === 'asc'
					? 'desc'
					: stateValue;
		setStateMethod(ascDescVal);
		dispatch(
			setModifiedListResultResponse({
				totalCount: listViewDataArray?.totalCount,
				employeeDetailsCollection: orderBy(
					listViewDataArray?.employeeDetailsCollection,
					[columnName],
					[ascDescVal as 'asc']
				),
			})
		);
	};

	return (
		<Fragment>
			<Loader isLoading={showLoader} />
			<Box
				sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '10px' }}
			>
				<StyledDropdownLabel>
					{MappingScreenLabels.groupLabel}
				</StyledDropdownLabel>
				<CustomDropdown
					defaultText={filter.label.NONE}
					disabled={groupListDropDownOptions.length === 0}
					value={groupSelected ?? ''}
					valueList={groupListDropDownOptions ?? []}
					handleChange={(e) => {
						handleGroupSelect && handleGroupSelect(e.target.value);
					}}
				/>
			</Box>
			<Box sx={{ display: 'flex', marginTop: '30px', width: '100%' }}>
				<ChipGroup
					chips={chips}
					color={STEPTheme.colors.radianceBlue}
					backgroundColor={STEPTheme.colors.cyanBlue}
					onSelect={handleSelect}
				/>
			</Box>
			<Box sx={{ margin: '10px 0 0 10px' }}>
				{<SelectedFilters isVertical={true} />}
			</Box>
			<FilterButtonsBox>
				{filtersApplied && (
					<Reset
						data-testid="result-reset-all-button"
						className="pr-0 justify-end"
						variant="text"
						onClick={handleResetAllFilter}
					>
						{AudienceFilterLabels.resetAll}
					</Reset>
				)}
				<FilterButton
					variant="outlined"
					color="secondary"
					onClick={handleFilterModalOpen}
				>
					{FilterButtonLabel}
				</FilterButton>
			</FilterButtonsBox>
			<TableContainer
				component={Paper}
				elevation={1}
				sx={{ boxShadow: 'none', height: 'auto', marginTop: '30px' }}
			>
				<Table
					stickyHeader
					aria-label="sticky table"
					sx={{ background: `${STEPTheme.colors.background}` }}
				>
					<TableHead>
						<TableRow>
							<TableCell className="bg-transparent">
								<HeadText>
									{resultTableHead.labels.NAME}
									{renderAscDesArraow(
										resultNameSortObj,
										'name',
										setResultNameSortObj
									)}
								</HeadText>
							</TableCell>
							<TableCell className="bg-transparent">
								<HeadText>
									{resultTableHead.labels.WWID}
									{renderAscDesArraow(
										resultWwidSortObj,
										'wwid',
										setResultWwidSortObj
									)}
								</HeadText>
							</TableCell>
							<TableCell className="bg-transparent">
								<HeadText>
									{resultTableHead.labels.EMAIL}
									{renderAscDesArraow(
										resultEmailSortObj,
										'emailId',
										setResultEmailSortObj
									)}
								</HeadText>
							</TableCell>
							<TableCell className="bg-transparent">
								<HeadText>
									{resultTableHead.labels.SECTOR}
									{renderAscDesArraow(
										resultSectorSortObj,
										'sector',
										setResultSectorSortObj
									)}
								</HeadText>
							</TableCell>
							<TableCell className="bg-transparent">
								<HeadText>
									{resultTableHead.labels.REGION}
									{renderAscDesArraow(
										resultRegionSortObj,
										'region',
										setResultRegionSortObj
									)}
								</HeadText>
							</TableCell>
							<TableCell className="bg-transparent">
								<HeadText>
									{resultTableHead.labels.RESPONDED}
									{renderAscDesArraow(
										resultRespondedSortObj,
										'isRespondedByWWID',
										setResultRespondedSortObj
									)}
								</HeadText>
							</TableCell>
							<TableCell className="bg-transparent">
								<HeadText>
									{resultTableHead.labels.SURVEY_COMPLETED_DATE}
									{renderAscDesArraow(
										resultCompletionDateSortObj,
										'questionnaireCompletionDate',
										setResultCompletionDateSortObj
									)}
								</HeadText>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody
						sx={{
							borderRadius: '4px',
							background: `${STEPTheme.colors.white}`,
						}}
					>
						{listViewDataArray?.employeeDetailsCollection?.length > 0 ? (
							listViewDataArray?.employeeDetailsCollection?.map(
								(row: IEmployeeDetailsCollection, index: number) => (
									<TableRow key={index}>
										<TableCell className="break-all" component="th" scope="row">
											{row.name}
										</TableCell>
										<TableCell>{row.wwid}</TableCell>
										<TableCell>{row.emailId}</TableCell>
										<TableCell>{row.sector}</TableCell>
										<TableCell>{row.region}</TableCell>
										<TableCell>{row.isRespondedByWWID}</TableCell>
										<TableCell>
											{getTimeInLastRefreshFormat(
												row.questionnaireCompletionDate,
												'ddmmyyyy'
											)}
										</TableCell>
									</TableRow>
								)
							)
						) : (
							<TableRow>
								<TableCell colSpan={7} align="center">
									<NoResultContentFound
										lineOneMessage={questionnaireResultsLabels.noDataLineOne}
										lineTwoMessage={questionnaireResultsLabels.noDataLineTwo}
										heading={''}
									/>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			{listViewDataArray?.totalCount > 0 && (
				<Grid container sx={{ justifyContent: 'flex-end' }}>
					<StyledTooltip
						title={tableHead.tooltips.ROWS_PER_PAGE}
						placement="left"
					>
						<TablePagination
							data-testid="paginationWrapper"
							rowsPerPageOptions={rowPage}
							component="div"
							count={listViewDataArray?.totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</StyledTooltip>
				</Grid>
			)}
			{filterSelectionOpen && (
				<AudiencePopup
					data-testid="audience-popup"
					closePopup={() => {
						setFilterSelectionOpen(false);
					}}
				/>
			)}
		</Fragment>
	);
};

export default ListTableView;
