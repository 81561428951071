import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Box, Button } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { QuestionActionsProps } from '../../types/CommonTypes';
const useStyles = makeStyles({
	menuStyle: {
		'&.MuiButtonBase-root': {
			paddingTop: '0',
		},
	},
	saveBtn: {
		width: '80%',
	},
});

const ContentCopy = styled(ContentCopyIcon)({
	cursor: 'pointer',
	marginTop: '2px',
});

const Delete = styled(DeleteIcon)({
	cursor: 'pointer',
	marginTop: '2px',
});
const QuestionActionsBox = styled(Box)({
	display: 'flex',
	justifyContent: 'space-around',
	width: '50px',
	alignSelf: 'end',
});
const QuestionActionsBoxOuter = styled(Box)({
	justifyContent: 'flex-end',
	display: 'flex',
	alignItems: 'start',
	flexDirection: 'column',
});
const QuestionActionButtons = styled(Box)({
	justifyContent: 'center',
	display: 'flex',
	width: '100%',
	marginTop: '70px',
});

const QuestionActions: React.FC<QuestionActionsProps> = ({
	onOpenDelete,
	onSaveQue,
	onCloneQue,
	label,
	index,
}) => {
	const handleOpenDelete = () => {
		onOpenDelete();
	};
	const classes = useStyles();

	const handleSave = () => {
		onSaveQue(index);
	};

	const handleQueClone = () => {
		onCloneQue();
	};

	return (
		<QuestionActionsBoxOuter data-testid="question-actions">
			<QuestionActionsBox>
				<ContentCopy
					onClick={handleQueClone}
					data-testid={`clone-button-${index}`}
				/>
				<Delete
					onClick={handleOpenDelete}
					data-testid={`delete-button-${index}`}
				/>
			</QuestionActionsBox>
			<QuestionActionButtons>
				<Button
					className={classes.saveBtn}
					variant="outlined"
					color="secondary"
					onClick={handleSave}
					data-testid={`save-button-${index}`}
				>
					{label}
				</Button>
			</QuestionActionButtons>
		</QuestionActionsBoxOuter>
	);
};

export default QuestionActions;
