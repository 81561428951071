import React, { useEffect } from 'react';
import { AlertColor, Button, styled } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	exportUsersAsList,
	exportUsersListExcelData,
	exportUsersListResponse,
	setExportUsersListExcelData,
	selectedAudienceCount,
	surveyQuestionnaireData,
	batchReportRequestResponse,
	batchReportRequest,
	setBatchReportRequestResponse,
} from '../../redux/SurveySlice';
import { questionsLabels } from '../../utils/QuestionsLabels';
import {
	API_STATUS_CONSTANT,
	AUDIENCE_CONSTANT,
} from '../../constants/StepConstants';
import {
	commonLabels,
	notificationsLabels,
	questionnaireResultsLabels,
	uploadExcelLabels,
} from '../../utils/CommonLabels';
import { Numbers } from '../../utils/Enum';
import StyledTooltip from '../StyledTooltip';
import GenericConfirmationPopup from './GenericConfirmationPopup';
import Toaster from './Toaster';

const StyledMsgBody = styled('div')({
	fontWeight: '700',
	fontSize: '25px',
	fontFamily: 'JohnsonDisplay-Bold',
	lineHeight: '32px',
});
interface ExportButtonProps {
	filename: string;
	onMaxDataExport: () => void;
	dataSavedAsDraft: boolean;
}

// It Takes two props data which is populated in ExcelSheet and fileName will be the name of downloaded Excel
const ExportExcelButton: React.FC<ExportButtonProps> = ({
	filename,
	onMaxDataExport,
	dataSavedAsDraft = false,
}) => {
	const dispatch = useAppDispatch();
	const [showToaster, setShowToaster] = React.useState(false);
	const [toasterType, setToasterType] = React.useState<AlertColor | undefined>(
		'info'
	);
	const [toasterMsg, setToasterMsg] = React.useState<string>(
		questionnaireResultsLabels.downloadInProgress
	);
	const [showPopup, setShowPopup] = React.useState(false);
	const [showLargeLimitWarning, setLargeLimitWarning] = React.useState(false);
	const exportUsersListRepnse = useAppSelector(exportUsersListResponse);
	const batchReportRequestResponseStatus = useAppSelector(
		batchReportRequestResponse
	);
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const exportUsersListExcelInfo = useAppSelector(exportUsersListExcelData);
	const selectedSurveyAudienceCount = useAppSelector(selectedAudienceCount);
	const modifiedFilterRequestPayload = {
		includeClause: surveyQuestionnaireInfo?.includeClause,
		excludeClause: surveyQuestionnaireInfo?.excludeClause,
	};
	useEffect(() => {
		if (
			exportUsersListRepnse === AUDIENCE_CONSTANT.SUCCESS &&
			exportUsersListExcelInfo
		) {
			handleExportClick(exportUsersListExcelInfo as Blob);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [exportUsersListRepnse, exportUsersListExcelInfo]);

	useEffect(() => {
		setShowToaster(false);
		if (batchReportRequestResponseStatus === API_STATUS_CONSTANT.SUCCESS) {
			setToasterType('success');
			setToasterMsg(uploadExcelLabels.audienceMaxRowsSuccessMessage);
			setShowToaster(true);
		} else if (
			batchReportRequestResponseStatus === API_STATUS_CONSTANT.FAILED
		) {
			setToasterType('error');
			setToasterMsg(notificationsLabels.networkError);
			setShowToaster(false);
		} else {
			setShowToaster(false);
		}
		setTimeout(() => {
			dispatch(setBatchReportRequestResponse('idle'));
		}, Numbers.oneThousandFiveHundred);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [batchReportRequestResponseStatus]);

	const retrieveData = () => {
		if (
			selectedSurveyAudienceCount &&
			selectedSurveyAudienceCount > Numbers.tenThousand &&
			selectedSurveyAudienceCount < Numbers.oneLakh
		) {
			setLargeLimitWarning(true);
		} else if (
			selectedSurveyAudienceCount &&
			selectedSurveyAudienceCount > Numbers.oneLakh
		) {
			setShowPopup(true);
		} else {
			setShowToaster(true);
			dispatch(exportUsersAsList(modifiedFilterRequestPayload));
		}
	};

	const handleLargeDataExport = () => {
		handleLargeDataClosePopup();
		setShowToaster(true);
		dispatch(exportUsersAsList(modifiedFilterRequestPayload));
	};

	useEffect(() => {
		if (dataSavedAsDraft) {
			handleMaxDataExport();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dataSavedAsDraft]);

	const handleMaxDataExport = () => {
		handleClosePopup();
		if (surveyQuestionnaireInfo?.surveyFormsDetails?.id) {
			const maxDataExportPayload = {
				id: surveyQuestionnaireInfo?.surveyFormsDetails?.id,
				reporttype: uploadExcelLabels.userExport,
			};
			setToasterType('info');
			setToasterMsg(questionnaireResultsLabels.downloadInProgress);
			setShowToaster(true);
			dispatch(batchReportRequest(maxDataExportPayload));
		} else {
			setToasterType('error');
			setToasterMsg(notificationsLabels.networkError);
			setShowToaster(true);
		}
	};

	const handleLargeDataClosePopup = () => {
		setLargeLimitWarning(false);
	};

	const handleClosePopup = () => {
		setShowPopup(false);
	};

	const handleCloseToast = () => {
		setShowToaster(false);
	};

	const handleExportClick = (excelData: Blob) => {
		const blob = new Blob([excelData], {
			type: 'application/octet-stream',
		});
		const downloadLink = document.createElement('a');
		downloadLink.href = URL.createObjectURL(blob);
		downloadLink.download = `${filename}.csv`;
		downloadLink.click();
		dispatch(setExportUsersListExcelData(null));
	};

	return (
		<>
			<Toaster
				onClose={handleCloseToast}
				hideDuration={Numbers.fiveThousand}
				message={toasterMsg}
				severity={toasterType}
				show={showToaster}
			></Toaster>
			<StyledTooltip
				title={questionsLabels.audienceExportTooltip}
				placement="top"
			>
				<Button
					variant="outlined"
					color="primary"
					onClick={retrieveData}
					disabled={showToaster || selectedSurveyAudienceCount === 0}
				>
					{questionsLabels.exportUserList}
				</Button>
			</StyledTooltip>
			<GenericConfirmationPopup
				open={showPopup}
				msgBody={
					<StyledMsgBody>
						{uploadExcelLabels.audienceMaxRowsMessage}
					</StyledMsgBody>
				}
				title={uploadExcelLabels.audienceMaxRowsTitle}
				rightBtnText={commonLabels.ok}
				leftBtnText={commonLabels.no}
				isRightBtnVisible={true}
				isLeftBtnVisible={false}
				rightBtnHandler={onMaxDataExport}
				leftBtnHandler={handleClosePopup}
			/>
			<GenericConfirmationPopup
				open={showLargeLimitWarning}
				msgBody={uploadExcelLabels.audienceLargeRowsMessage}
				title=""
				rightBtnText={commonLabels.yes}
				leftBtnText={commonLabels.no}
				isRightBtnVisible={true}
				isLeftBtnVisible={true}
				rightBtnHandler={handleLargeDataExport}
				leftBtnHandler={handleLargeDataClosePopup}
			/>
		</>
	);
};

export default ExportExcelButton;
