import React, {
	useState,
	SyntheticEvent,
	FocusEvent,
	useEffect,
	useRef,
	useMemo,
} from 'react';
import { Autocomplete, TextField, IconButton, debounce } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { SxProps } from '@mui/system';
import { Numbers } from './Enum';
import { commonLabels } from './CommonLabels';
import { validateInputField } from './CommonMethods';

interface GenericAutocompleteProps {
	options: string[];
	placeholder: string;
	heading?: string;
	id: string;
	onItemChange: (wwids: string[]) => void;
	showSelected: boolean;
	sx?: SxProps;
	defaultValue: string[];
	isAsync?: boolean;
	loading?: boolean;
	onItemAsyncChange?: (wwids: string[]) => void;
}

const CustomAutocomplete = styled(
	Autocomplete<string, boolean, boolean, boolean>
)({
	'& .MuiAutocomplete-popupIndicator': {
		display: 'none',
	},
	'& .MuiAutocomplete-clearIndicator': {
		display: 'block',
		alignItems: 'center',
	},
	'& .MuiAutocomplete-endAdornment': {
		top: '60%',
	},
});

export const GenericAutocomplete: React.FC<GenericAutocompleteProps> = ({
	options,
	placeholder,
	heading,
	id,
	onItemChange,
	showSelected,
	sx,
	defaultValue,
	isAsync = false,
	loading = false,
	onItemAsyncChange,
}) => {
	const [selectedItem, setSelectedItems] = useState<string[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [inputValue, setInputValue] = useState<string>('');
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const customAutocompleteFilter = useRef<HTMLDivElement>(null);
	const getPlaceholderText = () => {
		if (selectedItem.length > 0) {
			return showSelected
				? `${selectedItem.length} selected`
				: `${selectedItem.length} search`;
		}
		return showSelected ? '0 selected' : placeholder;
	};

	const triggerItemAsyncChange = useMemo(
		() =>
			debounce((request) => {
				onItemAsyncChange && onItemAsyncChange([...request.input]);
			}, Numbers.fourHundred),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(() => {
		let active = true;
		if (!isAsync || inputValue === '') {
			return undefined;
		}
		isAsync && active && triggerItemAsyncChange({ input: inputValue });
		return () => {
			active = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAsync, inputValue, triggerItemAsyncChange]);

	const handleInputChange = (
		_event: SyntheticEvent<Element, Event>,
		value: string
	) => {
		setInputValue(value);
		setError(null);
		if (!validateInputField(value)) {
			setError(commonLabels.errorMessage);
			setIsDropdownOpen(false);
		} else {
			setError(null);
			setIsDropdownOpen(true);
		}
	};

	const handleChange = (
		_event: SyntheticEvent<Element, Event>,
		value: string | string[] | null
	) => {
		if (Array.isArray(value)) {
			setSelectedItems(value);
			onItemChange(value);
			setIsDropdownOpen(false);
		}
	};

	const handleDelete = (optionToDelete: string) => {
		const foundOption = selectedItem.find(
			(option) => option === optionToDelete
		);
		if (foundOption) {
			setSelectedItems((prevSelected) =>
				prevSelected.filter((option) => option !== optionToDelete)
			);
			onItemChange(selectedItem.filter((option) => option !== optionToDelete));
		}
	};

	const handleFocus = () => {
		setIsDropdownOpen(true);
	};

	const handleBlur = (event: FocusEvent) => {
		if (!event.currentTarget.contains(event.relatedTarget)) {
			setIsDropdownOpen(false);
		}
	};

	useEffect(() => {
		if (defaultValue.length > 0) {
			const defaultValues: string[] = [];
			options.map((item) => {
				const tempItem = item
					?.split('(')
					[Numbers.one]?.split(')')
					[Numbers.zero]?.trim();
				if (tempItem && defaultValue?.find((v) => String(tempItem) === v)) {
					defaultValues.push(item);
				}
			});
			options.length > 0
				? setSelectedItems(defaultValues)
				: setSelectedItems(defaultValue);
		} else {
			setSelectedItems([]);
			setInputValue('');
			setError(null);
			setIsDropdownOpen(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue]);

	return (
		<CustomAutocomplete
			disabled={!isAsync && options.length === 0}
			id={id}
			ref={customAutocompleteFilter}
			loading={loading}
			options={showSelected ? selectedItem : options}
			getOptionLabel={(option: string) => option}
			multiple
			value={selectedItem}
			inputValue={inputValue}
			onChange={handleChange}
			onInputChange={handleInputChange}
			open={isDropdownOpen}
			onFocus={handleFocus}
			onBlur={handleBlur}
			renderOption={(props, option) => (
				<li {...props}>
					{option}
					{showSelected && (
						<IconButton
							size="small"
							onClick={(event) => {
								event.stopPropagation();
								handleDelete(option);
							}}
						>
							<CloseIcon fontSize="small" />
						</IconButton>
					)}
				</li>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					InputProps={{
						...params.InputProps,
						startAdornment: <InputAdornment position="start"></InputAdornment>,
					}}
					placeholder={getPlaceholderText()}
					label={heading}
					variant="standard"
					margin="normal"
					error={!!error}
					helperText={error}
					sx={{
						'& .MuiInput-underline:before': {
							borderBottom: 'none',
						},
						'& .MuiInput-underline:after': {
							borderBottom: 'none',
						},
						'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
							borderBottom: 'none',
						},
						'& .MuiInputBase-root.Mui-focused': {
							border: '2px solid red',
						},
					}}
				/>
			)}
			noOptionsText={loading || error ? '' : commonLabels.noOptions}
			sx={{ ...sx, mr: 2 }}
			clearOnBlur
		/>
	);
};
