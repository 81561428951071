import React from 'react';
import { Box, Grid, Typography, styled } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { previewQuestionnaireLabels } from '../../../utils/CommonLabels';
import { useAppSelector } from '../../../app/Hooks';
import {
	getSurveyDueDate,
	getSurveyDuration,
	getSurveyStartDate,
	surveyQuestionnaireData,
} from '../../../redux/SurveySlice';
import { dueDate, formatDate } from '../../../utils/DateFormatting';

const useStyles = makeStyles({
	dateTypography: {
		fontFamily: '"JohnsonText-Regular"',
		fontWeight: 700,
		lineHeight: '16px',
		fontSize: '14px',
	},
	dateTextTypography: {
		fontFamily: '"JohnsonText-Regular"',
		fontWeight: 500,
		lineHeight: '16px',
		fontSize: '14px',
	},
});

const StyledTypography = styled(Box)`
	margin-bottom: 2px;
	height: 12px;
	display: inline-flex;
	align-items: center;
`;
const StyledNormalTypography = styled(Box)`
	margin-bottom: 2px;
	height: 12px;
	display: inline-flex;
	align-items: center;
`;
const SubHeaderBox = styled(Box)`
	margin-bottom: 0px;
`;

const PreviewQuestionnaireSubHeader: React.FC<unknown> = () => {
	const classes = useStyles();
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const surveyStartDate = useAppSelector(getSurveyStartDate);
	const surveyDueDate = useAppSelector(getSurveyDueDate);
	const surveyDuration = useAppSelector(getSurveyDuration);
	const formattedDueDate = dueDate(
		surveyStartDate,
		surveyDueDate,
		surveyDuration
	);
	const formattedStartDate = formatDate(surveyStartDate);
	return (
		<SubHeaderBox data-testid="preview-sub-header">
			<Grid container xs={12} spacing={1}>
				<Grid item xs={8}>
					<Box>
						<StyledTypography>
							<Typography variant="h6">
								{surveyQuestionnaireInfo?.surveyFormsDetails?.surveyName}
							</Typography>
						</StyledTypography>
					</Box>
				</Grid>
				<Grid item xs={2} textAlign={'right'} justifyContent="right">
					<Box>
						<StyledNormalTypography>
							<Typography
								variant="subtitle2"
								className={classes.dateTypography}
							>
								{previewQuestionnaireLabels.startDate}
							</Typography>
							:
							<Typography
								variant="subtitle1"
								className={classes.dateTextTypography}
							>
								{`  ${formattedStartDate}`}
							</Typography>
						</StyledNormalTypography>
					</Box>
				</Grid>
				<Grid item xs={2} textAlign={'right'}>
					<Box>
						<StyledNormalTypography>
							<Typography
								variant="subtitle2"
								className={classes.dateTypography}
							>
								{previewQuestionnaireLabels.dueDate}
							</Typography>
							:
							<Typography
								variant="subtitle1"
								className={classes.dateTextTypography}
							>
								{`  ${formattedDueDate}`}
							</Typography>
						</StyledNormalTypography>
					</Box>
				</Grid>
			</Grid>
		</SubHeaderBox>
	);
};

export default PreviewQuestionnaireSubHeader;
