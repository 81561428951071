import { Box, Button } from '@mui/material';
import { FC } from 'react';
import DTH_LOGO from '../assets/images/DTH_LOGO.png';
import Info from '../assets/images/Info.svg';
import Reverse from '../assets/images/Reverse.svg';
import { commonLabels } from '../utils/CommonLabels';
import { STEPTheme } from '../utils/Theme';
interface NoAccess {
	header?: string;
	body?: string;
	buttonText?: string;
	isButtonVisible?: boolean;
	clickHandler?: () => void;
}
export const CustomErrorPage: FC<NoAccess> = ({
	header = commonLabels.noAccessPageHeader,
	body = commonLabels.noAccessPageBody,
	buttonText = commonLabels.noAccessPageButtonText,
	isButtonVisible = true,
}) => {
	const navigateToDTH = () => {
		// window.open(process.env.REACT_APP_REDIRECT_URI);
		const redirectUri: Location = process.env
			.REACT_APP_REDIRECT_URI as unknown as Location;
		window.location = redirectUri;
	};
	return (
		<div
			style={{
				display: 'flex',
				flexGrow: '1',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Box
				sx={{
					position: 'fixed',
					top: '110px',
					left: '50%',
					transform: 'translateX(-50%)',
					zIndex: -1000,
				}}
			>
				<img
					src={DTH_LOGO}
					alt="DTH Logo water mark"
					style={{ height: '404px', width: '558px', zIndex: -10 }}
				/>
			</Box>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<img src={Info} alt="Info Icon" />
				<div
					style={{
						lineHeight: '50px',
						fontSize: '40px',
						fontFamily: '"JohnsonText-Bold, sans-serif"',
						fontWeight: 'bold',
					}}
				>
					{header}
				</div>
				<div
					className="text-[15px] pt-3 leading-[50px] text-justify font-jnjTRegular"
					style={{
						fontSize: '15px',
						lineHeight: '50px',
						fontFamily: '"JohnsonText-Regular, sans-serif"',
					}}
				>
					{body}
				</div>
				{isButtonVisible && (
					<div
						className="px-[10%] pt-70"
						style={{
							paddingTop: '20px',
						}}
					>
						<Button
							sx={{
								color: STEPTheme.colors.white,
								bgcolor: STEPTheme.colors.primary,
								borderRadius: '10px',
								fontFamily: '"JohnsonText-Medium"',
								paddingLeft: '10px',
								paddingRight: '10px',
								'&:hover': {
									backgroundColor: STEPTheme.colors.primary,
									color: STEPTheme.colors.white,
									fontFamily: '"JohnsonText-Medium"',
								},
							}}
							onClick={navigateToDTH}
						>
							{buttonText}
							<img
								src={Reverse}
								alt="Reverse Icon"
								style={{ paddingLeft: '10px' }}
							/>
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};
