import { styled } from '@mui/material/styles';
import {
	Button,
	InputLabel,
	Select,
	MenuItem,
	FormControl,
	Box,
	Checkbox,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { STEPTheme } from '../../utils/Theme';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import { setIsLastScreenEndUser } from '../../redux/SurveySlice';
import { Numbers } from '../../utils/Enum';
import {
	API_STATUS_CONSTANT,
	BASIC_CONSTANT,
	QUECONSTANT,
	VALIDATIONS_RULES,
} from '../../constants/StepConstants';
import GenericConfirmationPopup from '../common/GenericConfirmationPopup';
import {
	ValidationErrorMsgs,
	commonLabels,
	notificationsLabels,
	questionnaireResultsLabels,
} from '../../utils/CommonLabels';
import {
	AnswerObj,
	AudienceObj,
	EndUserQuestionOptions,
	EndUserQuestions,
	UpdateMyQuestionnairesObj,
	UpdateMyQuestionnairesPayload,
} from '../../types/CommonTypes';
import { questionsLabels } from '../../utils/QuestionsLabels';
import {
	myQuestionnaireAPILoadingStatus,
	questionnaireData,
	setUpdateMyQuestionnairesAPIStatus,
	updateMyQuestionnairesData,
	users,
} from '../../redux/QuestionnarieUserSlice';
import Loader from '../Loader';
import { AppDispatch } from '../../app/Store';
import Toaster from '../common/Toaster';
import {
	breakPointBetween_0_1300,
	breakPointDown_601,
	breakPointDown_1025,
} from './common/makeStyles';

const QuestionBox = styled(Box)({
	borderTop: `1px solid ${STEPTheme.colors.border}`,
});

const QuestionDetailsBox = styled(Box)({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
	paddingBottom: '10px',
	alignItems: 'end',
	marginLeft: '10px',
});
const ActionButton = styled(Button)({
	width: '120px',
	color: STEPTheme.colors.black,
	background: STEPTheme.colors.white,
	padding: '8px 16px',
	borderRadius: '8px',
	justify: 'space-between',
	margin: '5px',
	fontSize: '14px',
	fontWeight: '500',
	lineHeight: '16px',
	border: `1px solid ${STEPTheme.colors.text}`,
	textTransform: 'none',
	'&:hover': {
		width: '123px',
	},
	'&.Mui-disabled': {
		background: '#eaeaea',
		color: '#c0c0c0',
		border: 'none',
	},
	[breakPointBetween_0_1300]: {
		width: '117px',
		height: '24px',
		'&:hover': {
			width: '117px',
		},
	},
});
const ResetButton = styled(Button)({
	width: '120px',
	height: '32px',
	color: STEPTheme.colors.white,
	background: 'red',
	padding: '8px 16px',
	borderRadius: '8px',
	justify: 'space-between',
	margin: '5px',
	border: `1px solid ${STEPTheme.colors.text}`,
	fontSize: '14px',
	fontWeight: '500',
	lineHeight: '16px',
	textTransform: 'none',
	'&:hover': {
		width: '123px',
		color: STEPTheme.colors.white,
		background: 'red',
	},
	'&.Mui-disabled': {
		background: '#eaeaea',
		color: '#c0c0c0',
		border: 'none',
	},
	[breakPointBetween_0_1300]: {
		width: '117px',
		height: '24px',
		'&:hover': {
			width: '117px',
		},
	},
});
const TextContainer = styled('div')({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '14px',
	fontWeight: '500',
	lineHeight: '16px',
	letterSpacing: '0px',
	background: `${STEPTheme.colors.white}`,
	padding: '10px',
	margin: '10px 10px 0 0',
});
const ActionButtonContainer = styled('div')({
	display: 'flex',
	marginLeft: 'auto',
});

const QuessionariesForLabel = styled(FormControlLabel)({
	'& .MuiTypography-root': {
		fontWeight: 'normal',
	},
});

const InputLabelStyle = styled(InputLabel)({
	'&.Mui-focused': {
		opacity: 0,
	},
	'&.MuiInputLabel-shrink': {
		opacity: 0,
	},
});

const StyledTextField = styled(TextField)({
	maxWidth: '650px',
	[breakPointDown_1025]: {
		width: '50%',
	},
	[breakPointDown_601]: {
		width: '98%',
	},
});

const StyledFormControl = styled(FormControl)({
	m: 1,
	width: '650px',
	marginLeft: '0',
	[breakPointDown_1025]: {
		width: '50%',
	},
	[breakPointDown_601]: {
		width: '98%',
	},
});

const QuestionCW = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const questionData = useAppSelector(questionnaireData);
	let questions: EndUserQuestions[] = [];

	if (questionData?.data?.questionJSONString) {
		try {
			questions = JSON.parse(questionData.data.questionJSONString);
		} catch (e) {
			questions = [];
		}
	}
	const selectedUsers = useAppSelector(users);
	let { id: questionnaireId } = useParams();
	questionnaireId = questionnaireId?.toUpperCase();
	const [submittedQuestionData, setSubmittedQuestionData] = useState<
		EndUserQuestions[]
	>([]);
	const [allQuestionList, setAllQuestionList] = useState<EndUserQuestions[]>(
		[]
	);
	const [submitButtonClick, setSubmitButtonClick] = useState(false);
	const [open, setOpen] = useState(true);
	const [openEndedError, setOpenEndedError] = useState('');
	const [openToast, setOpenToast] = useState(false);
	const [isInScope, setIsInScope] = useState(false);
	const [showLoader, setLoaderOpen] = useState(false);
	const [apiStatusMsg, setApiStatusMsg] = useState('');
	const [toastType, setToastType] = useState('');
	const [isSelf, setIsSelf] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const description =
		questionnaireResultsLabels.descriptionScope +
		questionData?.data?.questionnaireName;
	const handleClosePopup = () => {
		setOpen(false);
	};

	const updateMyQuestionnairesAPIStatus = useAppSelector(
		myQuestionnaireAPILoadingStatus
	);
	useEffect(() => {
		let questionString = '';
		if (questionData?.data?.questionJSONString) {
			questionString = questionData?.data?.questionJSONString;
		}
		// merge 2 properties in one to fix sonar max parameter allowed issue
		const statusAndString = {
			status: updateMyQuestionnairesAPIStatus,
			queString: questionString,
			dispatchObj: dispatch,
			isSelfUser: isSelf,
			navigate,
		};
		checkMyQuestionnaireStatus(
			statusAndString,
			setLoaderOpen,
			setOpenToast,
			setApiStatusMsg,
			setToastType,
			setIsInScope,
			setSubmittedQuestionData
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateMyQuestionnairesAPIStatus]);

	useEffect(() => {
		if (
			inputValue &&
			!VALIDATIONS_RULES.VALIDATION_OPEN_ENDED_END_USER.test(inputValue)
		) {
			setOpenEndedError(
				ValidationErrorMsgs.incorrectQuesTypeOpenEndUserFormatMsg
			);
		} else {
			setOpenEndedError('');
		}
	}, [inputValue]);
	const getAnswers = () => {
		const answers: AnswerObj[] = [];

		for (const answer of submittedQuestionData) {
			let sortedAnswer = answer.value;
			if (answer.questionType === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT) {
				const question = questions.find(
					(q: EndUserQuestions) => q.questionId === answer.questionId
				);
				if (question && question.options && answer.value) {
					const parsedValue = JSON.parse(answer.value as string);
					const optionsOrder = question.options.map(
						(option: EndUserQuestionOptions) => option.options
					);
					sortedAnswer = parsedValue.sort(
						(a: string, b: string) =>
							optionsOrder.indexOf(a) - optionsOrder.indexOf(b)
					);
				}
			}
			answers.push({
				questionId: `${questionnaireId}_${answer.questionId}`,
				questionType: answer.questionType,
				answer:
					answer.questionType === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT
						? sortedAnswer
						: answer.value,
				question: answer.question,
			});
		}
		return JSON.stringify(answers);
	};

	const getAudiences = () => {
		const audiences: AudienceObj = {};
		if (isSelf) {
			audiences.inScope = [];
			audiences.outScope = [];
		} else if (isInScope) {
			audiences.inScope = [];
			for (const user of selectedUsers) {
				audiences.inScope.push(Number(user));
			}
		} else {
			audiences.outScope = [];
			for (const user of selectedUsers) {
				audiences.outScope.push(Number(user));
			}
		}
		return audiences;
	};

	const handlePopupSubmit = () => {
		const updateMyQuestionnairesObj: UpdateMyQuestionnairesObj = {
			questionnaireId: questionnaireId ? questionnaireId : '',
			questionnaireCode: questionData?.data?.questionnaireCode,
			targetAudienceCollection: getAudiences(),
			responseJSON: getAnswers(),
		};
		const payload: UpdateMyQuestionnairesPayload = {
			updateMyQuestionnairesObj: updateMyQuestionnairesObj,
		};
		dispatch(updateMyQuestionnairesData(payload));
		setSubmitButtonClick(false);
		setLoaderOpen(true);
	};
	const handleSubmit = () => {
		setSubmitButtonClick(true);
		setOpen(true);
	};
	useEffect(() => {
		if (questionData?.data?.questionJSONString) {
			const data = JSON.parse(questionData.data.questionJSONString);
			setAllQuestionList(data);
			setSubmittedQuestionData([data[Numbers.zero]]);
		}
		if (questionData?.data?.targetAudience === BASIC_CONSTANT.SELF) {
			setIsSelf(true);
		}
	}, [questionData]);

	const handleCancel = (index: number) => {
		const array = [...submittedQuestionData];
		if (index !== 0) {
			array.splice(index, 1);
			setSubmittedQuestionData(array);
		}
		setOpenEndedError('');
	};
	const handleValueChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		index: number,
		type?: string
	) => {
		const array = [...submittedQuestionData];
		let values: string[] = [];

		if (array[index].value && type === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT) {
			try {
				values = JSON.parse(array[index].value as string);
			} catch (e) {
				values = [];
			}
		}

		if (type === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT) {
			if (event.target.checked) {
				values.push(event.target.value);
			} else {
				values = values.filter((value) => value !== event.target.value);
			}
			array[index].value = JSON.stringify(values);
		} else {
			array[index].value = event.target.value;
		}
		setSubmittedQuestionData(array);
	};

	const checkForNextQuestion = (row: EndUserQuestions) => {
		const values = row.value ? row.value : '';
		if (row.questionType === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT) {
			let hasEndQuestionnaire = false;
			values?.split(',')?.map((option: string) => {
				const selectedOptionConditionObject = row.options.find(
					(selectedOptionObj: EndUserQuestionOptions) =>
						selectedOptionObj?.options === option
				)?.condition;
				if (
					selectedOptionConditionObject === questionsLabels.endQuestionnaire
				) {
					hasEndQuestionnaire = true;
				}
			});
			return hasEndQuestionnaire;
		} else {
			const queIndex = row.options.findIndex(
				(x: EndUserQuestionOptions) =>
					x.options === values &&
					x?.condition === questionsLabels.endQuestionnaire
			);
			if (queIndex !== -1) {
				return true;
			}
			return false;
		}
	};

	const checkForNextQuestionIndex = (row: EndUserQuestions) => {
		const values = row.value ? row.value : '';
		const queIndex = row.options.findIndex(
			(x: EndUserQuestionOptions) =>
				x.options === values &&
				x?.condition?.includes(questionsLabels.continueTo)
		);

		if (queIndex !== -1) {
			return row.options[queIndex].condition;
		}
		return '';
	};

	const handleAddQuestion = (index: number, row: EndUserQuestions) => {
		if (row.questionType === QUECONSTANT.QUESTION_TYPE_OPEN) {
			if (
				row.value &&
				!VALIDATIONS_RULES.VALIDATION_OPEN_ENDED_END_USER.test(row.value)
			) {
				setOpenEndedError(
					ValidationErrorMsgs.incorrectQuesTypeOpenEndUserFormatMsg
				);
				return;
			} else {
				setOpenEndedError('');
			}
		}
		if (
			submittedQuestionData.length === allQuestionList.length ||
			checkForNextQuestion(row)
		) {
			handleSubmit();
		} else {
			let nextIndex = index + Numbers.one;
			const condition = checkForNextQuestionIndex(row);
			if (condition) {
				nextIndex =
					Number(condition.replace(questionsLabels.continueTo, '')) - 1;
			}
			allQuestionList[nextIndex].value = '';
			setSubmittedQuestionData([
				...submittedQuestionData,
				allQuestionList[nextIndex],
			]);
		}
	};

	const checkForYesNoQuestionSubmit = (
		questionIndex: number,
		questionRow: EndUserQuestions,
		isInScopeFlag: boolean,
		message: string,
		answer: string
	) => {
		if (questionIndex === 0) {
			setIsInScope(isInScopeFlag);
		}
		handleValueChange(
			{
				target: { value: answer },
			} as React.ChangeEvent<HTMLInputElement>,
			questionIndex
		);

		handleAddQuestion(questionIndex, questionRow);
	};
	const QuestionAction = (
		actionBtnText: string,
		cancelBtnText: string,
		row: EndUserQuestions,
		index: number
	) => (
		<ActionButtonContainer>
			{row.questionType === QUECONSTANT.QUESTION_TYPE_YES_NO && (
				<ActionButton
					onClick={() => {
						checkForYesNoQuestionSubmit(
							index,
							row,
							true,
							questionnaireResultsLabels.titleInScope,
							row.options[Numbers.zero].options
						);
					}}
					disabled={submittedQuestionData.length > index + Numbers.one}
				>
					{actionBtnText}
				</ActionButton>
			)}

			{row.questionType === QUECONSTANT.QUESTION_TYPE_YES_NO && (
				<ResetButton
					onClick={() => {
						checkForYesNoQuestionSubmit(
							index,
							row,
							false,
							questionnaireResultsLabels.titleOutOfScope,
							row.options[Numbers.one].options
						);
					}}
					disabled={submittedQuestionData.length > index + Numbers.one}
				>
					{cancelBtnText}
				</ResetButton>
			)}
			{row.questionType !== QUECONSTANT.QUESTION_TYPE_YES_NO && (
				<ActionButton
					onClick={() => {
						handleCancel(index);
					}}
					disabled={submittedQuestionData.length > index + Numbers.one}
				>
					{cancelBtnText}
				</ActionButton>
			)}
			{row.questionType !== QUECONSTANT.QUESTION_TYPE_YES_NO && (
				<ResetButton
					onClick={() => handleAddQuestion(index, row)}
					disabled={
						submittedQuestionData.length > index + Numbers.one ||
						(!row.value &&
							row.questionType !== QUECONSTANT.QUESTION_TYPE_YES_NO)
					}
				>
					{actionBtnText}
				</ResetButton>
			)}
		</ActionButtonContainer>
	);
	const handleCloseToast = () => {
		setOpenToast(false);
	};

	return (
		<Box>
			<Loader isLoading={showLoader} />
			<Toaster
				onClose={handleCloseToast}
				hideDuration={Numbers.twoThousand}
				message={apiStatusMsg}
				severity={
					toastType === API_STATUS_CONSTANT.SUCCESS ? 'success' : 'error'
				}
				show={openToast}
			></Toaster>
			{(isSelf || selectedUsers?.length > 0) &&
				submittedQuestionData?.map((row: EndUserQuestions, index: number) => (
					<QuestionBox key={row.questionId}>
						<TextContainer>{row.question} </TextContainer>
						{row.questionType === QUECONSTANT.QUESTION_TYPE_YES_NO && (
							<QuestionDetailsBox>
								<Box></Box>
								<Box>
									{QuestionAction(
										row.options[Numbers.zero].options,
										row.options[Numbers.one].options,
										row,
										index
									)}
								</Box>
							</QuestionDetailsBox>
						)}
						{row.questionType === QUECONSTANT.QUESTION_TYPE_DROPDOWN && (
							<QuestionDetailsBox>
								<StyledFormControl variant="standard">
									<InputLabelStyle id="demo-simple-select-standard-label">
										{commonLabels.selectReason}
									</InputLabelStyle>
									<Select
										labelId="demo-simple-select-standard-label"
										id="demo-simple-select-standard"
										value={row.value}
										disabled={
											submittedQuestionData.length > index + Numbers.one
										}
										onChange={(event) => {
											handleValueChange(
												event as React.ChangeEvent<HTMLInputElement>,
												index,
												row.questionType
											);
										}}
										label="Select"
										MenuProps={{
											PaperProps: {
												sx: {
													maxHeight: Numbers.fourtyEight * Numbers.five,
												},
											},
										}}
									>
										{row.options.map((option: EndUserQuestionOptions) => (
											<MenuItem key={option.options} value={option.options}>
												{option.options}
											</MenuItem>
										))}
									</Select>
								</StyledFormControl>
								{QuestionAction(
									commonLabels.submit,
									commonLabels.cancel,
									row,
									index
								)}
							</QuestionDetailsBox>
						)}
						{row.questionType === QUECONSTANT.QUESTION_TYPE_OPEN && (
							<QuestionDetailsBox>
								<StyledTextField
									margin="dense"
									placeholder={commonLabels.enterTxt}
									id="name"
									type="text"
									fullWidth
									disabled={submittedQuestionData.length > index + Numbers.one}
									value={row.value}
									variant="standard"
									inputProps={{
										maxLength: Numbers.twoFifty,
									}}
									onKeyPress={(event) => {
										const inputTextValue = (event.target as HTMLInputElement)
											.value;
										if (event.key === ' ' && !inputTextValue) {
											event.preventDefault();
										}
									}}
									error={!!openEndedError}
									helperText={openEndedError}
									onChange={(event) => {
										handleValueChange(
											event as React.ChangeEvent<HTMLInputElement>,
											index,
											row.questionType
										);
										setInputValue(event.target.value);
									}}
								/>
								{QuestionAction(
									commonLabels.submit,
									commonLabels.cancel,
									row,
									index
								)}
							</QuestionDetailsBox>
						)}
						{row.questionType === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT && (
							<QuestionDetailsBox>
								<Box>
									{row.options.map((option: EndUserQuestionOptions) => (
										<QuessionariesForLabel
											key={option.options}
											control={
												<Checkbox
													disabled={
														submittedQuestionData.length > index + Numbers.one
													}
												/>
											}
											value={option.options}
											label={option.options}
											onChange={(event) => {
												handleValueChange(
													event as React.ChangeEvent<HTMLInputElement>,
													index,
													row.questionType
												);
											}}
										/>
									))}
								</Box>
								{QuestionAction(
									commonLabels.submit,
									commonLabels.cancel,
									row,
									index
								)}
							</QuestionDetailsBox>
						)}
						{row.questionType === QUECONSTANT.QUESTION_TYPE_MULTIPLE_OPTION && (
							<QuestionDetailsBox>
								<RadioGroup
									value={row.value}
									onChange={(event) => {
										handleValueChange(event, index, row.questionType);
									}}
								>
									{row.options.map((option: EndUserQuestionOptions) => (
										<QuessionariesForLabel
											key={option.options}
											control={
												<Radio
													disabled={
														submittedQuestionData.length > index + Numbers.one
													}
												/>
											}
											value={option.options}
											label={option.options}
										/>
									))}
								</RadioGroup>
								{QuestionAction(
									commonLabels.submit,
									commonLabels.cancel,
									row,
									index
								)}
							</QuestionDetailsBox>
						)}
					</QuestionBox>
				))}
			{submitButtonClick && (
				<GenericConfirmationPopup
					open={open}
					msgBody={description}
					title={''}
					rightBtnText={commonLabels.submit}
					leftBtnText={commonLabels.cancel}
					isRightBtnVisible={true}
					isLeftBtnVisible={true}
					rightBtnHandler={handlePopupSubmit}
					leftBtnHandler={handleClosePopup}
				/>
			)}
		</Box>
	);
};
export default QuestionCW;

const checkMyQuestionnaireStatus = (
	statusAndString: {
		status: string;
		queString: string;
		dispatchObj: AppDispatch;
		isSelfUser: boolean;
		navigate: NavigateFunction;
	},
	setLoaderOpen: Dispatch<SetStateAction<boolean>>,
	setOpenToast: Dispatch<SetStateAction<boolean>>,
	setApiStatusMsg: Dispatch<SetStateAction<string>>,
	setToastType: Dispatch<SetStateAction<string>>,
	setIsInScope: Dispatch<SetStateAction<boolean>>,
	setSubmittedQuestionData: Dispatch<SetStateAction<EndUserQuestions[]>>
) => {
	if (statusAndString.status === API_STATUS_CONSTANT.SUCCESS) {
		setApiStatusMsg(questionnaireResultsLabels.recordSubmittedMsg);
		setToastType(API_STATUS_CONSTANT.SUCCESS);
		setLoaderOpen(false);
		setOpenToast(true);
		setIsInScope(false);
		if (statusAndString.queString) {
			const data = JSON.parse(statusAndString.queString);
			setSubmittedQuestionData([data[Numbers.zero]]);
		}
		statusAndString.dispatchObj(
			setUpdateMyQuestionnairesAPIStatus(API_STATUS_CONSTANT.IDLE)
		);
		if (statusAndString.isSelfUser) {
			statusAndString.navigate('/');
			statusAndString.dispatchObj(setIsLastScreenEndUser(true));
		}
	}
	if (statusAndString.status === API_STATUS_CONSTANT.FAILED) {
		setApiStatusMsg(notificationsLabels.networkError);
		setToastType(API_STATUS_CONSTANT.ERROR);
		setOpenToast(false);
		setLoaderOpen(false);
	}
};
