import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	Typography,
	styled,
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { STEPTheme } from '../../utils/Theme';
import { Numbers } from '../../utils/Enum';
import { commonLabels } from '../../utils/CommonLabels';

const StyledTitleTypography = styled(Box)`
	margin-bottom: 2px;
	margin-top: 50px;
`;
const StyledSubTitleTypography = styled(Box)`
	margin-bottom: 2px;
`;

const RedirectMsg = styled('div')({
	paddingRight: '25px',
	fontFamily: 'Johnson Text',
	fontSize: '16px',
	fontWeight: '500',
	lineHeight: '16px',
	textAlign: 'center',
});

const ActionButtonFooter = styled(Button)({
	width: '100%',
	height: '32px',
	color: STEPTheme.colors.white,
	background: STEPTheme.colors.primary,
	textTransform: 'capitalize',
	padding: '8px 8px 8px 16px',
	borderRadius: '8px',
	justify: 'space-between',
	border: `1px solid ${STEPTheme.colors.primary}`,
	'&:hover': {
		backgroundColor: STEPTheme.colors.primary,
		borderColor: STEPTheme.colors.primary,
	},
});
const CancelButtonFooter = styled(Button)({
	width: '100%',
	height: '32px',
	color: STEPTheme.colors.primary,
	background: STEPTheme.colors.white,
	textTransform: 'capitalize',
	padding: '8px 8px 8px 16px',
	borderRadius: '8px',
	justify: 'space-between',
	border: `1px solid ${STEPTheme.colors.white}`,
	'&:hover': {
		backgroundColor: STEPTheme.colors.primary,
		borderColor: STEPTheme.colors.primary,
		color: STEPTheme.colors.white,
	},
});
const useStyles = makeStyles({
	subTitleColor: {
		color: STEPTheme.colors.totalLightGray,
	},
	dialogBox: {
		borderRadius: '15px !important',
		maxWidth: '390px',
	},
	dialogTitle: {
		paddingBottom: '0 !important',
		marginBottom: '30px',
	},
	modalContent: {
		paddingTop: '5px !important',
		paddingBottom: '5px !important',
	},
	modalFooter: {
		justifyContent: 'space-around',
		padding: '20px 24px !important',
	},
	modalButton: {
		width: '50%',
	},
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		background: STEPTheme.colors.background,
		justifyContent: 'center',
	},
});
export interface GenericConfirmationPopupProps {
	open: boolean;
	leftBtnHandler: () => void;
	rightBtnHandler: () => void;
	title: string;
	msgBody: string | JSX.Element;
	msgNote?: string;
	additionalMsgNote?: string;
	isLeftBtnVisible: boolean;
	leftBtnText: string | JSX.Element;
	isRightBtnVisible: boolean;
	rightBtnText: string | JSX.Element;
}
const GenericConfirmationPopup: React.FC<GenericConfirmationPopupProps> = ({
	open,
	title,
	msgBody,
	msgNote,
	additionalMsgNote,
	isLeftBtnVisible,
	leftBtnText,
	isRightBtnVisible,
	rightBtnText,
	leftBtnHandler,
	rightBtnHandler,
}) => {
	const handleClose = () => {
		leftBtnHandler();
	};
	const handleSubmit = () => {
		rightBtnHandler();
	};

	const [redirectTimer, setRedirectTimer] = useState(Numbers.five);
	const showRedirectMsg =
		rightBtnText === commonLabels.redirectButtonLabel || false;
	const classes = useStyles();

	useEffect(() => {
		let timerInterval: NodeJS.Timer | undefined;
		if (showRedirectMsg && open) {
			timerInterval = setInterval(() => {
				const newTimer = redirectTimer - Numbers.one;
				setRedirectTimer(newTimer);
			}, Numbers.thousand);
			if (redirectTimer === Numbers.zero) {
				clearInterval(timerInterval);
				// Redirect to DTH after count down complete
				localStorage.clear();
				window.location.replace(`${process.env.REACT_APP_REDIRECT_URI}`);
			}
		}
		return () => {
			clearInterval(timerInterval);
		};
	});
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperProps={{
				component: 'form',
				className: classes.dialogBox,
			}}
			sx={{ overflowWrap: 'break-word' }}
		>
			<DialogTitle className={classes.dialogTitle}>
				<StyledTitleTypography>
					<Typography variant="h1">{title}</Typography>
				</StyledTitleTypography>
				<StyledSubTitleTypography>
					<Typography variant="h3" className={classes.subTitleColor}>
						{msgBody}
					</Typography>
					{msgNote && (
						<Typography variant="h3" className={classes.subTitleColor}>
							{msgNote}
						</Typography>
					)}
					{additionalMsgNote && (
						<Typography variant="h3" className={classes.subTitleColor}>
							{additionalMsgNote}
						</Typography>
					)}
				</StyledSubTitleTypography>
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={handleClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[Numbers.fiveHundred],
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogActions className={classes.modalFooter}>
				{isLeftBtnVisible && (
					<CancelButtonFooter onClick={handleClose}>
						{leftBtnText}
					</CancelButtonFooter>
				)}
				{showRedirectMsg && (
					<RedirectMsg>
						{commonLabels.redirectingMsg + redirectTimer}
					</RedirectMsg>
				)}
				{isRightBtnVisible && (
					<ActionButtonFooter onClick={handleSubmit}>
						{rightBtnText}
					</ActionButtonFooter>
				)}
			</DialogActions>
		</Dialog>
	);
};
export default GenericConfirmationPopup;
