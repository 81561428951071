import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import surveySlice from '../redux/SurveySlice';
import NavBarSlice from '../redux/NavBarSlice';
import QuestionnaireUserSlice from '../redux/QuestionnarieUserSlice';
import questionnaireResultsSlice from '../redux/QuestionnaireResultsSlice';
import QuestionnaireSurveySlice from '../redux/QuestionnaireSurveySlice';
import mappingSlice from '../redux/MappingSlice';
import questionnaireSpecificResultsResponseSlice from '../redux/QuestionnaireSpecificResultsSlice/QuestionnaireSpecificResultsResponseSlice';

export const store = configureStore({
	reducer: {
		survey: surveySlice,
		navbar: NavBarSlice,
		QuestionnaireSurvey: QuestionnaireUserSlice,
		questionnaireResults: questionnaireResultsSlice,
		Questionnaire: QuestionnaireSurveySlice,
		mapping: mappingSlice,
		questionnaireSpecificResultsResponse:
			questionnaireSpecificResultsResponseSlice,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
