import React from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { questionnaireInstructionsDetails } from '../../../redux/SurveySlice';
import { STEPTheme } from '../../../utils/Theme';

const useStyles = makeStyles({
	instructionBox: {
		fontFamily: '"JohnsonText-Regular"',
		fontWeight: 400,
		lineHeight: '16px',
		fontSize: '14px',
	},
});
const MainContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexWrap: 'wrap',
});

const QuestionnaireContainer = styled(Box)({
	display: 'flex',
	backgroundColor: STEPTheme.colors.white,
	flexDirection: 'column',
	marginBottom: '20px',
	boxShadow: '0px 0px 5px rgba(0,0,0,0.2)',
	borderRadius: '10px',
	marginTop: '10px',
	padding: '20px',
	minWidth: '78%',
	maxWidth: '78%',
});
const title = {
	fontFamily: 'JohnsonText-Bold',
	fontSize: '16px',
	fontWeight: '500',
	lineHeight: '16px',
};

const PreviewQuestionnaireInstructionDetails: React.FC<unknown> = () => {
	const classes = useStyles();
	const surveyQuestionnaireInfo: string = useSelector(
		questionnaireInstructionsDetails
	);
	if (surveyQuestionnaireInfo.length > 0) {
		return (
			<MainContainer data-testid="preview-main-container">
				<QuestionnaireContainer>
					<Box sx={title}>Instructions</Box>
					<Box
						className={classes.instructionBox}
						dangerouslySetInnerHTML={{ __html: surveyQuestionnaireInfo }}
					></Box>
				</QuestionnaireContainer>
			</MainContainer>
		);
	}
	return <Box data-testid="preview-main-container"></Box>;
};
export default PreviewQuestionnaireInstructionDetails;
