import { MenuItem, Select, styled } from '@mui/material';
import { STEPTheme } from '../../utils/Theme';
import { IDropDown } from '../../types/CommonTypes';
import { filter } from '../../utils/Filter';

const StyledSelectField = styled(Select)({
	width: '322px',
	height: '40px',
	border: '1px',
	color: STEPTheme.colors.darkGray,
	marginTop: '2px',
	borderRadius: '8px',
	'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: STEPTheme.colors.primary,
	},
	'& .MuiInputBase-input::placeholder': {
		fontFamily: '"JohnsonText-Regular"',
		fontSize: '16px',
		fontWeight: 400,
		lineHeight: '24px',
	},
	'&.Mui-disabled': {
		backgroundColor: `${STEPTheme.colors.disabledButton} !important`,
		borderColor: `${STEPTheme.colors.linkGray} !important`,
	},
});

const CustomDropdown = (props: IDropDown) => {
	const {
		value,
		valueList,
		register,
		registerName,
		validationMessage,
		handleChange,
		width,
		disabled = false,
		defaultText = '',
	} = props;
	return (
		<StyledSelectField
			disabled={disabled}
			value={value}
			placeholder="Select"
			{...(register &&
				registerName &&
				validationMessage && {
					...register(registerName, {
						required: validationMessage,
					}),
				})}
			sx={width ? { width: width } : {}}
			onChange={(event) => {
				handleChange(event as React.ChangeEvent<HTMLInputElement>);
			}}
			displayEmpty
			renderValue={() => value || filter.label.SELECT}
		>
			{defaultText && (
				<MenuItem value={defaultText}>
					<em>{defaultText}</em>
				</MenuItem>
			)}

			{valueList?.map(
				(itemValue: { key: string; value: string }, index: number) => {
					return (
						<MenuItem key={index} value={itemValue.value}>
							{itemValue.key}
						</MenuItem>
					);
				}
			)}
		</StyledSelectField>
	);
};

export default CustomDropdown;
