/**
 * any app specific constants that you don't want to keep in config can go here.
 */
export const messages = {
	common: {
		APP_TITLE: 'STEP',
		NOT_QUESTIONNAIRE_FOUND: 'No Questionnaire Found!',
		NO_USER_FOUND: 'No User Found',
	},
};
