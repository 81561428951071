import { IGraphData } from '../types/CommonTypes';
import {
	IQuestionnaireResultsSummaryCollection,
	IOptionalScopeSummaryCollection,
	IRegionalSummaryCollection,
	ICompletionSummaryCollection,
	IMetricsData,
	IParameter,
} from '../types/QuestionnaireResultsType';
import { questionnaireResultsLabels } from './CommonLabels';

import { Numbers } from './Enum';

export const getQuestionnaireResultByCategory = (
	data: IQuestionnaireResultsSummaryCollection,
	sector: string
) => {
	if (sector === 'All') {
		return data?.all;
	} else if (sector === 'Med Tech') {
		return data?.medical_devices;
	} else if (sector === 'Pharma') {
		return data?.pharmaceuticals;
	} else if (sector === 'Non-Operating') {
		return data?.corporate;
	} else {
		return data?.all;
	}
};

export const getOptionalResultsData = (
	optionalCompletionSummary: IOptionalScopeSummaryCollection,
	dataDisplayType: string
) => {
	let yMaxOptionalResult = 0;
	let asiaPacificInScope = 0;
	let asiaPacificNotAssessed = 0;
	let asiaPacificOutOfScope = 0;
	let emeaInScope = 0;
	let emeaNotAssessed = 0;
	let emeaOutOfScope = 0;
	let northAmericaInScope = 0;
	let latinAmericaInScope = 0;
	let northAmericaNotAssessed = 0;
	let latinAmericaNotAssessed = 0;
	let northAmericaOutOfScope = 0;
	let latinAmericaOutOfScope = 0;

	if (dataDisplayType === 'Percentage') {
		yMaxOptionalResult = Numbers.hundred;
		asiaPacificInScope = getObjPropValue(
			optionalCompletionSummary?.asia_pacific?.inScopeInPercentage
		);
		asiaPacificNotAssessed = getObjPropValue(
			optionalCompletionSummary?.asia_pacific?.notAssessedInPercentage
		);
		asiaPacificOutOfScope = getObjPropValue(
			optionalCompletionSummary?.asia_pacific?.notInScopeInPercentage
		);
		emeaInScope = getObjPropValue(
			optionalCompletionSummary?.emea?.inScopeInPercentage
		);
		emeaNotAssessed = getObjPropValue(
			optionalCompletionSummary?.emea?.notAssessedInPercentage
		);
		emeaOutOfScope = getObjPropValue(
			optionalCompletionSummary?.emea?.notInScopeInPercentage
		);
		northAmericaInScope = getObjPropValue(
			optionalCompletionSummary?.north_america?.inScopeInPercentage
		);
		latinAmericaInScope = getObjPropValue(
			optionalCompletionSummary?.latin_america?.inScopeInPercentage
		);
		northAmericaNotAssessed = getObjPropValue(
			optionalCompletionSummary?.north_america?.notAssessedInPercentage
		);
		latinAmericaNotAssessed = getObjPropValue(
			optionalCompletionSummary?.latin_america?.notAssessedInPercentage
		);
		northAmericaOutOfScope = getObjPropValue(
			optionalCompletionSummary?.north_america?.notInScopeInPercentage
		);
		latinAmericaOutOfScope = getObjPropValue(
			optionalCompletionSummary?.latin_america?.notInScopeInPercentage
		);
	} else {
		yMaxOptionalResult =
			getObjPropValue(optionalCompletionSummary?.emea?.totalCount) +
			getObjPropValue(optionalCompletionSummary?.asia_pacific?.totalCount) +
			getObjPropValue(optionalCompletionSummary?.north_america?.totalCount) +
			Numbers.sixty;
		asiaPacificInScope = getObjPropValue(
			optionalCompletionSummary?.asia_pacific?.inScopeInValue
		);
		asiaPacificNotAssessed = getObjPropValue(
			optionalCompletionSummary?.asia_pacific?.notAssessedInValue
		);
		asiaPacificOutOfScope = getObjPropValue(
			optionalCompletionSummary?.asia_pacific?.notInScopeInValue
		);
		emeaInScope = getObjPropValue(
			optionalCompletionSummary?.emea?.inScopeInValue
		);
		emeaNotAssessed = getObjPropValue(
			optionalCompletionSummary?.emea?.notAssessedInValue
		);
		emeaOutOfScope = getObjPropValue(
			optionalCompletionSummary?.emea?.notInScopeInValue
		);
		northAmericaInScope = getObjPropValue(
			optionalCompletionSummary?.north_america?.inScopeInValue
		);
		latinAmericaInScope = getObjPropValue(
			optionalCompletionSummary?.latin_america?.inScopeInValue
		);
		northAmericaNotAssessed = getObjPropValue(
			optionalCompletionSummary?.north_america?.notAssessedInValue
		);
		latinAmericaNotAssessed = getObjPropValue(
			optionalCompletionSummary?.latin_america?.notAssessedInValue
		);
		northAmericaOutOfScope = getObjPropValue(
			optionalCompletionSummary?.north_america?.notInScopeInValue
		);
		latinAmericaOutOfScope = getObjPropValue(
			optionalCompletionSummary?.latin_america?.notInScopeInValue
		);
	}
	return {
		yMaxOptionalResult,
		asiaPacificInScope,
		asiaPacificNotAssessed,
		asiaPacificOutOfScope,
		emeaInScope,
		emeaNotAssessed,
		emeaOutOfScope,
		northAmericaInScope,
		latinAmericaInScope,
		northAmericaNotAssessed,
		latinAmericaNotAssessed,
		northAmericaOutOfScope,
		latinAmericaOutOfScope,
	};
};

export const getCompletedByRegionData = (
	regionalCompletionSummary: IRegionalSummaryCollection,
	dataDisplayType: string
) => {
	let completedByRegionYMax = 0;
	let totalCount = 0;
	let totalInNorthAmerica = 0;
	let totalInAsiaPacific = 0;
	let totalInEMEA = 0;
	let totalInLatinAmerica = 0;
	let completedInNorthAmerica = 0;
	let completedInLatinAmerica = 0;
	let completedInAsiaPacific = 0;
	let completedInEMEA = 0;

	if (dataDisplayType === 'Percentage') {
		completedByRegionYMax = Numbers.hundred;
		totalCount = getObjPropValue(regionalCompletionSummary?.emea?.totalCount);
		completedInAsiaPacific = getObjPropValue(
			regionalCompletionSummary?.asia_pacific?.respondedInPercentage
		);
		completedInEMEA = getObjPropValue(
			regionalCompletionSummary?.emea?.respondedInPercentage
		);
		completedInNorthAmerica = getObjPropValue(
			regionalCompletionSummary?.north_america?.respondedInPercentage
		);
		completedInLatinAmerica = getObjPropValue(
			regionalCompletionSummary?.latin_america?.respondedInPercentage
		);
		totalInAsiaPacific = getObjPropValue(
			regionalCompletionSummary?.asia_pacific?.notRespondedInPercentage
		);
		totalInEMEA = getObjPropValue(
			regionalCompletionSummary?.emea?.notRespondedInPercentage
		);
		totalInNorthAmerica = getObjPropValue(
			regionalCompletionSummary?.north_america?.notRespondedInPercentage
		);
		totalInLatinAmerica = getObjPropValue(
			regionalCompletionSummary?.latin_america?.notRespondedInPercentage
		);
	} else {
		completedByRegionYMax =
			getObjPropValue(regionalCompletionSummary?.emea?.totalCount) +
			getObjPropValue(regionalCompletionSummary?.asia_pacific?.totalCount) +
			getObjPropValue(regionalCompletionSummary?.north_america?.totalCount) +
			Numbers.sixty;
		totalCount = getObjPropValue(regionalCompletionSummary?.emea?.totalCount);
		completedInAsiaPacific = getObjPropValue(
			regionalCompletionSummary?.asia_pacific?.respondedInNumber
		);
		completedInEMEA = getObjPropValue(
			regionalCompletionSummary?.emea?.respondedInNumber
		);
		completedInNorthAmerica = getObjPropValue(
			regionalCompletionSummary?.north_america?.respondedInNumber
		);
		completedInLatinAmerica = getObjPropValue(
			regionalCompletionSummary?.latin_america?.respondedInNumber
		);
		totalInAsiaPacific = getObjPropValue(
			regionalCompletionSummary?.asia_pacific?.totalCount
		);
		totalInEMEA = getObjPropValue(regionalCompletionSummary?.emea?.totalCount);
		totalInNorthAmerica = getObjPropValue(
			regionalCompletionSummary?.north_america?.totalCount
		);
		totalInLatinAmerica = getObjPropValue(
			regionalCompletionSummary?.latin_america?.totalCount
		);
	}
	return {
		completedByRegionYMax,
		totalCount,
		completedInAsiaPacific,
		completedInEMEA,
		completedInNorthAmerica,
		completedInLatinAmerica,
		totalInAsiaPacific,
		totalInEMEA,
		totalInNorthAmerica,
		totalInLatinAmerica,
	};
};

export const getCompletedOverallData = (
	overallCompletionSummary: ICompletionSummaryCollection,
	dataDisplayType: string
) => {
	let respondedVal = 0;
	let notRespondedVal = 0;
	const totalCount = getObjPropValue(overallCompletionSummary?.totalCount);
	if (dataDisplayType === 'Percentage') {
		respondedVal = getObjPropValue(
			overallCompletionSummary?.respondedInPercentage
		);
		notRespondedVal = getObjPropValue(
			overallCompletionSummary?.notRespondedInPercentage
		);
	} else {
		respondedVal = getObjPropValue(overallCompletionSummary?.respondedInNumber);
		notRespondedVal = getObjPropValue(
			overallCompletionSummary?.notRespondedInNumber
		);
	}
	return { respondedVal, notRespondedVal, totalCount };
};

const getObjPropValue = (optionalCompletionSummaryObjProp: number) => {
	return optionalCompletionSummaryObjProp || Numbers.zero;
};

export const FilterButtonLabel = 'Filters';

/**
 * @method
 * @summary Returns a number which depeicts the responded/not-responded audience-count in percentage or value
 * @param dataDisplayType<string> 'Percentage' || 'Values'
 * @param data<IMetricsData>
 * @param type<string> '' || 'other'
 * @returns Number
 */
export const getStatusData = (
	dataDisplayType: string,
	data: IMetricsData,
	type: string
): number => {
	let statusValue = Numbers.zero;
	const intialValue = Number(data?.impactedEmployees) ?? Numbers.zero;
	const totalValue = Number(data?.totalEmployees) ?? Numbers.zero;
	if (type === '') {
		statusValue =
			dataDisplayType === 'Percentage'
				? Number(intialValue / totalValue) * Numbers.hundred
				: intialValue;
	} else {
		const differenceData = Number(totalValue - intialValue);
		statusValue =
			dataDisplayType === 'Percentage'
				? Number(differenceData / totalValue) * Numbers.hundred
				: differenceData;
	}
	return isNaN(statusValue)
		? Numbers.zero
		: Number(Number(statusValue).toFixed(Numbers.four));
};

// prepare group wise graph data stats
export const getGroupGraphData = (
	groupResponse: IParameter[],
	type: string,
	overallEmployeeCount: number
) => {
	let processedGroupData: IGraphData[] = [];
	let maxCount = Numbers.hundred;
	if (type === questionnaireResultsLabels.dataDisplayTypePercentage) {
		maxCount = Numbers.hundred;
		processedGroupData = groupResponse.map((data: IParameter) => {
			return {
				label: data.parameterName,
				values:
					overallEmployeeCount > Numbers.zero
						? [
								Number(
									(
										(data.parameterValue * Numbers.hundred) /
										overallEmployeeCount
									).toFixed(Numbers.four)
								),
							]
						: [],
			};
		});
	} else {
		maxCount = overallEmployeeCount;
		processedGroupData = groupResponse.map((data: IParameter) => {
			return {
				label: data.parameterName,
				values: [data.parameterValue],
			};
		});
	}

	return [{ processedGroupData, maxCount }];
};
