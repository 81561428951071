import React from 'react';
import { Box, Typography, styled } from '@mui/material';

interface FallbackUIProps {
	fallbackText: string;
}

const SurveyFallbackUIContainer = styled(Box)({
	minHeight: '350px',
	alignItems: 'center',
	justifyContent: 'center',
	display: 'flex',
	background: '#F9F8F7',
	marginBottom: '40px',
	borderRadius: '16px',
	boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
});

const FallbackUI: React.FC<FallbackUIProps> = ({ fallbackText }) => {
	return (
		<SurveyFallbackUIContainer>
			<Typography variant="h1">{fallbackText}</Typography>
		</SurveyFallbackUIContainer>
	);
};

export default FallbackUI;
