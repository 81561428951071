import React from 'react';
import { Box, styled, Typography, Button } from '@mui/material';
import { ReactComponent as AlertIcon } from '../../assets/images/alert-circle.svg';
import { ReactComponent as RefreshIcon } from '../../assets/images/refresh-icon.svg';
import { questionnaireResultsLabels } from '../../utils/CommonLabels';

const ErrorContentContainer = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	flexDirection: 'column',
});
interface ErrorContentProps {
	height?: string;
}

export const ErrorContent: React.FC<ErrorContentProps> = ({
	height = '300px',
}) => {
	const handleRefresh = () => {
		window.location.reload();
	};
	return (
		<ErrorContentContainer sx={{ height: height }}>
			<AlertIcon />
			<Typography variant="h3" sx={{ marginTop: '8px' }}>
				{questionnaireResultsLabels.error}
			</Typography>
			<Typography variant="body2" sx={{ marginTop: '8px' }}>
				{questionnaireResultsLabels.errorFromOurEnd}
			</Typography>
			<Typography variant="body2" sx={{ marginBottom: '8px' }}>
				{questionnaireResultsLabels.refreshHint}
			</Typography>
			<Button
				data-testid="Refresh-Button"
				variant="contained"
				color="primary"
				component="span"
				endIcon={<RefreshIcon />}
				onClick={handleRefresh}
			>
				{questionnaireResultsLabels.refresh}
			</Button>
		</ErrorContentContainer>
	);
};

export default ErrorContent;
