import React from 'react';
import { Box, Button, styled } from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { makeStyles } from '@material-ui/core';
import { STEPTheme } from '../../utils/Theme';
import { commonLabels } from '../../utils/CommonLabels';
import { QuestionFooterProps } from '../../types/CommonTypes';

const useStyles = makeStyles({
	actionBox: {
		display: 'flex',
		justifyContent: 'end',
		marginTop: '5px',
	},
	plusIcon: {
		width: '0.8em',
	},
});
const SaveAsDraftButton = styled(Button)({
	fontFamily: "'JohnsonText-Regular'",
	fontSize: '14px',
	fontWeight: '400',
	lineHeight: '16px',
	width: 'auto',
	height: '32px',
	marginLeft: '25px',
	color: STEPTheme.colors.text,
	textTransform: 'capitalize',
	background: 'white',
	padding: '8px 16px',
	borderRadius: '8px',
	border: `1px solid ${STEPTheme.colors.text}`,
	'&:hover': {
		backgroundColor: 'white',
		borderColor: 'black',
		color: STEPTheme.colors.text,
	},
});
const ActionButton = styled(Button)({
	width: 'auto',
	height: '32px',
	marginLeft: '15px',
	color: STEPTheme.colors.white,
	background: STEPTheme.colors.primary,
	textTransform: 'capitalize',
	padding: '8px 8px 8px 16px',
	borderRadius: '8px',
	justify: 'space-between',
	'&:hover': {
		backgroundColor: STEPTheme.colors.primary,
		borderColor: STEPTheme.colors.primary,
	},
});
const QuestionFooter: React.FC<QuestionFooterProps> = ({
	handleNext,
	handleSaveDraft,
	buttonEnable,
}) => {
	const classes = useStyles();

	return (
		<Box className={classes.actionBox} data-testid="question-footer">
			<SaveAsDraftButton onClick={handleSaveDraft}>
				{commonLabels.saveAsDraft}
			</SaveAsDraftButton>
			<ActionButton
				onClick={handleNext}
				disabled={buttonEnable}
				variant="contained"
				color="primary"
			>
				{commonLabels.saveProceed}
				<ArrowForward className={classes.plusIcon} />
			</ActionButton>
		</Box>
	);
};
export default QuestionFooter;
