export const questionnaireAPIKeys: { [key: string]: string } = {
	reportTypeKeyMetrics: 'keyMetrics',
	reportTypeList: 'list',
	reportTypeMain: 'result',
	reportSubTypeRegionalGraph: 'regionalGraph',
	reportSubTypeGroupGraph: 'groupGraph',
	reportSubTypeResponseStatus: 'employeesResponseStatus',
	reportSubTypeSummitGroupStatus: 'employeesSummitGroupStatus',
	reportSubTypeDefaulterDetails: 'employeesDefaultersDetails',
	responded: 'responded',
	notResponded: 'not responded',
	defaulter: 'defaulter',
};
