/* eslint-disable react/prop-types */
import { Box, Button, styled } from '@mui/material';
import { useState, useEffect } from 'react';
import _ from 'lodash';
import AudiencePopup from '../../../components/mapping/AudiencePopup';
import SelectedFilters from '../../../components/SelectedFilters';
import { commonLabels } from '../../../utils/CommonLabels';
import {
	AudienceFilterLabels,
	initialFilterLabelJson,
	initialFilterValue,
} from '../../../utils/SurveyAudience';
import { STEPTheme } from '../../../utils/Theme';
import {
	resetAppliedFilterData,
	setSelectedFilterRequestPayload,
	setSurveyAudienceBlobName,
	setSurveyAudienceIncludeClause,
	setSurveyAudienceExcludeClause,
	setSurveyAudienceFilterLabelJson,
} from '../../../redux/SurveySlice';
import { useAppDispatch, useAppSelector } from '../../../app/Hooks';

const StyledBox = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	width: '100%',
	marginBottom: '20px',
});

const Reset = styled(Button)({
	width: '123px',
	height: '16px',
	fontFamily: 'JohnsonText-Regular',
	fontSize: '16px',
	fontWeight: 400,
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
	marginTop: '62px',
	color: STEPTheme.colors.blue,
	textTransform: 'none',
});

const StyledButton = styled(Button)({
	backgroundColor: STEPTheme.colors.black,
	color: STEPTheme.colors.white,
	textTransform: 'none',
	minWidth: '100px',
	minHeight: '24px',
	border: '1px solid black',
	lineHeight: '16px',
	fontSize: '14px',
	fontFamily: 'JohnsonText-Regular',
	'&:hover': {
		backgroundColor: STEPTheme.colors.black,
		borderColor: STEPTheme.colors.black,
	},
});

const selectedFiltersBoxStyle = {
	position: 'relative',
	bottom: '20px',
};

interface ResultFilterProps {
	hasAudienceDataLoaded: boolean;
}

const ResultFilter: React.FC<ResultFilterProps> = ({
	hasAudienceDataLoaded,
}) => {
	const [filterPopupOpen, setFilterPopupOpen] = useState(false);
	const [filtersApplied, setFiltersApplied] = useState(false);
	const dispatch = useAppDispatch();
	const filterRequestPayload = useAppSelector(
		(state) => state.survey.selectedFilterRequestPayload
	);

	useEffect(() => {
		const includeFilters = JSON.parse(filterRequestPayload.includeFilters);
		const excludeFilters = JSON.parse(filterRequestPayload.excludeFilters);
		setFiltersApplied(
			!_.isEqual(includeFilters, initialFilterValue) ||
				!_.isEqual(excludeFilters, initialFilterValue)
		);
	}, [filterRequestPayload]);

	const handleReset = () => {
		dispatch(resetAppliedFilterData([]));
		dispatch(
			setSelectedFilterRequestPayload({
				includeFilters: JSON.stringify(initialFilterValue),
				excludeFilters: JSON.stringify(initialFilterValue),
				includeClause: '',
				excludeClause: '',
				filterLabelJson: initialFilterLabelJson,
			})
		);
		dispatch(setSurveyAudienceBlobName(JSON.stringify(initialFilterValue)));
		dispatch(setSurveyAudienceIncludeClause(''));
		dispatch(setSurveyAudienceExcludeClause(''));
		dispatch(setSurveyAudienceFilterLabelJson(initialFilterLabelJson));
		setFiltersApplied(false);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<StyledBox>
				{filtersApplied ? (
					<Reset
						variant="text"
						className="mt-0 "
						onClick={() => {
							handleReset();
						}}
						data-testid="manage-filter-landing-reset"
					>
						{AudienceFilterLabels.resetAll}
					</Reset>
				) : (
					<Box width="123px" height="16px" />
				)}
				<StyledButton
					data-testid="btn"
					variant="outlined"
					onClick={() => {
						setFilterPopupOpen(true);
					}}
					disabled={!hasAudienceDataLoaded}
				>
					{commonLabels.filters}
				</StyledButton>
			</StyledBox>
			{filtersApplied && (
				<Box sx={selectedFiltersBoxStyle}>
					<SelectedFilters
						isVertical={false}
						filterLabelStyles={{
							position: 'relative',
							marginTop: '28px',
							right: '8vw',
						}}
					/>
				</Box>
			)}
			<Box>
				{filterPopupOpen && (
					<AudiencePopup
						data-testid="audience-popup"
						closePopup={() => {
							setFilterPopupOpen(false);
						}}
					/>
				)}
			</Box>
		</Box>
	);
};

export default ResultFilter;
