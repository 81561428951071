import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, styled } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { ReactComponent as ArrowRightIcon } from '../../assets/images/arrowRight.svg';
import UploadExcel from '../../components/common/UploadExcel';
import ExportButton from '../../components/common/ExportExcelButton';
import ManageFilters from '../../components/common/ManageFilters';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import { getQuestionnairePayload } from '../../utils/Helpers';
import {
	APILoadingStatus,
	configurationData,
	resetAPIFlag,
	selectedAudienceCount,
	surveyQuestionnaireData,
	updateQuestionnaireData,
	setSelectedFilterRequestPayload,
	setDummySelectedFilterRequestPayload,
	setSelectedFilterResponse,
	setSurveyAudienceBlobName,
	setSurveyAudienceCount,
	setValidWWIDsResponse,
	setSurveyAudienceIncludeClause,
	setSurveyAudienceFilterLabelJson,
	setSurveyAudienceExcludeClause,
	setSurveyExAudienceBlobName,
	getSurveyAudienceCount,
} from '../../redux/SurveySlice';
import {
	actionLabels,
	audienceSelectionTypes,
} from '../../utils/AudienceSelectionLabel';
import { commonLabels, uploadExcelLabels } from '../../utils/CommonLabels';
import GenericModalPopup from '../../components/common/GenericModalPopup';
import { Numbers } from '../../utils/Enum';
import Loader from '../../components/Loader';
import { API_STATUS_CONSTANT } from '../../constants/StepConstants';
import {
	initialFilterValue,
	initialFilterLabelJson,
} from '../../utils/SurveyAudience';
import GenericConfirmationPopup from '../../components/common/GenericConfirmationPopup';

interface CreateSurveyAudiencesProps {
	handleNext: () => void;
}

const StyledAudienceSelectionContainer = styled(Box)({
	width: '100%',
	background: '#F9F8F7',
	display: 'flex',
	flexDirection: 'column',
	minHeight: '400px',
	padding: '20px 0px',
});

const FormContainer = styled(FormControlLabel)({
	'& .MuiTypography-root': {
		fontFamily: 'JohnsonText-Regular',
		fontSize: '16px',
		lineHeight: '16px',
		fontWeight: '400',
	},
});

const StyledButtonsContainer = styled(Box)({
	width: '80%',
	gap: '20px',
	display: 'inline-flex',
	justifyContent: 'flex-end',
	margin: 'auto',
	paddingBottom: '20px',
});

const CreateSurveyAudiences: React.FC<CreateSurveyAudiencesProps> = ({
	handleNext,
}) => {
	const audienceCount = useAppSelector(getSurveyAudienceCount);
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	surveyQuestionnaireInfo?.surveyIncludedAudienceFilter?.endsWith('.xlsx');
	const [audienceSelectionType, setAudienceSelectionType] = useState(
		surveyQuestionnaireInfo?.includeClause?.endsWith('.xlsx')
			? audienceSelectionTypes.EXCEL_UPLOAD
			: audienceSelectionTypes.FILTER
	);
	const [open, setOpen] = React.useState(false);
	const [triggerdFromExport, setTriggerdFromExport] = React.useState(false);
	const [isSaveProceedFromExport, setIsSaveProceededFromExport] =
		React.useState(false);

	const dispatch = useAppDispatch();

	const selectedSurveyAudienceCount = useAppSelector(selectedAudienceCount);

	useEffect(() => {
		if (!surveyQuestionnaireInfo?.includeClause?.endsWith('.xlsx')) {
			dispatch(
				setSelectedFilterRequestPayload({
					includeFilters: surveyQuestionnaireInfo?.surveyIncludedAudienceFilter,
					excludeFilters: surveyQuestionnaireInfo?.surveyExcludedAudienceFilter,
					includeClause: surveyQuestionnaireInfo?.includeClause,
					excludeClause: surveyQuestionnaireInfo?.excludeClause,
					filterLabelJson: surveyQuestionnaireInfo?.filterLabelJson,
					pageSize: Numbers.ten,
					pageNumber: Numbers.one,
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClosePopup = () => {
		setOpen(false);
		dispatch(resetAPIFlag('idle'));
	};

	const configs = useAppSelector(configurationData);
	const [showLoader, setShowLoader] = React.useState(false);
	const [isSaveProceed, setIsSaveProceed] = React.useState(false);
	const APICompletionStatus = useAppSelector(APILoadingStatus);

	const [
		showAudienceSelectionTypeChangeConfirmPopUp,
		setShowAudienceSelectionTypeChangeConfirmPopUp,
	] = React.useState(false);
	const [audienceSelectionTempType, setAudienceSelectionTempType] = useState(
		audienceSelectionType
	);

	const confirmAudienceSelectionTypeChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (audienceCount && audienceCount > 0) {
			setShowAudienceSelectionTypeChangeConfirmPopUp(true);
			setAudienceSelectionTempType((event.target as HTMLInputElement).value);
		} else {
			handleAudienceSelectionTypeChange();
			setAudienceSelectionType((event.target as HTMLInputElement).value);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleAudienceSelectionTypeChange = () => {
		dispatch(setSelectedFilterResponse([]));
		dispatch(setSurveyAudienceBlobName(JSON.stringify(initialFilterValue)));
		dispatch(setSurveyExAudienceBlobName(JSON.stringify(initialFilterValue)));
		dispatch(setSurveyAudienceIncludeClause(''));
		dispatch(setSurveyAudienceCount(0));
		dispatch(setValidWWIDsResponse([]));
		dispatch(setSurveyAudienceFilterLabelJson(initialFilterLabelJson));
		dispatch(setSurveyAudienceExcludeClause(''));
		dispatch(
			setSelectedFilterRequestPayload({
				includeFilters: JSON.stringify(initialFilterValue),
				excludeFilters: JSON.stringify(initialFilterValue),
				includeClause: '',
				excludeClause: '',
				filterLabelJson: initialFilterLabelJson,
				pageSize: Numbers.ten,
				pageNumber: Numbers.one,
			})
		);
		dispatch(
			setDummySelectedFilterRequestPayload({
				includeFilters: JSON.stringify(initialFilterValue),
				excludeFilters: JSON.stringify(initialFilterValue),
				includeClause: '',
				excludeClause: '',
				filterLabelJson: initialFilterLabelJson,
				pageSize: Numbers.ten,
				pageNumber: Numbers.one,
			})
		);
		setAudienceSelectionType(audienceSelectionTempType);
	};

	const handleFileUpload = () => {
		// Added as it is part component
	};
	// Sets isFileUploaded to false so that Confirm and Add questions is disabled
	const handleClearFile = () => {
		// Added as it is part component
	};
	useEffect(() => {
		if (APICompletionStatus === API_STATUS_CONSTANT.SUCCESS) {
			setShowLoader(false);
			setIsSaveProceededFromExport(true);
			if (isSaveProceed) {
				dispatch(resetAPIFlag('idle'));
				handleNext();
			} else {
				setOpen(true);
			}
		} else {
			dispatch(resetAPIFlag('idle'));
			setOpen(false);
			setShowLoader(false);
			setIsSaveProceededFromExport(false);
			setIsSaveProceed(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [APICompletionStatus]);

	const handleMaxDataExport = () => {
		dispatch(resetAPIFlag('idle'));
		setTriggerdFromExport(true);
		setIsSaveProceededFromExport(false);
		const payload = getQuestionnairePayload({
			...surveyQuestionnaireInfo,
			userEmailId: configs?.user?.userEmailId ?? '',
		});
		dispatch(updateQuestionnaireData(payload));
	};

	const handleSaveDraft = () => {
		setTriggerdFromExport(false);
		setShowLoader(true);
		setIsSaveProceed(false);
		const payload = getQuestionnairePayload({
			...surveyQuestionnaireInfo,
			userEmailId: configs?.user?.userEmailId ?? '',
		});
		dispatch(updateQuestionnaireData(payload));
	};

	const handleSaveAndNextClick = () => {
		setIsSaveProceed(true);
		setShowLoader(true);
		const payload = getQuestionnairePayload({
			...surveyQuestionnaireInfo,
			userEmailId: configs?.user?.userEmailId ?? '',
		});
		dispatch(updateQuestionnaireData(payload));
	};

	const handleConfirmPopup = () => {
		setShowAudienceSelectionTypeChangeConfirmPopUp(false);
		handleAudienceSelectionTypeChange();
	};

	const handleCancelPopup = () => {
		setShowAudienceSelectionTypeChangeConfirmPopUp(false);
	};

	return (
		<StyledAudienceSelectionContainer>
			<Loader isLoading={showLoader} />
			<Typography
				variant="h1"
				sx={{
					marginInline: 'auto',
				}}
			>
				{actionLabels.chooseAudienceForQuestionnaire}
			</Typography>
			<FormControl sx={{ marginInline: 'auto' }}>
				<RadioGroup
					row
					aria-labelledby="demo-row-radio-buttons-group-label"
					name="row-radio-buttons-group"
					value={audienceSelectionType}
					onChange={confirmAudienceSelectionTypeChange}
				>
					<FormContainer
						value={audienceSelectionTypes.FILTER}
						control={<Radio />}
						label="Select with Filters"
					/>
					<FormContainer
						value={audienceSelectionTypes.EXCEL_UPLOAD}
						control={<Radio />}
						label="Upload From Excel"
					/>
				</RadioGroup>
			</FormControl>
			{audienceSelectionType === audienceSelectionTypes.EXCEL_UPLOAD ? (
				<UploadExcel
					onFileUpload={handleFileUpload}
					onReset={handleClearFile}
				/>
			) : (
				<ManageFilters
					onHandleClose={handleClosePopup}
					onHandleOpen={handleClosePopup}
					onPageChange={handleClosePopup}
					audienceSelectionType={audienceSelectionType}
				/>
			)}
			<StyledButtonsContainer>
				<Button
					variant="outlined"
					color="secondary"
					onClick={handleSaveDraft}
					disabled={selectedSurveyAudienceCount === 0}
				>
					{commonLabels.saveAsDraft}
				</Button>

				{/* Export Button is available only for Select with Filters Section */}
				{audienceSelectionType !== audienceSelectionTypes.EXCEL_UPLOAD && (
					<ExportButton
						onMaxDataExport={handleMaxDataExport}
						dataSavedAsDraft={isSaveProceedFromExport && triggerdFromExport}
						filename={`${uploadExcelLabels.audience}_${surveyQuestionnaireInfo?.surveyFormsDetails?.questionnaireCode}`}
					/>
				)}
				<Button
					variant="contained"
					color="primary"
					endIcon={<ArrowRightIcon />}
					disabled={selectedSurveyAudienceCount === 0}
					onClick={handleSaveAndNextClick}
				>
					{commonLabels.saveNext}
				</Button>
			</StyledButtonsContainer>
			<GenericModalPopup
				openProp={open && !triggerdFromExport}
				msgBody={commonLabels.saveAsDraftText}
				mainMsg=""
				rightBtnText={commonLabels.ok}
				leftBtnText={commonLabels.no}
				isRightBtnVisible={true}
				isLeftBtnVisible={false}
				rightBtnHandler={handleClosePopup}
				leftBtnHandler={handleClosePopup}
			/>
			<GenericConfirmationPopup
				open={showAudienceSelectionTypeChangeConfirmPopUp}
				msgBody={commonLabels.audienceDataLossMessage}
				title={commonLabels.audienceWarningMsg}
				rightBtnText={commonLabels.proceed}
				leftBtnText={commonLabels.cancel}
				isRightBtnVisible={true}
				isLeftBtnVisible={true}
				rightBtnHandler={handleConfirmPopup}
				leftBtnHandler={handleCancelPopup}
				data-testid="confirmation-popup"
			/>
		</StyledAudienceSelectionContainer>
	);
};
export default CreateSurveyAudiences;
