import { MenuItem, Select } from '@mui/material';
import { questionsLabels } from '../../utils/QuestionsLabels';
import { ISurveyFormsQuestion } from '../../types/SurveyProps';
import { ConfigureOptionsProp } from '../../types/QuestionType';
import { QUECONSTANT } from '../../constants/StepConstants';

const ConfigureOptions = (props: ConfigureOptionsProp) => {
	const { option, isEditable, index, questions, classN, register, i } = props;
	/**
	 * This function return the customize condition eg: Question To 2. if the Order 2 of Question exist else the default.
	 */
	const returnSelectedValue = () => {
		let isExistDependQuesOrdr = true;
		const selectedQuesOrder =
			option?.condition && option.condition.includes(questionsLabels.continueTo)
				? option.condition.split(questionsLabels.continueTo)[1]
				: 0;
		if (selectedQuesOrder !== 0) {
			isExistDependQuesOrdr = questions.some(
				(question: ISurveyFormsQuestion) => {
					return question?.order === Number(selectedQuesOrder) - 1;
				}
			);
		}

		return isExistDependQuesOrdr ? option?.condition : '';
	};
	const returnDropdDownValues = (questionIndex: number) => {
		{
			let options = [
				<MenuItem value="" key="default">
					{QUECONSTANT.QUESTION_CONDITION_LABEL}
				</MenuItem>,
				<MenuItem value={questionsLabels.endQuestionnaire} key="end">
					{questionsLabels.endQuestionnaire}
				</MenuItem>,
			];
			options = [...options];
			!props.showOnlyStaticOptions &&
				questions.forEach((question: ISurveyFormsQuestion) => {
					if (question?.order && question?.order > questionIndex) {
						options.push(
							<MenuItem
								key={question?.questionId}
								value={`${questionsLabels.continueTo} ${question?.order + 1}`}
							>
								{questionsLabels.continueTo} {question?.order + 1}
							</MenuItem>
						);
					}
				});
			return options;
		}
	};
	return (
		<Select
			className={classN}
			labelId="dropdown-label"
			id="dropdown"
			data-testid="dropdown"
			disabled={!isEditable}
			variant="standard"
			value={returnSelectedValue()}
			{...register(`options.${i}.condition`)}
			displayEmpty
		>
			{returnDropdDownValues(index)}
		</Select>
	);
};

export default ConfigureOptions;
