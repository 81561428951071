export const audienceSelectionTableHeaders: { [key: string]: string } = {
	wwid: 'WWID',
	name: 'Name',
	emailId: 'Email ID',
};

export const audienceSelectionTypes: { [key: string]: string } = {
	EXCEL_UPLOAD: 'excelUpload',
	FILTER: 'filter',
};

export const actionLabels: { [key: string]: string } = {
	chooseAudienceForQuestionnaire: 'Choose Audience for Questionnaire',
};
