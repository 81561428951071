import React from 'react';
import { styled } from '@mui/material/styles';
import { ReactComponent as LoadingSpinner } from '../assets/images/loader.svg';

const LogoContainer = styled('div')({
	position: 'fixed',
	top: '0px',
	left: '0px',
	height: '100vh',
	width: '100vw',
	zIndex: '99999',
	backgroundColor: 'rgb(0 0 0 / 60%)',
	backdropFilter: 'blur(8px)',
	display: 'flex',
	justifyContent: 'center',
	svg: {
		position: 'absolute',
		top: '50%',
		animation: `circle linear 3s infinite`,
	},
});

interface LoaderProps {
	isLoading: boolean;
}

const Loader: React.FC<LoaderProps> = ({ isLoading }) => {
	return isLoading ? (
		<LogoContainer data-testid="loader">
			<LoadingSpinner />
		</LogoContainer>
	) : null;
};

export default Loader;
