import styled from '@emotion/styled';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { STEPTheme } from '../utils/Theme';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(() => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: STEPTheme.colors.black,
		borderRadius: '14px',
		padding: '8px 16px 8px 7px',
		gap: '7px',
		whiteSpace: 'pre-line',
		span: {
			fontFamily: '"JohnsonText-Regular"',
			fontSize: '14px',
			fontWeight: '400',
			lineHeight: '16px',
			letterSpacing: '0px',
			textAlign: 'left',
			cursor: 'pointer',
			margin: '-3px 0',
			display: 'block',
			svg: {
				marginBottom: '-3px',
				marginRight: '5px',
			},
			'&.disabled-action': {
				cursor: 'none',
				color: `${STEPTheme.colors.linkGray}`,
			},
		},
	},
}));

export default StyledTooltip;
