import { styled, Box, Card, ThemeProvider, BoxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StyledTooltip from '../StyledTooltip';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import { setIsLastScreenEndUser } from '../../redux/SurveySlice';
import {
	EndUserListingPageLabels,
	commonLabels,
} from '../../utils/CommonLabels';
import { STEPTheme, stepMuiTheme } from '../../utils/Theme';
import { ReactComponent as ArrowBlack } from '../../assets/images/Arrow-Black.svg';
import {
	getQuestionnaireData,
	getQuestionnaireDetailsCWAPIStatus,
	getTodoMessage,
	questionnaireData,
	updateResponseJSON,
} from '../../redux/QuestionnarieUserSlice';
import { IType } from '../../types/CommonTypes';
import GenericConfirmationPopup from '../common/GenericConfirmationPopup';
import GenericModalPopup from '../common/GenericModalPopup';
import { API_STATUS_CONSTANT } from '../../constants/StepConstants';
import MultiplePageQuestionnaire from './MultiplePageQuestionnaire';
import {
	breakPointDown_768,
	breakPointBetween_480_768,
	breakPointBetween_768_1025,
	breakPointBetween_768_1300,
	breakPointDown_339,
	breakPointDown_480,
} from './common/makeStyles';

const QuestionnaireName = styled(Box)<BoxProps>({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '25px',
	lineHeight: '32px',
	fontWeight: '700',
	color: STEPTheme.colors.white,
	display: 'flex',
	alignItems: 'center',
	position: 'relative',
	bottom: '50px',
	overflowWrap: 'break-word',
	wordBreak: 'break-word',
	[breakPointDown_768]: {
		position: 'relative',
		right: '14.5vw',
		fontSize: '20px',
		lineHeight: '24px',
	},
	[breakPointBetween_480_768]: {
		position: 'relative',
		right: '4.5vw',
		width: '108%',
	},
	[breakPointDown_480]: {
		right: '11.5vw',
		width: '128%',
	},
	[breakPointDown_339]: {
		fontSize: '18px',
		lineHeight: '20px',
	},
	[breakPointBetween_768_1025]: {
		fontSize: '25px',
		lineHeight: '32px',
	},
});

const IconWrapper = styled('div')({
	position: 'relative',
	width: '47px',
	height: '30px',
	resize: 'both',
});
const IconStyle = styled(ArrowBlack)({
	marginRight: '10px',
	cursor: 'pointer',
});

const QuestionnaireNameBox = styled(Box)({
	backgroundColor: STEPTheme.colors.primary,
	padding: '75px 65px 60px 65px',
	overflow: 'hidden',
});

const StyledCard = styled(Card)({
	flexDirection: 'column',
	display: 'block',
	marginLeft: 'auto',
	marginRight: 'auto',
	marginBottom: '20%',
	marginTop: '-40px',
	borderRadius: '16px',
	padding: '30px',
	paddingTop: '30px',
	paddingBottom: '15vh',
	width: '62%',
	height: 'auto',
	position: 'relative',
	justifyContent: 'center',
	boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
	overflow: 'visible',
	[breakPointDown_768]: {
		marginLeft: '9.5%',
		marginBottom: '20%',
		marginTop: '-65px',
		paddingTop: '10px',
		width: '65%',
	},
	[breakPointBetween_768_1300]: {
		display: 'flex',
		flexDirection: 'column',
		width: '83.33vw',
		marginLeft: '8.07vw',
		padding: '2vh 0vh 14.9vh 0vh',
	},
});

const StyledBox = styled(Box)({
	margin: '20px',
	alignItems: 'center',
	display: 'flex',
	[breakPointDown_768]: {
		marginLeft: '0px',
		marginRight: '0px',
	},
});

const QuestionnaireMultiplePage = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const questionnaireInfo = useAppSelector(questionnaireData);
	const [questionnaireName, setQuestionnaireName] = useState('');
	const { id: questionnaireId } = useParams();
	const [showPopUp, setShowPopUp] = useState(false);
	const responseJSON = useAppSelector(
		(state) => state.QuestionnaireSurvey.responseJSON
	);
	const questionnaireDetailsAPIStatus = useAppSelector(
		getQuestionnaireDetailsCWAPIStatus
	);
	const [isGenericModalOpen, setIsGenericModalOpen] = useState(false);
	const genericModalMsg = useAppSelector(getTodoMessage);
	useEffect(() => {
		const endUserReq: IType = {
			id: questionnaireId ?? '',
		};
		dispatch(getQuestionnaireData(endUserReq));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setQuestionnaireName(questionnaireInfo?.data?.questionnaireName);
	}, [questionnaireInfo]);

	useEffect(() => {
		if (
			questionnaireDetailsAPIStatus ===
			API_STATUS_CONSTANT.ERROR_PERMISSION_DENIED
		) {
			setIsGenericModalOpen(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireDetailsAPIStatus]);

	const goToHome = () => {
		navigate('/');
		navigate(0);
	};

	const handleRedirection = () => {
		setShowPopUp(true);
		dispatch(setIsLastScreenEndUser(true));
	};

	const handleConfirmPopup = () => {
		dispatch(setIsLastScreenEndUser(true));
		if (responseJSON.length > 0) {
			const clearedResponses = responseJSON.map((answer) => ({
				...answer,
				answer: '',
			}));
			clearedResponses.forEach((clearedAnswer) => {
				dispatch(updateResponseJSON(clearedAnswer));
			});
		}
		navigate('/');
		setShowPopUp(false);
	};

	const handleCancelPopup = () => {
		setShowPopUp(false);
	};

	return (
		<ThemeProvider theme={stepMuiTheme}>
			<Box>
				<QuestionnaireNameBox>
					<QuestionnaireName data-testid="questionnaire-name">
						<StyledTooltip
							title={EndUserListingPageLabels.backArrowTooltip}
							placement="top"
						>
							<IconWrapper>
								<IconStyle
									data-testid="back-arrow"
									onClick={() => {
										handleRedirection();
									}}
								/>
							</IconWrapper>
						</StyledTooltip>
						{questionnaireName}
					</QuestionnaireName>
				</QuestionnaireNameBox>
				<StyledCard raised={true}>
					<StyledBox className="d-flex">
						{questionnaireInfo && (
							<MultiplePageQuestionnaire data-testid="multiple-page-questionnaire"></MultiplePageQuestionnaire>
						)}
					</StyledBox>
				</StyledCard>
				<GenericConfirmationPopup
					open={showPopUp}
					msgBody={commonLabels.questionnaireDataLossMessage}
					title={commonLabels.questionnaireWarningMsg}
					rightBtnText={commonLabels.confirm}
					leftBtnText={commonLabels.cancel}
					isRightBtnVisible={true}
					isLeftBtnVisible={true}
					rightBtnHandler={handleConfirmPopup}
					leftBtnHandler={handleCancelPopup}
					data-testid="confirmation-popup"
				/>

				{isGenericModalOpen && (
					<GenericModalPopup
						openProp={true}
						msgBody={genericModalMsg}
						mainMsg=""
						rightBtnText={'Go To Home'}
						leftBtnText={''}
						isRightBtnVisible={true}
						isLeftBtnVisible={false}
						rightBtnHandler={goToHome}
						leftBtnHandler={() => {}}
						hideCloseBtn={true}
					/>
				)}
			</Box>
		</ThemeProvider>
	);
};

export default QuestionnaireMultiplePage;
