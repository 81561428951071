import { STEPTheme } from '../../../utils/Theme';
export const Styles = {
	groupCharecter: {
		fontWeight: '400',
		fontSize: '10px',
		lineHeight: '24px',
		fontStyle: 'italic',
		height: '10px',
		paddingBottom: '4px',
		paddingRight: '6px',
		color: STEPTheme.colors.darkGray,
	},
	groupName: {
		fontFamily: 'JohnsonText-Regular',
		fontSize: '14px',
		lineHeight: '12px',
		paddingBottom: '1vh',
	},
	radioGroups: {
		'.MuiFormControlLabel-root': {
			height: '16px',
			padding: '2vh 1vw 1vh 2vw',
		},
	},
	dflexRow: {
		display: 'flex',
		flexDirection: 'row',
	},
	defaultRadioGroup: {
		flexDirection: 'row',
		'.MuiFormControlLabel-root': {
			height: '16px',
			padding: '1vh 1vw 1vh 2vw',
		},
	},
	controlLabel: {
		'& .MuiTypography-root': {
			fontFamily: 'JohnsonText-Regular',
			fontWeight: 400,
			fontSize: '14px',
			lineHeight: '16px',
			height: '16px',
		},
	},
	radioControl: {
		'& .MuiSvgIcon-root': {
			width: '16px',
		},
	},
	defaultCheckBox: {
		'& .MuiSvgIcon-root': {
			width: '14px',
			height: '14px',
		},
	},
	effectiveDate: {
		'&.MuiFormControl-root-MuiTextField-root': {
			width: '322px',
			border: '4px',
			marginTop: '2px',
		},
		'.MuiInputBase-input': {
			paddingTop: '10px ',
			paddingBottom: '10px',
			height: '1.2em',
		},
		'.MuiOutlinedInput-root': {
			borderRadius: '8px',
		},
	},
	questionText: {
		width: '20px',
		lineHeight: '16px',
		fontFamily: 'JohnsonText-Regular',
	},
};
