import React from 'react';
import { Grid, Typography, Stack, styled } from '@mui/material';
import {
	ChipsLabels,
	questionnaireResultsLabels,
} from '../../utils/CommonLabels';
import { STEPTheme } from '../../utils/Theme';
import { IMetricsData } from '../../types/QuestionnaireResultsType';
import { prepareKeyMetricsData } from '../../utils/CommonMethods';
import KeyMetricsItem from './KeyMetricsItem';

const KeyMetricsContainer = styled(Stack)({
	gap: '20px',
	width: '100%',
});

const KeyMetricsItemContainer = styled(Grid)({
	width: '100%',
	gridTemplateColumns: '1fr 1fr 1fr',
	justifyContent: 'space-between',
	marginBottom: '30px',
	borderRadius: '12px',
});

interface KeyMetricsProps {
	type: string;
	employeesAtRiskOfOverdue: IMetricsData;
	employeesOverdue: IMetricsData;
	employeesDefaulters: IMetricsData;
	triggerSelectedDays: (selectedDay: number) => void;
	selectedDay: number;
	refreshFn: () => void;
	filterViewEmployees: (metricsType: string) => void;
}

export const KeyMetrics: React.FC<KeyMetricsProps> = ({
	type,
	employeesAtRiskOfOverdue,
	employeesOverdue,
	employeesDefaulters,
	triggerSelectedDays,
	selectedDay,
	filterViewEmployees,
}) => {
	return (
		<KeyMetricsContainer>
			<Typography variant="h1">
				{questionnaireResultsLabels.keyMetrics}
			</Typography>
			<KeyMetricsItemContainer container>
				<KeyMetricsItem
					percentage={prepareKeyMetricsData(
						type,
						employeesAtRiskOfOverdue?.impactedEmployees,
						employeesAtRiskOfOverdue?.totalEmployees
					)}
					avatarDiameterInPixel={'68px'}
					avatarColor={STEPTheme.colors.yellow}
					metricsType={questionnaireResultsLabels.metricsTypeAtRisk}
					metricsLabelLineOne={questionnaireResultsLabels.comingDue}
					triggerSelectedDays={triggerSelectedDays}
					selectedDay={selectedDay}
					filterViewEmployees={() =>
						filterViewEmployees(ChipsLabels.RISKOVERDUE)
					}
				/>

				<KeyMetricsItem
					percentage={prepareKeyMetricsData(
						type,
						employeesOverdue?.impactedEmployees,
						employeesOverdue?.totalEmployees
					)}
					avatarDiameterInPixel={'68px'}
					avatarColor={STEPTheme.colors.amber}
					metricsType={questionnaireResultsLabels.metricsTypeOverdue}
					metricsLabelLineOne={questionnaireResultsLabels.notCompliant}
					triggerSelectedDays={triggerSelectedDays}
					selectedDay={selectedDay}
					filterViewEmployees={() =>
						filterViewEmployees(ChipsLabels.ALREADYOVERDUE)
					}
				/>

				<KeyMetricsItem
					percentage={prepareKeyMetricsData(
						type,
						employeesDefaulters?.impactedEmployees,
						employeesDefaulters?.totalEmployees
					)}
					avatarDiameterInPixel={'68px'}
					avatarColor={STEPTheme.colors.lightRed}
					metricsType={questionnaireResultsLabels.metricsTypeDefaulter}
					metricsLabelLineOne={questionnaireResultsLabels.defaulters}
					metricsLabelLineTwo={questionnaireResultsLabels.after}
					triggerSelectedDays={triggerSelectedDays}
					selectedDay={selectedDay}
					filterViewEmployees={() => filterViewEmployees(ChipsLabels.DEFAULTER)}
				/>
			</KeyMetricsItemContainer>
		</KeyMetricsContainer>
	);
};

export default KeyMetrics;
