import React from 'react';
import Button from '@mui/material/Button';
import { IHCCIconButtonProps } from '../../types/CommonTypes';
import { STEPTheme } from '../../utils/Theme';

export const baseProperties = (width = '', height = '') => {
	return {
		width: `${width} !important` || '191px',
		height: height || '32px',
	};
};

export const colorProperties = (color = '', backgroundColor = '') => {
	return {
		color: color || STEPTheme.colors.white,
		backgroundColor:
			backgroundColor || `${STEPTheme.colors.primary} !important`,
		'&:hover': {
			backgroundColor:
				backgroundColor || `${STEPTheme.colors.primary} !important`,
		},
	};
};

export const boxProperties = (
	whiteSpace = '',
	borderRadius = '',
	textTransform = '',
	padding = '',
	border = ''
) => {
	return {
		whiteSpace: whiteSpace || 'nowrap',
		borderRadius: borderRadius || '8px !important',
		textTransform: textTransform || 'none',
		padding: padding || '8px, 16px, 8px, 16px !important',
		border: border || 'none',
	};
};

export const fontProperties = (
	fontFamily = '',
	fontSize = '',
	fontWeight = ''
) => {
	return {
		fontFamily: fontFamily || 'JohnsonText-Regular',
		fontSize: fontSize || '16px !important',
		fontWeight: fontWeight || '500',
	};
};

export const alignedProperties = (
	lineHeight = '',
	letterSpacing = '',
	textAlign = '',
	align = ' '
) => {
	return {
		lineHeight: lineHeight || '16px',
		letterSpacing: letterSpacing || '0px',
		textAlign: textAlign || 'center',
		align: align || 'start',
	};
};

export const IconButton: React.FC<IHCCIconButtonProps> = ({
	iconType,
	labelText,
	style,
	onClick,
	align,
}) => {
	const buttonStyle = {
		...baseProperties(style?.width, style?.height),
		...fontProperties(style?.fontFamily, style?.fontSize, style?.fontWeight),
		...alignedProperties(
			style?.lineHeight,
			style?.letterSpacing,
			style?.textAlign,
			style?.align
		),
		...boxProperties(
			style?.whiteSpace,
			style?.borderRadius,
			style?.textTransform,
			style?.padding
		),
		...colorProperties(style?.color, style?.backgroundColor),
	};
	return (
		<Button
			startIcon={align === 'start' ? iconType : ''}
			endIcon={align === 'end' ? iconType : ''}
			sx={{ ...buttonStyle }}
			onClick={onClick}
			data-testid="icon-button"
		>
			{labelText}
		</Button>
	);
};
