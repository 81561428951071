import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { STEPTheme } from '../../utils/Theme';
import {
	IQuestionnaireRequestQAndA,
	ISurveyFormsQuestion,
} from '../../types/SurveyProps';
import {
	currentQuestion,
	setCurrentQuestion,
} from '../../redux/QuestionnaireResultsSlice';
import { questionnaireResultsLabels } from '../../utils/CommonLabels';
import { getQuestionLabel } from '../../utils/Helpers';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import { QUECONSTANT } from '../../constants/StepConstants';
import ResponseGraph from './ResponseGraph';
const QuestionsComponent: React.FC<IQuestionnaireRequestQAndA> = ({
	questionJSONString,
}) => {
	const StyledTabs = styled(Tabs)({
		'.Mui-selected': {
			fontFamily: '"JohnsonText-Regular"',
			fontSize: '16px',
			fontWeight: '700',
			lineHeight: '16px',
			letterSpacing: '0px',
			textAlign: 'center',
			color: `${STEPTheme.colors.text} !important`,
			textTransform: 'none',
		},
		fontFamily: '"JohnsonText-Regular"',
		height: '24px',
		padding: '4px',
		'.MuiTabs-flexContainer': {
			flexWrap: 'wrap !important',
		},
	});
	const a11yProps = (index: number) => {
		return {
			id: `result-response-tab-${index}`,
			'aria-controls': `result-response-tabpanel-${index}`,
		};
	};
	const QuestionTypeInnerContainer = styled(Box)({
		padding: '5px 16px',
		gap: '8px',
		borderRadius: '12px',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		fontFamily: '"JohnsonText-Regular"',
	});
	const QuestionTypeContainer = styled('div')({
		float: 'right',
		marginTop: '-20px',
		padding: '4px',
		borderBottomLeftRadius: '12px',
		borderBottomRightRadius: '12px',
		flexGrow: 0,
		display: 'flex',
		justifyContent: 'flex-end',
		fontWeight: '500',
		fontSize: '14px',
		lineHeight: '16px',
		color: `${STEPTheme.colors.white}`,
		background: `${STEPTheme.colors.middlGray}`,
	});
	const currentSelectedQuestion = useAppSelector(currentQuestion);
	const QuesData = JSON.parse(questionJSONString);
	const [value, setValue] = useState(currentSelectedQuestion);
	const [quesType, setQuesType] = useState(
		QuesData[currentSelectedQuestion]?.questionType
	);
	const dispatch = useAppDispatch();

	/**
	 * @method
	 * @summary Actives the tab for response graph section (based on the selected question)
	 * @summary Initially it will point to the first Question
	 * @param newValue: Number
	 * @returns void
	 */
	const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
		setQuesType(QuesData[newValue]?.questionType);
		setValue(newValue);
		dispatch(setCurrentQuestion(newValue));
	};

	return QuesData && QuesData.length > 0 ? (
		<Box sx={{ width: '100%' }}>
			<QuestionTypeContainer>
				<QuestionTypeInnerContainer>
					{getQuestionLabel(quesType)}
				</QuestionTypeInnerContainer>
			</QuestionTypeContainer>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					padding: 0,
					width: '-webkit-fill-available',
				}}
			>
				<Typography variant="h2" sx={{ mb: '20px' }}>
					{`${questionnaireResultsLabels.responseGraphTxt}`}
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					border: '1px solid #EFEDEA',
					borderRadius: '4px',
					width: 'max-content',
				}}
			>
				<StyledTabs
					value={value}
					onChange={handleChange}
					aria-label=""
					TabIndicatorProps={{
						style: {
							backgroundColor: `${STEPTheme.colors.primary}`,
						},
					}}
				>
					{QuesData?.map((item: ISurveyFormsQuestion, index: number) => (
						<Tab
							disabled={item.questionType === QUECONSTANT.QUESTION_TYPE_OPEN}
							role="tab"
							key={item?.questionId}
							label={`${questionnaireResultsLabels.question} ${Number(index + 1)}`}
							{...a11yProps(index)}
						/>
					))}
				</StyledTabs>
			</Box>
			<ResponseGraph
				qType={quesType}
				value={value}
				QuesData={QuesData[value]}
			/>
		</Box>
	) : null;
};

const Questions = React.memo(QuestionsComponent);
export default Questions;
