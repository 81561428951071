import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { STEPTheme } from '../../../utils/Theme';
import { useAppSelector } from '../../../app/Hooks';
import {
	selectedFilterRequestPayloadData,
	surveyQuestionnaireData,
} from '../../../redux/SurveySlice';
import { IAppliedFilterArray } from '../../../types/CommonTypes';
import { getFilterLabelById } from '../../../utils/Helpers';
import { initialFilterValue } from '../../../utils/SurveyAudience';

const StyledBox = styled(Box)({
	width: 'auto',
	top: '304px',
	left: '98px',
	padding: '4px 8px',
	gap: '8px',
	display: 'flex',
	color: STEPTheme.colors.white,
	boxShadow: '0px 10px 20px rgba(0,0,0,0.1)',
});

const BoxContiner = styled(Box)({
	display: 'flex',
	gap: '8px',
	flexWrap: 'wrap',
});

const StyledTypography = styled(Typography)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '14px',
	fontWeight: '400',
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
	color: STEPTheme.colors.text,
});

interface ISelectedUserCriteriaProps {
	selectedFilterType: string;
}

const SelectedUserCriteria: React.FC<ISelectedUserCriteriaProps> = ({
	selectedFilterType,
}) => {
	let filtersToShow = '';
	const filterRequestPayload = useAppSelector(
		selectedFilterRequestPayloadData
	) as IAppliedFilterArray;
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	let includedFilterFromLabelJson = '';
	let excludedFilterFromLabelJson = '';

	if (surveyQuestionnaireInfo.surveyIncludedAudienceFilter) {
		includedFilterFromLabelJson = JSON.parse(
			surveyQuestionnaireInfo.surveyIncludedAudienceFilter
		);
		excludedFilterFromLabelJson = JSON.parse(
			surveyQuestionnaireInfo.surveyExcludedAudienceFilter || ''
		);
	}

	if (selectedFilterType === 'INCLUDE') {
		if (
			filterRequestPayload.includeFilters !== JSON.stringify(initialFilterValue)
		) {
			filtersToShow = includedFilterFromLabelJson;
		} else {
			filtersToShow = JSON.parse(
				surveyQuestionnaireInfo.surveyIncludedAudienceFilter
			);
		}
	} else {
		if (
			filterRequestPayload.excludeFilters !== JSON.stringify(initialFilterValue)
		) {
			filtersToShow = excludedFilterFromLabelJson;
		} else {
			filtersToShow = JSON.parse(
				surveyQuestionnaireInfo?.surveyExcludedAudienceFilter || ''
			);
		}
	}

	return (
		<>
			<BoxContiner>
				{Object.entries(filtersToShow).map(
					(includedFiltersValue: [string, string]) => {
						const filerValues: string[][] = [];
						includedFiltersValue &&
							includedFiltersValue[1].length > 0 &&
							Object.values(includedFiltersValue[1]).map(
								(filterVal: string) => {
									filerValues.push(Object.values(filterVal));
								}
							);
						return (
							includedFiltersValue &&
							includedFiltersValue[1].length > 0 && (
								<StyledBox>
									<StyledTypography>
										{getFilterLabelById(includedFiltersValue[0])}-
										{filerValues.join(', ')}
									</StyledTypography>
								</StyledBox>
							)
						);
					}
				)}
			</BoxContiner>
		</>
	);
};

export default SelectedUserCriteria;
