import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Tabs, Tab, useMediaQuery, useTheme } from '@mui/material';
import { StyledTabsProps, StyledTabProps } from '../types/CommonTypes';
import { STEPTheme } from '../utils/Theme';
import { useAppDispatch, useAppSelector } from '../app/Hooks';
import { setNavBarPayload } from '../redux/NavBarSlice';
import {
	configurationData,
	getHomeScreenActivatedTab,
	getIsLastScreenEndUser,
	setHomeScreenActivatedTab,
	setIsLastScreenEndUser,
} from '../redux/SurveySlice';
import { Numbers } from '../utils/Enum';

const StyledTabs = styled((props: StyledTabsProps) => (
	<Tabs
		{...props}
		TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
	/>
))({
	'& .MuiTabs-indicator': {
		display: 'flex',
		justifyContent: 'center',
	},
	'& .MuiTabs-indicatorSpan': {
		width: '100%',
		backgroundColor: STEPTheme.colors.primary,
	},
});

const StyledTab = styled((props: StyledTabProps) => <Tab {...props} />)(
	({ theme }) => ({
		textTransform: 'none',
		fontFamily: '"JohnsonText-Regular"',
		fontWeight: theme.typography.fontWeightRegular,
		fontSize: '16px',
		color: STEPTheme.colors.text,
		borderBottom: `2px solid ${STEPTheme.colors.border} `,
		'&.Mui-selected': {
			color: STEPTheme.colors.text,
			fontWeight: '700',
		},
		'&.Mui-focusVisible': {
			backgroundColor: STEPTheme.colors.primary,
		},
		visibility: 'visible',
	})
);
const HideStyledTab = styled(StyledTab)({
	visibility: 'hidden',
});

const NavBar: React.FC<unknown> = () => {
	const activateTab = useAppSelector(getHomeScreenActivatedTab);
	const prevScreenEndUser = useAppSelector(getIsLastScreenEndUser);
	const configs = useAppSelector(configurationData);
	const isAdmin = !!(
		configs?.rolePermissions?.isLMSAdmin || configs?.rolePermissions?.isAdmin
	);
	const [value, setValue] = useState(activateTab);
	const dispatch = useAppDispatch();
	const theme = useTheme();
	const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

	useEffect(() => {
		let activeTabNumber = isAdmin && isLargeScreen ? Numbers.zero : Numbers.one;
		if (prevScreenEndUser) {
			activeTabNumber = Numbers.one;
			dispatch(setIsLastScreenEndUser(false));
		}
		// Changing the active tab position based on admin permission

		setValue(activeTabNumber);
		dispatch(setHomeScreenActivatedTab(activeTabNumber));
		dispatch(setNavBarPayload({ val: activeTabNumber }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAdmin, dispatch, isLargeScreen]);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
		dispatch(setHomeScreenActivatedTab(newValue));
		dispatch(setNavBarPayload({ val: newValue }));
	};

	return (
		<StyledTabs value={value} onChange={handleChange} aria-label="Admin-Tabs">
			{isAdmin && isLargeScreen ? (
				<StyledTab disabled={false} label="Admin" />
			) : (
				<HideStyledTab disabled={false} label="Admin" />
			)}
			{isAdmin && isLargeScreen && (
				<StyledTab disabled={false} label="End User" />
			)}
		</StyledTabs>
	);
};

export default NavBar;
