import { AxiosRequestConfig } from 'axios';
import {
	IAppliedFilterArray,
	ISurveyListingRequest,
	IFetchAudiencePayload,
	IExportAudiencePayload,
	IReportRequestPayload,
} from '../types/CommonTypes';
import {
	IPostSelectedAudienceFilteredData,
	IQuestionnaireDataRequest,
	IQuestionnaireIDRequest,
} from '../types/SurveyProps';
import axiosIntercept from '../app/authentication/axiosIntercept';
import { IType } from '../types/EnduserType';
import { Numbers } from '../utils/Enum';
import { commonLabels } from '../utils/CommonLabels';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
	skipGlobalErrorHandling?: boolean;
}

export const FetchUserWWID = async (userWWIDReq: string) => {
	const URL = `users/${Numbers.one}/${Numbers.tenThousand}/${userWWIDReq}`;
	return axiosIntercept.get(URL);
};

export const FetchSurveyList = async (
	requestPayload: ISurveyListingRequest
) => {
	let URL = `Questionnaire`;
	if (requestPayload.type !== commonLabels.meText) {
		URL = `${URL}/admin`;
	}
	return axiosIntercept.get(URL);
};

export const FetchConfigData = async () => {
	return axiosIntercept.get('configs?category=system');
};

export const FetchAudienceFilterList = async () => {
	return axiosIntercept.get('users');
};

export const PostQuestionnaireData = async (
	requestPayload: IQuestionnaireDataRequest,
	config: CustomAxiosRequestConfig = {}
) => {
	return axiosIntercept.post('questionnaire', requestPayload, {
		...config,
		skipGlobalErrorHandling: true,
	} as AxiosRequestConfig);
};

export const PostSelectedAudienceFilteredData = async (
	requestPayload: IAppliedFilterArray
) => {
	return axiosIntercept.post('users', requestPayload);
};

export const PostFetchAudienceData = async (
	requestPayload: IFetchAudiencePayload
) => {
	return axiosIntercept.post('users/fetchAudience', requestPayload);
};

export const PostSelectedAudienceCollectionFilteredData = async (
	requestPayload: IPostSelectedAudienceFilteredData
) => {
	return axiosIntercept.post('users/validate', requestPayload);
};

export const ExportUsersAsList = async (
	requestPayload: IExportAudiencePayload
) => {
	return axiosIntercept.post('users/export', requestPayload, {
		responseType: 'blob',
	});
};

export const BatchReportRequest = async (
	requestPayload: IReportRequestPayload
) => {
	const URL = `questionnaire/${requestPayload.id}/reportrequest/${requestPayload.reporttype}`;
	return axiosIntercept.post(URL);
};

export const GetQuestionnaireByID = async (
	requestPayload: IQuestionnaireIDRequest
) => {
	return axiosIntercept.get(`questionnaire/${requestPayload.id}`);
};

export const GetQuestionnaireDeactiveResponse = async (
	requestPayload: IQuestionnaireIDRequest
) => {
	return axiosIntercept.put(
		`questionnaire/${requestPayload.id}/deactivatequestionnaire`
	);
};

export const DeleteDraftQuestionnaire = async (
	requestPayload: IQuestionnaireIDRequest
) => {
	return axiosIntercept.delete(`questionnaire/${requestPayload.id}`);
};

export const PutQuestionnaireDetails = async (requestPayload: IType) => {
	axiosIntercept.defaults.headers.common['Content-Type'] = 'application/json';
	return axiosIntercept.put(
		`questionnaire/${requestPayload.id}/copyquestionnaire`
	);
};
