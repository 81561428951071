import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import React from 'react';
import { Box, Button, styled } from '@mui/material';
import { ModalPopup } from '../../types/CommonTypes';

const style = {
	position: 'absolute' as const,
	top: '50%',
	left: '50%',
	height: '184px',
	transform: 'translate(-50%, -50%)',
	width: 352,
	bgcolor: 'background.paper',
	radius: '16px',
	boxShadow: 24,
	p: 4,
	padding: '24px',
	Gap: '36px',
	borderRadius: '12px',
};

const styletypo = {
	'&.MuiTypography-root': {
		fontSize: '1.6rem',
		marginTop: '39px',
		fontWeight: '500px',
	},
	height: '94px',
	lineHeight: '32px',

	gap: '8px',
	fontFamily: 'JohnsonDisplay-Bold',
};
const StyledModalButtonsContainer = styled(Box)({
	width: '97%',
	gap: '30px',
	display: 'inline-flex',
	justifyContent: 'center',
	marginInline: 'auto',
	paddingBottom: '20px',
	borderRadius: '12px',
	height: '40px',
	marginTop: '10px',
});
const styleConfirmButton = {
	width: '340px',
	height: '40px',
};
const styleIconBtn = {
	position: 'absolute',
	right: 8,
	top: 8,
	color: 'black',
};
const TextContainer = styled('div')({
	height: '20px',
	overflow: 'auto',
	fontSize: '1rem',
});

const GenericModalPopup: React.FC<ModalPopup> = ({
	openProp,
	msgBody,
	mainMsg,
	hideCloseBtn = false,
	isRightBtnVisible,
	isLeftBtnVisible,
	rightBtnText,
	leftBtnText,
	rightBtnHandler,
	leftBtnHandler,
}) => {
	const handleClose = () => {
		rightBtnHandler();
		leftBtnHandler();
	};
	return (
		<Modal
			open={openProp}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			onClose={handleClose}
		>
			<Box sx={style}>
				<Typography sx={styletypo} id="modal-modal-description">
					{msgBody}
					<TextContainer>{mainMsg}</TextContainer>
				</Typography>
				{!hideCloseBtn && (
					<IconButton
						sx={styleIconBtn}
						aria-label="close"
						onClick={handleClose}
						className="icon-button"
					>
						<CloseIcon />
					</IconButton>
				)}
				<StyledModalButtonsContainer>
					{isLeftBtnVisible && (
						<Button onClick={handleClose}>{leftBtnText}</Button>
					)}
					{isRightBtnVisible && (
						<Button
							sx={styleConfirmButton}
							variant="contained"
							color="primary"
							onClick={handleClose}
						>
							{rightBtnText}
						</Button>
					)}
				</StyledModalButtonsContainer>
			</Box>
		</Modal>
	);
};
export default GenericModalPopup;
