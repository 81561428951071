export const questionsLabels: { [key: string]: string } = {
	addQueIns: 'Add Questionnaire Instructions',
	addQueInsDes: 'Add Instructions',
	char825: '1000 characters.',
	deleteQueTitle: 'Are you sure, you want to delete Question?',
	addQuestions: 'Add Questions',
	multiOptionQue: 'Multiple Option Question',
	addCondition: 'Add Condition',
	continueTo: 'Continue to Que',
	addOption: 'Add Option',
	multiSelectQue: 'Multiple Select Question',
	selectYourQuestionType: 'Select Your Question Type',
	multipleChoice: 'Multiple Choice',
	yesNo: 'Yes & No',
	multiSelect: 'Multi-Select',
	openEnded: 'Open Ended',
	yesNoQue: 'Yes or No Question',
	questionnaireName: 'Questionnaire Name',
	startDate: 'Start Date',
	dueDate: 'Due Date',
	totalAudience: 'Total Audience',
	addNewQue: 'Add New Question',
	dropDownQue: 'Dropdown List',
	dropDownQuestion: 'Dropdown List Question',
	uploadDropDownQuestion: 'Upload Dropdown List',
	openEndedQue: 'Open Ended Question',
	queRequired: 'Question Is required',
	optionRequired: 'Option Is required',
	queMaxLength: 'Question should be at most 250 characters',
	minOption: 'Minimum 2 options required',
	queReorderError: 'Please save the question before changing its order!',
	uploadEmailTemplate: 'Upload Email Template',
	onBehalf: 'On Behalf',
	questionnaireForRequired: 'Completing questionnaire for is required',
	owningRequired: 'Questionnaire owning location is required',
	addTemplate: 'Add Template',
	viewTemplate: 'View Template',
	cancel: 'Cancel',
	save: 'Save',
	emailTemplate: 'Email Template',
	subject: 'Subject',
	exportUserList: 'Export User List',
	edit: 'Edit',
	endQuestionnaire: 'End Questionnaire',
	pleaseUploadFileAndQuestion: 'Please enter question and file',
	fileRequired: 'Please upload a file',
	downloadTemplate: 'Download Template',
	editTooltip:
		'Click to edit Questionnaire Name, Questionnaire Start Date,Questionnaire Due Date',
	audienceExportTooltip: 'Export to CSV',
	manageFilterTooltip: 'Click to view data options',
	addQuestionnaireInstructionTooltip:
		'Add questionnaire specific user instructions (Max 1000 CHAR). ',
	questionFieldTooltip: 'Type question here (Max 250 CHAR)',
	dropDownQuestionTooltip: 'User selects response from dropdown',
	multiSelectQuestionTooltip: 'Can select more than one answer',
	multiChoiceQuestionTooltip: 'Only one answer',
	openEndQuestionTooltip: 'Free text response',
	questionnaireNameTooltip: ' This is the name users will see.',
	instructionTooltip:
		'Add questions-specific user instructions (Max 250 CHAR).',
	audienceTooltip: '# of target audience',
	identicalOptionsValidation:
		'Option name cannot be same, Please change option names.',
};
