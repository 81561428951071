import React, { useEffect, useRef, useState } from 'react';
import { Button, Box, Typography, styled } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import * as XLSX from 'xlsx'; //NOSONAR
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as DownloadIcon } from '../../assets/images/Download.svg';
import { ReactComponent as UploadIcon } from '../../assets/images/uploadIcon.svg';
import { questionsLabels } from '../../utils/QuestionsLabels';
import { setDropDownOptionsCount } from '../../redux/QuestionnaireSurveySlice';
import {
	selectSurveyFormsQuestion,
	clearDropDownOptions,
} from '../../redux/SurveySlice';
import { ISurveyFormsQuestion } from '../../types/SurveyProps';
import { commonLabels } from '../../utils/CommonLabels';
import { Numbers } from '../../utils/Enum';
import { STEPTheme } from '../../utils/Theme';

const useStyles = makeStyles({
	downladExcel: {
		paddingRight: '430px',
	},
	errorMessage: {
		color: STEPTheme.colors.red,
		marginBlockStart: '2px',
		marginBlockEnd: '2px',
		textTransform: 'none',
		fontFamily: 'JohnsonText-Regular',
		fontSize: '12px',
	},
});
const UploadBtnContainer = styled(Box)<{ readonly: boolean }>(
	({ readonly }) => ({
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '15px',
		opacity: readonly ? Numbers.zeroAndHalf : Numbers.one,
		pointerEvents: readonly ? 'none' : 'auto',
	})
);

const CloseIconContainer = styled(Box)<{ readonly: boolean }>(
	({ readonly }) => ({
		cursor: 'pointer',
		opacity: readonly ? Numbers.zeroAndHalf : Numbers.one,
		pointerEvents: readonly ? 'none' : 'auto',
	})
);

const UploadFileTextContainer = styled(Box)<{ readonly: boolean }>(
	({ readonly }) => ({
		display: 'flex',
		marginTop: '10px',
		opacity: readonly ? Numbers.zeroAndHalf : Numbers.one,
		pointerEvents: readonly ? 'none' : 'auto',
	})
);

const UploadFileText = styled(Typography)({});
const UploadFileTextCont = styled(Box)({
	display: 'flex',
});
type ParsedDataItem = {
	[key: string]: string;
};
interface ExcelUploadProps {
	index: number;
	item: ISurveyFormsQuestion;
	onFileUploadExcelData: (data: {
		excelData: string;
		filename: string;
	}) => void;
	readonly: boolean;
	onFileRemoved: () => void;
}
const QuestionUpload: React.FC<ExcelUploadProps> = ({
	index,
	onFileUploadExcelData,
	item,
	readonly,
	onFileRemoved,
}) => {
	const classes = useStyles();
	const questionId = item.questionId;
	const surveyFormsQuestion = useSelector(selectSurveyFormsQuestion);
	const currentSurveyQuestion = surveyFormsQuestion.find(
		(surveyQuestion) => surveyQuestion.questionId === questionId
	);
	const dispatch = useDispatch();
	const [fileName, setFileName] = useState('');
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const showErrorMessage = (message: string) => {
		setErrorMessage(message);
	};
	const useMountEffect = () =>
		useEffect(() => {
			if (item.fileName && item.fileName !== '' && fileName === '') {
				setFileName(item.fileName);
			}
		}, []);

	useMountEffect();

	const fileInputRef = useRef<HTMLInputElement>(null);
	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedfile = event.target.files?.[Numbers.zero];
		if (selectedfile) {
			extractDataFromFile(selectedfile);
		}
	};

	const extractDataFromFile = (file: File) => {
		setErrorMessage(null);
		const reader = new FileReader();
		reader.onload = (loadEvent) => {
			if (loadEvent.target) {
				const data = new Uint8Array(loadEvent.target.result as ArrayBuffer);
				const workbook = XLSX.read(data, { type: 'array' });
				const firstSheetName = workbook.SheetNames[Numbers.zero];
				const worksheet = workbook.Sheets[firstSheetName];
				const parsedData: ParsedDataItem[] =
					XLSX.utils.sheet_to_json(worksheet);

				let firstKey = '';
				let hasDuplicateOptions = false;
				const optionSet = new Set<string>();
				if (parsedData.length > Numbers.zero) {
					firstKey = Object.keys(parsedData[Numbers.zero] as object)[
						Numbers.zero
					].toUpperCase();
					parsedData.forEach((item) => {
						const option = (Object.values(item)[Numbers.zero] as string)
							.toLowerCase()
							.trim();
						if (optionSet.has(option)) {
							hasDuplicateOptions = true;
						}
						optionSet.add(option);
					});
				}

				if (firstKey !== 'OPTIONS' || hasDuplicateOptions) {
					if (firstKey !== 'OPTIONS') {
						showErrorMessage(commonLabels.addValidDropDownOptionFile);
					} else if (hasDuplicateOptions) {
						showErrorMessage(commonLabels.duplicateOptionErrorMessage);
					}
					if (fileInputRef.current) {
						fileInputRef.current.value = '';
					}
					return;
				}

				setFileName(file.name);

				const newData = JSON.stringify(
					parsedData.slice(Numbers.zero, Numbers.thirty)
				);
				onFileUploadExcelData({ excelData: newData, filename: file.name });
				dispatch(
					setDropDownOptionsCount({
						questionId: item.questionId,
						length: parsedData.slice(Numbers.zero, Numbers.thirty).length,
					})
				);
			}
		};
		reader.readAsArrayBuffer(file);
	};

	const handleDeletedFile = () => {
		setFileName('');
		if (currentSurveyQuestion && currentSurveyQuestion.questionId) {
			dispatch(clearDropDownOptions(currentSurveyQuestion.questionId));
		}
		dispatch(
			setDropDownOptionsCount({
				questionId: item.questionId,
				length: 0,
			})
		);
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
		onFileRemoved();
	};

	function s2ab(s: string) {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i < s.length; i++) {
			view[i] = s.charCodeAt(i) & Numbers.zeroxff;
		}
		return buf;
	}

	const handleExportTemplate = () => {
		const conditionalText = `${questionsLabels.endQuestionnaire}/${questionsLabels.continueTo}`;
		const excelData = [
			['options', 'condition'],
			['option 1', conditionalText],
		];
		const workbook = XLSX.utils.book_new();
		const worksheet = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
		const excelFile = XLSX.write(workbook, {
			bookType: 'xlsx',
			type: 'binary',
		});
		const blob = new Blob([s2ab(excelFile)], {
			type: 'application/octet-stream',
		});
		const downloadLink = document.createElement('a');
		downloadLink.href = URL.createObjectURL(blob);
		downloadLink.download = `Dropdown_list.xlsx`;
		downloadLink.click();
	};

	return (
		<>
			<UploadBtnContainer readonly={readonly} data-testid="UploadContainer">
				<input
					ref={fileInputRef}
					type="file"
					accept=".xlsx, .xls"
					onChange={handleFileChange}
					disabled={readonly}
					style={{ display: 'none' }}
					id={`questionaire-${index + 1}`}
					data-testid="inputContainer"
				/>
				<label
					htmlFor={`questionaire-${index + 1}`}
					data-testid="LabelContainer"
				>
					<Button
						variant="outlined"
						color="secondary"
						component="span"
						startIcon={<UploadIcon />}
						data-testid={'buttonDropDown'}
					>
						{questionsLabels.uploadDropDownQuestion}
					</Button>
				</label>
				<Box className={classes.downladExcel}>
					<Button
						variant="outlined"
						color="secondary"
						component="span"
						startIcon={<DownloadIcon />}
						onClick={handleExportTemplate}
						sx={{ mr: '10px' }}
					>
						{questionsLabels.downloadTemplate}
					</Button>
				</Box>
			</UploadBtnContainer>
			<UploadFileTextContainer readonly={readonly}>
				{fileName && (
					<UploadFileTextCont>
						<Box>
							<AttachFileOutlinedIcon></AttachFileOutlinedIcon>
						</Box>
						<Box>
							<UploadFileText>{fileName}</UploadFileText>
						</Box>
					</UploadFileTextCont>
				)}
				{fileName && (
					<CloseIconContainer readonly={readonly}>
						<CloseRoundedIcon
							onClick={() => !readonly && handleDeletedFile()}
							data-testid="close-icon"
						></CloseRoundedIcon>
					</CloseIconContainer>
				)}
			</UploadFileTextContainer>
			{errorMessage && (
				<Box className={classes.errorMessage}>{errorMessage}</Box>
			)}
		</>
	);
};
export default QuestionUpload;
