import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { styled } from '@mui/material';
import {
	setModifiedSurveyResponse,
	getFilterDate,
	setFilterDate,
	surveyAllListingData,
	surveyListingRequest,
} from '../redux/SurveySlice';
import {
	EndUserListingRequest,
	IDateFilter,
	IEndUserQuestionnarieListingDataItem,
	ISurveyListingDataItem,
} from '../types/CommonTypes';
import { useAppDispatch, useAppSelector } from '../app/Hooks';
import {
	endUserSurveyListingRequest,
	setModifiedEndUserSurveyResponse,
	surveyAllListingDataQuestionnaire,
} from '../redux/QuestionnarieUserSlice';
import { Numbers } from '../utils/Enum';
import { filterEndUserSurveyData, filterSurveyData } from '../utils/Helpers';
import {
	breakPointDown_768,
	breakPointDown_1025,
} from './endUser/common/makeStyles';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

const StyledRangePickerContainer = styled('div')({
	[breakPointDown_1025]: {
		'& .ant-picker-panels': {
			position: 'fixed',
			top: '25%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			maxHeight: '90vh',
			overflowY: 'auto',
			backgroundColor: '#fff',
		},
	},
	[breakPointDown_768]: {
		'& .ant-picker-panels': {
			top: '50%',
			flexDirection: 'column !important',
		},
	},
});

const FilterCalender: React.FC<{ type: string; scope?: string }> = (props: {
	type: string;
	scope?: string;
}) => {
	const dispatch = useAppDispatch();
	const [defaultDate, setDefaultDate] = useState({
		startDate: '0000/00/00',
		endDate: '0000/00/00',
	});
	const surveyLegacyListData: ISurveyListingDataItem[] =
		useAppSelector(surveyAllListingData);
	const date = useAppSelector(getFilterDate);
	const ensUserSurveyReq: EndUserListingRequest = useAppSelector(
		endUserSurveyListingRequest
	);
	const endUserAllSurveyList: IEndUserQuestionnarieListingDataItem[] =
		useAppSelector(surveyAllListingDataQuestionnaire);

	const surveyReqData = useAppSelector(surveyListingRequest);

	const handleDateRangeChange = (StartDate: string, EndDate: string): void => {
		let updatedDateFilter: IDateFilter = date;
		if (props.type) {
			const finalDate = new Date(EndDate);
			finalDate.setHours(
				Numbers.twentyThree,
				Numbers.fiftyNine,
				Numbers.fiftyNine,
				Numbers.nineNintyNine
			);
			if (props.type === 'startDate') {
				updatedDateFilter = { ...date, startDate: StartDate, endDate: EndDate };
				dispatch(setFilterDate(updatedDateFilter));
				setDefaultDate({ startDate: date.startDate, endDate: date.endDate });
			} else {
				updatedDateFilter = {
					...date,
					createdAtStartDate: StartDate,
					createdAtEndDate: EndDate,
				};
				dispatch(setFilterDate(updatedDateFilter));
				setDefaultDate({
					startDate: date.createdAtStartDate,
					endDate: date.createdAtEndDate,
				});
			}

			if (props.scope === 'endUser') {
				handleFilterEndUser(updatedDateFilter);
			} else {
				handleFilterAdmin(updatedDateFilter);
			}
		}
	};
	const handleFilterEndUser = (updatedDateFilter: IDateFilter) => {
		const questionnairesFilteredData = filterEndUserSurveyData(
			endUserAllSurveyList,
			ensUserSurveyReq,
			updatedDateFilter
		);
		dispatch(setModifiedEndUserSurveyResponse(questionnairesFilteredData));
	};

	const handleFilterAdmin = (updatedDateFilter: IDateFilter) => {
		// Applying all filters
		const surveyFilteredDataDate = filterSurveyData(
			surveyLegacyListData,
			surveyReqData,
			updatedDateFilter
		);
		dispatch(setModifiedSurveyResponse(surveyFilteredDataDate));
	};

	useEffect(() => {
		if (props.type === 'startDate' && (date.startDate || date.endDate)) {
			setDefaultDate({
				startDate: date.startDate,
				endDate: date.endDate,
			});
		}
		if (
			(props.type === 'createdAt' || props.type === 'dueDate') &&
			(date.createdAtStartDate || date.createdAtEndDate)
		) {
			setDefaultDate({
				startDate: date.createdAtStartDate,
				endDate: date.createdAtEndDate,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date]);

	return (
		<RangePicker
			data-testid="date-calender"
			placeholder={['dd.mm.yyyy', 'dd.mm.yyyy']}
			value={
				defaultDate.startDate !== '0000/00/00'
					? [
							dayjs(defaultDate.startDate, dateFormat),
							dayjs(defaultDate.endDate, dateFormat),
						]
					: [null, null]
			}
			format={dateFormat}
			onChange={(_, values) => {
				values[0] !== '' || values[1] !== ''
					? handleDateRangeChange(values[0], values[1])
					: handleDateRangeChange('', '');
			}}
			panelRender={(panelNode) => (
				<StyledRangePickerContainer>{panelNode}</StyledRangePickerContainer>
			)}
		/>
	);
};
export default FilterCalender;
