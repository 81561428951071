import React, { useEffect } from 'react';
import { Box, styled } from '@mui/material';
import { Paper, makeStyles } from '@material-ui/core';
import { CreateQuestionnairePreviewProps } from '../../types/CommonTypes';
import PreviewQuestionnaireHeader from '../../components/questions/preview/PreviewQuestionnaireHeader';
import PreviewQuestionnaireSubHeader from '../../components/questions/preview/PreviewQuestionnaireSubHeader';
import PreviewQuestionnaireFooter from '../../components/questions/preview/PreviewQuestionnaireFooter';
import PreviewQuestionnaireBox from '../../components/questions/preview/PreviewQuestionnaireBox';
import PreviewQuestionnaireInstrucrionDetails from '../../components/questions/preview/PreviewQuestionnaireInstructionDetails';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import Loader from '../../components/Loader';
import {
	APILoadingStatus,
	configurationData,
	resetAPIFlag,
	surveyQuestionnaireData,
	updateQuestionnaireData,
} from '../../redux/SurveySlice';
import { API_STATUS_CONSTANT } from '../../constants/StepConstants';
import { getQuestionnairePayload } from '../../utils/Helpers';
import { STEPTheme } from '../../utils/Theme';

const StyledContainer = styled(Box)({
	minHeight: '400px',
	width: '100%',
	background: '#F9F8F7',
});

const useStyles = makeStyles({
	actionBox: {
		display: 'flex',
		justifyContent: 'end',
		marginTop: '20px',
	},
	plusIcon: {
		width: '0.8em',
	},
	flexPaper: {
		flex: 1,
		margin: '5px 16px',
		minWidth: '79%',
		maxWidth: '79%',
		boxShadow: 'none',
		background: STEPTheme.colors.background,
	},
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		background: STEPTheme.colors.background,
		justifyContent: 'center',
	},
});

const CreateSurveyPreview: React.FC<CreateQuestionnairePreviewProps> = ({
	handleNext,
}) => {
	const dispatch = useAppDispatch();
	const classes = useStyles();
	const [showLoader, setLoaderOpen] = React.useState(false);
	const [isSaveProceed, setSaveProceed] = React.useState(false);
	const APICompletionStatus = useAppSelector(APILoadingStatus);
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const configs = useAppSelector(configurationData);
	useEffect(() => {
		if (APICompletionStatus === API_STATUS_CONSTANT.SUCCESS) {
			setLoaderOpen(false);
			if (isSaveProceed) {
				dispatch(resetAPIFlag('idle'));
				handleNext();
			}
		} else {
			dispatch(resetAPIFlag('idle'));
			setLoaderOpen(false);
			setSaveProceed(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [APICompletionStatus]);
	const saveAsProceed = () => {
		setSaveProceed(true);
		setLoaderOpen(true);
		const payload = getQuestionnairePayload({
			...surveyQuestionnaireInfo,
			userEmailId: configs?.user?.userEmailId || '',
		});
		dispatch(updateQuestionnaireData(payload));
	};

	return (
		<StyledContainer>
			<PreviewQuestionnaireHeader></PreviewQuestionnaireHeader>
			<div className={classes.root}>
				<Paper className={classes.flexPaper}>
					<PreviewQuestionnaireSubHeader></PreviewQuestionnaireSubHeader>
				</Paper>
			</div>
			<PreviewQuestionnaireInstrucrionDetails></PreviewQuestionnaireInstrucrionDetails>
			<PreviewQuestionnaireBox></PreviewQuestionnaireBox>
			<Loader isLoading={showLoader} />
			<PreviewQuestionnaireFooter
				handleNext={saveAsProceed}
			></PreviewQuestionnaireFooter>
		</StyledContainer>
	);
};
export default CreateSurveyPreview;
