import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as JnjFooterLogo } from '../assets/images/JNJ_Logo.svg';
import { ReactComponent as JnjFooterLogoMobile } from '../assets/images/JNJ-Logo.svg';
import { STEPTheme } from '../utils/Theme';
import { commonLabels } from '../utils/CommonLabels';
import { breakPointDown_480 } from './endUser/common/makeStyles';

const StyledFooterContainer = styled('div')(({ theme }) => ({
	height: '72px',
	[theme.breakpoints.down('md')]: {
		padding: '0px 16px',
	},
	padding: '0px 72px',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	background: STEPTheme.colors.white,
	boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.12)',
	borderTop: `1px solid ${STEPTheme.colors.border}`,
	marginTop: 'auto',
}));

const StyledLogoLinksContainer = styled('div')({
	display: 'flex',
	alignItems: 'center',
});

const StyledLogo = styled(JnjFooterLogo)({
	display: 'block',
	fill: STEPTheme.colors.primary,
	height: '100%',
	width: '80%',
	[breakPointDown_480]: {
		display: 'none',
	},
});

const StyledLogoMobile = styled(JnjFooterLogoMobile)({
	display: 'none',
	fill: STEPTheme.colors.primary,
	height: '100%',
	width: '100%',
	[breakPointDown_480]: {
		display: 'block',
	},
});

const StyledTypography = styled(Typography)({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'flex-end',
	fontSize: '10px',
	lineHeight: '12px',
	fontWeight: 500,
	color: STEPTheme.colors.disabledText,
	fontFamily: '"JohnsonText-Regular"',
});

const Footer: React.FC<unknown> = () => {
	return (
		<StyledFooterContainer data-testid="footer">
			<StyledLogoLinksContainer>
				<StyledLogo />
				<StyledLogoMobile />
			</StyledLogoLinksContainer>
			<StyledTypography variant="caption">
				{commonLabels.allRightsReserved}
			</StyledTypography>
		</StyledFooterContainer>
	);
};

export default Footer;
