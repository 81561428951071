import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { Button } from 'antd';
import { useRef, useState } from 'react';
import { questionnaireResultsLabels } from '../../utils/CommonLabels';
import { ReactComponent as InfoCircle } from '../../assets/images/info-circle.svg';
import { ReactComponent as AlertCircle } from '../../assets/images/alert-circle.svg';
import { ReactComponent as Refresh } from '../../assets/images/refresh.svg';
import { STEPTheme } from '../../utils/Theme';
import { ICustomInfoChartProps } from '../../types/CommonTypes';
import { Numbers } from '../../utils/Enum';
import LoadingIcon from '../questionnaire-results/LoadingIcon';

const InfoContainer = styled(Box)({
	margin: 'auto',
	width: 'fit-content',
	textAlign: 'center',
});

const StyledHeader = styled(Typography)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '18px',
	fontWeight: Numbers.sevenHundred,
	lineHeight: '23.22px',
});

const StyledBody = styled(Typography)({
	fontFamily: 'JohnsonText-Regular',
	fontWeight: Numbers.hundred,
});

const RefreshButton = styled(Button)({
	fontFamily: 'JohnsonText-Regular',
	backgroundColor: STEPTheme.colors.primary,
	color: STEPTheme.colors.white,
	'&:hover': {
		backgroundColor: `${STEPTheme.colors.primary} !important`,
		color: `${STEPTheme.colors.white} !important`,
		filter: 'brightness(80%)',
	},
});

const CustomInfoChart = (props: ICustomInfoChartProps) => {
	const refreshSvg = useRef<HTMLInputElement>(null);
	const [buttonHovered, setButtonHovered] = useState(false);

	const handleRefreshButtonHover = () => {
		if (refreshSvg.current) {
			if (buttonHovered) {
				refreshSvg.current.style.padding = '3px';
			} else {
				refreshSvg.current.style.padding = '3px 0 3px 10px';
			}
			setButtonHovered(!buttonHovered);
		}
	};

	const returnIconBasedOnType = () => {
		if (props.type === 'info') {
			return <InfoCircle />;
		} else if (props.type === 'error') {
			return <AlertCircle />;
		} else {
			return <LoadingIcon />;
		}
	};

	return (
		<InfoContainer>
			{returnIconBasedOnType()}
			<StyledHeader>{props.titleText}</StyledHeader>
			<br />
			<StyledBody>{props.bodyText}</StyledBody>
			<br />
			{props.type === 'error' && (
				<RefreshButton
					onMouseEnter={handleRefreshButtonHover}
					onMouseLeave={handleRefreshButtonHover}
					onClick={props.handleRefresh}
				>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box>{questionnaireResultsLabels.refreshTxt}</Box>
						<div ref={refreshSvg} style={{ padding: '3px' }}>
							<Refresh />
						</div>
					</Box>
				</RefreshButton>
			)}
		</InfoContainer>
	);
};

export default CustomInfoChart;
