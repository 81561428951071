import { ApiErrorMappingLabels } from '../utils/CommonLabels';
import { errorMapping } from '../utils/ErrorMapping';
export const getErrorMessage = (
	status: number,
	customCode: string,
	message: string
): string => {
	const errorCategory = errorMapping[status];
	if (errorCategory) {
		// Checking for the specific message and error code combination
		if (
			customCode === ApiErrorMappingLabels.apiExceptionCode &&
			message === ApiErrorMappingLabels.apiErrorMsgTxt
		) {
			return '';
		}
		return errorCategory[customCode] || ApiErrorMappingLabels.apiError;
	}
	return ApiErrorMappingLabels.apiError;
};
