import { useState, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import PropTypes from 'prop-types';
import '../../assets/css/styles.css';
import { COMMON } from '../../utils/Enum';

interface TextEditorProps {
	OnchangeHandler: (event: string) => void;
	emailTemplate: string;
	charLimit?: number;
}

const TextEditor: React.FC<TextEditorProps> = ({
	OnchangeHandler,
	emailTemplate,
	charLimit,
}) => {
	const editor = useRef(null);
	const [content, setContent] = useState(emailTemplate);
	const onChange = (newContent: string) => {
		OnchangeHandler(newContent);
	};
	const config = useMemo(
		() => ({
			readonly: false,
			limitChars: charLimit ?? COMMON.DEFAULT_CHAR_LIMIT,
			askBeforePasteFromWord: false,
			askBeforePasteHTML: false,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return (
		<div>
			<JoditEditor
				ref={editor}
				value={content}
				onBlur={(newContent) => setContent(newContent)}
				onChange={onChange}
				config={config}
			/>
		</div>
	);
};
TextEditor.propTypes = {
	OnchangeHandler: PropTypes.func.isRequired,
	emailTemplate: PropTypes.string.isRequired,
	charLimit: PropTypes.number,
};
export default TextEditor;
