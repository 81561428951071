import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import { Box, BoxProps } from '@mui/material';
import { useDispatch } from 'react-redux';
import StyledTooltip from '../StyledTooltip';
import { questionsLabels } from '../../utils/QuestionsLabels';
import { QuestionInstructionProps } from '../../types/CommonTypes';
import { useAppSelector } from '../../app/Hooks';
import {
	setCheckboxState,
	setInstructions,
} from '../../redux/QuestionnaireSurveySlice';
import { RootState } from '../../app/Store';
import {
	MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION,
	VALIDATIONS_RULES,
} from '../../constants/StepConstants';
import { Numbers } from '../../utils/Enum';
import { commonLabels } from '../../utils/CommonLabels';

interface AddInstructionsProps extends BoxProps {
	readonly?: boolean;
}

const useStyles = makeStyles({
	formControlInputIns: {
		'&.MuiFormControl-root': {
			width: '100%',
			marginLeft: '15px',
		},
	},
	mainDiv: {
		display: 'flex',
		marginTop: '10px',
	},
	subDiv: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
	},
	textField: {
		display: 'flex',
		width: '100%',
		paddingLeft: '20px',
	},
	checkBox: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: '12px',
	},
});

const AddInstructions = styled(Box)<AddInstructionsProps>(({ readonly }) => ({
	fontFamily: '"JohnsonText-Regular"',
	fontSize: '14px',
	fontWeight: '400',
	lineHeight: '16px',
	paddingLeft: '18px',
	paddingTop: '2px',
	color: readonly ? '#A39992' : '#212121',
}));

const QuestionInstruction: React.FC<QuestionInstructionProps> = ({
	instructions,
	handleError,
	onUpdateInstructions,
	readonly,
	questionId,
}) => {
	const classes = useStyles({ readonly });
	const dispatch = useDispatch();
	const checkboxState = useAppSelector(
		(state: RootState) => state.Questionnaire.checkboxState[questionId] || false
	);
	const [localInstructions, setLocalInstructions] = useState(instructions);
	const [, setLocalInstructionsLength] = useState(Numbers.twoFifty);
	const [error, setError] = useState(false);
	const [helperText, setHelperText] = useState('');

	const handleSetError = () => {
		setError(false);
		setHelperText('');
		handleError(false);
	};

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const eventValue = event.target.value;
		const trimmedValue = eventValue.trimStart();
		if (eventValue !== trimmedValue || eventValue === '') {
			handleSetError();
		} else if (
			!VALIDATIONS_RULES.VALIDATION_COMMON_INPUT_FIELDS.test(eventValue)
		) {
			setError(true);
			setHelperText(commonLabels.incorrectQuestionnaireDescFormatErrorMsg);
			handleError(true);
		} else {
			handleSetError();
		}
		dispatch(setInstructions(trimmedValue));
		setLocalInstructions(trimmedValue);
		onUpdateInstructions(trimmedValue);
		setLocalInstructionsLength(Numbers.twoFifty - trimmedValue?.length);
	};

	useEffect(() => {
		setLocalInstructions(instructions);
		onUpdateInstructions(instructions ?? '');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instructions]);

	const handleCheckBoxChange = () => {
		dispatch(setCheckboxState({ questionId, value: !checkboxState }));
	};

	return (
		<Box className={classes.mainDiv} data-testid="question-instruction">
			<Box className={classes.subDiv}>
				<Box style={{ height: '14px', width: '14px' }}>
					<Checkbox
						data-testid="question-checked"
						onChange={handleCheckBoxChange}
						disabled={readonly}
						checked={checkboxState || !!localInstructions}
					/>
				</Box>
				<Box className={classes.textField}>
					{checkboxState || localInstructions ? (
						<StyledTooltip
							title={questionsLabels.instructionTooltip}
							placement="top"
						>
							<TextField
								className={classes.formControlInputIns}
								margin="dense"
								placeholder={questionsLabels.addQueInsDes}
								id="name"
								data-testid="instruction"
								type="text"
								fullWidth
								disabled={readonly}
								value={localInstructions}
								variant="standard"
								onChange={handleInputChange}
								inputProps={{
									maxLength: MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION,
								}}
								error={error}
								helperText={helperText}
							/>
						</StyledTooltip>
					) : (
						<Box className={classes.checkBox}>
							<AddInstructions readonly={readonly}>
								<label>{questionsLabels.addQueInsDes}</label>
							</AddInstructions>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
};
export default QuestionInstruction;
