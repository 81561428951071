/* eslint-disable no-dupe-else-if */
import React from 'react';
import { Box, Button, styled } from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { makeStyles } from '@material-ui/core';
import { STEPTheme } from '../../utils/Theme';
import { commonLabels } from '../../utils/CommonLabels';
import { QuestionnaireFooterProps } from '../../types/CommonTypes';

const useStyles = makeStyles({
	actionBox: {
		display: 'flex',
		justifyContent: 'end',
		marginTop: '180px',
	},
	actionTopBox: {
		display: 'flex',
		justifyContent: 'end',
		marginTop: '5px',
	},
	questionBox: {
		display: 'flex',
		justifyContent: 'end',
		marginTop: '34px',
	},
	plusIcon: {
		width: '0.8em',
	},
	saveAsDraft: {
		width: '144px',
		height: '32px',
	},
});

const ActionButton = styled(Button)({
	width: 'auto',
	height: '32px',
	marginLeft: '10px',
	color: STEPTheme.colors.white,
	background: STEPTheme.colors.primary,
	textTransform: 'capitalize',
	padding: '8px 8px 8px 16px',
	borderRadius: '8px',
	justify: 'space-between',
	'&:hover': {
		backgroundColor: STEPTheme.colors.primary,
		borderColor: STEPTheme.colors.primary,
	},
});
const QuestionnaireFooter: React.FC<QuestionnaireFooterProps> = ({
	handleNext,
	handleSaveDraft,
	buttonEnable,
	index,
}) => {
	const classes = useStyles();
	if (index === 0) {
		return (
			<Box className={classes.actionBox} data-testid="question-footer">
				<Button
					className={classes.saveAsDraft}
					variant="outlined"
					color="secondary"
					onClick={handleSaveDraft}
					disabled={buttonEnable}
				>
					{commonLabels.saveAsDraft}
				</Button>
				<ActionButton
					onClick={handleNext}
					disabled={buttonEnable}
					variant="contained"
					color="primary"
				>
					{commonLabels.saveProceed}
					<ArrowForward className={classes.plusIcon} />
				</ActionButton>
			</Box>
		);
	}
	return (
		<Box className={classes.actionTopBox} data-testid="question-footer">
			<Button
				data-testid="save-as-draft"
				className={classes.saveAsDraft}
				variant="outlined"
				color="secondary"
				onClick={handleSaveDraft}
				disabled={buttonEnable}
			>
				{commonLabels.saveAsDraft}
			</Button>
			<ActionButton
				data-testid="save-and-proceed"
				onClick={handleNext}
				disabled={buttonEnable}
				variant="contained"
				color="primary"
			>
				{commonLabels.saveProceed}
				<ArrowForward className={classes.plusIcon} />
			</ActionButton>
		</Box>
	);
};
export default QuestionnaireFooter;
