import { Box, Checkbox, Divider, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { MappingScreenLabels } from '../../utils/CommonLabels';
import { IconButton } from '../common/IconButton';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/Copy-logo-off.svg';
import { IGroup, IGroupCheckBoxState } from '../../types/MappingTypes';
import { Numbers } from '../../utils/Enum';
import StyledTooltip from '../StyledTooltip';
import { STEPTheme } from '../../utils/Theme';
import { TextBlueButton } from './common/TextButtonBlue';

const StyledGroupListLabel = styled(Typography)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '16px',
	fontWeight: '500',
	lineHeight: '16px',
	gap: '4px',
	padding: '10px 15px',
});

const GroupCountContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	padding: '0 5px',
});

const GroupListItem = styled(Box)({
	display: 'flex',
	height: '30px',
	cursor: 'pointer',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textWrap: 'nowrap',
	width: '17.5vw',
	margin: 'auto',
});
// Component to display the list of groups
const MappingGroupList = (props: {
	groupCount: number;
	addGroup: () => void;
	groups: IGroup[];
	selectGroup: (groupId: string) => void;
	selectedGroup: string;
	copiedGroup: string;
	copyGroup: (groupId: string) => void;
	deleteGroup: () => void;
	checkBoxState: IGroupCheckBoxState;
	onCheckBoxChange: (id: string, checked: boolean) => void;
}) => {
	const groups = props.groups.filter((group) => {
		if (!(group.groupId === '0' || group.groupId === '-1')) {
			return group;
		}
	});
	const groupCount = groups.length;
	// Count of currently selected checkboxes
	const selectedCheckboxCount = Object.values(props.checkBoxState).filter(
		Boolean
	).length;
	// On click of checkbox calls the onCheckBoxChange function received as props
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { id, checked } = event.target;
		props.onCheckBoxChange(id, checked);
		// selectedCheckboxcount updating one more render, added one
		if (groupCount === selectedCheckboxCount + Numbers.one) {
			setRenderCounter(Numbers.two);
			setIsSelectAllChecked(true);
		}
		if (!checked) {
			setRenderCounter(Numbers.one);
			setIsSelectAllChecked(false);
		}
	};
	/**This counter required to force render dom  */
	const [renderCounter, setRenderCounter] = useState(Numbers.one);
	const [isSelectAllCheckeded, setIsSelectAllChecked] = useState(false);

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = event.target;
		props.groups.forEach((group) => {
			if (!(group.groupId === '0' || group.groupId === '-1')) {
				props.onCheckBoxChange(group.groupId, checked);
			}
		});
		if (checked) {
			setRenderCounter(Numbers.two);
			setIsSelectAllChecked(true);
		} else {
			setRenderCounter(Numbers.one);
			setIsSelectAllChecked(false);
		}
	};

	const renderGroupTree = () => {
		return (
			<Box
				sx={{
					minHeight: '44vh',
					marginBottom: '1vh',
					maxHeight: '44vh',
					overflow: 'auto',
				}}
			>
				{groupCount > Numbers.zero &&
					groups.map((group) => {
						return (
							<GroupListItem
								sx={
									group.groupId === props.selectedGroup
										? { backgroundColor: STEPTheme.colors.grayBackground }
										: {}
								}
								key={group.groupId}
							>
								<Checkbox
									data-testid={`checkbox-listItem-${group.groupId}`}
									sx={{
										'& .MuiSvgIcon-root': {
											width: '16px',
										},
									}}
									onChange={handleChange}
									checked={props.checkBoxState[group.groupId]}
									id={group.groupId}
								/>
								<StyledTooltip title={group.groupName} placement="right">
									<Typography
										data-testid={'group-name'}
										onClick={() => {
											props.selectGroup(group.groupId);
										}}
										sx={{
											fontWeight: 400,
											padding: '3px 0',
											width: '12vw',
											overflow: 'hidden',
											'& .MuiTypography-root': {
												fontSize: '16px',
												lineHeight: '16px',
												alignSelf: 'center',
											},
										}}
									>
										{group.groupName}
									</Typography>
								</StyledTooltip>
								<StyledTooltip
									title={MappingScreenLabels.clickToCopy}
									placement="right"
								>
									<Box
										data-testid="test-copy-btn-click"
										onClick={() => {
											props.copyGroup(group.groupId);
										}}
										sx={{ padding: '5px', marginLeft: '1vw', height: '14px' }}
									>
										<CopyIcon />
									</Box>
								</StyledTooltip>
							</GroupListItem>
						);
					})}
			</Box>
		);
	};

	useEffect(() => {
		if (groupCount === 0) {
			setRenderCounter(Numbers.one);
			setIsSelectAllChecked(false);
		} else {
			// not required: only added for sonar rules
		}
	}, [groupCount]);

	return (
		<Box>
			<StyledGroupListLabel>
				{MappingScreenLabels.groupListLabel}
			</StyledGroupListLabel>
			<Divider />
			<GroupCountContainer>
				<Box
					sx={{
						paddingTop: '5px',
						fontFamily: 'JohnsonText-Regular',
						fontSize: '14px',
						lineHeight: '16px',
						fontWeight: 500,
						paddingLeft: '10px',
					}}
				>
					<Checkbox
						disabled={groupCount === 0}
						sx={{
							padding: '0 5px 3px 0px',
							'& .MuiSvgIcon-root': {
								width: '16px',
							},
						}}
						onChange={handleSelectAll}
						checked={groupCount > 0 && isSelectAllCheckeded}
					/>
					{`${groupCount} ${MappingScreenLabels.groupLabel}`}
				</Box>
				<TextBlueButton
					data-testid="text-blue-btn"
					onClick={() => {
						props.deleteGroup();
					}}
					type="text"
					// If count of group is zero or no checkboxes are selected then delete button is disabled
					disabled={groupCount < 1 || selectedCheckboxCount < 1}
				>
					{MappingScreenLabels.delete}
				</TextBlueButton>
			</GroupCountContainer>
			{/* Force rendering of tree on select ALL*/}
			{renderCounter === Numbers.one &&
				!isSelectAllCheckeded &&
				renderGroupTree()}
			{renderCounter === Numbers.two &&
				isSelectAllCheckeded &&
				renderGroupTree()}
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<IconButton
					data-testid="icon-btn"
					style={{ width: '17.5vw', height: '32px' }}
					iconType={<PlusIcon />}
					align="start"
					labelText={MappingScreenLabels.createGroupLabel}
					onClick={props.addGroup}
				/>
			</Box>
		</Box>
	);
};

export default MappingGroupList;
