import React from 'react';
import { Box, styled, Typography, Stack } from '@mui/material';
import { ReactComponent as InfoIcon } from '../../assets/images/InfoIcon.svg';
import { questionnaireResultsLabels } from '../../utils/CommonLabels';
import { Numbers } from '../../utils/Enum';

const NoContentContainer = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	flexDirection: 'column',
});
interface NoContentProps {
	lineOneMessage: string;
	lineTwoMessage?: string;
	height?: string;
	heading?: string;
}

export const NoResultContentFound: React.FC<NoContentProps> = ({
	lineOneMessage,
	heading,
	lineTwoMessage,
	height = '240px',
}) => {
	return (
		<Stack data-testid="no-content" sx={{ width: '100%' }}>
			{heading && (
				<Typography
					variant="h1"
					sx={{ fontSize: '24px', fontWeight: Numbers.fiveHundred }}
				>
					{heading}
				</Typography>
			)}
			<NoContentContainer sx={{ height: height }}>
				<InfoIcon />
				<Typography variant="h3" sx={{ margin: '14px 0px', fontWeight: '700' }}>
					{questionnaireResultsLabels.noDataFound}
				</Typography>
				<Typography variant="body2">{lineOneMessage}</Typography>
				{lineTwoMessage && (
					<Typography variant="body2">{lineTwoMessage}</Typography>
				)}
			</NoContentContainer>
		</Stack>
	);
};

export default NoResultContentFound;
