import './App.css';
import './assets/css/styles.css';
import './assets/css/mui-overwrite.css';
import './assets/css/base.css';
import { styled, ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import {
	InteractionRequiredAuthError,
	IPublicClientApplication,
} from '@azure/msal-browser';
import { Box } from '@mui/material';
import { commonLabels, notificationsLabels } from '../src/utils/CommonLabels';
import Header from './components/Header';
import Footer from './components/Footer';
import RouterOutlet from './app/RouterOutlet';
import { stepMuiTheme, STEPTheme } from './utils/Theme';
import {
	configurationData,
	fetchConfigData,
	setConfigurationData,
} from './redux/SurveySlice';
import { useAppDispatch, useAppSelector } from './app/Hooks';
import { checkRolePermissions } from './utils/Helpers';
import { getAuthDetails } from './app/authentication/authToken';
import { loginRequest, msalConfig } from './app/authentication/AuthConfig';
import GenericConfirmationPopup from './components/common/GenericConfirmationPopup';
import { Numbers } from './utils/Enum';

declare global {
	interface Window {
		msalInstance: IPublicClientApplication;
		msalUserName: string;
	}
}

const STEPContainer = styled('div')`
	padding: 0px 0px;
	margin: 0px 0px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	background: ${STEPTheme.colors.background};
`;

const App = () => {
	const cookieAuth = getAuthDetails();
	const { instance } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const AUTHENTICATION_TIMEOUT = 500;
	const [userData, setUserData] = useState(cookieAuth);
	const dispatch = useAppDispatch();
	const configs = useAppSelector(configurationData);
	const isDev =
		process.env.REACT_APP_ENVIRONMENT &&
		process.env.REACT_APP_ENVIRONMENT.toLowerCase() === 'dev';
	const renewalTime = {
		five: 5,
		sixty: 60,
		thousand: 1000,
	};
	const TOKEN_RENEWAL_TIME =
		renewalTime.five * renewalTime.sixty * renewalTime.thousand;
	const LOGIN_TIMEOUT = 1500;
	const [showErrorPopup, setShowErrorPopup] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showRedirectPopup, setShowRedirectPopup] = useState(false);
	const [showInactivityPopup, setShowInactivityPopup] = useState(false);
	let interval: NodeJS.Timeout | undefined;
	let userActivityTimeout: NodeJS.Timeout | undefined;
	let redirectTimeout: NodeJS.Timeout | undefined;

	useEffect(() => {
		if (!isAuthenticated) {
			setTimeout(() => {
				if (instance.getAllAccounts().length === 0) {
					instance
						.handleRedirectPromise()
						.then((_response) => {
							// eslint-disable-next-line no-console
							console.log('response', _response); //NOSONAR
						})
						.catch((err) => {
							// eslint-disable-next-line no-console
							console.log('handle Redirect Promise not found', err); //NOSONAR
						});

					if (!localStorage['redirectURI']) {
						localStorage['redirectURI'] = window.location.pathname;
					}
					instance.loginRedirect(loginRequest).catch((e) => {
						// eslint-disable-next-line no-console
						console.log('login Redirect error found', e); //NOSONAR
					});
				}
			}, AUTHENTICATION_TIMEOUT);
		} else {
			instance.setActiveAccount(instance.getAllAccounts()[0]);
			const userName = instance.getAllAccounts()[0].username;
			window.msalInstance = instance;
			window.msalUserName = userName;
			setUserData(getAuthDetails());
			if (localStorage['redirectURI'] && localStorage['redirectURI'] !== '') {
				const redirectURI = localStorage['redirectURI'];
				localStorage.removeItem('redirectURI');
				window.location.pathname = redirectURI;
			}
			setTimeout(() => {
				window.location.hash = '';
			}, LOGIN_TIMEOUT);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAuthenticated]);

	useEffect(() => {
		window.scrollTo(0, 0);
		checkToken();
		removeRedirectUrl();
		const account = instance.getAllAccounts()[0];
		const userName = account?.username;
		window.msalInstance = instance;
		window.msalUserName = userName;
		// Set a 5min timer for token refresh
		getNewToken();

		setTimeout(() => {
			window.location.hash = '';
		}, LOGIN_TIMEOUT);
		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	useEffect(() => {
		trackUserActivity();
		// empty dependency array- for the use effect to be triggered in every render
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (configs?.rolePermissions?.isAdmin && !configs?.location) {
			dispatch(fetchConfigData());
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configs?.rolePermissions]);

	// After one minute of showing inactivity popup- logout user
	const handleUserInactivity = () => {
		setShowInactivityPopup(true);
		// Logout after 1 min of showing pop-up
		redirectTimeout = setTimeout(() => {
			handleLogout();
		}, Numbers.sixtyThousand);
	};

	// Logout user
	const handleLogout = () => {
		localStorage.clear();
		// Clear all event listeners
		removeEventListeners();
		// This will trigger logout from DTH
		window.location.replace(
			`${process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI}`
		);
	};

	// Reset timeout in case of any activity by user
	const resetTimer = () => {
		clearTimeout(userActivityTimeout);
		clearTimeout(redirectTimeout);
		// 45 mins of inactivity will trigger popup
		userActivityTimeout = setTimeout(
			handleUserInactivity,
			Numbers.TIME_FOURTY_FIVE_IN_MILLI_SECS
		);
	};

	// Silently acquire token using configs
	const acquireToken = () => {
		const account = instance.getAllAccounts()[0];
		const userName = account?.username;
		const config = {
			scopes: loginRequest.scopes,
			authority: msalConfig.auth.authority,
			account: account,
		};
		const redRequest = {
			scopes: loginRequest.scopes,
			loginHint: userName,
		};
		instance
			.acquireTokenSilent(config)
			.then(
				(res) => {
					// eslint-disable-next-line no-console
					console.log('Token Renewed...', isDev ? res : {}); //NOSONAR
				},
				(err) => {
					if (err instanceof InteractionRequiredAuthError) {
						return instance.acquireTokenRedirect(redRequest);
					}
				}
			)
			.catch((error) => {
				if (error instanceof InteractionRequiredAuthError) {
					return instance.acquireTokenRedirect(redRequest);
				}
			});
	};

	const getNewToken = () => {
		// Request a new token in every 5 mins
		interval = setInterval(() => {
			acquireToken();
		}, TOKEN_RENEWAL_TIME);
	};

	const removeEventListeners = () => {
		window.removeEventListener('load', resetTimer, true);
		window.removeEventListener('mousemove', resetTimer, true);
		window.removeEventListener('mousedown', resetTimer, true);
		window.removeEventListener('touchstart', resetTimer, true);
		window.removeEventListener('touchmove', resetTimer, true);
		window.removeEventListener('click', resetTimer, true);
		window.removeEventListener('keydown', resetTimer, true);
		window.removeEventListener('scroll', resetTimer, true);
		window.removeEventListener('wheel', resetTimer, true);
	};

	const trackUserActivity = () => {
		window.addEventListener('load', resetTimer, true);
		window.addEventListener('mousemove', resetTimer, true);
		window.addEventListener('mousedown', resetTimer, true);
		window.addEventListener('touchstart', resetTimer, true);
		window.addEventListener('touchmove', resetTimer, true);
		window.addEventListener('click', resetTimer, true);
		window.addEventListener('keydown', resetTimer, true);
		window.addEventListener('scroll', resetTimer, true);
		window.addEventListener('wheel', resetTimer, true);
	};

	showRedirectPopUp = () => {
		setShowRedirectPopup(true);
	};

	showErrorPopUp = (message: string) => {
		setErrorMessage(message);
		setShowErrorPopup(true);
	};

	const handleClosePopup = () => {
		setShowErrorPopup(false);
	};

	const checkToken = () => {
		if (userData?.idToken) {
			dispatch(
				setConfigurationData({
					...configs,
					user: userData,
					rolePermissions: checkRolePermissions(),
				})
			);
		}
	};

	const removeRedirectUrl = () => {
		if (localStorage['redirectURI'] && localStorage['redirectURI'] !== '') {
			const redirectURI = localStorage['redirectURI'];
			localStorage.removeItem('redirectURI');
			window.location.pathname = redirectURI;
		}
	};

	if (userData?.idToken === '') {
		// Until msal initializes show a wrapper
		return <div>{commonLabels.waitMsg}</div>;
	} else {
		// User is authenticated with valid token
		return (
			<ThemeProvider theme={stepMuiTheme}>
				<Box
					sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
				>
					<Header />
					<STEPContainer>
						<GenericConfirmationPopup
							open={showRedirectPopup || showInactivityPopup}
							msgBody={
								showRedirectPopup
									? commonLabels.sessionExpiredText
									: commonLabels.sessionGoingToExpireInactivity
							}
							title={
								showRedirectPopup
									? commonLabels.sessionExpiredTitle
									: commonLabels.userIdlePopupTitle
							}
							rightBtnText={
								showRedirectPopup
									? commonLabels.redirectButtonLabel
									: commonLabels.extendButtonText
							}
							leftBtnText={''}
							isRightBtnVisible={true}
							isLeftBtnVisible={false}
							rightBtnHandler={() => {
								if (showRedirectPopup) {
									handleLogout();
								} else {
									// Reset the timer
									resetTimer();
									setShowInactivityPopup(false);
									acquireToken();
								}
							}}
							leftBtnHandler={() => {}}
						></GenericConfirmationPopup>
						<GenericConfirmationPopup
							open={showErrorPopup}
							title={notificationsLabels.errorTitle}
							msgBody={errorMessage}
							rightBtnText={commonLabels.ok}
							leftBtnText={commonLabels.no}
							isRightBtnVisible={true}
							isLeftBtnVisible={false}
							rightBtnHandler={handleClosePopup}
							leftBtnHandler={handleClosePopup}
						/>
						<RouterOutlet />
					</STEPContainer>
					<Footer />
				</Box>
			</ThemeProvider>
		);
	}
};

export default App;
export let showRedirectPopUp: () => void;
export let showErrorPopUp: (message: string) => void;
