import { AxiosRequestConfig } from 'axios';
import axiosIntercept from '../app/authentication/axiosIntercept';
import {
	EndUserListingPayload,
	UpdateMyQuestionnairesPayload,
} from '../types/CommonTypes';
import { IType } from '../types/EnduserType';

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
	skipGlobalErrorHandling?: boolean;
}

export const GetQuestionnairesForMe = async () => {
	return axiosIntercept.get('myquestionnaire');
};

export const GetQuestionnaireDetailsCW = async (
	requestPayload: IType,
	config: CustomAxiosRequestConfig = {}
) => {
	return axiosIntercept.get(`myquestionnaire/${requestPayload.id}`, {
		...config,
		skipGlobalErrorHandling: true,
	} as AxiosRequestConfig);
};

export const GetUsersListForCompletingQuestionnaire = async (
	requestPayload: EndUserListingPayload
) => {
	return axiosIntercept.post(
		'myquestionnaire/fetchquestionnaire',
		requestPayload.endUserListingObj
	);
};

export const UpdateMyQuestionnaires = async (
	requestPayload: UpdateMyQuestionnairesPayload
) => {
	return axiosIntercept.put(
		`myquestionnaire/${requestPayload.updateMyQuestionnairesObj.questionnaireId}`,
		requestPayload.updateMyQuestionnairesObj
	);
};
