import { Box, Button, IconButton } from '@mui/material';
import { questionnaireResultsLabels } from '../../utils/CommonLabels';
import StyledTooltip from '../StyledTooltip';
import { ReactComponent as DownloadIcon } from '../../assets/images/download-01.svg';

// type=icon will show only icon, if any other text passed it will be shown as text button with icon
const Download = (props: { type: string; handleDownload: () => void }) => {
	return (
		<StyledTooltip
			title={questionnaireResultsLabels.completeDownloadTooltip}
			placement="top-end"
		>
			<Box>
				{props.type === 'icon' ? (
					<IconButton aria-label="download" onClick={props.handleDownload}>
						<DownloadIcon />
					</IconButton>
				) : (
					<Button
						variant="outlined"
						color="secondary"
						startIcon={<DownloadIcon />}
						onClick={props.handleDownload}
					>
						{props.type}
					</Button>
				)}
			</Box>
		</StyledTooltip>
	);
};

export default Download;
