import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/Store';
import { TabPanelProps } from '../types/CommonTypes';
const initialState: TabPanelProps = {
	index: 0,
	value: 0,
};
export const NavBarSlice = createSlice({
	name: 'navbar',
	initialState,
	reducers: {
		setNavBarPayload: (state, action: PayloadAction<{ val: number }>) => {
			const { val } = action.payload;
			state.value = val;
		},
	},
});

export const navbar = (state: RootState) => state.navbar.value;
export const { setNavBarPayload } = NavBarSlice.actions;
export default NavBarSlice.reducer;
