import React from 'react';
import { styled } from '@mui/material/styles';
import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	Typography,
} from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { STEPTheme } from '../../utils/Theme';
import { DialogProps } from '../../types/CommonTypes';
import { questionsLabels } from '../../utils/QuestionsLabels';
import { QUECONSTANT } from '../../constants/StepConstants';
import StyledTooltip from '../StyledTooltip';

const ActionButton = styled(Button)({
	width: '200px',
	height: '32px',
	textTransform: 'none',
	color: STEPTheme.colors.white,
	background: STEPTheme.colors.text,
	padding: '1px 10px',
	borderRadius: '6px',
	whiteSpace: 'nowrap',
	fontSize: 'small',
	border: `1px solid ${STEPTheme.colors.text}`,
	wordWrap: 'break-word',
	marginRight: '8px',
	'&:hover': {
		backgroundColor: STEPTheme.colors.text,
		borderColor: STEPTheme.colors.white,
	},
});

const TitleContainer = styled('div')({
	display: 'flex',
	alignItems: 'center',
	whiteSpace: 'nowrap',
});

const CloseIcon = styled(CloseOutlinedIcon)({
	marginLeft: '290px',
	cursor: 'pointer',
});

const QuestionPopup: React.FC<DialogProps> = ({ open, onClose }) => {
	const handleClose = (e: string) => {
		onClose(e);
	};
	return (
		<Dialog
			open={open}
			data-testid="close"
			onClose={() => handleClose('close')}
			PaperProps={{ style: { borderRadius: 10, maxWidth: '640px' } }}
		>
			<DialogTitle style={{ marginBottom: '20px' }}>
				<TitleContainer>
					<Typography variant="h1">
						{questionsLabels.selectYourQuestionType}
					</Typography>
					<CloseIcon
						data-testid="close-icon"
						onClick={() => handleClose('close')}
					/>
				</TitleContainer>
			</DialogTitle>
			<DialogActions>
				<StyledTooltip
					title={questionsLabels.multiChoiceQuestionTooltip}
					placement="top"
				>
					<ActionButton
						data-testid="multipleChoice"
						variant="outlined"
						size="small"
						onClick={() =>
							handleClose(QUECONSTANT.QUESTION_TYPE_MULTIPLE_OPTION)
						}
					>
						{questionsLabels.multipleChoice}
					</ActionButton>
				</StyledTooltip>
				<ActionButton
					data-testid="yesNo"
					variant="outlined"
					onClick={() => handleClose(QUECONSTANT.QUESTION_TYPE_YES_NO)}
				>
					{questionsLabels.yesNo}
				</ActionButton>
				<StyledTooltip
					title={questionsLabels.multiSelectQuestionTooltip}
					placement="top"
				>
					<ActionButton
						data-testid="multiSelect"
						variant="outlined"
						onClick={() => handleClose(QUECONSTANT.QUESTION_TYPE_MULTI_SELECT)}
					>
						{questionsLabels.multiSelect}
					</ActionButton>
				</StyledTooltip>
				<StyledTooltip
					title={questionsLabels.openEndQuestionTooltip}
					placement="top"
				>
					<ActionButton
						data-testid="openEnded"
						variant="outlined"
						onClick={() => handleClose(QUECONSTANT.QUESTION_TYPE_OPEN)}
					>
						{questionsLabels.openEnded}
					</ActionButton>
				</StyledTooltip>
				<StyledTooltip
					title={questionsLabels.dropDownQuestionTooltip}
					placement="top"
				>
					<ActionButton
						data-testid="dropDownQue"
						variant="outlined"
						onClick={() => handleClose(QUECONSTANT.QUESTION_TYPE_DROPDOWN)}
					>
						{questionsLabels.dropDownQue}
					</ActionButton>
				</StyledTooltip>
			</DialogActions>
		</Dialog>
	);
};

export default QuestionPopup;
