import React from 'react';
import { Grid, Typography, Stack, styled } from '@mui/material';
import { questionnaireResultsLabels } from '../../utils/CommonLabels';
import KeyMetricsItemError from './KeyMetricsItemError';

const KeyMetricsContainer = styled(Stack)({
	gap: '20px',
	width: '100%',
});

const KeyMetricsItemContainer = styled(Grid)({
	width: '100%',
	gridTemplateColumns: '1fr 1fr 1fr',
	justifyContent: 'space-between',
	marginBottom: '30px',
	borderRadius: '12px',
});

interface KeyMetricsProps {
	triggerSelectedDays: (selectedDay: number) => void;
	selectedDay: number;
	refreshFn: () => void;
}

export const KeyMetricsError: React.FC<KeyMetricsProps> = ({
	triggerSelectedDays,
	selectedDay,
	refreshFn,
}) => {
	return (
		<KeyMetricsContainer>
			<Typography variant="h1">
				{questionnaireResultsLabels.keyMetrics}
			</Typography>
			<KeyMetricsItemContainer container>
				{
					<KeyMetricsItemError
						metricsType={questionnaireResultsLabels.metricsTypeAtRisk}
						metricsLabelLineOne={questionnaireResultsLabels.comingDue}
						triggerSelectedDays={triggerSelectedDays}
						selectedDay={selectedDay}
						refreshFn={refreshFn}
						status={questionnaireResultsLabels.error}
					/>
				}
				{
					<KeyMetricsItemError
						metricsType={questionnaireResultsLabels.metricsTypeOverdue}
						metricsLabelLineOne={questionnaireResultsLabels.notCompliant}
						triggerSelectedDays={triggerSelectedDays}
						selectedDay={selectedDay}
						refreshFn={refreshFn}
						status={questionnaireResultsLabels.error}
					/>
				}
				{
					<KeyMetricsItemError
						metricsType={questionnaireResultsLabels.metricsTypeDefaulter}
						metricsLabelLineOne={questionnaireResultsLabels.defaulters}
						metricsLabelLineTwo={questionnaireResultsLabels.after}
						triggerSelectedDays={triggerSelectedDays}
						selectedDay={selectedDay}
						refreshFn={refreshFn}
						status={questionnaireResultsLabels.error}
					/>
				}
			</KeyMetricsItemContainer>
		</KeyMetricsContainer>
	);
};

export default KeyMetricsError;
