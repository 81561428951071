import { Routes, Route } from 'react-router-dom';
import ListingPage from '../pages/ListingPage';
import CreateSurvey from '../pages/create-survey/CreateSurvey';
import QuestionnaireResult from '../pages/questionnaire-results/QuestionnaireResult';
import LandingScreen from '../components/endUser/LandingScreen';
import CWQuestionnairePage from '../pages/CwQuestionnairePage';
import QuestionnaireMultiplePage from '../components/endUser/QuestionnaireMultiplePage';
import MappingLandingScreen from '../components/mapping/MappingLandingScreen';
import { CustomErrorPage } from '../pages/CustomErrorPage';

const RouterOutlet = () => {
	return (
		<Routes>
			<Route path="/" element={<ListingPage />} />
			<Route path="/listing" element={<ListingPage />} />
			<Route path="/create-survey" element={<CreateSurvey />} />
			<Route path="/edit-survey" element={<CreateSurvey />} />
			<Route path="/edit-survey/:landingStep" element={<CreateSurvey />} />
			<Route
				path="/questionnaire-results/:questionnaireID"
				element={<QuestionnaireResult />}
			/>
			<Route path="/end-user/:id" element={<LandingScreen />} />
			<Route path="/cwuser/:id" element={<CWQuestionnairePage />} />
			<Route
				path="/end-user/multi-page-questionnaire/:id"
				element={<QuestionnaireMultiplePage />}
			/>
			<Route
				path="/mapping/:questionnaireID"
				element={<MappingLandingScreen />}
			/>
			<Route
				path="/unauthorized"
				Component={() => {
					// This will trigger logout from DTH
					window.location.replace(`${process.env.REACT_APP_REDIRECT_URI}`);
					return null;
				}}
			/>
			<Route path="/error" element={<CustomErrorPage />} />
			<Route path="*" element={<CustomErrorPage />} />
		</Routes>
	);
};
export default RouterOutlet;
