import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
	Box,
	Grid,
	Button,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Typography,
} from '@mui/material';
import { batch } from 'react-redux';
import { filter, APIKeys } from '../utils/Filter';
import { IEndUserQuestionnarieListingDataItem } from '../types/CommonTypes';
import { useAppDispatch, useAppSelector } from '../app/Hooks';
import { STEPTheme } from '../utils/Theme';
import {
	surveyAllListingDataQuestionnaire,
	endUserSurveyListingRequest,
	setModifiedEndUserSurveyResponse,
	EndUserSurveyModifiedListingData,
	resetEndUserSurveyPayload,
	setEndUserSurveyPayload,
} from '../redux/QuestionnarieUserSlice';
import { commonLabels } from '../utils/CommonLabels';
import { END_USER_CONSTANT } from '../constants/StepConstants';
import { setFilterDate, getFilterDate } from '../redux/SurveySlice';
import { filterEndUserSurveyData } from '../utils/Helpers';
import Table from './common/Table';
import QuestionnaireFilterSegment from './QuestionnaireFilterSegment';
import { breakPointDown_768 } from './endUser/common/makeStyles';

const TextContainer = styled('span')({
	display: 'flex',
	justifyContent: 'space-between',
});

const ResultTextContainer = styled('div')({
	fontFamily: "'JohnsonText-Regular'",
	fontSize: '14px',
	fontWeight: '400',
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
	color: STEPTheme.colors.darkGray,
	margin: '12px 0px 38px 0px',
});

const ItemSurveyText = styled('span')``;

const ResetButton = styled(Button)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '16px',
	fontWeight: '500',
	lineHeight: '16px',
	width: '114px',
	height: '32px',
	color: STEPTheme.colors.text,
	background: 'transparent',
	padding: '8px 16px',
	borderRadius: '8px',
	justify: 'space-between',
	border: `1px solid ${STEPTheme.colors.text}`,
	'&:hover': {
		backgroundColor: 'white',
		borderColor: 'black',
		color: STEPTheme.colors.text,
	},
	[breakPointDown_768]: {
		height: '26px',
	},
});
const StyledSelectField = styled(Select)({
	height: '32px',
	border: '1px',
	color: STEPTheme.colors.darkGray,
	marginTop: '0',
	borderRadius: '8px',
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: STEPTheme.colors.darkGray,
	},
	'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: STEPTheme.colors.red,
		borderWidth: '1px',
	},
});
const MultiStyleFormControl = styled(FormControl)({
	width: '100%',
	'& .MuiFormLabel-root': {
		top: '-11px',
		opacity: '0.6',
		'&.MuiInputLabel-shrink': {
			opacity: '0',
		},
	},
});

const StyledTypography = styled(Typography)`
	font-family: JohnsonText-Regular;
	font-size: 14px;
	font-weight: 500;
	line-height: 12px;
	letter-spacing: 0px;
	text-align: left;
	color: ${STEPTheme.colors.text};
	margin-bottom: 4px;
	// width: 220px;
	height: 12px;
`;

const StyledGrid = styled(Grid)({
	justifyContent: 'space-between',
	marginBottom: '26px',
	[breakPointDown_768]: {
		justifyContent: 'flex-start',
	},
});

export const getFilterLabel = (item: string) => {
	if (item === APIKeys.status) {
		return filter.label.STATUS;
	} else if (item === APIKeys.questionnaireName) {
		return filter.label.QUESTIONNAIRE_NAME;
	} else if (item === APIKeys.response) {
		return filter.label.RESPONSE;
	} else {
		return '';
	}
};

const getDistinctData = <T, K extends keyof T>(
	data: T[],
	property: K
): T[K][] => {
	const modifiedData = data.reduce((values: T[K][], current) => {
		if (current[property]) {
			values.push(current[property]);
		}
		return values;
	}, []);

	return [...new Set(modifiedData)];
};

const FilterAction: React.FC<unknown> = () => {
	const surveyLegacyListData: IEndUserQuestionnarieListingDataItem[] =
		useAppSelector(surveyAllListingDataQuestionnaire);
	const surveyListData: IEndUserQuestionnarieListingDataItem[] = useAppSelector(
		EndUserSurveyModifiedListingData
	);
	const filteredDate = useAppSelector(getFilterDate);
	const surveyReqData = useAppSelector(endUserSurveyListingRequest);
	const dispatch = useAppDispatch();
	const [statusOptions, setStatusOption] = useState<string[]>([]);
	const [status, setStatus] = useState<string | null>('');
	const [response, setResponse] = useState<string | null>('');
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [errorMessageState, setErrorMessageState] = useState<string | null>(
		null
	);
	const [reset, setReset] = useState(false);
	const [responseStatusOptions, setResponseStatusOptions] = useState<string[]>(
		[]
	);
	useEffect(() => {
		resetState();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		setStatusOption([
			...new Set(surveyLegacyListData.map((item) => item.questionnaireStatus)),
		]);
		setResponseStatusOptions([
			...new Set(surveyLegacyListData.map((item) => item.answerStatus)),
		]);
	}, [surveyLegacyListData]);
	useEffect(() => {
		if (errorMessageState) return;
		handleFilterChange();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [surveyReqData]);
	const handleFilterChange = () => {
		let surveyFilteredData = surveyLegacyListData;
		surveyFilteredData = filterEndUserSurveyData(
			surveyFilteredData,
			surveyReqData,
			filteredDate
		);
		dispatch(setModifiedEndUserSurveyResponse(surveyFilteredData));
	};

	const handleChangeStatus = (newValue: string | null, type: string) => {
		if (type === END_USER_CONSTANT.STATUS) {
			setStatus(newValue);
		} else {
			setResponse(newValue);
		}
		dispatch(
			setEndUserSurveyPayload({
				item: type,
				val: newValue,
			})
		);
	};

	const resetState = () => {
		batch(() => {
			dispatch(resetEndUserSurveyPayload({}));
			dispatch(setModifiedEndUserSurveyResponse(surveyLegacyListData));
			setResponse('');
			setStatus('');
			setErrorMessage(null);
			dispatch(
				setFilterDate({
					startDate: '',
					endDate: '',
					createdAtStartDate: '',
					createdAtEndDate: '',
				})
			);
			setReset(true);
		});
	};

	return (
		<>
			<div className="survey-texts">
				<Box>
					<TextContainer>
						<ResultTextContainer>
							<ItemSurveyText>{`${filter.label.TOTAL_NUMBER_QUESTIONNAIRE_RECORDS}: ${surveyListData.length}`}</ItemSurveyText>
						</ResultTextContainer>
					</TextContainer>
					<Table />
				</Box>
			</div>
			<StyledGrid container spacing={2}>
				<Grid item xs={6} sm={6} md={3}>
					<QuestionnaireFilterSegment
						data-testid="questionnaire-Name"
						type="questionnaireName"
						filterItems={getDistinctData(
							surveyListData,
							'questionnaireName'
						).concat(getDistinctData(surveyListData, 'questionnaireCode'))}
						errorMessage={errorMessage}
						reset={reset}
						setReset={setReset}
						setErrorMessageState={setErrorMessageState}
						resetErrorMessageState={() => setErrorMessageState(null)}
					/>
				</Grid>
				<Grid item xs={6} sm={6} md={3}>
					<StyledTypography>{filter.label.STATUS}</StyledTypography>
					<MultiStyleFormControl>
						<InputLabel id="status-label">{commonLabels.select}</InputLabel>
						<StyledSelectField
							labelId="status-select-label"
							id="status-select"
							value={status}
							label={END_USER_CONSTANT.STATUS}
							fullWidth
							onChange={(event) =>
								handleChangeStatus(
									event.target.value as string,
									END_USER_CONSTANT.STATUS
								)
							}
						>
							{statusOptions.map((name) => (
								<MenuItem key={name} value={name}>
									{name}
								</MenuItem>
							))}
						</StyledSelectField>
					</MultiStyleFormControl>
				</Grid>
				<Grid item xs={6} sm={6} md={3}>
					<StyledTypography>{filter.label.RESPONSE}</StyledTypography>
					<MultiStyleFormControl>
						<InputLabel id="response-label">{commonLabels.select}</InputLabel>
						<StyledSelectField
							labelId="response-select-label"
							id="response-select"
							value={response}
							label={END_USER_CONSTANT.RESPONSE}
							onChange={(event) =>
								handleChangeStatus(
									event.target.value as string,
									END_USER_CONSTANT.RESPONSE
								)
							}
						>
							{responseStatusOptions.map((name) => (
								<MenuItem key={name} value={name}>
									{name}
								</MenuItem>
							))}
						</StyledSelectField>
					</MultiStyleFormControl>
				</Grid>

				<Grid
					item
					xs={5}
					sm={5}
					md={2}
					sx={{
						display: 'flex',
						alignItems: 'flex-end',
						justifyContent: 'end',
						gap: '1rem',
					}}
				>
					<ResetButton
						data-testid="reset-button"
						variant="outlined"
						size="small"
						onClick={resetState}
					>
						{`${filter?.label?.RESET}`}
					</ResetButton>
				</Grid>
			</StyledGrid>
		</>
	);
};

export default FilterAction;
