import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
	Autocomplete,
	Chip,
	TextField,
	InputAdornment,
	Box,
	Typography,
} from '@mui/material';
import { STEPTheme } from '../utils/Theme';
import { useAppDispatch, useAppSelector } from '../app/Hooks';
import { EndUserListingRequest } from '../types/CommonTypes';
import { ReactComponent as SearchIcon } from '../assets/images/search.svg';
import {
	endUserSurveyListingRequest,
	setEndUserSurveyPayload,
} from '../redux/QuestionnarieUserSlice';
import { APIKeys } from '../utils/Filter';
import { ValidationErrorMsgs, commonLabels } from '../utils/CommonLabels';
import {
	ADMIN_SCREEN_VALIDATION,
	EVENT_KEYS,
} from '../constants/StepConstants';
import { getFilterLabel } from './QuestionarieFilterActions';
import {
	breakPointBetween_480_601,
	breakPointBetween_768_1025,
	breakPointDown_480,
	breakPointBetween_339_400,
	breakPointDown_339,
} from './endUser/common/makeStyles';

const StyledBox = styled(Box)({
	height: '48px',
	justifyContent: 'space-between',
	border: `1px ${STEPTheme.colors.text}`,
	'.MuiAutocomplete-inputRoot': {
		backgroundColor: `${STEPTheme.colors.background} !important`,
		padding: '0 !important',
		height: '32px',
		borderRadius: '8px',
		border: `1px solid ${STEPTheme.colors.darkGray}`,
		'&.Mui-focused': {
			borderColor: STEPTheme.colors.red,
		},
	},
});

const StyledTypography = styled(Typography)`
	font-family: JohnsonText-Regular;
	font-size: 14px;
	font-weight: 500;
	line-height: 12px;
	letter-spacing: 0px;
	text-align: left;
	color: ${STEPTheme.colors.text};
	margin-bottom: 4px;
	width: 220px;
	height: 12px;
`;

const StyledTextField = styled(TextField)({
	'& .MuiAutocomplete-input': {
		maxWidth: '75%',
		textOverflow: 'ellipsis',
		[breakPointBetween_768_1025]: {
			maxWidth: '60% ',
		},
		[breakPointBetween_480_601]: {
			maxWidth: '70% ',
		},
		[breakPointDown_480]: {
			maxWidth: '65% ',
		},
		[breakPointBetween_339_400]: {
			maxWidth: '60% ',
		},
		[breakPointDown_339]: {
			maxWidth: '50% ',
		},
	},
	'&.Mui-focused': {
		borderColor: STEPTheme.colors.red,
	},
});

const QuestionnaireFilterSegment: React.FC<{
	type: string;
	filterItems: string[];
	errorMessage: string | null;
	reset: boolean;
	setReset: React.Dispatch<React.SetStateAction<boolean>>;
	setErrorMessageState: React.Dispatch<React.SetStateAction<string | null>>;
	resetErrorMessageState: () => void;
}> = (props: {
	type: string;
	filterItems: string[];
	errorMessage: string | null;
	reset: boolean;
	setReset: React.Dispatch<React.SetStateAction<boolean>>;
	resetErrorMessageState: () => void;
	setErrorMessageState: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
	const dispatch = useAppDispatch();

	const surveyReqData = useAppSelector(endUserSurveyListingRequest);
	const [localErrorMessageState, localSetErrorMessageState] = useState<
		string | null
	>(null);

	const setFilterValue = (
		item: string,
		surveyRequestData: EndUserListingRequest
	) => {
		if (item === APIKeys.status) {
			return surveyRequestData?.status ? surveyRequestData?.status : '';
		} else if (item === APIKeys.response) {
			return surveyRequestData?.response ? surveyRequestData?.response : '';
		} else if (item === APIKeys.questionnaireName) {
			return surveyRequestData?.questionnaireName
				? surveyRequestData?.questionnaireName
				: '';
		} else {
			return '';
		}
	};

	useEffect(() => {
		if (props.reset) {
			localSetErrorMessageState(null);
			props.resetErrorMessageState();
			props.setReset(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.reset]);

	const handleOnChange = (newValue: string | null) => {
		const value = newValue || '';
		if (value === '') {
			localSetErrorMessageState(null);
			props.setErrorMessageState(null);
		} else if (
			!ADMIN_SCREEN_VALIDATION.VALIDATION_QUESTIONNAIRE_NAME_ID.test(value)
		) {
			localSetErrorMessageState(
				ValidationErrorMsgs.incorrectQuestionnaireNameFormatErrorMsg
			);
			props.setErrorMessageState(
				ValidationErrorMsgs.incorrectQuestionnaireNameFormatErrorMsg
			);
		} else {
			localSetErrorMessageState(null);
			props.setErrorMessageState(null);
		}
		dispatch(
			setEndUserSurveyPayload({
				item: props.type,
				val: newValue,
			})
		);
	};

	const autoClear = useRef(null);
	return (
		<StyledBox key={props.type}>
			<StyledTypography key={props.type}>
				{getFilterLabel(props.type)}
			</StyledTypography>
			<Autocomplete
				data-testid="autocomplete-filter"
				ref={autoClear}
				value={setFilterValue(props.type, surveyReqData)}
				onKeyPress={(event) => {
					const target = event.target as HTMLInputElement;
					if (event.charCode === EVENT_KEYS.SPACE && !target.value) {
						event.preventDefault();
					}
				}}
				filterOptions={(options, params) => {
					const trimmedInputValue = params.inputValue.trimEnd();
					return options.filter((option) =>
						option.toLowerCase().includes(trimmedInputValue.toLowerCase())
					);
				}}
				onInputChange={(event, newValue, reason) => {
					if (reason === 'input') {
						handleOnChange(newValue);
					}
				}}
				onChange={(event: React.SyntheticEvent, newValue: string | null) => {
					handleOnChange(newValue);
					if (!newValue) {
						localSetErrorMessageState(null);
					}
				}}
				id={`"${props.type}_id"`}
				options={props.filterItems}
				freeSolo
				renderTags={(value: readonly string[], getTagProps) =>
					value.map((option: string, index: number) => (
						<Box key={`wwid_${index}`}>
							<Chip
								variant="outlined"
								label={option}
								{...getTagProps({ index })}
								key={index}
							/>
						</Box>
					))
				}
				renderInput={(params) => (
					<StyledTextField
						{...params}
						variant="filled"
						placeholder={commonLabels.enterTxt}
						onBlur={(e) => {
							dispatch(
								setEndUserSurveyPayload({
									item: props.type,
									val: e.target.value,
								})
							);
						}}
						error={!!localErrorMessageState || !!props.errorMessage}
						helperText={localErrorMessageState || props.errorMessage}
						InputProps={{
							...params.InputProps,
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
							disableUnderline: true,
						}}
					/>
				)}
			/>
		</StyledBox>
	);
};
export default QuestionnaireFilterSegment;
