import React, { useEffect, useState } from 'react';
import { AppBar, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { STEPTheme } from '../utils/Theme';
import STEP_LOGO from '../assets/images/STEP-Logo-other.svg';
import DTH_LOGO from '../assets/images/DTHLogo.png';
import STEP_SMALL_LOGO from '../assets/images/STEP_SMALL_LOGO.svg';
import NavBar from './Navbar';
import UserMenu from './UserMenu';

const StyledLogo = styled('img')({
	marginRight: '10px',
});

const StyledLogoDTH = styled('img')({
	marginRight: '10px',
	height: '42px',
	width: '172px',
});

const StyledSmallLogo = styled('img')({
	marginRight: '10px',
	height: '40px',
	width: '40px',
});

const StyledSmallLogoDTH = styled('img')({
	marginRight: '10px',
	height: '30px',
	width: '120px',
});

const StyledAppBar = styled(AppBar)({
	background: STEPTheme.colors.white,
	width: 'auto',
	boxShadow: 'none',
});

const StyledDiv = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	height: '64px',
	justifyContent: 'space-between',
	borderRadius: '12px',
	margin: '8px',
	opacity: 1,
	boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
	[theme.breakpoints.up('sm')]: {
		padding: '0px 10px',
	},
	[theme.breakpoints.up('md')]: {
		padding: '0px 16px',
	},
	[theme.breakpoints.up('lg')]: {
		padding: '0px 64px',
	},
	[theme.breakpoints.up('xl')]: {
		padding: '0px 64px',
	},
}));

const LogoTabContainer = styled('div')({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
});

const UserMenuContainer = styled('div')({
	flexGrow: 0,
	display: 'flex',
	justifyContent: 'flex-end',
});

const Header: React.FC<unknown> = () => {
	const URLarray = window.location.href.split('/');
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();
	const { pathname } = location;
	const goToHome = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		navigate('/');
		navigate(0);
	};
	const [isDTHLogoVisible, setIsDTHLogoVisible] = useState(false);

	const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
	const getBackgroundColor = (path: string) => {
		if (path.includes('end-user') || path.includes('cwuser')) {
			return 'rgba(255, 255, 255, 0.8)';
		} else {
			return STEPTheme.colors.white;
		}
	};

	const getAppBarColor = (path: string) => {
		if (path.includes('end-user') || path.includes('cwuser')) {
			return STEPTheme.colors.primary;
		} else if (path.includes('questionnaire-results')) {
			return STEPTheme.colors.background;
		} else {
			return STEPTheme.colors.background;
		}
	};

	useEffect(() => {
		setIsDTHLogoVisible(URLarray[URLarray.length - 1] === 'error');
	}, [URLarray]);

	return (
		<StyledAppBar
			className="step-app-bar-header"
			position="sticky"
			sx={{ background: getAppBarColor(location.pathname) }}
		>
			<StyledDiv
				sx={{
					background: getBackgroundColor(location.pathname),
					opacity: getBackgroundColor(location.pathname),
				}}
			>
				<LogoTabContainer>
					<Link to="/" onClick={goToHome}>
						{isLargeScreen ? (
							!isDTHLogoVisible ? (
								<StyledLogo
									src={STEP_LOGO}
									className="STEP-logo"
									title="Self-service Training Efficiency Profile"
									alt="STEP"
									data-testid="STEP_LOGO"
								/>
							) : (
								<StyledLogoDTH
									src={DTH_LOGO}
									className="STEP-logo"
									title="Self-service Training Efficiency Profile"
									alt="STEP"
									data-testid="STEP_LOGO"
								/>
							)
						) : !isDTHLogoVisible ? (
							<StyledSmallLogo
								src={STEP_SMALL_LOGO}
								className="STEP-logo"
								title="Self-service Training Efficiency Profile"
								alt="STEP"
								data-testid="STEP_LOGO"
							/>
						) : (
							<StyledSmallLogoDTH
								src={DTH_LOGO}
								className="STEP-logo"
								title="Self-service Training Efficiency Profile"
								alt="STEP"
								data-testid="STEP_LOGO"
							/>
						)}
					</Link>
				</LogoTabContainer>
				{pathname === '/' && <NavBar />}
				<UserMenuContainer>
					<UserMenu data-testid="userName"></UserMenu>
				</UserMenuContainer>
			</StyledDiv>
		</StyledAppBar>
		// </Box>
	);
};

export default Header;
