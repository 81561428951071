import { styled } from '@mui/material/styles';
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	InputAdornment,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography,
	Divider,
	Grid,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
	EndUserListingPageLabels,
	EndUserTableHead,
	ValidationErrorMsgs,
	commonLabels,
} from '../../utils/CommonLabels';
import { ResetButton } from '../QuestionarieFilterActionStyleComponent';
import { STEPTheme } from '../../utils/Theme';
import { filter } from '../../utils/Filter';
import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import {
	fetchUserListingData,
	setPageIndex,
	setRowsPerPageValue,
	setUserFilter,
} from '../../redux/QuestionnarieUserSlice';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import { configurationData } from '../../redux/SurveySlice';
import { Numbers, PageRows } from '../../utils/Enum';
import { EndUserFilter } from '../../types/CommonTypes';
import {
	ASSESMENT_STATUS_CONSTANT,
	VALIDATIONS_RULES,
} from '../../constants/StepConstants';
import StyledTooltip from '../StyledTooltip';
import { breakPointDown_480 } from './common/makeStyles';

const OutOfScopeText = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	'& .MuiTypography-root': {
		fontWeight: '400',
		color: STEPTheme.colors.errorText,
	},
	'& .MuiButtonBase-root': {
		padding: '0 9px 0 0',
	},
});
const InScopeText = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	'& .MuiTypography-root': {
		fontWeight: '400',
		color: STEPTheme.colors.lightGreen,
	},
	'& .MuiButtonBase-root': {
		padding: '0 9px 0 0',
	},
});
const NotAssessedText = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	'& .MuiTypography-root': {
		fontWeight: '400',
	},
	'& .MuiButtonBase-root': {
		padding: '0 9px 0 0',
	},
});

const StyledTextField = styled(TextField)({
	width: '100%',
});

const StyledSelectField = styled(Select)({
	width: '100%',
	height: '32px',
	border: '1px',
	color: STEPTheme.colors.darkGray,
	marginTop: '0',
	borderRadius: '8px',
	'& .MuiOutlinedInput-notchedOutline': {
		borderColor: STEPTheme.colors.darkGray,
	},
	'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: STEPTheme.colors.darkGray,
		borderWidth: '1px',
	},
});
const StyledTypography = styled(Typography)`
	font-family: JohnsonText-Regular;
	font-size: 14px;
	font-weight: 500;
	line-height: 12px;
	letter-spacing: 0px;
	text-align: left;
	color: ${STEPTheme.colors.text};
	margin-bottom: 4px;
	width: 220px;
	height: 12px;
`;

const MultiStyleBox = styled(Box)({
	width: '100%',
	'& .MuiInputBase-root': {
		width: '100%',
	},
});
const MultiStyleFormControl = styled(FormControl)({
	width: '100%',
	'& .MuiFormLabel-root': {
		top: '-11px',
		opacity: '0.6',
		'&.MuiInputLabel-shrink': {
			opacity: '0',
		},
	},
});

const StyledBox = styled(Box)({
	width: '100%',
	height: '48px',
	justifyContent: 'space-between',
	border: `1px ${STEPTheme.colors.text}`,
	justifySelf: 'stretched',
	'.MuiInputBase-root': {
		width: '100%',
		backgroundColor: `${STEPTheme.colors.white} !important`,
		padding: '0 !important',
		height: '32px',
		borderRadius: '8px',
		border: `1px solid ${STEPTheme.colors.darkGray}`,
	},
});
const StyledResetButton = styled(ResetButton)({
	[breakPointDown_480]: {
		width: '50%',
	},
});

const ActionButton = styled(Button)({
	width: '120px',
	height: '32px',
	color: STEPTheme.colors.white,
	background: 'red',
	padding: '8px 16px',
	justify: 'space-between',
	margin: '5px',
	fontSize: '14px',
	fontWeight: '500',
	lineHeight: '16px',
	textTransform: 'none',
	'&:hover': {
		width: '123px',
		color: STEPTheme.colors.white,
		background: STEPTheme.colors.red,
	},
	'&.Mui-disabled': {
		background: '#eaeaea',
		color: '#c0c0c0',
		border: 'none',
	},
	[breakPointDown_480]: {
		width: '50%',
	},
});
const ActionButtonBox = styled(Box)({
	display: 'flex',
	justifyContent: 'flex-end',
	marginBottom: '10px',
	marginTop: '20px',
	[breakPointDown_480]: {
		justifyContent: 'center',
	},
});

const GridContainer = styled(Grid)(({ theme }) => ({
	paddingRight: theme.spacing(1),
	width: '100%',
	margin: '0px',
	justifyContent: 'space-between',
	marginBottom: '16px',
}));

export const getFilterLabel = (item: string) => {
	if (item === EndUserTableHead.label.WWID) {
		return EndUserTableHead.label.WWID;
	} else if (item === EndUserTableHead.label.LAST_NAME) {
		return EndUserTableHead.label.LAST_NAME;
	} else if (item === EndUserTableHead.label.FIRST_NAME) {
		return EndUserTableHead.label.FIRST_NAME;
	} else if (item === EndUserTableHead.label.PARTNER_COMPANY) {
		return EndUserTableHead.label.PARTNER_COMPANY;
	} else {
		return EndUserTableHead.label.ASSESMENT_STATUS;
	}
};
const ITEM_HEIGHT = Numbers.fourtyEight;
const ITEM_PADDING_TOP = Numbers.eight;

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * Numbers.fourAndHalf + ITEM_PADDING_TOP,
			width: Numbers.twoFifty,
		},
	},
};
const TableFilter: React.FC<unknown> = () => {
	const [wwid, setWWID] = React.useState<string>('');
	const [firstName, setFirstName] = React.useState<string>('');
	const [lastName, setLastName] = React.useState<string>('');
	const [assestmentStatus, setAssestmentStatus] = React.useState<string[]>([]);
	const [partnerCompany, setPartnerCompany] = React.useState<string>('');
	const [wwidError, setWwidError] = React.useState<string>('');
	const [firstNameError, setFirstNameError] = React.useState<string>('');
	const [lastNameError, setLastNameError] = React.useState<string>('');
	const [isCWFilterEmpty, setCWFilterAsEmpty] = React.useState<boolean>(true);
	const dispatch = useAppDispatch();
	const configs = useAppSelector(configurationData);
	const assestmentStatusOptions = configs?.assessment_status || [
		ASSESMENT_STATUS_CONSTANT.NOT_ASSESSED,
		ASSESMENT_STATUS_CONSTANT.IN_SCOPE,
		ASSESMENT_STATUS_CONSTANT.OUT_OF_SCOPE,
	];
	const { id: questionnaireId } = useParams();
	const fetchUserList = (
		id: string | undefined,
		pageSize: number,
		pageNumber: number,
		sortBy: string,
		sortOrder: string,
		filters: EndUserFilter
	) => {
		if (id) {
			id = id.replace(':', '');
		}
		dispatch(
			fetchUserListingData({
				endUserListingObj: {
					questionnaireId: id ?? '',
					pageSize: pageSize,
					pageNumber: pageNumber,
					sortBy: sortBy,
					sortOrder: sortOrder,
					filter: filters,
				},
			})
		);
	};

	/**
	 * @useEffect
	 * @summary - Checks whether the filter options are not empty as a whole and they are not invalid
	 */
	useEffect(() => {
		setCWFilterAsEmpty(
			(wwid === '' || wwidError !== '') &&
				(firstName === '' || firstNameError !== '') &&
				(lastName === '' || lastNameError !== '') &&
				assestmentStatus?.length === 0 &&
				partnerCompany === ''
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [wwid, firstName, lastName, assestmentStatus, partnerCompany]);

	const onApply = () => {
		const filters: EndUserFilter = {
			wwiDs: wwid,
			lastName: lastName,
			firstName: firstName,
			partnerCompany: partnerCompany,
			assessmentStatus: assestmentStatus ? assestmentStatus.join(' | ') : '',
		};
		setWwidError('');
		setFirstNameError('');
		setLastNameError('');

		let hasError = false;

		if (wwid && !VALIDATIONS_RULES.VALIDATION_FILTER_WWIDS.test(wwid)) {
			setWwidError(ValidationErrorMsgs.incorrectWWIDsFormatMsg);
			hasError = true;
		}
		if (
			lastName &&
			!VALIDATIONS_RULES.VALIDATION_ONLY_ALPHABET_STRING.test(lastName)
		) {
			setLastNameError(ValidationErrorMsgs.incorrectLastNameEndUserFormatMsg);
			hasError = true;
		}
		if (
			firstName &&
			!VALIDATIONS_RULES.VALIDATION_ONLY_ALPHABET_STRING.test(firstName)
		) {
			setFirstNameError(ValidationErrorMsgs.incorrectFirstNameEndUserFormatMsg);
			hasError = true;
		}
		if (hasError) {
			return;
		}
		dispatch(setRowsPerPageValue(PageRows.fifthOption));
		dispatch(setPageIndex(Numbers.zero));
		dispatch(setUserFilter(filters));
		fetchUserList(
			questionnaireId,
			PageRows.fifthOption,
			Numbers.one,
			'',
			'',
			filters
		);
	};
	const onReset = () => {
		const filters: EndUserFilter = {
			wwiDs: '',
			lastName: '',
			firstName: '',
			partnerCompany: '',
			assessmentStatus: '',
		};
		dispatch(setUserFilter(filters));
		setWWID('');
		setFirstName('');
		setLastName('');
		setAssestmentStatus([]);
		setPartnerCompany('');
		setWwidError('');
		setFirstNameError('');
		setLastNameError('');
		fetchUserList(
			questionnaireId,
			PageRows.fifthOption,
			Numbers.one,
			'',
			'',
			filters
		);
		dispatch(setRowsPerPageValue(PageRows.fifthOption));
		dispatch(setPageIndex(Numbers.zero));
	};
	const handleChange = (event: SelectChangeEvent, key: string) => {
		const {
			target: { value },
		} = event;
		if (key === 'status') {
			setAssestmentStatus(
				// On autofill we get a stringified value.
				typeof value === 'string' ? value.split(',') : value
			);
		}
	};

	const validateInput = (value: string, type: string) => {
		let error = '';
		if (!value) {
			return error;
		}
		if (
			type === 'wwid' &&
			!VALIDATIONS_RULES.VALIDATION_FILTER_WWIDS.test(value)
		) {
			error = ValidationErrorMsgs.incorrectWWIDsFormatMsg;
		} else if (
			type === 'lastName' &&
			!VALIDATIONS_RULES.VALIDATION_ONLY_ALPHABET_STRING.test(value)
		) {
			error = ValidationErrorMsgs.incorrectLastNameEndUserFormatMsg;
		} else if (
			type === 'firstName' &&
			!VALIDATIONS_RULES.VALIDATION_ONLY_ALPHABET_STRING.test(value)
		) {
			error = ValidationErrorMsgs.incorrectFirstNameEndUserFormatMsg;
		} else {
			/* empty */
		}
		return error;
	};

	return (
		<div className="survey-filters">
			<GridContainer container spacing={{ xs: 2, sm: 2, md: 2, lg: 0, xl: 0 }}>
				<Grid item xs={12} sm={6} md={3} lg={2}>
					<StyledBox>
						<StyledTypography>{EndUserTableHead.label.WWID}</StyledTypography>
						<StyledTextField
							value={wwid}
							variant="filled"
							placeholder={commonLabels.enterTxt}
							error={!!wwidError}
							helperText={wwidError}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								const value = event.target.value.trim();
								setWWID(value);
								setWwidError(validateInput(value, 'wwid'));
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								disableUnderline: true,
							}}
						/>
					</StyledBox>
				</Grid>
				<Grid item xs={12} sm={6} md={3} lg={2}>
					<StyledBox>
						<StyledTypography>
							{EndUserTableHead.label.LAST_NAME}
						</StyledTypography>
						<StyledTextField
							value={lastName}
							variant="filled"
							placeholder={commonLabels.enterTxt}
							error={!!lastNameError}
							helperText={lastNameError}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								const value = event.target.value.trimStart();
								setLastName(value);
								setLastNameError(validateInput(value, 'lastName'));
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								disableUnderline: true,
							}}
						/>
					</StyledBox>
				</Grid>
				<Grid item xs={12} sm={6} md={3} lg={2}>
					<StyledBox>
						<StyledTypography>
							{EndUserTableHead.label.FIRST_NAME}
						</StyledTypography>
						<StyledTextField
							value={firstName}
							variant="filled"
							placeholder={commonLabels.enterTxt}
							error={!!firstNameError}
							helperText={firstNameError}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								const value = event.target.value.trimStart();
								setFirstName(value);
								setFirstNameError(validateInput(value, 'firstName'));
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								disableUnderline: true,
							}}
						/>
					</StyledBox>
				</Grid>
				<Grid item xs={12} sm={6} md={3} lg={2}>
					<StyledBox>
						<StyledTypography>
							{EndUserTableHead.label.PARTNER_COMPANY}
						</StyledTypography>
						<StyledTextField
							value={partnerCompany}
							variant="filled"
							placeholder={commonLabels.enterTxt}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setPartnerCompany(event.target.value);
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								disableUnderline: true,
							}}
						/>
					</StyledBox>
				</Grid>
				<Grid item xs={12} sm={6} md={3} lg={2}>
					<MultiStyleBox>
						<StyledTypography>
							{EndUserTableHead.label.ASSESMENT_STATUS}
						</StyledTypography>
						<MultiStyleFormControl>
							<InputLabel id="demo-multiple-name-label1">
								{commonLabels.select}
							</InputLabel>
							<StyledSelectField
								labelId="demo-multiple-checkbox-label"
								id="demo-multiple-checkbox"
								multiple
								displayEmpty
								placeholder={commonLabels.select}
								value={assestmentStatus}
								onChange={(event) =>
									handleChange(event as SelectChangeEvent, 'status')
								}
								renderValue={(selected: unknown) =>
									(selected as string[]).join(', ')
								}
								MenuProps={MenuProps}
							>
								{assestmentStatusOptions?.map((name) => (
									<MenuItem key={name} value={name}>
										{name === ASSESMENT_STATUS_CONSTANT.OUT_OF_SCOPE && (
											<OutOfScopeText>
												<Checkbox
													checked={assestmentStatus.indexOf(name) > -1}
												/>
												<ListItemText primary={name} />
											</OutOfScopeText>
										)}
										{name === ASSESMENT_STATUS_CONSTANT.IN_SCOPE && (
											<InScopeText>
												<Checkbox
													checked={assestmentStatus.indexOf(name) > -1}
												/>
												<ListItemText primary={name} />
											</InScopeText>
										)}
										{name === ASSESMENT_STATUS_CONSTANT.NOT_ASSESSED && (
											<NotAssessedText>
												<Checkbox
													checked={assestmentStatus.indexOf(name) > -1}
												/>
												<ListItemText primary={name} />
											</NotAssessedText>
										)}
									</MenuItem>
								))}
							</StyledSelectField>
						</MultiStyleFormControl>
					</MultiStyleBox>
				</Grid>
			</GridContainer>
			<ActionButtonBox>
				<StyledTooltip
					title={EndUserListingPageLabels.resetTooltip}
					placement="top"
				>
					<StyledResetButton
						disabled={isCWFilterEmpty}
						variant="outlined"
						size="small"
						onClick={() => onReset()}
					>
						{`${filter?.label?.RESET_FILTER}`}
					</StyledResetButton>
				</StyledTooltip>
				<StyledTooltip
					title={EndUserListingPageLabels.applyTooltip}
					placement="top"
				>
					<ActionButton
						disabled={isCWFilterEmpty}
						variant="outlined"
						onClick={() => onApply()}
					>
						{filter.label.APPLY_FILTER}
					</ActionButton>
				</StyledTooltip>
			</ActionButtonBox>
			<Divider></Divider>
		</div>
	);
};

export default TableFilter;
