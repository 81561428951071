import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../app/Store';
import {
	AnswerObj,
	EndUserFilter,
	EndUserListingPayload,
	EndUserListingRequest,
	IAudienceListingRequest,
	IEndUserQuestionnarieListingDataItem,
	IEndUserSurveyState,
	IType,
	UpdateMyQuestionnairesPayload,
	UserResponse,
} from '../types/CommonTypes';
import {
	GetQuestionnaireDetailsCW,
	GetQuestionnairesForMe,
	GetUsersListForCompletingQuestionnaire,
	UpdateMyQuestionnaires,
} from '../middleware/EndUserService';
import { Numbers, PageRows } from '../utils/Enum';
import { API_STATUS_CONSTANT, RESPONSE_CODE } from '../constants/StepConstants';
import { commonLabels } from '../utils/CommonLabels';
const initialState: IEndUserSurveyState = {
	endUserSurveyListingRequest: {} as EndUserListingRequest,
	audienceListingRequest: {} as IAudienceListingRequest,
	QuestionSurveyAllListingData: [],
	EndUserSurveyModifiedListingData: [],
	pageIndex: Numbers.zero,
	pageSize: PageRows.fourthOption,
	userFilter: {} as EndUserFilter,
	users: [] as string[],
	index: Numbers.zero,
	UserListingData: {} as UserResponse,
	PartnerCompanyList: [] as string[],
	myQuestionnaireAPIStatus: API_STATUS_CONSTANT.IDLE,
	questionnaireDetailsCWAPIStatus: API_STATUS_CONSTANT.IDLE,
	todoMessage: '',
	resetInputValues: false,
	responseJSON: [] as AnswerObj[],
};
export const fetchUserListingData = createAsyncThunk(
	'QuestionnarieSurvey/FetchUserList',
	async (userListingReq: EndUserListingPayload, { dispatch }) => {
		GetUsersListForCompletingQuestionnaire(userListingReq)
			.then((response) => {
				if (response?.data) {
					const questionnaireList = response?.data;
					dispatch(setUserListingData(questionnaireList.data));
				} else {
					dispatch(
						setUserListingData({
							recipients: [],
							recordCount: 0,
						})
					);
				}
			})
			.catch(() => {
				dispatch(
					setUserListingData({
						recipients: [],
						recordCount: 0,
					})
				);
			});
	}
);

export const updateMyQuestionnairesData = createAsyncThunk(
	'QuestionnarieSurvey/PostMyQuestionnairesData',
	async (requestPayload: UpdateMyQuestionnairesPayload, { dispatch }) => {
		UpdateMyQuestionnaires(requestPayload)
			.then((response) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data
				) {
					dispatch(
						setUpdateMyQuestionnairesAPIStatus(API_STATUS_CONSTANT.SUCCESS)
					);
					dispatch(resetAllStoredResponseJSON());
				} else {
					dispatch(
						setUpdateMyQuestionnairesAPIStatus(API_STATUS_CONSTANT.FAILED)
					);
				}
			})
			.catch(() => {
				dispatch(
					setUpdateMyQuestionnairesAPIStatus(API_STATUS_CONSTANT.FAILED)
				);
			});
	}
);
export const fetchQuestionnaireSurveyData = createAsyncThunk(
	'QuestionnarieSurvey/FetchSurveyList',
	async (_, { dispatch }) => {
		GetQuestionnairesForMe()
			.then((response) => {
				if (response?.data) {
					const questionnaireList = response?.data.data.map(
						(item: {
							createdAt: string;
							startDate: string;
							dueDate: string;
						}) => {
							item.startDate = item.startDate.split('T')[0];
							item.dueDate = item.dueDate.split('T')[0];
							return item;
						}
					);
					dispatch(getListData(questionnaireList));
					dispatch(setModifiedEndUserSurveyResponse(questionnaireList));
				}
			})
			.catch(() => {
				dispatch(setTodoMsg(''));
			});
	}
);

export const getQuestionnaireData = createAsyncThunk(
	'Survey/QuestionnairesData',
	async (req: IType, { dispatch }) => {
		try {
			const response: AxiosResponse = await GetQuestionnaireDetailsCW(req, {
				skipGlobalErrorHandling: true,
			});
			if (response?.status === RESPONSE_CODE.HTTP_STATUS_OK && response?.data) {
				dispatch(setAllQuestionaireData(response.data));
				dispatch(
					setQuestionnaireDetailsCWAPIStatus(API_STATUS_CONSTANT.SUCCESS)
				);
			} else if (response?.status === RESPONSE_CODE.HTTP_STATUS_NO_CONTENT) {
				// Display a no access popup - as no data available for requested questionnaire
				dispatch(
					setQuestionnaireDetailsCWAPIStatus(
						API_STATUS_CONSTANT.ERROR_PERMISSION_DENIED
					)
				);
				dispatch(setTodoMsg(commonLabels.noAccessToSurveyTxt));
			} else {
				dispatch(
					setQuestionnaireDetailsCWAPIStatus(API_STATUS_CONSTANT.FAILED)
				);
				dispatch(setTodoMsg(''));
			}
		} catch (e) {
			if (e && typeof e === 'object' && 'response' in e) {
				const errorResponse = e as { response: AxiosResponse };
				if (
					Number(errorResponse.response.status) ===
					RESPONSE_CODE.HTTP_STATUS_FORBIDDEN
				) {
					dispatch(
						setQuestionnaireDetailsCWAPIStatus(
							API_STATUS_CONSTANT.ERROR_PERMISSION_DENIED
						)
					);
					dispatch(setTodoMsg(errorResponse.response.data?.message));
				} else {
					dispatch(
						setQuestionnaireDetailsCWAPIStatus(API_STATUS_CONSTANT.FAILED)
					);
					dispatch(setTodoMsg(''));
				}
			} else {
				dispatch(
					setQuestionnaireDetailsCWAPIStatus(API_STATUS_CONSTANT.FAILED)
				);
				dispatch(setTodoMsg(''));
			}
		}
	}
);

export const QuestionnaireUserSlice = createSlice({
	name: 'QuestionnaireSurvey',
	initialState,
	reducers: {
		setAllQuestionaireData: (state, action: PayloadAction) => {
			state.questionnaireData = action.payload;
		},
		getListData: (state, action: PayloadAction<[]>) => {
			state.QuestionSurveyAllListingData = action.payload;
		},
		setEndUserSurveyPayload: (
			state,
			action: PayloadAction<{ item: string; val: string | null }>
		) => {
			const { item, val } = action.payload;
			if (item === 'questionnaireName') {
				state.endUserSurveyListingRequest.questionnaireName = val;
			} else if (item === 'response') {
				state.endUserSurveyListingRequest.response = val;
			} else {
				if (item === 'status') {
					state.endUserSurveyListingRequest.status = val;
				}
			}
		},
		resetEndUserSurveyPayload: (state, action: PayloadAction<object>) => {
			state.endUserSurveyListingRequest = action.payload;
		},
		setModifiedEndUserSurveyResponse: (
			state,
			action: PayloadAction<IEndUserQuestionnarieListingDataItem[]>
		) => {
			state.EndUserSurveyModifiedListingData = action.payload;
		},
		setTodoMsg: (state, action: PayloadAction<string>) => {
			state.todoMessage = action.payload;
		},
		setUserListingData: (state, action: PayloadAction<UserResponse>) => {
			state.UserListingData = action.payload;
		},
		setPartnerCompanyList: (state, action: PayloadAction<string[]>) => {
			state.PartnerCompanyList = action.payload;
		},
		setPageIndex: (state, action: PayloadAction<number>) => {
			state.pageIndex = action.payload;
		},
		setRowsPerPageValue: (state, action: PayloadAction<number>) => {
			state.pageSize = action.payload;
		},
		setUserFilter: (state, action: PayloadAction<EndUserFilter>) => {
			state.userFilter = action.payload;
		},
		setUsers: (state, action: PayloadAction<string[]>) => {
			state.users = action.payload;
		},
		setUpdateMyQuestionnairesAPIStatus: (
			state,
			action: PayloadAction<IEndUserSurveyState['myQuestionnaireAPIStatus']>
		) => {
			state.myQuestionnaireAPIStatus = action.payload;
		},
		setQuestionnaireDetailsCWAPIStatus: (
			state,
			action: PayloadAction<
				IEndUserSurveyState['questionnaireDetailsCWAPIStatus']
			>
		) => {
			state.questionnaireDetailsCWAPIStatus = action.payload;
		},
		updateAnswerJSONByQId: (state, action: PayloadAction<AnswerObj>) => {
			const uniqueQuestionID = action.payload.questionId.split('_').pop();
			const index = state.responseJSON.findIndex((answer) => {
				const answerQuestionId = answer.questionId.split('_').pop();
				return answerQuestionId === uniqueQuestionID;
			});

			if (index > -1) {
				state.responseJSON[index].answer = action.payload.answer;
			}
		},

		updateResponseJSON: (state, action: PayloadAction<AnswerObj>) => {
			const uniqueQuestionID = action.payload.questionId.split('_').pop();

			const index = state.responseJSON.findIndex((answer) => {
				const answerQuestionId = answer.questionId.split('_').pop();
				return answerQuestionId === uniqueQuestionID;
			});

			if (index > -1) {
				state.responseJSON[index] = action.payload;
			} else {
				state.responseJSON.push(action.payload);
			}
		},

		updateAllResponseJSON: (state, action: PayloadAction<AnswerObj[]>) => {
			state.responseJSON = action.payload;
		},
		resetAllStoredResponseJSON: (state) => {
			state.responseJSON = [] as AnswerObj[];
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(updateMyQuestionnairesData.pending, (state) => {
				state.myQuestionnaireAPIStatus = API_STATUS_CONSTANT.LOADING;
			})
			.addCase(updateMyQuestionnairesData.rejected, (state) => {
				state.myQuestionnaireAPIStatus = API_STATUS_CONSTANT.FAILED;
			})
			.addCase(getQuestionnaireData.rejected, (state) => {
				state.questionnaireDetailsCWAPIStatus = API_STATUS_CONSTANT.FAILED;
			});
	},
});
export const surveyAllListingDataQuestionnaire = (state: RootState) =>
	state.QuestionnaireSurvey.QuestionSurveyAllListingData;
export const EndUserSurveyModifiedListingData = (state: RootState) =>
	state.QuestionnaireSurvey.EndUserSurveyModifiedListingData;
export const endUserSurveyListingRequest = (state: RootState) =>
	state.QuestionnaireSurvey.endUserSurveyListingRequest;
export const userListingData = (state: RootState) =>
	state.QuestionnaireSurvey.UserListingData;
export const partnerCompanyList = (state: RootState) =>
	state.QuestionnaireSurvey.PartnerCompanyList;
export const pageIndex = (state: RootState) =>
	state.QuestionnaireSurvey.pageIndex;
export const pageSize = (state: RootState) =>
	state.QuestionnaireSurvey.pageSize;
export const userFilter = (state: RootState) =>
	state.QuestionnaireSurvey.userFilter;
export const users = (state: RootState) => state.QuestionnaireSurvey.users;
export const myQuestionnaireAPILoadingStatus = (state: RootState) =>
	state.QuestionnaireSurvey.myQuestionnaireAPIStatus;
export const questionnaireData = (state: RootState) =>
	state.QuestionnaireSurvey.questionnaireData;
export const getQuestionnaireDetailsCWAPIStatus = (state: RootState) =>
	state.QuestionnaireSurvey.questionnaireDetailsCWAPIStatus;
export const getTodoMessage = (state: RootState) =>
	state.QuestionnaireSurvey.todoMessage;
export const {
	getListData,
	setEndUserSurveyPayload,
	resetEndUserSurveyPayload,
	setModifiedEndUserSurveyResponse,
	setUserListingData,
	setPartnerCompanyList,
	setPageIndex,
	setRowsPerPageValue,
	setUserFilter,
	setUsers,
	setUpdateMyQuestionnairesAPIStatus,
	setTodoMsg,
	setAllQuestionaireData,
	setQuestionnaireDetailsCWAPIStatus,
	updateResponseJSON,
	updateAnswerJSONByQId,
	updateAllResponseJSON,
	resetAllStoredResponseJSON,
} = QuestionnaireUserSlice.actions;
export default QuestionnaireUserSlice.reducer;
