import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
	Box,
	Typography,
	ToggleButtonGroup,
	ToggleButton,
	Button,
} from '@mui/material';
import { startCase } from 'lodash';
import { STEPTheme } from '../../utils/Theme';
import { ReactComponent as GraphView } from '../../assets/images/Graph-view.svg';
import { ReactComponent as ListView } from '../../assets/images/List-view.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/Download.svg';
import ButtonsGroup from '../../components/questionnaire-results/ButtonsGroup';
import KeyMetrics from '../../components/questionnaire-results/KeyMetrics';
import { Numbers } from '../../utils/Enum';
import {
	getFormattedCurrentDateTime,
	getTimeInLastRefreshFormat,
} from '../../utils/DateFormatting';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	downloadResultsApiStatus,
	downloadResultsData,
	fetchGroupGraphData,
	fetchParameterList,
	fetchQuestionnaireResultsData,
	fetchRegionalGraphData,
	fetchResultKeyMetrices,
	keyMetricsData,
	keyMetricsAPIStatus,
	parameterList,
	parameterListAPIStatus,
	regionalData,
	setDownloadResultsApiStatus,
	setDownloadResultsData,
	setDataDisplayTypeAction,
	groupGraphData,
	totalEmployeeCountRegional,
	reportDownload,
	totalEmployeeCountGroup,
	summitEdgeFormReportRequest,
	setSummitReportApiStatus,
	summitReportApiCompletionStatus,
} from '../../redux/QuestionnaireResultsSlice';
import {
	ChipsLabels,
	commonLabels,
	notificationsLabels,
	questionnaireResultsLabels,
	uploadExcelLabels,
} from '../../utils/CommonLabels';
import {
	getGroupGraphData,
	getStatusData,
} from '../../utils/QuestionnaireResult';
import { API_STATUS_CONSTANT } from '../../constants/StepConstants';
import GenericModalPopup from '../../components/common/GenericModalPopup';
import GenericConfirmationPopup from '../../components/common/GenericConfirmationPopup';
import {
	audienceFilterAPILoadingStatus,
	batchReportRequest,
	batchReportRequestResponse,
	getAudienceFilterData,
	selectedFilterRequestPayloadData,
	questionnaireId,
	setBatchReportRequestResponse,
} from '../../redux/SurveySlice';
import BarChartCard from '../../components/common/BarChartCard';
import Main from '../../components/QuestionnaireSpecificResultsResponse/Main';
import DonutChart from '../../components/common/DonutChart';
import {
	getAudienceSubset,
	getGroupsList,
	groupsList,
} from '../../redux/MappingSlice';
import {
	getGroupDropDownList,
	getParameterDropDownList,
	prepareRegionalGraphData,
} from '../../utils/CommonMethods';
import StyledTooltip from '../../components/StyledTooltip';
import { IAudienceListingRequest, IGraphData } from '../../types/CommonTypes';
import { initialFilterValue } from '../../utils/SurveyAudience';
import KeyMetricsError from '../../components/questionnaire-results/KeyMetricsError';
import { IDownloadReq } from '../../types/QuestionnaireResultsType';
import { questionnaireAPIKeys } from '../../utils/ApiKeys';
import ListTableView from './views/ListTableView';
import ResultFilter from './views/ResultFilter';

const ToggleButtonStyled = styled(ToggleButton)({
	'&.Mui-selected': {
		backgroundColor: 'white',
		'.toggle-selected-view .path-bg': {
			fill: 'white',
		},
		'.toggle-selected-view .path-stroke': {
			stroke: '#EB1700',
		},
	},
	padding: '0',
});

const GraphContainers = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	height: 'auto',
	justifyContent: 'space-between',
});

const QuestionnaireResultContainer = styled(Box)({
	background: '#F9F8F7',
	minHeight: '500px',
	padding: '40px 100px',
	maxWidth: '100%',
});

const ResultHeadingContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	alignItems: 'baseline',
	marginBottom: '20px',
});

const HeadingTextContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'baseline',
	gap: '20px',
	width: '50%',
});

const GeneralResultHeadingContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	alignItems: 'center',
	marginBottom: '10px',
});

const ResultActionRightContainer = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	rowGap: '5px',
	flexWrap: 'wrap',
});

const GeneralResultsContainer = styled(Box)({
	display: 'flex',
	width: '100%',
	gap: '3%',
	marginBottom: '20px',
	flexDirection: 'column',
});

const StyledMsgBody = styled('div')({
	fontWeight: '500',
	fontSize: '25px',
	fontFamily: '"JohnsonDisplay-Bold"',
	lineHeight: '32px',
});

const QuestionnaireResult: React.FC<unknown> = () => {
	const groupListFetched = useAppSelector(groupsList);
	const isApiLoaded = useAppSelector(audienceFilterAPILoadingStatus);
	const parameterListFetched = useAppSelector(parameterList);
	const apiStatusParameterList = useAppSelector(parameterListAPIStatus);
	const groupData = useAppSelector(groupGraphData);
	const overallEmployeeCountGroup = useAppSelector(totalEmployeeCountGroup);
	const [completedByGroupData, setCompletedByGroupData] = useState<
		IGraphData[]
	>([]);
	const [groupYMax, setGroupYMax] = useState(Numbers.hundred);
	const overallEmployeeCountRegional = useAppSelector(
		totalEmployeeCountRegional
	);
	const groupListDropDownOptions = getGroupDropDownList(groupListFetched);
	const parameterListDropDownOptions =
		getParameterDropDownList(parameterListFetched);
	const [showBatchPopup, setShowBatchPopup] = React.useState(false);
	const [showPopup, setShowPopup] = React.useState(false);
	const [batchPopupMsg, setBatchPopupMsg] = React.useState<string>(
		questionnaireResultsLabels.downloadInProgress
	);
	const [showSummitPopup, setShowSummitPopup] = React.useState(false);
	const [summitPopupMsg, setSummitPopupMsg] = React.useState<string>(
		questionnaireResultsLabels.downloadInProgress
	);
	const [showMaxLimitWarning, setShowMaxLimitWarning] = React.useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [disabledCompleteDownload, setDisabledCompleteDownload] =
		React.useState(false);
	const [dataDisplayType, setDataDisplayType] = useState(
		questionnaireResultsLabels.dataDisplayTypePercentage
	);
	const [viewToggleButton, setViewToggleButton] = useState('graphView');
	const [preFilterValue, setPreFilterViewValue] = useState('');
	const dataDisplayTypeBtnList = [
		questionnaireResultsLabels.dataDisplayTypePercentage,
		questionnaireResultsLabels.dataDisplayTypeValues,
	];
	const batchReportRequestResponseStatus = useAppSelector(
		batchReportRequestResponse
	);
	const summitReportApiStatus = useAppSelector(summitReportApiCompletionStatus);
	const downloadResultsApiCurrentStatus = useAppSelector(
		downloadResultsApiStatus
	);
	const downloadResultsFileData = useAppSelector(downloadResultsData);
	const regionDataFetched = useAppSelector(regionalData);
	const [regionData, setRegionData] = useState<IGraphData[]>([]);
	const [regionalYMax, setRegionalYMax] = useState(Numbers.hundred);
	const dispatch = useAppDispatch();
	const { questionnaireID } = useParams<{ questionnaireID: string }>();
	const [chipData, setChipData] = useState([
		{
			text: questionnaireResultsLabels.respondedTxt,
			selected: true,
		},
		{
			text: questionnaireResultsLabels.notRespondedTxt,
			selected: false,
		},
		{
			text: questionnaireResultsLabels.notCompliant,
			selected: false,
		},
	]);
	const [groupSelected, setGroupSelected] = useState('');
	const [selectedGroupId, setSelectedGroupId] = useState('');
	const [parameterSelected, setParameterSelected] = useState('');
	const [hasAudienceDataLoaded, setHasAudienceDataLoaded] =
		useState<boolean>(false);
	const filterRequestPayload = useAppSelector(selectedFilterRequestPayloadData);
	const includedFilters = filterRequestPayload.includeFilters
		? JSON.parse(filterRequestPayload.includeFilters)
		: (initialFilterValue as unknown as IAudienceListingRequest);
	const excludedFilters = filterRequestPayload.excludeFilters
		? JSON.parse(filterRequestPayload.excludeFilters)
		: (initialFilterValue as unknown as IAudienceListingRequest);
	const keyMetricesData = useAppSelector(keyMetricsData);
	const keyMetricesAPIStatus = useAppSelector(keyMetricsAPIStatus);
	const [selectedDay, setSelectedDay] = useState(Numbers.seven);
	const [currentReportType, setCurrentReportType] = useState<
		string | undefined
	>(undefined);
	const [currentMetricsSubType, setCurrentMetricsSubType] = useState<
		string | undefined
	>(undefined);
	useEffect(() => {
		if (isApiLoaded !== 'success') {
			setHasAudienceDataLoaded(false);
			isApiLoaded !== 'loading' && dispatch(getAudienceFilterData());
		} else {
			setHasAudienceDataLoaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isApiLoaded]);

	// use effect to fetch group graph data
	useEffect(() => {
		if (
			apiStatusParameterList === 'success' &&
			parameterListFetched?.length > Numbers.zero
		) {
			setParameterSelected(parameterListFetched[Numbers.zero]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiStatusParameterList]);

	// fetch latest group graph data if any one- group/parameter changes
	useEffect(() => {
		if (viewToggleButton === 'graphView') {
			getGroupWiseGraphData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		viewToggleButton,
		groupSelected,
		parameterSelected,
		filterRequestPayload,
		groupListFetched,
	]);

	useEffect(() => {
		// dispatch to get audience details
		dispatch(
			getAudienceSubset({
				questionnaireId: `${questionnaireID}`,
			})
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireId]);

	useEffect(() => {
		dispatch(
			fetchQuestionnaireResultsData({
				questionnaireId: `${questionnaireID}`,
			})
		);
		dispatch(
			getGroupsList({
				questionnaireId: `${questionnaireID}`,
			})
		);
		dispatch(fetchParameterList());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireID]);

	useEffect(() => {
		if (questionnaireID && viewToggleButton === 'graphView') {
			dispatch(
				fetchResultKeyMetrices({
					questionnaireId: `${questionnaireID}`,
					actionPayload: {
						includeFilter: JSON.stringify({ ...includedFilters }),
						excludeFilter: JSON.stringify({ ...excludedFilters }),
						days: selectedDay,
						pageNumber: Numbers.one,
						pageSize: Numbers.hundred,
						metricName: '',
						metricSubType: '',
					},
				})
			);
			getRegionGraphData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewToggleButton, questionnaireID, filterRequestPayload]);

	const refresh = () => {
		dispatch(
			fetchResultKeyMetrices({
				questionnaireId: `${questionnaireID}`,
				actionPayload: {
					includeFilter: JSON.stringify({ ...includedFilters }),
					excludeFilter: JSON.stringify({ ...excludedFilters }),
					days: selectedDay,
					pageNumber: Numbers.one,
					pageSize: Numbers.hundred,
					metricName: '',
					metricSubType: '',
				},
			})
		);
	};

	useEffect(() => {
		setShowBatchPopup(false);
		if (batchReportRequestResponseStatus === API_STATUS_CONSTANT.SUCCESS) {
			setBatchPopupMsg(uploadExcelLabels.audienceMaxRowsSuccessMessage);
			setShowBatchPopup(true);
		} else if (
			batchReportRequestResponseStatus === API_STATUS_CONSTANT.FAILED
		) {
			setBatchPopupMsg(notificationsLabels.networkError);
			setShowBatchPopup(false);
		} else {
			setShowBatchPopup(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [batchReportRequestResponseStatus]);

	useEffect(() => {
		setShowSummitPopup(false);
		if (summitReportApiStatus === API_STATUS_CONSTANT.SUCCESS) {
			setSummitPopupMsg(questionnaireResultsLabels.summitSuccessPopupMsg);
			setShowSummitPopup(true);
		} else if (summitReportApiStatus === API_STATUS_CONSTANT.NO_CONTENT) {
			setSummitPopupMsg(questionnaireResultsLabels.summitNoContentPopupMsg);
			setShowSummitPopup(true);
		} else if (summitReportApiStatus === API_STATUS_CONSTANT.FAILED) {
			setSummitPopupMsg(notificationsLabels.networkError);
			setShowSummitPopup(false);
		} else {
			setShowSummitPopup(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [summitReportApiStatus]);

	// use effect to build data of regional graph when data filter changes
	useEffect(() => {
		const regionaDataInfo = prepareRegionalGraphData(
			regionDataFetched,
			dataDisplayType,
			overallEmployeeCountRegional
		);
		setRegionData(regionaDataInfo[Numbers.zero].preppedRegionalData);
		setRegionalYMax(regionaDataInfo[Numbers.zero].maxCount);
	}, [regionDataFetched, dataDisplayType, overallEmployeeCountRegional]);

	// use effect to build data of group-wise graph when data filter changes
	useEffect(() => {
		const groupDataInfo = getGroupGraphData(
			groupData,
			dataDisplayType,
			overallEmployeeCountGroup
		);
		setCompletedByGroupData(groupDataInfo[Numbers.zero].processedGroupData);
		setGroupYMax(groupDataInfo[Numbers.zero].maxCount);
	}, [groupData, dataDisplayType, overallEmployeeCountGroup]);

	const chipChangeHandler = (chipSelected: string) => {
		// set all selected to false
		const updatedChipData = chipData.map(
			(chip: { text: string; selected: boolean }) => {
				chip.selected = false;
				if (chip.text === chipSelected) {
					chip.selected = true;
				}
				return chip;
			}
		);
		getRegionGraphData();
		setChipData(updatedChipData);
	};
	const parameterSelectHandler = (parameter: string) => {
		setParameterSelected(parameter);
	};

	const groupSelectHandler = (groupNameSelected: string) => {
		setGroupSelected(groupNameSelected);
		const selectedGroupOption = groupListFetched.find(
			(group) => group.groupName === groupNameSelected
		);
		selectedGroupOption && setSelectedGroupId(selectedGroupOption.groupId);
	};

	// Using data received from getOptionalResultsData function optionalBarChartData is prepared
	// Updates the currently selected sector
	const handleDataDisplayChange = (buttonName: string) => {
		setDataDisplayType(buttonName);
		dispatch(setDataDisplayTypeAction(buttonName));
	};

	useEffect(() => {
		if (
			downloadResultsApiCurrentStatus === API_STATUS_CONSTANT.SUCCESS &&
			downloadResultsFileData
		) {
			const fileName = getFileName(currentReportType, currentMetricsSubType);
			handleExportFile(downloadResultsFileData as Blob, fileName);
			dispatch(setDownloadResultsApiStatus(API_STATUS_CONSTANT.IDLE));
			setDisabledCompleteDownload(false);
		}
		if (downloadResultsApiCurrentStatus === API_STATUS_CONSTANT.FAILED) {
			setDisabledCompleteDownload(false);
			dispatch(setDownloadResultsApiStatus(API_STATUS_CONSTANT.IDLE));
		}
		if (
			downloadResultsApiCurrentStatus === API_STATUS_CONSTANT.ERROR_BAD_REQUEST
		) {
			setShowMaxLimitWarning(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		downloadResultsApiCurrentStatus,
		downloadResultsFileData,
		currentReportType,
		currentMetricsSubType,
	]);

	const getFileName = (reportType?: string, metricsSubType?: string) => {
		const formattedDate = getFormattedCurrentDateTime();
		let fileName = '';

		if (reportType === questionnaireAPIKeys.reportTypeMain) {
			fileName = `${questionnaireResultsLabels.overallDownloadReport}_${formattedDate}.csv`;
		} else {
			switch (metricsSubType) {
				case questionnaireAPIKeys.reportSubTypeResponseStatus:
					fileName = `${questionnaireResultsLabels.completedResponseStatusReport}_${formattedDate}.csv`;
					break;
				case questionnaireAPIKeys.reportSubTypeSummitGroupStatus:
					fileName = `${questionnaireResultsLabels.summitGroupStatusReport}_${formattedDate}.csv`;
					break;
				case questionnaireAPIKeys.reportSubTypeDefaulterDetails:
					fileName = `${questionnaireResultsLabels.nonCompliantStatusReport}_${formattedDate}.csv`;
					break;
				case questionnaireAPIKeys.reportSubTypeRegionalGraph:
					fileName = `${questionnaireResultsLabels.statusByRegionReport}_${formattedDate}.csv`;
					break;
				case questionnaireAPIKeys.reportSubTypeGroupGraph:
					fileName = `${questionnaireResultsLabels.userGroupDistributionReport}_${formattedDate}.csv`;
					break;
				default:
					fileName = `${questionnaireResultsLabels.overallDownloadReport}_${formattedDate}.csv`;
					break;
			}
		}

		return fileName;
	};

	const handleExportFile = (excelData: Blob, fileName: string) => {
		const blob = new Blob([excelData], {
			type: 'application/octet-stream',
		});
		const downloadLink = document.createElement('a');
		downloadLink.href = URL.createObjectURL(blob);
		downloadLink.download = fileName;
		downloadLink.click();
		dispatch(setDownloadResultsData(null));
	};

	const handleCloseToast = () => {
		setShowBatchPopup(false);
		dispatch(setBatchReportRequestResponse('idle'));
	};
	const handleCloseSummitPopup = () => {
		setShowSummitPopup(false);
		dispatch(setSummitReportApiStatus('idle'));
	};
	const handleClosePopup = () => {
		setShowPopup(false);
	};
	const handleCloseMaxDataWarningPopup = () => {
		setShowMaxLimitWarning(false);
		setDisabledCompleteDownload(false);
		dispatch(setDownloadResultsApiStatus(API_STATUS_CONSTANT.IDLE));
	};
	const handleMaxDataExport = () => {
		setShowMaxLimitWarning(false);
		dispatch(setDownloadResultsApiStatus(API_STATUS_CONSTANT.IDLE));
		if (questionnaireID) {
			const maxDataExportPayload = {
				id: `${questionnaireID}`,
				reporttype: uploadExcelLabels.resultExport,
			};
			setBatchPopupMsg(questionnaireResultsLabels.downloadInProgress);
			setShowBatchPopup(true);
			dispatch(batchReportRequest(maxDataExportPayload));
		} else {
			setDisabledCompleteDownload(false);
			setBatchPopupMsg(notificationsLabels.networkError);
			setShowBatchPopup(true);
		}
	};
	const handleView = (event: React.MouseEvent<HTMLElement>) => {
		setPreFilterViewValue('');
		setViewToggleButton((event.currentTarget as HTMLInputElement).value);
	};
	const triggerSelectedDaysHandler = (selectedDayAs: number) => {
		setSelectedDay(selectedDayAs);
		if (questionnaireID) {
			dispatch(
				fetchResultKeyMetrices({
					questionnaireId: `${questionnaireID}`,
					actionPayload: {
						includeFilter: JSON.stringify({ ...includedFilters }),
						excludeFilter: JSON.stringify({ ...excludedFilters }),
						days: selectedDayAs,
						pageNumber: Numbers.one,
						pageSize: Numbers.hundred,
						metricName: '',
						metricSubType: '',
					},
				})
			);
		}
	};
	const filterViewEmployees = (metricsType: string) => {
		setPreFilterViewValue(metricsType);
		setViewToggleButton('tableView');
	};

	const getRegionGraphData = () => {
		let selectedChip = chipData?.find((chip) => chip.selected)?.text;
		if (selectedChip === questionnaireResultsLabels.notCompliant) {
			selectedChip = questionnaireResultsLabels.defaulterTxt;
		}
		if (selectedChip) {
			dispatch(
				fetchRegionalGraphData({
					questionnaireId: `${questionnaireID}`,
					includeFilter: JSON.stringify({ ...includedFilters }),
					excludeFilter: JSON.stringify({ ...excludedFilters }),
					key: selectedChip,
				})
			);
		}
	};

	const getGroupWiseGraphData = () => {
		if (groupSelected) {
			const groupId = groupListFetched.find((group) => {
				return group.groupName === groupSelected;
			})?.groupId;
			dispatch(
				fetchGroupGraphData({
					questionnaireId: `${questionnaireID}`,
					actionPayload: {
						parameter: parameterSelected,
						includeFilter: JSON.stringify({ ...includedFilters }),
						excludeFilter: JSON.stringify({ ...excludedFilters }),
						groupId: groupId,
					},
				})
			);
		} else if (parameterSelected && groupListFetched.length > 0) {
			// All the group API only if the group count > 0
			dispatch(
				fetchGroupGraphData({
					questionnaireId: `${questionnaireID}`,
					actionPayload: {
						parameter: parameterSelected,
						includeFilter: JSON.stringify({ ...includedFilters }),
						excludeFilter: JSON.stringify({ ...excludedFilters }),
					},
				})
			);
		}
		// eslint-disable-next-line no-empty
		else {
			//  empty block for SONAR
		}
	};

	// handles refresh of graph requested
	const handleGraphRefresh = (type: string) => {
		switch (type) {
			case 'group':
				getGroupWiseGraphData();
				break;
			case 'region':
				getRegionGraphData();
				break;
			default:
				break;
		}
	};

	const handleReportDownload = (
		reportType?: string,
		metricsSubType?: string
	) => {
		setCurrentReportType(reportType);
		setCurrentMetricsSubType(metricsSubType);
		const reportDownloadPayload: IDownloadReq = {
			reportType: questionnaireAPIKeys.reportTypeList,
			reportSubType: null,
			regionalSubType: null,
			groupName: null,
			groupParameter: null,
			includeFilter: '',
			excludeFilter: '',
		};
		if (reportType === questionnaireAPIKeys.reportTypeMain) {
			if (viewToggleButton === questionnaireResultsLabels.graphView) {
				reportDownloadPayload.reportType = questionnaireAPIKeys.reportTypeMain;
			}
		} else {
			reportDownloadPayload.reportType =
				questionnaireAPIKeys.reportTypeKeyMetrics;
			reportDownloadPayload.includeFilter = JSON.stringify({
				...includedFilters,
			});
			reportDownloadPayload.excludeFilter = JSON.stringify({
				...excludedFilters,
			});
		}
		if (metricsSubType) {
			reportDownloadPayload.reportSubType = metricsSubType;
		}
		if (metricsSubType === questionnaireAPIKeys.reportSubTypeRegionalGraph) {
			const selectedChip = chipData.find((chip) => chip.selected === true);
			const selectedChipKey = selectedChip ? selectedChip.text : null;

			if (selectedChipKey === ChipsLabels.DEFAULTER) {
				reportDownloadPayload.regionalSubType = questionnaireAPIKeys.defaulter;
			} else if (selectedChipKey === ChipsLabels.NOTRESPONDED) {
				reportDownloadPayload.regionalSubType =
					questionnaireAPIKeys.notResponded;
			} else if (selectedChipKey === ChipsLabels.RESPONDED) {
				reportDownloadPayload.regionalSubType = questionnaireAPIKeys.responded;
			} else {
				reportDownloadPayload.regionalSubType = null;
			}
		} else if (
			metricsSubType === questionnaireAPIKeys.reportSubTypeGroupGraph
		) {
			const selectedGroupName = groupSelected;
			const selectedGroupParameter = parameterSelected;
			if (selectedGroupId && selectedGroupId !== '') {
				reportDownloadPayload.groupId = selectedGroupId;
			}
			reportDownloadPayload.groupName = selectedGroupName;
			reportDownloadPayload.groupParameter = selectedGroupParameter;
		} else {
			reportDownloadPayload.regionalSubType = null;
			reportDownloadPayload.groupName = null;
			reportDownloadPayload.groupParameter = null;
		}

		dispatch(
			reportDownload({
				questionnaireId: `${questionnaireID}`,
				downloadReqPayload: reportDownloadPayload,
			})
		);
	};

	const handleGlobalDownload = () => {
		handleReportDownload(questionnaireAPIKeys.reportTypeMain);
	};

	const handleSummitDownload = () => {
		const summitEdgeFormRequestPayload = {
			questionnaireId: `${questionnaireID}`,
			requestPayload: {
				includeFilter: JSON.stringify({
					...includedFilters,
				}),
				excludeFilter: JSON.stringify({
					...excludedFilters,
				}),
			},
			groupId: selectedGroupId,
		};

		dispatch(summitEdgeFormReportRequest(summitEdgeFormRequestPayload));
	};

	return (
		<>
			<QuestionnaireResultContainer>
				<GenericConfirmationPopup
					open={showBatchPopup}
					msgBody={<StyledMsgBody>{batchPopupMsg}</StyledMsgBody>}
					title={''}
					rightBtnText={commonLabels.ok}
					leftBtnText={commonLabels.no}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={handleCloseToast}
					leftBtnHandler={handleCloseToast}
				/>
				<GenericConfirmationPopup
					open={showSummitPopup}
					msgBody={<StyledMsgBody>{summitPopupMsg}</StyledMsgBody>}
					title={''}
					rightBtnText={commonLabels.ok}
					leftBtnText={commonLabels.no}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={handleCloseSummitPopup}
					leftBtnHandler={handleCloseSummitPopup}
				/>
				<ResultHeadingContainer>
					<HeadingTextContainer>
						<Box>
							{keyMetricesData?.questionnaireName && (
								<Typography variant="h1">
									{`${questionnaireResultsLabels.questionnaireResults} for ${keyMetricesData?.questionnaireName}`}
								</Typography>
							)}
							{keyMetricesData?.lastRefreshedDate && (
								<Typography variant="body2" fontSize="14px" lineHeight="18px">
									{`${questionnaireResultsLabels.lastRefreshTxt} ${
										keyMetricesData.lastRefreshedDate &&
										getTimeInLastRefreshFormat(
											keyMetricesData?.lastRefreshedDate
										)
									}`}
								</Typography>
							)}
						</Box>
					</HeadingTextContainer>
					<ResultActionRightContainer>
						{viewToggleButton === 'graphView' && (
							<ButtonsGroup
								onButtonSelect={handleDataDisplayChange}
								buttonsList={dataDisplayTypeBtnList}
							/>
						)}
						<Box>
							<StyledTooltip
								title={questionnaireResultsLabels.downloadButtonTooltip}
								placement="top"
							>
								<Button
									variant="outlined"
									color="secondary"
									component="span"
									startIcon={<DownloadIcon />}
									onClick={handleSummitDownload}
									sx={{
										mr: '10px',
										mt: '2px',
										minWidth: '124px',
										minHeight: '32px',
										background: STEPTheme.colors.background,
									}}
								>
									{questionnaireResultsLabels.summitDownload}
								</Button>
							</StyledTooltip>
							<StyledTooltip
								title={questionnaireResultsLabels.downloadButtonTooltip}
								placement="top"
							>
								<Button
									variant="outlined"
									color="secondary"
									component="span"
									startIcon={<DownloadIcon />}
									onClick={handleGlobalDownload}
									sx={{
										mr: '10px',
										mt: '2px',
										minWidth: '124px',
										minHeight: '32px',
										background: STEPTheme.colors.background,
									}}
								>
									{questionnaireResultsLabels.download}
								</Button>
							</StyledTooltip>
						</Box>
						<ToggleButtonGroup
							value={viewToggleButton}
							exclusive
							onChange={handleView}
						>
							<StyledTooltip
								title={questionnaireResultsLabels.listViewButtonLabel}
								placement="top"
							>
								<ToggleButtonStyled value="tableView">
									<ListView className="toggle-selected-view" />
								</ToggleButtonStyled>
							</StyledTooltip>
							<StyledTooltip
								title={questionnaireResultsLabels.graphViewButtonLabel}
								placement="top"
							>
								<ToggleButtonStyled
									value="graphView"
									sx={{
										marginLeft: '0px',
									}}
								>
									<GraphView className="toggle-selected-view" />
								</ToggleButtonStyled>
							</StyledTooltip>
						</ToggleButtonGroup>
					</ResultActionRightContainer>
				</ResultHeadingContainer>
				<GeneralResultHeadingContainer>
					{viewToggleButton === 'tableView' ? (
						<ListTableView preFilterValue={preFilterValue} />
					) : (
						<Fragment>
							<ResultFilter hasAudienceDataLoaded={hasAudienceDataLoaded} />
							{keyMetricesAPIStatus === API_STATUS_CONSTANT.FAILED ? (
								<KeyMetricsError
									triggerSelectedDays={triggerSelectedDaysHandler}
									selectedDay={selectedDay}
									refreshFn={refresh}
								/>
							) : (
								<KeyMetrics
									type={dataDisplayType}
									employeesAtRiskOfOverdue={
										keyMetricesData?.metrics?.employeesAtRiskOfOverdue ?? {}
									}
									employeesOverdue={
										keyMetricesData?.metrics?.employeesOverdue ?? {}
									}
									employeesDefaulters={
										keyMetricesData?.metrics?.employeesDefaulters ?? {}
									}
									triggerSelectedDays={triggerSelectedDaysHandler}
									selectedDay={selectedDay}
									refreshFn={refresh}
									filterViewEmployees={filterViewEmployees}
								/>
							)}
							<GeneralResultsContainer>
								<GraphContainers
									sx={{
										flexDirection: 'row',
									}}
								>
									<Box
										sx={{
											display: 'flex',
											height: '303px',
											justifyContent: 'space-between',
											alignItems: 'stretch',
											boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
											borderRadius: '12px',
											background: '#fff',
											width: '29%',
											padding: '0px 20px',
										}}
									>
										<DonutChart
											reportSubTypeKey={
												questionnaireAPIKeys.reportSubTypeResponseStatus
											}
											downloadHandler={handleReportDownload}
											data={[
												{
													label: questionnaireResultsLabels.respondedTxt,
													value: getStatusData(
														questionnaireResultsLabels.dataDisplayTypeValues,
														keyMetricesData?.metrics?.employeesResponseStatus ??
															{},
														''
													),
													color: STEPTheme.colors.lightGreen,
												},
												{
													label: questionnaireResultsLabels.notRespondedTxt,
													value: getStatusData(
														questionnaireResultsLabels.dataDisplayTypeValues,
														keyMetricesData?.metrics?.employeesResponseStatus ??
															{},
														'other'
													),
													color: STEPTheme.colors.lightRed,
												},
											]}
											id={'donut-chart-svg'}
											width={Numbers.oneFifty}
											height={Numbers.oneFifty}
											summaryData={[
												{
													label: questionnaireResultsLabels.respondedTxt,
													value: getStatusData(
														dataDisplayType,
														keyMetricesData?.metrics?.employeesResponseStatus ??
															{},
														''
													),
													color: STEPTheme.colors.lightGreen,
												},
												{
													label: questionnaireResultsLabels.notRespondedTxt,
													value: getStatusData(
														dataDisplayType,
														keyMetricesData?.metrics?.employeesResponseStatus ??
															{},
														'other'
													),
													color: STEPTheme.colors.lightRed,
												},
											]}
											centerText={`${questionnaireResultsLabels.respondedTxt}|${getStatusData(
												dataDisplayType,
												keyMetricesData?.metrics?.employeesResponseStatus ?? {},
												''
											)}${dataDisplayType === 'Percentage' ? '%' : ''}`}
											heading={questionnaireResultsLabels.completed}
											chartType={dataDisplayType}
											summaryPosition="below"
											refresh={refresh}
											showAdditionalInfo={true}
										/>
									</Box>
									<Box
										sx={{
											display: 'flex',
											height: '303px',
											justifyContent: 'space-between',
											alignItems: 'stretch',
											boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
											borderRadius: '12px',
											background: '#fff',
											width: '29%',
											padding: '0px 20px',
										}}
									>
										<DonutChart
											reportSubTypeKey={
												questionnaireAPIKeys.reportSubTypeSummitGroupStatus
											}
											downloadHandler={handleReportDownload}
											data={[
												{
													label: questionnaireResultsLabels.mappedSummitGroup,
													value: getStatusData(
														questionnaireResultsLabels.dataDisplayTypeValues,
														keyMetricesData?.metrics
															?.employeesSummitGroupStatus ?? {},
														''
													),
													color: STEPTheme.colors.lightGreen,
												},
												{
													label:
														questionnaireResultsLabels.mappedNotSummitGroup,
													value: getStatusData(
														questionnaireResultsLabels.dataDisplayTypeValues,
														keyMetricesData?.metrics
															?.employeesSummitGroupStatus ?? {},
														'other'
													),
													color: STEPTheme.colors.lightRed,
												},
											]}
											id={'donut-chart-svg-1'}
											width={Numbers.oneFifty}
											height={Numbers.oneFifty}
											summaryData={[
												{
													label: questionnaireResultsLabels.mappedSummitGroup,
													value: getStatusData(
														dataDisplayType,
														keyMetricesData?.metrics
															?.employeesSummitGroupStatus ?? {},
														''
													),
													color: STEPTheme.colors.lightGreen,
												},
												{
													label:
														questionnaireResultsLabels.mappedNotSummitGroup,
													value: getStatusData(
														dataDisplayType,
														keyMetricesData?.metrics
															?.employeesSummitGroupStatus ?? {},
														'other'
													),
													color: STEPTheme.colors.lightRed,
												},
											]}
											centerText={`${questionnaireResultsLabels.mapped}|${getStatusData(
												dataDisplayType,
												keyMetricesData?.metrics?.employeesSummitGroupStatus ??
													{},
												''
											)}${dataDisplayType === 'Percentage' ? '%' : ''}`}
											heading={questionnaireResultsLabels.summitGroupStatus}
											chartType={dataDisplayType}
											summaryPosition="below"
											refresh={refresh}
											showAdditionalInfo={true}
										/>
									</Box>
									<Box
										sx={{
											display: 'flex',
											height: '303px',
											justifyContent: 'space-between',
											boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
											borderRadius: '12px',
											background: '#fff',
											width: '29%',
											padding: '0px 20px',
											alignItems: 'stretch',
										}}
									>
										<DonutChart
											reportSubTypeKey={
												questionnaireAPIKeys.reportSubTypeDefaulterDetails
											}
											downloadHandler={handleReportDownload}
											data={[
												{
													label:
														questionnaireResultsLabels.respondedAfterDueDate,
													value: getStatusData(
														questionnaireResultsLabels.dataDisplayTypeValues,
														keyMetricesData?.metrics
															?.employeesDefaultersDetails ?? {},
														''
													),
													color: STEPTheme.colors.amber,
												},
												{
													label: questionnaireResultsLabels.notRespondedYet,
													value: getStatusData(
														questionnaireResultsLabels.dataDisplayTypeValues,
														keyMetricesData?.metrics
															?.employeesDefaultersDetails ?? {},
														'other'
													),
													color: STEPTheme.colors.lightRed,
												},
											]}
											id={'donut-chart-svg-3'}
											width={Numbers.oneFifty}
											height={Numbers.oneFifty}
											summaryData={[
												{
													label:
														questionnaireResultsLabels.respondedAfterDueDate,
													value: getStatusData(
														dataDisplayType,
														keyMetricesData?.metrics
															?.employeesDefaultersDetails ?? {},
														''
													),
													color: STEPTheme.colors.amber,
												},
												{
													label: questionnaireResultsLabels.notRespondedYet,
													value: getStatusData(
														dataDisplayType,
														keyMetricesData?.metrics
															?.employeesDefaultersDetails ?? {},
														'other'
													),
													color: STEPTheme.colors.lightRed,
												},
											]}
											centerText={`${questionnaireResultsLabels.respondedAfterDueDate}|${getStatusData(
												dataDisplayType,
												keyMetricesData?.metrics?.employeesDefaultersDetails ??
													{},
												''
											)}${dataDisplayType === 'Percentage' ? '%' : ''}`}
											heading={questionnaireResultsLabels.notCompliant}
											chartType={dataDisplayType}
											summaryPosition="below"
											refresh={refresh}
											showAdditionalInfo={true}
										/>
									</Box>
								</GraphContainers>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										paddingTop: '30px',
									}}
								>
									<BarChartCard
										cardKey={questionnaireAPIKeys.reportSubTypeRegionalGraph}
										downloadHandler={handleReportDownload}
										cardName={startCase(
											questionnaireResultsLabels.completedByRegionTxt
										)}
										graphData={regionData}
										isChipToShow={true}
										chipData={chipData}
										handleChipSelect={(chipSelected) => {
											chipChangeHandler(chipSelected);
										}}
										colors={[
											STEPTheme.colors.lightBlue,
											STEPTheme.colors.latinBlue,
										]}
										legends={[
											{
												key: questionnaireResultsLabels.contingentWorkerTxt,
												color: STEPTheme.colors.lightBlue,
												label: questionnaireResultsLabels.contingentWorkerTxt,
											},
											{
												key: questionnaireResultsLabels.employeeTxt,
												color: STEPTheme.colors.latinBlue,
												label: questionnaireResultsLabels.employeeTxt,
											},
										]}
										chartType={dataDisplayType}
										isParameterToShow={false}
										isGroupToShow={false}
										graphId={'region-graph-id'}
										height={174}
										yMax={regionalYMax}
										refreshHandler={() => handleGraphRefresh('region')}
									/>
									<BarChartCard
										cardKey={questionnaireAPIKeys.reportSubTypeGroupGraph}
										downloadHandler={handleReportDownload}
										cardName={startCase(
											questionnaireResultsLabels.groupWiseTxt
										)}
										graphData={completedByGroupData}
										isChipToShow={false}
										colors={[STEPTheme.colors.purple]}
										legends={[
											{
												key: 'users',
												color: STEPTheme.colors.purple,
												label: questionnaireResultsLabels.totalNoOfUsersTxt,
											},
										]}
										chartType={dataDisplayType}
										isParameterToShow={true}
										isGroupToShow={true}
										graphId={'group-graph-id'}
										height={200}
										yMax={groupYMax}
										groupList={groupListDropDownOptions}
										paramList={parameterListDropDownOptions}
										handleParameterSelect={(e) =>
											parameterSelectHandler(e.target.value)
										}
										handleGroupSelect={(e) =>
											groupSelectHandler(e.target.value)
										}
										groupSelected={groupSelected}
										parameterSelected={parameterSelected}
										refreshHandler={() => handleGraphRefresh('group')}
									/>
								</Box>
							</GeneralResultsContainer>
							<Main />
						</Fragment>
					)}
				</GeneralResultHeadingContainer>
			</QuestionnaireResultContainer>
			{viewToggleButton === 'graphView' && (
				<Fragment>
					<GenericModalPopup
						openProp={showPopup}
						msgBody={questionnaireResultsLabels.reportDownloadErrorTitle}
						mainMsg=""
						rightBtnText={commonLabels.ok}
						leftBtnText={commonLabels.no}
						isRightBtnVisible={true}
						isLeftBtnVisible={false}
						rightBtnHandler={handleClosePopup}
						leftBtnHandler={handleClosePopup}
					/>
					<GenericConfirmationPopup
						open={showMaxLimitWarning}
						msgBody={
							<StyledMsgBody>
								{uploadExcelLabels.audienceMaxRowsMessage}
							</StyledMsgBody>
						}
						title={uploadExcelLabels.audienceMaxRowsTitle}
						rightBtnText={commonLabels.ok}
						leftBtnText={commonLabels.no}
						isRightBtnVisible={true}
						isLeftBtnVisible={false}
						rightBtnHandler={handleMaxDataExport}
						leftBtnHandler={handleCloseMaxDataWarningPopup}
					/>
				</Fragment>
			)}
		</>
	);
};

export default QuestionnaireResult;
