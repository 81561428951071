import { QUECONSTANT } from '../../constants/StepConstants';
import { IAnswers, IAnswersData } from '../../types/QuestionnaireResultsType';
import { BarChartDataItem, DataItemProps } from '../../types/ResultsType';
import { ISurveyFormsQuestion } from '../../types/SurveyProps';
import { questionnaireResultsLabels } from '../../utils/CommonLabels';
import { STEPTheme } from '../../utils/Theme';
import { Numbers } from '../../utils/Enum';

const primaryColors = [
	STEPTheme.colors.blue,
	STEPTheme.colors.responseGraphNoOptionColor,
];
const pieChartColors = Object.values(STEPTheme.pieChartColors);

// Function to find answer data for a particular question Id
export const getAnswersDataByQuestionId = (
	questionId: string,
	answersData: IAnswersData[]
) => {
	return answersData.find((answer) => `${answer.questionId}` === questionId);
};

export const getTotalEmployeeAnsweredByQuestionId = (
	questionId: string,
	answersData: IAnswersData[]
) => {
	const answerObj = getAnswersDataByQuestionId(questionId, answersData);
	if (answerObj) {
		return answerObj?.totalEmployeesAnswered;
	} else {
		return Numbers.zero;
	}
};

export const getResponseCountByOption = (
	option: string,
	answers: IAnswers[],
	answersData: IAnswersData,
	dataDisplayType: string
) => {
	const foundOptionResponseObj = answers.find(
		(answer) => answer.option === option
	);
	if (
		foundOptionResponseObj &&
		answersData.totalEmployeesAnswered !== Numbers.zero
	) {
		if (
			dataDisplayType === questionnaireResultsLabels.dataDisplayTypePercentage
		) {
			return parseFloat(
				(
					(foundOptionResponseObj?.count / answersData.totalEmployeesAnswered) *
					Numbers.hundred
				).toFixed(Numbers.two)
			);
		} else {
			return foundOptionResponseObj?.count;
		}
	} else {
		return Numbers.zero;
	}
};
/**
 * @description get center text for donut chart based on type of data selected and responses
 * @description Finds out maximum value of responses and based on dataDisplayType returns centerText
 * @param answersData
 * @param dataDisplayType
 * @param questionJSONString
 * @returns string
 */

export const getCenterTextForDonut = (
	answersData: IAnswersData[],
	dataDisplayType: string,
	questionJSONString: ISurveyFormsQuestion
) => {
	const questionId = questionJSONString.questionId;
	const answerObj = getAnswersDataByQuestionId(questionId, answersData);
	let centerText = '';
	if (answerObj) {
		const totalAnswered = answerObj.totalEmployeesAnswered;
		const answers = answerObj.answers;
		const maxOption = answers.reduce((max, current) => {
			return current.count > max.count ? current : max;
		}, answers[Numbers.zero]);
		if (totalAnswered > Numbers.zero) {
			if (
				dataDisplayType === questionnaireResultsLabels.dataDisplayTypePercentage
			) {
				const maxPercentage = parseFloat(
					((maxOption?.count / totalAnswered) * Numbers.hundred).toFixed(
						Numbers.two
					)
				);
				centerText = `${maxOption.option}|${maxPercentage}${'%'}`;
			} else {
				centerText = `${maxOption.option}|${maxOption?.count}`;
			}
		} else {
			if (
				dataDisplayType === questionnaireResultsLabels.dataDisplayTypePercentage
			) {
				centerText = `${answers[Numbers.zero].option}|${Numbers.zero}${'%'}`;
			} else {
				centerText = `${answers[Numbers.zero].option}|${Numbers.zero}`;
			}
		}
	}

	return centerText;
};

export const getChartSummaryData = (
	chartType: string,
	dataDisplayType: string,
	questionJSONString: ISurveyFormsQuestion,
	answersData: IAnswersData[]
) => {
	const questionId = questionJSONString.questionId;
	const answerObj = getAnswersDataByQuestionId(questionId, answersData);
	const totalResponded = getTotalEmployeeAnsweredByQuestionId(
		questionJSONString?.questionId,
		answersData
	);
	const barChartData = [] as DataItemProps[];
	if (answerObj) {
		switch (chartType) {
			case 'donutChart':
				return (
					questionJSONString?.options?.map((item, index: number) => ({
						label: `${item?.options} ${getResponseCountByOption(item.options, answerObj.answers, answerObj, dataDisplayType)}${
							dataDisplayType ===
							questionnaireResultsLabels.dataDisplayTypePercentage
								? '%'
								: `/${totalResponded}`
						}`,
						value: getResponseCountByOption(
							item.options,
							answerObj.answers,
							answerObj,
							dataDisplayType
						),
						color: primaryColors[index],
						outOfValue: Numbers.zero,
						totalValue: Numbers.hundred,
					})) || []
				);
			case 'barChart':
				questionJSONString?.options?.map((item, index: number) =>
					barChartData.push({
						label: `Answer ${index + 1}`,
						secondaryLabel: item?.options,
						value: getResponseCountByOption(
							item.options,
							answerObj.answers,
							answerObj,
							dataDisplayType
						),
						color: STEPTheme.colors.responseGraphMultipleOptionsColor,
						outOfValue: Numbers.zero,
						totalValue: Numbers.hundred,
					})
				);
				return barChartData;
			case 'dropDown-donut':
				return (
					questionJSONString?.options?.map((item, index: number) => ({
						label: `${item?.options} ${getResponseCountByOption(item.options, answerObj.answers, answerObj, dataDisplayType)}${
							dataDisplayType ===
							questionnaireResultsLabels.dataDisplayTypePercentage
								? '%'
								: `/${totalResponded}`
						}`,
						value: getResponseCountByOption(
							item.options,
							answerObj.answers,
							answerObj,
							dataDisplayType
						),
						color: pieChartColors[index]
							? pieChartColors[index]
							: pieChartColors[Math.abs(index - pieChartColors.length)],
						outOfValue: Numbers.zero,
						totalValue: Numbers.hundred,
					})) || []
				);
			default:
				return [];
		}
	} else {
		return [];
	}
};

export const getGraphData = (
	questionType: string,
	dataDisplayType: string,
	questionJSONString: ISurveyFormsQuestion,
	answersData: IAnswersData[]
) => {
	const questionId = questionJSONString.questionId;
	const answerObj = getAnswersDataByQuestionId(questionId, answersData);
	if (answerObj) {
		if (questionType === QUECONSTANT.QUESTION_TYPE_DROPDOWN) {
			return (
				questionJSONString?.options?.map((item, index: number) => ({
					label: `${item?.options} ${getResponseCountByOption(item.options, answerObj.answers, answerObj, dataDisplayType)}${dataDisplayType === questionnaireResultsLabels.dataDisplayTypePercentage ? '%' : ''}`,
					value: getResponseCountByOption(
						item.options,
						answerObj.answers,
						answerObj,
						dataDisplayType
					),
					color: pieChartColors[index]
						? pieChartColors[index]
						: pieChartColors[Math.abs(index - pieChartColors.length)],
				})) || []
			);
		} else if (questionType === QUECONSTANT.QUESTION_TYPE_YES_NO) {
			return (
				questionJSONString?.options?.map((item, index: number) => ({
					label: `${item?.options} ${getResponseCountByOption(item.options, answerObj.answers, answerObj, dataDisplayType)}${dataDisplayType === questionnaireResultsLabels.dataDisplayTypePercentage ? '%' : ''}`,
					value: getResponseCountByOption(
						item.options,
						answerObj.answers,
						answerObj,
						dataDisplayType
					),
					color: primaryColors[index],
				})) || []
			);
		} else {
			return [];
		}
	} else {
		return [];
	}
};

export const getBarGraphData = (
	dataDisplayType: string,
	questionJSONString: ISurveyFormsQuestion,
	answersData: IAnswersData[]
) => {
	const questionId = questionJSONString.questionId;
	const answerObj = getAnswersDataByQuestionId(questionId, answersData);
	if (answerObj) {
		const barChartData = [] as BarChartDataItem[];
		questionJSONString?.options?.map((_item, index: number) =>
			barChartData.push({
				label: `Answer ${index + 1}`,
				segments: [
					{
						value: getResponseCountByOption(
							_item.options,
							answerObj.answers,
							answerObj,
							dataDisplayType
						),
						color: STEPTheme.colors.responseGraphMultipleOptionsColor,
						label: `Answer ${index + 1}`,
					},
				],
			})
		);
		return barChartData;
	} else {
		return [];
	}
};

export const getYMaxValue = (
	questionId: string,
	answersData: IAnswersData[],
	dataDisplayType: string
) => {
	let yMax = Numbers.hundred;
	if (
		dataDisplayType === questionnaireResultsLabels.dataDisplayTypePercentage
	) {
		return yMax;
	} else {
		yMax = getTotalEmployeeAnsweredByQuestionId(questionId, answersData);
	}
	return yMax;
};
