import { Box, Button, styled } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as CheckIcon } from '../../assets/images/Check-icon.svg';
import { IChipGroup } from '../../types/CommonTypes';

const useStyles = makeStyles({
	checkIcon: {
		marginRight: '2px',
		left: '2.66px',
	},
});

const StyledButton = styled(Button)({
	border: '1px',
	borderRadius: '25px',
	textTransform: 'none',
	height: '24px',
	margin: '5px',
	cursor: 'pointer',
});

const generateStyles = (
	props: IChipGroup,
	selected: boolean,
	visible = true
) => {
	if (selected) {
		return {
			color: props.color,
			border: `1px solid ${props.color}`,
			background: props.backgroundColor,
			display: visible ? 'flex' : 'none',
		};
	} else {
		return {
			color: props.color,
			border: `1px solid ${props.color}`,
			display: visible ? 'flex' : 'none',
		};
	}
};

// Component displays a group of chips which can be customized according to size, color and selected color. onSelect function passed in props is called when chip is selected
const ChipGroup = (props: IChipGroup) => {
	const classes = useStyles();

	return (
		<Box sx={{ display: 'flex', gap: 1 }}>
			{props.chips.map(
				(chip: { text: string; selected: boolean; visible?: boolean }) => (
					<StyledButton
						key={chip.text}
						sx={generateStyles(props, chip.selected, chip.visible)}
						onClick={() => props.onSelect(chip.text)}
					>
						{chip.selected && <CheckIcon className={classes.checkIcon} />}
						{chip.text}
					</StyledButton>
				)
			)}
		</Box>
	);
};

export default ChipGroup;
