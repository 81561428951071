import { Numbers } from '../../../utils/Enum';
import { stepMuiTheme } from '../../../utils/Theme';
const theme = stepMuiTheme;

export const breakPointBetween_0_1300 = theme.breakpoints.between(
	Numbers.zero,
	Numbers.thirteenHundred
);

export const breakPointBetween_601_768 = theme.breakpoints.between(
	Numbers.sixHundredOne,
	Numbers.sevenSixtyEight
);

export const breakPointBetween_480_768 = theme.breakpoints.between(
	Numbers.fourHundredEighty,
	Numbers.sevenSixtyEight
);

export const breakPointBetween_480_601 = theme.breakpoints.between(
	Numbers.fourHundredEighty,
	Numbers.sixHundredOne
);

export const breakPointBetween_768_1025 = theme.breakpoints.between(
	Numbers.sevenSixtyEight,
	Numbers.thousandTwentyFive
);

export const breakPointBetween_768_1300 = theme.breakpoints.between(
	Numbers.sevenSixtyEight,
	Numbers.thirteenHundred
);

export const breakPointDown_339 = theme.breakpoints.down(
	Numbers.threeThirtyNine
);

export const breakPointDown_768 = theme.breakpoints.down(
	Numbers.sevenSixtyEight
);

export const breakPointDown_1025 = theme.breakpoints.down(
	Numbers.thousandTwentyFive
);

export const breakPointDown_601 = theme.breakpoints.down(Numbers.sixHundredOne);

export const breakPointBetween_339_400 = theme.breakpoints.between(
	Numbers.threeThirtyNine,
	Numbers.fourHundred
);

export const breakPointDown_480 = theme.breakpoints.down(
	Numbers.fourHundredEighty
);

export const breakPointBetween_200_368 = theme.breakpoints.between(
	Numbers.twoHundred,
	Numbers.threeSixtyEight
);
