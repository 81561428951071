import React, { useEffect } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Grid,
	Button,
	Dialog,
	DialogTitle,
	IconButton,
	DialogContent,
	useMediaQuery,
	useTheme,
	tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { orderBy } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@material-ui/core';
import { APIKeys, tableHead } from '../utils/Filter';
import { Numbers, PageRows } from '../utils/Enum';
import {
	IDateFilter,
	IEndUserQuestionnarieListingDataItem,
} from '../types/CommonTypes';
import { ReactComponent as DownArrow } from '../assets/images/Doarrow-narrow-up.svg';
import {
	getFilterDate,
	pageIndex,
	setFilterDate,
	setPageIndex,
	setRowsPerPageValue,
	configurationData,
} from '../redux/SurveySlice';
import { useAppDispatch, useAppSelector } from '../app/Hooks';
import {
	EndUserSurveyModifiedListingData,
	fetchQuestionnaireSurveyData,
	setModifiedEndUserSurveyResponse,
} from '../redux/QuestionnarieUserSlice';
import { STEPTheme } from '../utils/Theme';
import {
	ActionLabel,
	EndUserListingPageLabels,
	commonLabels,
	questionnaireResultsLabels,
} from '../utils/CommonLabels';
import { messages } from '../utils/Messages';
import { ReactComponent as EditLogo } from '../assets/images/Edit-Icon.svg';
import { ReactComponent as RightArrowBlack } from '../assets/images/arrow-narrow-right.svg';
import { ReactComponent as ActiveFilterLogo } from '../assets/images/Enablefilter-funnel.svg';
import { ReactComponent as FilterLogo } from '../assets/images/filter-funnel-02.svg';
import { BASIC_CONSTANT, END_USER_CONSTANT } from '../constants/StepConstants';
import { AppDispatch } from '../app/Store';
import FilterCalender from './FilterCalender';
import FallbackUI from './common/FallbackUi';
import StyledTooltip from './StyledTooltip';

const StyledTableHeaderCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: STEPTheme.colors.background,
		color: 'black',
	},
}));

const QuestionarieSurveyTable = () => {
	const theme = useTheme();
	const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
	const date = useAppSelector(getFilterDate);
	const surveyTablePageIndex: number = useAppSelector(pageIndex);
	const questionnairesList: IEndUserQuestionnarieListingDataItem[] =
		useAppSelector(EndUserSurveyModifiedListingData);
	const totalQuestionnaires = questionnairesList.length;
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const configs = useAppSelector(configurationData);
	const [surveyNameSortObj, setSurveyNameSortObj] = React.useState(
		END_USER_CONSTANT.DESCENDING
	);
	const [responseStatusObj, setResponseStatusObj] = React.useState(
		END_USER_CONSTANT.DESCENDING
	);
	const [statusObj, setStatusObj] = React.useState(
		END_USER_CONSTANT.DESCENDING
	);
	const [surveyIdSortObj, setSurveyIdObj] = React.useState(
		END_USER_CONSTANT.DESCENDING
	);
	const [dueDateObj, setDueDateObj] = React.useState(
		END_USER_CONSTANT.DESCENDING
	);
	const [startDateObj, setStartDateObj] = React.useState(
		END_USER_CONSTANT.DESCENDING
	);
	const [dueDateOpen, setDueDateOpen] = React.useState(false);
	const [startDateOpen, setStartDateOpen] = React.useState(false);
	const [enduserListingTriggered, setEndUserListingTriggered] =
		React.useState(false);
	useEffect(() => {
		!enduserListingTriggered &&
			getEndUserListingData(dispatch, setEndUserListingTriggered);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enduserListingTriggered]);
	useEffect(() => {
		dispatch(
			setFilterDate({
				startDate: '',
				endDate: '',
				createdAtStartDate: '',
				createdAtEndDate: '',
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const rowsPerPageArr = [
		Numbers.ten,
		Numbers.fifteen,
		Numbers.twenty,
		Numbers.twentyFive,
		Numbers.fifty,
		Numbers.hundred,
	];
	const rowPage =
		configs?.rows_per_page?.length > 0
			? configs?.rows_per_page?.map((i) => Number(i))
			: rowsPerPageArr;
	const [page, setPage] = React.useState(surveyTablePageIndex);
	const [rowsPerPage, setRowsPerPage] = React.useState(
		checkRowPerPage(rowPage)
	);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		dispatch(setPageIndex(newPage));
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
		dispatch(setRowsPerPageValue(rowsPerPage));
		dispatch(setPageIndex(Numbers.zero));
	};

	const handleClose = () => {
		setDueDateOpen(false);
		setStartDateOpen(false);
	};

	useEffect(() => {
		setDueDateOpen(false);
		setStartDateOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnairesList]);

	const handleSortClick = (value: string, key: string) => {
		const sortKeyValue = { value, key };
		value = handleSortBy(
			sortKeyValue,
			setSurveyNameSortObj,
			setSurveyIdObj,
			setStartDateObj,
			setDueDateObj,
			setResponseStatusObj,
			setStatusObj
		);
		handleSortCodeId(value, key, questionnairesList, dispatch);
	};

	const handleRedirectionEndUser = (questionnaireId: string) => {
		navigate(`/end-user/${questionnaireId}`);
	};

	const handleRedirection = (
		questionnaireType: string | undefined,
		questionnaireId: string
	) => {
		if (questionnaireType === BASIC_CONSTANT.SINGLE_PAGE) {
			navigate(`/cwuser/${questionnaireId}`);
		} else {
			navigate(`/end-user/${questionnaireId}`);
		}
	};

	const useStyles = makeStyles({
		tableRowBg: {
			background: STEPTheme.colors.white,
			borderRadius: 16,
		},
		paginationBorder: {
			borderRadius: '16px',
		},
		tableCellActionButton: {
			[theme.breakpoints.down('md')]: {
				position: 'sticky',
				right: 0,
				borderLeft: `{2px solid ${STEPTheme.colors.white}`,
				background: STEPTheme.colors.background,
			},
		},
	});

	const HeadText = styled('div')({
		width: 'auto',
		height: '22px',
		fontFamily: 'JohnsonText-Regular',
		fontSize: '16px',
		fontWeight: '700',
		lineHeight: '16px',
		letterSpacing: '0px',
		textAlign: 'left',
		display: 'flex',
		svg: {
			cursor: 'pointer',
		},
		'.up-arrow': {
			svg: {
				cursor: 'pointer',
				transform: 'rotate(180deg)',
			},
		},
	});
	const StyledButton = styled(Button)({
		height: '40px',
		color: STEPTheme.colors.black,
		background: 'transparent',
		borderRadius: '8px',
		justify: 'space-between',
		border: `none`,
		textTransform: 'none',
		textAlign: 'left',
		padding: '4px 10px',
		lineHeight: '16px',
		fontSize: '14px',
		'&:hover': {
			backgroundColor: STEPTheme.colors.linkGray,
		},
	});

	const classes = useStyles();

	return totalQuestionnaires > 0 ? (
		<>
			<TableContainer
				sx={{
					borderRadius: '12px',
					maxHeight: '420px',
					maxWidth: '100%',
					boxShadow: ' 0px 5px 6px rgba(0, 0, 0, 0.16)',
					background: STEPTheme.colors.background,
				}}
			>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<StyledTableHeaderCell>
								<HeadText>
									{tableHead.label.QUESTIONNAIRE_NAME}
									<StyledTooltip
										title={EndUserListingPageLabels.columnHeaderTooltip}
										placement="top"
									>
										{questionnaireHeaderCell(
											surveyNameSortObj,
											'questionnaireName',
											handleSortClick
										)}
									</StyledTooltip>
								</HeadText>
							</StyledTableHeaderCell>

							<StyledTableHeaderCell>
								<HeadText>
									{tableHead.label.QUESTIONNAIRE_ID}
									<StyledTooltip
										title={EndUserListingPageLabels.columnHeaderTooltip}
										placement="top"
									>
										{questionnaireHeaderCell(
											surveyIdSortObj,
											'questionnaireCode',
											handleSortClick
										)}
									</StyledTooltip>
								</HeadText>
							</StyledTableHeaderCell>

							<StyledTableHeaderCell>
								<HeadText>
									{tableHead.label.START_DATE}

									<StyledTooltip
										title={EndUserListingPageLabels.columnHeaderTooltip}
										placement="top"
									>
										{questionnaireHeaderCell(
											startDateObj,
											'startDate',
											handleSortClick
										)}
									</StyledTooltip>
									{checkStartDateOpen(startDateOpen, date) ? (
										<StyledTooltip
											title={EndUserListingPageLabels.startDateTooltip}
											placement="top"
										>
											<FilterLogo
												className="cursor-pointer"
												onClick={() => setStartDateOpen(true)}
											/>
										</StyledTooltip>
									) : (
										<ActiveFilterLogo
											className="cursor-pointer"
											onClick={() => setStartDateOpen(true)}
										/>
									)}
								</HeadText>
							</StyledTableHeaderCell>
							<Dialog
								open={startDateOpen}
								onClose={() => setStartDateOpen(false)}
								style={{ marginTop: '150px' }}
							>
								<DialogTitle variant="h5">
									{questionnaireResultsLabels.selectStartDate}
								</DialogTitle>
								<IconButton
									aria-label="close"
									onClick={handleClose}
									sx={{
										position: 'absolute',
										right: Numbers.eight,
										top: Numbers.eight,
									}}
								>
									<CloseIcon />
								</IconButton>
								<DialogContent>
									<FilterCalender type={'startDate'} scope={'endUser'} />
								</DialogContent>
							</Dialog>

							<StyledTableHeaderCell>
								<HeadText>
									{tableHead.label.END_DATE}
									<StyledTooltip
										title={EndUserListingPageLabels.columnHeaderTooltip}
										placement="top"
									>
										{questionnaireHeaderCell(
											dueDateObj,
											'dueDate',
											handleSortClick
										)}
									</StyledTooltip>
									{checkDueDateOpen(dueDateOpen, date) ? (
										<StyledTooltip
											title={EndUserListingPageLabels.dueDateTooltip}
											placement="top"
										>
											<FilterLogo
												className="cursor-pointer"
												onClick={() => setDueDateOpen(true)}
											/>
										</StyledTooltip>
									) : (
										<ActiveFilterLogo
											className="cursor-pointer"
											onClick={() => setDueDateOpen(true)}
										/>
									)}
								</HeadText>
							</StyledTableHeaderCell>
							<Dialog
								open={dueDateOpen}
								onClose={() => setDueDateOpen(false)}
								style={{ marginTop: '150px' }}
							>
								<DialogTitle variant="h5">
									{questionnaireResultsLabels.selectDueDate}
								</DialogTitle>
								<IconButton
									aria-label="close"
									onClick={handleClose}
									sx={{
										position: 'absolute',
										right: Numbers.eight,
										top: Numbers.eight,
									}}
								>
									<CloseIcon />
								</IconButton>
								<DialogContent>
									<FilterCalender type={'dueDate'} scope={'endUser'} />
								</DialogContent>
							</Dialog>

							<StyledTableHeaderCell>
								<HeadText>
									{tableHead.label.STATUS}
									<StyledTooltip
										title={EndUserListingPageLabels.columnHeaderTooltip}
										placement="top"
									>
										{questionnaireHeaderCell(
											statusObj,
											'questionnaireStatus',
											handleSortClick
										)}
									</StyledTooltip>
								</HeadText>
							</StyledTableHeaderCell>

							<StyledTableHeaderCell>
								<HeadText>
									{tableHead.label.RESPONSE}
									<StyledTooltip
										title={EndUserListingPageLabels.columnHeaderTooltip}
										placement="top"
									>
										{questionnaireHeaderCell(
											responseStatusObj,
											'response',
											handleSortClick
										)}
									</StyledTooltip>
								</HeadText>
							</StyledTableHeaderCell>

							<TableCell
								className={classes.tableCellActionButton}
								sx={{ background: STEPTheme.colors.background }}
							>
								<HeadText> {tableHead.label.ACTION}</HeadText>
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody className={classes.tableRowBg}>
						{questionnairesList
							?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							?.map((row) => (
								<TableRow hover key={row.questionnaireCode} tabIndex={-1}>
									<TableCell className="break-all" component="th" scope="row">
										{row.questionnaireName}
									</TableCell>
									<TableCell>{row.questionnaireCode}</TableCell>
									<TableCell>{row.startDate}</TableCell>
									<TableCell>
										{Number(row?.duration) > 0
											? `${row?.duration} ${commonLabels.days}`
											: row.dueDate}
									</TableCell>
									<TableCell>{row.questionnaireStatus}</TableCell>
									<TableCell>{row.answerStatus}</TableCell>
									<TableCell className={classes.tableCellActionButton}>
										<>
											{row.questionnaireStatus === ActionLabel.Active &&
												(row.responseStatus === 'No' ? (
													<StyledButton
														onClick={() =>
															handleRedirectionEndUser(row.questionnaireId)
														}
													>
														{isLargeScreen &&
															ActionLabel.Complete_Questionnaire}
														<RightArrowBlack />
													</StyledButton>
												) : (
													<StyledButton
														onClick={() =>
															handleRedirection(
																row.questionnaireType,
																row.questionnaireId
															)
														}
													>
														{isLargeScreen && ActionLabel.Edit_Response}
														<EditLogo style={{ marginLeft: '5px' }} />
													</StyledButton>
												))}
											{row.questionnaireStatus === ActionLabel.Completed && (
												<StyledButton> - </StyledButton>
											)}
										</>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>

			<Grid container sx={{ justifyContent: 'flex-end' }}>
				<StyledTooltip
					title={EndUserListingPageLabels.rowsPerPageListingScreenTooltip}
					placement="left"
				>
					<TablePagination
						className={classes.paginationBorder}
						rowsPerPageOptions={rowPage}
						component="div"
						sx={{
							'& .MuiTablePagination-toolbar': {
								paddingLeft: 0,
							},
							'& .MuiInputBase-root': {
								marginRight: {
									xs: '10px',
									sm: '10px',
									md: '32px',
									lg: '32px',
								},
							},
						}}
						count={totalQuestionnaires}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</StyledTooltip>
			</Grid>
		</>
	) : (
		<FallbackUI fallbackText={messages.common.NOT_QUESTIONNAIRE_FOUND} />
	);
};
export default QuestionarieSurveyTable;

const getEndUserListingData = (
	dispatch: AppDispatch,
	setEndUserListingTriggered: React.Dispatch<React.SetStateAction<boolean>>
) => {
	setEndUserListingTriggered(true);
	dispatch(fetchQuestionnaireSurveyData());
};

const handleSortBy = (
	sortKeyValue: { value: string; key: string },
	setSurveyNameSortObj: React.Dispatch<React.SetStateAction<string>>,
	setSurveyIdObj: React.Dispatch<React.SetStateAction<string>>,
	setStartDateObj: React.Dispatch<React.SetStateAction<string>>,
	setDueDateObj: React.Dispatch<React.SetStateAction<string>>,
	setResponseStatusObj: React.Dispatch<React.SetStateAction<string>>,
	setStatusObj: React.Dispatch<React.SetStateAction<string>>
) => {
	if (sortKeyValue.value === APIKeys.questionnaireName) {
		setSurveyNameSortObj(sortKeyValue.key);
	} else if (sortKeyValue.value === APIKeys.questionnaireCode) {
		setSurveyIdObj(sortKeyValue.key);
	} else if (sortKeyValue.value === APIKeys.startDate) {
		setStartDateObj(sortKeyValue.key);
	} else if (sortKeyValue.value === APIKeys.dueDate) {
		setDueDateObj(sortKeyValue.key);
	} else if (sortKeyValue.value === APIKeys.response) {
		setResponseStatusObj(sortKeyValue.key);
		sortKeyValue.value = 'answerStatus';
	} else {
		setStatusObj(sortKeyValue.key);
	}
	return sortKeyValue.value;
};

const handleSortCodeId = (
	value: string,
	key: string,
	questionnairesList: IEndUserQuestionnarieListingDataItem[],
	dispatch: AppDispatch
) => {
	if (value === APIKeys.questionnaireCode) {
		let sortAlphaNum = (
			a: IEndUserQuestionnarieListingDataItem,
			b: IEndUserQuestionnarieListingDataItem
		) =>
			a['questionnaireCode'].localeCompare(b['questionnaireCode'], 'en', {
				numeric: true,
			});
		if (key === END_USER_CONSTANT.DESCENDING) {
			sortAlphaNum = (
				a: IEndUserQuestionnarieListingDataItem,
				b: IEndUserQuestionnarieListingDataItem
			) =>
				b['questionnaireCode'].localeCompare(a['questionnaireCode'], 'en', {
					numeric: true,
				});
		}
		const copyList = [...questionnairesList];
		copyList.sort(sortAlphaNum);
		const sortedData = copyList;
		dispatch(setModifiedEndUserSurveyResponse(sortedData));
	} else {
		dispatch(
			setModifiedEndUserSurveyResponse(
				orderBy(questionnairesList, [value], key === 'asc' ? 'asc' : 'desc')
			)
		);
	}
};
const checkStartDateOpen = (startDateOpen: boolean, date: IDateFilter) => {
	return !startDateOpen && date.startDate === '' && date.endDate === '';
};

const checkDueDateOpen = (dueDateOpen: boolean, date: IDateFilter) => {
	return (
		!dueDateOpen &&
		date.createdAtStartDate === '' &&
		date.createdAtEndDate === ''
	);
};

const checkRowPerPage = (rowPage: number[]): number | (() => number) => {
	return rowPage ? rowPage[0] : PageRows.firstOption;
};

const questionnaireHeaderCell = (
	surveySortObj: string,
	headerName: string,
	handleSortClick: (value: string, key: string) => void
) => {
	if (surveySortObj === END_USER_CONSTANT.DESCENDING) {
		return (
			<div className="up-arrow">
				<DownArrow
					onClick={() => {
						handleSortClick(headerName, END_USER_CONSTANT.ASCENDING);
					}}
				/>
			</div>
		);
	} else {
		return (
			<DownArrow
				onClick={() => {
					handleSortClick(headerName, END_USER_CONSTANT.DESCENDING);
				}}
			/>
		);
	}
};
