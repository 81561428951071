import React from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import { ChartSummaryProps } from '../../types/ResultsType';
import ChartIndicator from './ChartIndicator';

const ChartSummaryContainer = styled(Box)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: '10px',
	fontFamily: '"JohnsonText-Regular"',
});

export const ChartSummary: React.FC<ChartSummaryProps> = ({
	data,
	chartType,
	className,
}) => {
	return (
		<Stack>
			{data.map((element) => (
				<ChartSummaryContainer className={`${className}`} key={element.label}>
					<ChartIndicator
						key={element.label}
						color={element.color}
						label={element.label}
					/>
					{element?.secondaryLabel && (
						<Box sx={{ maxWidth: '300px', wordWrap: 'white-space' }}>
							{element?.secondaryLabel}
						</Box>
					)}
					{chartType === 'Percentage' && (
						<Typography fontSize="14px" variant="body1">
							{element.value}%
						</Typography>
					)}
					{chartType === 'Values' && (
						<Typography fontSize="14px" variant="body1">
							{element.outOfValue}/{element.totalValue}
						</Typography>
					)}
				</ChartSummaryContainer>
			))}
		</Stack>
	);
};

export default ChartSummary;
