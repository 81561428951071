import React, { useEffect } from 'react';
import {
	Button,
	Box,
	styled,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	Typography,
	IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { questionsLabels } from '../../utils/QuestionsLabels';
import { STEPTheme } from '../../utils/Theme';
import {
	NOTI_CONSTANT,
	VALIDATIONS_RULES,
} from '../../constants/StepConstants';
import {
	surveyQuestionnaireData,
	updateSurveyQuestionnaire,
} from '../../redux/SurveySlice';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	initialEmailTemplate,
	reminderEmailTemplate,
	overdueEmailTemplate,
} from '../../assets/files/Email';
import { Numbers, QUE_JOURNEY_STEPS } from '../../utils/Enum';
import { ValidationErrorMsgs } from '../../utils/CommonLabels';
import TextEditor from './TextEditor';

const SaveButton = styled(Button)({
	width: '361.5px',
	height: '35px',
	padding: '8px 16px',
	borderRadius: '12px',
	gap: '8px',
});

const CancelButton = styled(Button)({
	width: '361.5px',
	height: '35px',
	padding: '8px 16px',
	borderRadius: '12px',
	marginRight: '8px',
	background: STEPTheme.colors.white,
	'&:hover': {
		backgroundColor: 'white',
		borderColor: 'black',
		color: STEPTheme.colors.text,
	},
});

const CancelText = styled(Typography)({
	width: '56px',
	height: '24px',
	fontSize: '0.875rem',
	fontWeight: '500',
	lineHeight: '24px',
	letterSpacing: '0px',
	textAlign: 'center',
	color: STEPTheme.colors.primary,
});
const StyledBody = styled(Box)({
	width: '731px',
	height: '330px',
	left: '24px',
	border: '1px solid',
});
const ButtonBox = styled(Box)({
	width: '740px',
	height: '40px',
	gap: '8px',
	justifyContent: 'space-evenly',
});

interface AddTemplateProps {
	open: boolean;
	type?: string;
	leftBtnText: string;
	leftBtnHandler: () => void;
	rightBtnText: string;
	rightBtnHandler: (templateLable: string) => void;
	templateLabel: string;
}
const StyledTextField = {
	width: '100%',
	border: '1px',
	color: STEPTheme.colors.darkGray,
	display: 'inline-flex',
	justifyContent: 'flex-end',
	'& .MuiInputBase-root': {
		height: '40px',
		borderRadius: '8px',
	},
};

const AddTemplatePopup: React.FC<AddTemplateProps> = ({
	open,
	type,
	leftBtnText,
	rightBtnText,
	leftBtnHandler,
	rightBtnHandler,
	templateLabel,
}) => {
	const handleClose = () => {
		leftBtnHandler();
	};
	const dispatch = useAppDispatch();
	const [initialMailContent, setInitialMailContent] = React.useState('');
	const [subject, setSubject] = React.useState('');
	const [nameError, setNameError] = React.useState(false);
	const surveyQuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const handleSubjectChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setSubject(event.target.value);
	};

	const handleSave = () => {
		if (
			subject === '' ||
			subject === null ||
			!VALIDATIONS_RULES.VALIDATION_NOTIFICATION_MAIL_SUBJECT.test(subject)
		) {
			setNameError(true);
		} else {
			if (type === NOTI_CONSTANT.NOTIFICATION_INITIAL_EMAIL) {
				dispatch(
					updateSurveyQuestionnaire({
						...surveyQuestionnaireInfo,
						currentStep: QUE_JOURNEY_STEPS.STEP_NOTIFICATIONS,
						notificationConfig: {
							...surveyQuestionnaireInfo.notificationConfig,
							initialEmailSubject: subject,
							initialEmailBody:
								initialMailContent || initialStoreBody || initialEmailTemplate,
						},
					})
				);
			} else if (type === NOTI_CONSTANT.NOTIFICATION_REMINDER_EMAIL) {
				dispatch(
					updateSurveyQuestionnaire({
						...surveyQuestionnaireInfo,
						currentStep: QUE_JOURNEY_STEPS.STEP_NOTIFICATIONS,
						notificationConfig: {
							...surveyQuestionnaireInfo.notificationConfig,
							reminderEmailSubject: subject,
							reminderEmailBody:
								initialMailContent ||
								reminderStoreBody ||
								reminderEmailTemplate,
						},
					})
				);
			} else {
				dispatch(
					updateSurveyQuestionnaire({
						...surveyQuestionnaireInfo,
						currentStep: QUE_JOURNEY_STEPS.STEP_NOTIFICATIONS,
						notificationConfig: {
							...surveyQuestionnaireInfo.notificationConfig,
							overdueEmailSubject: subject,
							overdueEmailBody:
								initialMailContent || overdueStoreBody || overdueEmailTemplate,
						},
					})
				);
			}
			rightBtnHandler(templateLabel);
			handleClose();
		}
	};
	const initialStoreBody =
		surveyQuestionnaireInfo.notificationConfig.initialEmailBody;
	const reminderStoreBody =
		surveyQuestionnaireInfo.notificationConfig.reminderEmailBody;
	const overdueStoreBody =
		surveyQuestionnaireInfo.notificationConfig.overdueEmailBody;
	useEffect(() => {
		if (type === NOTI_CONSTANT.NOTIFICATION_INITIAL_EMAIL) {
			setSubject(
				surveyQuestionnaireInfo.notificationConfig.initialEmailSubject
			);
		} else if (type === NOTI_CONSTANT.NOTIFICATION_REMINDER_EMAIL) {
			setSubject(
				surveyQuestionnaireInfo.notificationConfig.reminderEmailSubject
			);
		} else {
			setSubject(
				surveyQuestionnaireInfo.notificationConfig.overdueEmailSubject
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [surveyQuestionnaireInfo]);

	const mailContentChangeHandler = (newContent: string) => {
		setInitialMailContent(newContent);
	};

	const handleEmailRender = () => {
		if (type === NOTI_CONSTANT.NOTIFICATION_INITIAL_EMAIL) {
			return (
				<TextEditor
					OnchangeHandler={(content) => mailContentChangeHandler(content)}
					emailTemplate={initialStoreBody || initialEmailTemplate}
				/>
			);
		} else if (type === NOTI_CONSTANT.NOTIFICATION_REMINDER_EMAIL) {
			return (
				<TextEditor
					OnchangeHandler={(content) => mailContentChangeHandler(content)}
					emailTemplate={reminderStoreBody || reminderEmailTemplate}
				/>
			);
		} else {
			return (
				<TextEditor
					OnchangeHandler={(content) => mailContentChangeHandler(content)}
					emailTemplate={overdueStoreBody || overdueEmailTemplate}
				/>
			);
		}
	};

	const validateSubject = (subjectInput: string) => {
		if (subjectInput === '') {
			setNameError(false);
		} else if (
			subjectInput === '' ||
			subjectInput === null ||
			!VALIDATIONS_RULES.VALIDATION_NOTIFICATION_MAIL_SUBJECT.test(subjectInput)
		) {
			setNameError(true);
		} else {
			setNameError(false);
		}
	};

	return (
		<div>
			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
				maxWidth="lg"
				disableEnforceFocus
			>
				<DialogTitle id="customized-dialog-title">
					<Typography variant="h5" gutterBottom sx={{ marginTop: '56px' }}>
						{questionsLabels.emailTemplate}
					</Typography>
					<Typography variant="h6" sx={{ marginBottom: '10px' }}>
						{questionsLabels.subject}
					</Typography>
					<TextField
						sx={StyledTextField}
						id="outlined-basic"
						value={subject}
						placeholder="Enter Subject"
						variant="outlined"
						onKeyPress={(event) => {
							const inputValue = (event.target as HTMLInputElement).value;
							if (event.key === ' ' && !inputValue) {
								event.preventDefault();
							}
						}}
						required
						error={nameError}
						helperText={
							nameError
								? ValidationErrorMsgs.incorrectNotificationMailSubjectFormatMsg
								: ''
						}
						onChange={(event) => {
							handleSubjectChange(event);
							validateSubject(event.target.value);
						}}
					/>
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[Numbers.fiveHundred],
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<StyledBody>{handleEmailRender()}</StyledBody>
				</DialogContent>

				<DialogActions>
					<ButtonBox>
						<CancelButton onClick={handleClose} variant="contained">
							<CancelText> {leftBtnText}</CancelText>
						</CancelButton>
						<SaveButton autoFocus onClick={handleSave} variant="contained">
							{rightBtnText}
						</SaveButton>
					</ButtonBox>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AddTemplatePopup;
