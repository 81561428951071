import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { ChartIndicatorProps } from '../../types/ResultsType';

const ChartIndicatorContainer = styled(Box)({
	display: 'flex',
	gap: '10px',
	alignItems: 'center',
});

const ColoredCircle = styled(Box)({
	minWidth: '10px',
	minHeight: '10px',
	borderRadius: '50%',
});

export const ChartIndicator: React.FC<ChartIndicatorProps> = ({
	color,
	label,
}) => {
	return (
		<ChartIndicatorContainer>
			<ColoredCircle
				sx={{
					background: color,
				}}
			></ColoredCircle>
			<Typography fontSize="14px">{label} </Typography>
		</ChartIndicatorContainer>
	);
};

export default ChartIndicator;
