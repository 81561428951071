/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { RootState } from '../../app/Store';
import { QuestionnaireSpecificResultsResponse } from '../../middleware/QuestionnaireSpecificResultsServices/QuestionnaireSpecificResultsResponse';
import {
	RESPONSE_CODE,
	API_STATUS_CONSTANT,
} from '../../constants/StepConstants';
import { IQuestionnaireDataRequest } from '../../types/SurveyProps';

const initialState = {
	resultsData: {} as IQuestionnaireDataRequest,
	resultAPICompletionStatus: 'idle',
};

export const fetchQuestionnaireResultsData = createAsyncThunk(
	'fetchQuestionnaireResultsData',
	async (questionnaireId: string, { dispatch }) => {
		QuestionnaireSpecificResultsResponse(questionnaireId)
			.then((response: AxiosResponse) => {
				if (
					response?.status === RESPONSE_CODE.HTTP_STATUS_OK &&
					response?.data?.data
				) {
					dispatch(setQuestionnaireResultsData(response?.data?.data));
					dispatch(setResultAPICompletionStatus(API_STATUS_CONSTANT?.SUCCESS));
				} else {
					dispatch(setResultAPICompletionStatus(API_STATUS_CONSTANT?.FAILED));
				}
			})
			.catch(() => {
				dispatch(setResultAPICompletionStatus(API_STATUS_CONSTANT?.FAILED));
			});
	}
);

export const questionnaireSpecificResultsResponseSlice = createSlice({
	name: 'questionnaireSpecificResultsResponse',
	initialState,
	reducers: {
		setQuestionnaireResultsData: (
			state,
			action: PayloadAction<IQuestionnaireDataRequest>
		) => {
			state.resultsData = action.payload;
		},
		setResultAPICompletionStatus: (state, action) => {
			state.resultAPICompletionStatus = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchQuestionnaireResultsData.pending, (state) => {
				state.resultAPICompletionStatus = 'loading';
			})
			.addCase(fetchQuestionnaireResultsData.rejected, (state) => {
				state.resultAPICompletionStatus = 'failed';
			});
	},
});

export const getQuestionnaireResultsData = (state: RootState) =>
	state.questionnaireSpecificResultsResponse.resultsData;
export const getResultAPICompletionStatus = (state: RootState) =>
	state.questionnaireSpecificResultsResponse.resultAPICompletionStatus;
export const { setQuestionnaireResultsData, setResultAPICompletionStatus } =
	questionnaireSpecificResultsResponseSlice.actions;
export default questionnaireSpecificResultsResponseSlice.reducer;
