import { Numbers } from './Enum';

export const formatDate = (date: Date | null): string => {
	if (!date) {
		return '';
	}

	const day = date.getDate().toString().padStart(Numbers.two, '0');
	const month = date.toLocaleDateString('en-US', { month: 'short' });
	const year = date.getFullYear();

	return `${day}.${month}.${year}`;
};

export const dueDate = (
	startDate: Date | null,
	otherDueDate: Date | null,
	duration?: number
): string => {
	if (startDate !== null && duration && duration !== 0) {
		const getDueDate = new Date(startDate);
		getDueDate.setDate(getDueDate.getDate() + duration);
		return formatDate(getDueDate);
	}
	if (otherDueDate !== null) {
		return formatDate(otherDueDate);
	}
	return '';
};

// This function converts timestamp into '22 Mar 2024, 06:57:04 AM EST' Format or 22.03.2024 Format
export const getTimeInLastRefreshFormat = (
	refreshTimeStamp: string,
	type = ''
) => {
	const d = new Date(refreshTimeStamp);
	const lastRefreshedDate = d.toLocaleString('default', { day: '2-digit' });
	const lastRefreshedMonth = d.toLocaleDateString('en-US', { month: 'short' });
	const lastRefreshedNumberMonth = d.toLocaleDateString('en-US', {
		month: '2-digit',
	});
	const lastRefreshYear = d.getFullYear();
	if (lastRefreshYear < Numbers.twoThousandTwentyFour) return ''; // STEP application started at the year 2024, so logically no Date in the UI will show before 2024.
	const lastRefreshTime = d.toLocaleTimeString().split(':');

	// Add zero if hour, minute or second is in single digit
	const formattedLastRefreshTime = lastRefreshTime
		.map((component) => (component.length === 1 ? `0${component}` : component))
		.join(':');

	return type === ''
		? `${lastRefreshedDate} ${lastRefreshedMonth} ${lastRefreshYear}, ${formattedLastRefreshTime} EST`
		: `${lastRefreshedDate}.${lastRefreshedNumberMonth}.${lastRefreshYear}`;
};
/**
 * @description get string date in format eg 2024-07-30
 * @param date
 * @returns 'yyyy-mm-dd'
 */
export const getFormattedDate = (date: string | number | Date): string => {
	const newDate = new Date(date);
	return `${newDate.getFullYear()}-${('0' + (newDate.getMonth() + Numbers.one)).slice(-Numbers.two)}-${newDate.getDate().toString().padStart(Numbers.two, '0')}`;
};
/**
 * @description get string date and time in format eg 2024-07-30_13-22-40
 * @param date
 * @returns 'yyyy-mm-dd_HH-MM-SS'
 */
export const getFormattedCurrentDateTime = () => {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentMonth = String(currentDate.getMonth() + Numbers.one).padStart(
		Numbers.two,
		`${Numbers.zero}`
	);
	const currentDay = String(currentDate.getDate()).padStart(
		Numbers.two,
		`${Numbers.zero}`
	);
	const currentHours = String(currentDate.getHours()).padStart(
		Numbers.two,
		`${Numbers.zero}`
	);
	const currentMinutes = String(currentDate.getMinutes()).padStart(
		Numbers.two,
		`${Numbers.zero}`
	);
	const currentSeconds = String(currentDate.getSeconds()).padStart(
		Numbers.two,
		`${Numbers.zero}`
	);

	return `${currentYear}-${currentMonth}-${currentDay}_${currentHours}-${currentMinutes}-${currentSeconds}`;
};
