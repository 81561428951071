import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	SelectChangeEvent,
	TextField,
	styled,
	ThemeProvider,
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { ISurveyFormsQuestion } from '../../types/SurveyProps';
import {
	API_STATUS_CONSTANT,
	QUECONSTANT,
	VALIDATIONS_RULES,
} from '../../constants/StepConstants';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import { ReactComponent as ArrowRightIcon } from '../../assets/images/arrowRight.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/arrow-left.svg';
import { ReactComponent as ArrowLeftIconDisabled } from '../../assets/images/Arrow-left-disabled.svg';
import { ReactComponent as ArrowRightIconDisable } from '../../assets/images/arrow-right-disable.svg';
import { Numbers } from '../../utils/Enum';
import {
	EndUserMultiPageSurveyLabels,
	commonLabels,
	ValidationErrorMsgs,
	questionnaireResultsLabels,
} from '../../utils/CommonLabels';
import {
	myQuestionnaireAPILoadingStatus,
	questionnaireData,
	setUpdateMyQuestionnairesAPIStatus,
	updateAllResponseJSON,
	updateAnswerJSONByQId,
	updateMyQuestionnairesData,
	updateResponseJSON,
} from '../../redux/QuestionnarieUserSlice';
import {
	AnswerObj,
	UpdateMyQuestionnairesObj,
	UpdateMyQuestionnairesPayload,
} from '../../types/CommonTypes';
import { setIsLastScreenEndUser } from '../../redux/SurveySlice';
import GenericConfirmationPopup from '../common/GenericConfirmationPopup';
import InputCharacterRemaining from '../questions/InputCharacterRemaining';
import { AppDispatch } from '../../app/Store';
import Loader from '../Loader';
import { questionsLabels } from '../../utils/QuestionsLabels';
import { STEPTheme, stepMuiTheme } from '../../utils/Theme';
import {
	breakPointBetween_0_1300,
	breakPointBetween_768_1300,
	breakPointDown_339,
	breakPointDown_480,
	breakPointDown_601,
	breakPointDown_768,
	breakPointBetween_480_601,
	breakPointBetween_601_768,
	breakPointBetween_200_368,
} from './common/makeStyles';

const useStyles = makeStyles((theme) => ({
	buttonBox: {
		display: 'flex',
		paddingTop: '120px',
		gap: '10px',
	},
	buttonBoxBothButtons: {
		display: 'flex',
		justifyContent: 'center',
		gap: '10px',
		position: 'absolute',
		bottom: '30px',
		right: '40px',
		marginTop: '50px',
		overflow: 'visible',
		[breakPointBetween_601_768]: {
			left: '28%',
			right: 'initial',
		},
		[breakPointBetween_480_601]: {
			left: '22%',
			right: 'initial',
		},
		[breakPointDown_339]: {
			right: '8%',
		},
	},
	openEndedBox: {
		position: 'relative',
		width: '100%',
		minWidth: '57.22vw',
		[breakPointBetween_0_1300]: {
			left: '3%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			marginTop: '40px',
			marginRight: '20px',
		},
		[breakPointDown_768]: {
			left: '0%',
		},
	},
	characterBox: {
		position: 'absolute',
		top: '-20px',
		right: '5px',
	},
	questionTileBox: {
		display: 'flex',
	},
	optionBox: {
		paddingLeft: '22px',
		marginBottom: '20px',
	},
	textFieldWithCounter: {
		position: 'relative',
		width: '100%',
		[breakPointBetween_768_1300]: {
			width: '95%',
		},
		[breakPointDown_768]: {
			width: '100%',
		},
	},
	formControl: {
		margin: theme.spacing(1),
		'& .MuiInputBase-input': {
			minWidth: '56vw !important',
		},
		[breakPointBetween_768_1300]: {
			'& .MuiInputBase-input': {
				minWidth: '68vw !important',
			},
			position: 'relative',
			left: '20px',
		},
		[breakPointDown_768]: {
			'& .MuiInputBase-input': {
				minWidth: '55vw !important',
				paddingRight: '0px !important',
			},
		},
	},
}));

const StyledTextField = styled(TextField)({
	'& textarea': {
		cursor: 'default',
	},
	[breakPointDown_601]: {
		'& textarea': {
			cursor: 'text',
			paddingRight: '6px',
			overflow: 'auto !important',
		},
		'& ::-webkit-scrollbar': {
			width: '10px !important',
		},
	},
});

const StyledBackButton = styled(Button)({
	width: '114px',
	height: '32px',
	'&:hover': {
		boxShadow: 'none',
		border: '1px solid black',
		backgroundColor: 'inherit',
	},
	[breakPointBetween_0_1300]: {
		width: '110px',
		height: '26px',
	},
	[breakPointDown_480]: {
		fontWeight: '500',
		width: '108px',
		height: '26px',
	},
});

interface CustomButtonProps {
	label: string;
}

const StyledNextButton = styled(Button)<CustomButtonProps>(({ label }) => ({
	width: '114px',
	height: '32px',
	backgroundColor:
		label === commonLabels.submit
			? STEPTheme.colors.red
			: STEPTheme.colors.black,
	'&:hover': {
		backgroundColor:
			label === commonLabels.submit
				? STEPTheme.colors.red
				: STEPTheme.colors.black,
	},
	'&:disabled': {
		backgroundColor: STEPTheme.colors.disabledButton,
	},
	[breakPointBetween_0_1300]: {
		width: '110px',
		height: '26px',
	},
	[breakPointDown_480]: {
		fontWeight: '500',
		width: '108px',
		height: '26px',
	},
}));

const InputLabelStyle = styled(InputLabel)({
	'&.Mui-focused': {
		opacity: 0,
	},
	'&.MuiInputLabel-shrink': {
		opacity: 0,
	},
});

interface OptionType {
	condition: string;
	options: string;
}

const QuestionNumberTypography = styled(Box)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '25px',
	lineHeight: '32px',
	fontWeight: '700',
	textAlign: 'left',
	wordWrap: 'break-word',
	[breakPointBetween_0_1300]: {
		fontSize: '22px',
		lineHeight: '24px',
	},
	[breakPointBetween_768_1300]: {
		marginLeft: '10px',
	},
	[breakPointBetween_200_368]: {
		fontSize: '20px',
		lineHeight: '22px',
		marginLeft: 'auto',
	},
});

const QuestionTypography = styled(Box)({
	fontFamily: 'JohnsonText-Regular',
	fontSize: '25px',
	lineHeight: '32px',
	fontWeight: '700',
	textAlign: 'left',
	paddingLeft: '5px',
	overflowWrap: 'break-word',
	wordBreak: 'break-word',
	[breakPointBetween_0_1300]: {
		fontSize: '22px',
		lineHeight: '24px',
	},
	[breakPointBetween_768_1300]: {
		marginLeft: '10px',
	},
	[breakPointBetween_200_368]: {
		marginLeft: '0px',
	},
});

const QuestionOptionsTypography = styled(Box)({
	fontFamily: '"JohnsonText-Regular"',
	fontSize: '18px',
	lineHeight: '24px',
	fontWeight: '400',
	paddingTop: '20px',
	overFlow: 'auto',
	textAlign: 'left',
	marginBottom: '30px',
	[breakPointBetween_0_1300]: {
		position: 'relative',
		top: '5px',
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '16px',
	},
	[breakPointBetween_768_1300]: {
		left: '17px',
		marginTop: '5px',
	},
});

const QueInstructionCont = styled(Box)({
	fontFamily: '"JohnsonText-Regular"',
	fontSize: '15px',
	marginTop: '15px',
	textAlign: 'left',
	overflowWrap: 'break-word',
	wordBreak: 'break-word',
	maxWidth: '100%',
	[breakPointBetween_0_1300]: {
		position: 'relative',
		fontWeight: '400',
		fontSize: '16px',
		lineHeight: '18px',
	},
	[breakPointBetween_768_1300]: {
		left: '18px',
		paddingRight: '20px',
	},
});

const MultiplePageQuestionnaire: React.FC<unknown> = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const questionnaireInfo = useAppSelector(questionnaireData);
	let { id: questionnaireId } = useParams();
	questionnaireId = questionnaireId?.toUpperCase();
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [selectedOptions, setSelectedOptions] = useState('');
	const [selectedOption, setSelectedOption] = useState<string[]>([]);
	const [questionIndex, setQuestionIndex] = useState<number[]>([]);
	const [openEndedError, setOpenEndedError] = useState('');
	const questions = JSON.parse(questionnaireInfo?.data?.questionJSONString);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [readonlyQuestionIndex, setReadonlyQuestionIndex] = useState(-1);
	const [isAnswerChanged, setIsAnswerChanged] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [showPopup, setShowPopup] = useState(false);
	const [confirmationPopUp, setConfirmationPopUp] = useState(false);
	const [charsCountForName, setCharsCountForName] = React.useState(0);
	const remainingCountForQuestionTitle: number =
		Numbers.twoFifty - charsCountForName;
	const responseJSON = useAppSelector(
		(state) => state.QuestionnaireSurvey.responseJSON
	);
	const [showLoader, setLoaderOpen] = useState(false);
	const updateMyQuestionnairesAPIStatus = useAppSelector(
		myQuestionnaireAPILoadingStatus
	);

	const classes = useStyles();

	useEffect(() => {
		const statusAndString = {
			status: updateMyQuestionnairesAPIStatus,
			dispatchObj: dispatch,
			navigate,
		};
		checkMyQuestionnaireStatus(statusAndString, setLoaderOpen);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateMyQuestionnairesAPIStatus]);

	useEffect(() => {
		const answers = JSON.parse(questionnaireInfo?.data?.responseJSON);
		if (answers && answers.length > 0) dispatch(updateAllResponseJSON(answers));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireInfo.data.responseJSON]);

	useEffect(() => {
		if (
			inputValue &&
			!VALIDATIONS_RULES.VALIDATION_OPEN_ENDED_END_USER.test(inputValue)
		) {
			setOpenEndedError(ValidationErrorMsgs.incorrectTypeFormat);
		} else {
			setOpenEndedError('');
		}
	}, [inputValue]);

	useEffect(() => {
		if (readonlyQuestionIndex !== currentQuestionIndex) {
			if (Array.isArray(responseJSON)) {
				const savedOptions = responseJSON.find(
					(answer) =>
						answer.questionId ===
						`${questionnaireId}_${questions[currentQuestionIndex]?.questionId}`
				)?.answer;

				if (
					questions[currentQuestionIndex]?.questionType ===
					QUECONSTANT.QUESTION_TYPE_DROPDOWN
				) {
					if (typeof savedOptions === 'string') {
						setSelectedOptions(savedOptions);
					}
				} else if (
					questions[currentQuestionIndex]?.questionType ===
					QUECONSTANT.QUESTION_TYPE_MULTI_SELECT
				) {
					setSelectedOptions('');
					if (typeof savedOptions === 'string') {
						let parsedOptions = [];
						try {
							parsedOptions = JSON.parse(savedOptions);
						} catch {
							parsedOptions.push(savedOptions);
						}
						setSelectedOption(parsedOptions);
					} else if (Array.isArray(savedOptions)) {
						setSelectedOption(savedOptions);
					}
				} else if (
					questions[currentQuestionIndex]?.questionType ===
					QUECONSTANT.QUESTION_TYPE_OPEN
				) {
					setSelectedOption([]);
					if (typeof savedOptions === 'string') {
						setSelectedOptions(savedOptions);
					}
					setCharsCountForName(savedOptions?.toString().length ?? 0);
				} else {
					if (typeof savedOptions === 'string') {
						setSelectedOption([]);
						setSelectedOptions(savedOptions);
					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentQuestionIndex, responseJSON, readonlyQuestionIndex]);

	const resetNextQAnswers = (currentQuestionIndex: number) => {
		const currentQuestion = questions[currentQuestionIndex];
		if (responseJSON.length > 0) {
			const currentQAnswerIndex = responseJSON.findIndex(
				(option) =>
					option.questionId ===
					`${questionnaireId}_${currentQuestion.questionId}`
			);

			if (currentQAnswerIndex > -1) {
				for (let i = currentQAnswerIndex + 1; i < responseJSON.length; i++) {
					if (
						(responseJSON[i] && Array.isArray(responseJSON[i]?.answer)) ||
						responseJSON[i]?.answer !== ''
					) {
						const newAns: AnswerObj = {
							questionId: `${questionnaireId}_${responseJSON[i]?.questionId}`,
							questionType: responseJSON[i]?.questionType,
							question: responseJSON[i]?.question,
						};
						if (
							responseJSON[i]?.questionType ===
							QUECONSTANT.QUESTION_TYPE_MULTI_SELECT
						) {
							newAns.answer = [];
						} else {
							newAns.answer = '';
						}
						dispatch(updateAnswerJSONByQId(newAns));
					}
				}
			}
		}
	};

	const navigateToQuestion = () => {
		if (currentQuestionIndex === questions.length - 1) {
			setShowConfirmModal(true);
			return;
		}
		const currentQuestion = questions[currentQuestionIndex];
		if (
			currentQuestion.questionType === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT
		) {
			const isLastQuestion = currentQuestionIndex === questions.length - 1;
			let hasEndQuestionnaire = false;
			selectedOption?.map((option: string) => {
				const selectedOptionConditionObject = currentQuestion?.options?.find(
					(selectedOptionObj: OptionType) =>
						selectedOptionObj.options === option
				)?.condition;
				if (
					selectedOptionConditionObject === questionsLabels.endQuestionnaire
				) {
					hasEndQuestionnaire = true;
				}
			});
			const nextQuestionIndex = currentQuestionIndex + 1;
			if (hasEndQuestionnaire || isLastQuestion) {
				setShowConfirmModal(true);
			} else {
				setQuestionIndex((prevQuestionIndex) => {
					return [...prevQuestionIndex, currentQuestionIndex];
				});
				setCurrentQuestionIndex(nextQuestionIndex);
			}
		} else if (
			currentQuestion.questionType === QUECONSTANT.QUESTION_TYPE_OPEN
		) {
			setQuestionIndex((prevQuestionIndex) => [
				...prevQuestionIndex,
				currentQuestionIndex,
			]);
			if (currentQuestionIndex === questions.length - 1) {
				setShowConfirmModal(true);
			} else if (
				currentQuestion?.options[0]?.condition?.startsWith(
					questionsLabels.continueTo
				)
			) {
				const nextQuestionNumber = parseInt(
					currentQuestion?.options[0]?.condition.split(' ')[3]
				);
				setQuestionIndex([...questionIndex, currentQuestionIndex]);
				setCurrentQuestionIndex(nextQuestionNumber - 1);
			} else if (currentQuestion?.options[0]?.condition === '') {
				setCurrentQuestionIndex(currentQuestionIndex + 1);
			} else if (
				currentQuestion?.options[0]?.condition ===
				questionsLabels.endQuestionnaire
			) {
				setShowConfirmModal(true);
			} else {
				/* empty */
			}
		} else {
			const selectedOptionObject = currentQuestion.options.find(
				(option: OptionType) => option.options.includes(selectedOptions)
			);
			if (
				selectedOptionObject &&
				selectedOptionObject.condition &&
				selectedOptionObject.condition.startsWith(questionsLabels.continueTo)
			) {
				const nextQuestionNumber = parseInt(
					selectedOptionObject.condition.split(' ')[3]
				);
				if (nextQuestionNumber - 1 < currentQuestionIndex) {
					setQuestionIndex((questionIndex) => {
						return [...questionIndex, currentQuestionIndex];
					});
					setCurrentQuestionIndex(currentQuestionIndex + 1);
				} else {
					setQuestionIndex((questionIndex) => {
						return [...questionIndex, currentQuestionIndex];
					});
					setCurrentQuestionIndex(nextQuestionNumber - 1);
				}
			} else if (
				selectedOptionObject &&
				selectedOptionObject.condition === questionsLabels.endQuestionnaire
			) {
				setShowConfirmModal(true);
			} else {
				setQuestionIndex((questionIndex) => {
					return [...questionIndex, currentQuestionIndex];
				});
				setCurrentQuestionIndex(currentQuestionIndex + 1);
			}
		}
	};

	//This function compares the new answer object with the current answer object.
	//It takes in a new answer object as a parameter.
	const getComparisonCurrentNewAnswerObj = (
		newAns: AnswerObj
	): { isEqualAnswer: boolean; currentAnswr: string | string[] } => {
		const currentAnswer = responseJSON.find(
			(answer) => answer.questionId === newAns.questionId
		);

		let isEqualAnswer: boolean;

		if (currentAnswer) {
			let currentAnswerArray: string[] = [];

			if (typeof currentAnswer.answer === 'string') {
				try {
					currentAnswerArray = JSON.parse(currentAnswer.answer);
				} catch {
					currentAnswerArray = [currentAnswer.answer];
				}
			} else if (Array.isArray(currentAnswer.answer)) {
				currentAnswerArray = currentAnswer.answer;
			}

			if (Array.isArray(currentAnswerArray) && Array.isArray(newAns.answer)) {
				// Ensure both answers are sorted before comparison
				const sortedCurrentAnswer = [...currentAnswerArray].sort();
				const sortedNewAnswer = [...newAns.answer].sort();
				isEqualAnswer =
					JSON.stringify(sortedCurrentAnswer) ===
					JSON.stringify(sortedNewAnswer);
			} else if (
				typeof currentAnswer.answer === 'string' &&
				typeof newAns.answer === 'string'
			) {
				isEqualAnswer = currentAnswer.answer === newAns.answer;
			} else {
				isEqualAnswer = false;
			}
		} else {
			isEqualAnswer = false;
		}

		return {
			isEqualAnswer,
			currentAnswr: currentAnswer?.answer ?? '',
		};
	};

	const getQuestionnaireCode = () => {
		return questionnaireInfo.data?.questionnaireCode;
	};

	// this method is used to create newAns object
	const getNewAnsObj = (option: string | string[]): AnswerObj => {
		const currentQuestion = questions[currentQuestionIndex];
		const newAns: AnswerObj = {
			questionId: `${questionnaireId}_${currentQuestion.questionId}`,
			questionType: currentQuestion.questionType,
			question: currentQuestion.question,
			answer: option,
		};
		return newAns;
	};

	const getAnswers = () => {
		return JSON.stringify(responseJSON);
	};

	// Function to sort options based on the order they appear in the current question
	const sortOptions = (
		options: string[],
		currentQuestion: ISurveyFormsQuestion
	): string[] => {
		const correctOrder = currentQuestion.options.map(
			(opt: OptionType) => opt.options
		);
		return [...options].sort(
			(a, b) => correctOrder.indexOf(a) - correctOrder.indexOf(b)
		);
	};

	const handleNext = () => {
		let newAns: AnswerObj;
		const currentQuestion: ISurveyFormsQuestion =
			questions[currentQuestionIndex];

		// Determine the new answer object based on the question type
		if (
			currentQuestion.questionType === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT
		) {
			newAns = getNewAnsObj(sortOptions(selectedOption, currentQuestion));
		} else {
			newAns = getNewAnsObj(selectedOptions);
		}

		const isLastQuestion = currentQuestionIndex === questions.length - 1;
		const compareObj = getComparisonCurrentNewAnswerObj(newAns);

		if (
			!isLastQuestion &&
			!compareObj.isEqualAnswer &&
			currentQuestion.questionType !== QUECONSTANT.QUESTION_TYPE_OPEN &&
			!isAnswerChanged &&
			!(compareObj.currentAnswr === '') &&
			!(
				Array.isArray(compareObj.currentAnswr) &&
				compareObj.currentAnswr.length === 0
			)
		) {
			setIsAnswerChanged(true);
		} else {
			navigateToQuestion();
			setCharsCountForName(0);
			if (!isLastQuestion) {
				setSelectedOption([]);
				setSelectedOptions('');
			}
			dispatch(updateResponseJSON(newAns));
			setIsAnswerChanged(false);
		}
	};

	const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newAns: AnswerObj = getNewAnsObj(event.target.value);
		const compareObj = getComparisonCurrentNewAnswerObj(newAns);

		if (compareObj.currentAnswr === '' || compareObj.isEqualAnswer) {
			setIsAnswerChanged(false);
		}

		setSelectedOptions(event.target.value);
		setReadonlyQuestionIndex(currentQuestionIndex);
	};

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedOptionValue = event.target.value;

		setSelectedOption((prevOptions) => {
			let updatedOptions: string[];
			if (event.target.checked) {
				updatedOptions = [...prevOptions, selectedOptionValue];
			} else {
				updatedOptions = prevOptions.filter(
					(option) => option !== selectedOptionValue
				);
			}

			const newAns: AnswerObj = getNewAnsObj(updatedOptions);
			const compareObj = getComparisonCurrentNewAnswerObj(newAns);

			if (
				(Array.isArray(compareObj.currentAnswr) &&
					compareObj.currentAnswr.length === 0) ||
				compareObj.isEqualAnswer
			) {
				setIsAnswerChanged(false);
			}

			const hasEndQuestionnaire = updatedOptions.some(
				(option) =>
					questions[currentQuestionIndex]?.options?.find(
						(opt: OptionType) => opt.options === option
					)?.condition === questionsLabels.endQuestionnaire
			);

			if (!hasEndQuestionnaire) {
				setReadonlyQuestionIndex(currentQuestionIndex);
			}

			return updatedOptions;
		});
	};

	const handleTextFieldChange = (
		event: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		const input = event.target.value;
		setSelectedOptions(input);
		setCharsCountForName(input.length);
		setInputValue(input);
		setReadonlyQuestionIndex(currentQuestionIndex);
	};

	const handleDropDownValueChange = (event: SelectChangeEvent) => {
		const selectedOption = event.target.value;
		const selectedOptionCondition = questions[
			currentQuestionIndex
		].options.find(
			(option: OptionType) => option.options === selectedOption
		)?.condition;

		const newAns: AnswerObj = getNewAnsObj(event.target.value);
		const compareObj = getComparisonCurrentNewAnswerObj(newAns);
		if (compareObj.currentAnswr === '' || compareObj.isEqualAnswer) {
			setIsAnswerChanged(false);
		}
		setSelectedOptions(selectedOption);

		if (selectedOptionCondition !== questionsLabels.endQuestionnaire) {
			setReadonlyQuestionIndex(currentQuestionIndex);
		}
	};

	const handleKeyPress = (event: React.KeyboardEvent) => {
		const inputTextValue = (event.target as HTMLInputElement).value;
		if (event.key === ' ' && !inputTextValue) {
			event.preventDefault();
		}
	};

	const handleNavigation = () => {
		if (isAnswerChanged) {
			let newAns: AnswerObj;
			const currentQuestion: ISurveyFormsQuestion =
				questions[currentQuestionIndex];

			// Determine the new answer object based on the question type
			if (
				currentQuestion.questionType === QUECONSTANT.QUESTION_TYPE_MULTI_SELECT
			) {
				newAns = getNewAnsObj(sortOptions(selectedOption, currentQuestion));
			} else {
				newAns = getNewAnsObj(selectedOptions);
			}

			dispatch(updateResponseJSON(newAns));
			navigateToQuestion();
			setCharsCountForName(0);
			setIsAnswerChanged(false);
			resetNextQAnswers(currentQuestionIndex);
		}
	};

	const handlePrev = () => {
		if (readonlyQuestionIndex !== currentQuestionIndex) {
			if (questionIndex.length > 0) {
				const prevQuestionIndex = questionIndex[questionIndex.length - 1];
				setQuestionIndex(questionIndex.slice(0, -1));
				setCurrentQuestionIndex(prevQuestionIndex);
				resetData();
			}
		} else {
			setShowPopup(true);
		}
		setCharsCountForName(0);
	};

	const resetData = () => {
		setReadonlyQuestionIndex(-1);
		setCharsCountForName(0);
	};

	const handleClosePopup = () => {
		setShowConfirmModal(false);
	};

	const handleConfirmPopup = () => {
		setIsAnswerChanged(false);
		const savedAnswer = responseJSON.find(
			(answer) =>
				answer.questionId ===
				`${questionnaireId}_${questions[currentQuestionIndex]?.questionId}`
		)?.answer;

		if (savedAnswer) {
			if (Array.isArray(savedAnswer)) {
				setSelectedOption(savedAnswer);
			} else {
				setSelectedOptions(savedAnswer);
			}
		}
	};

	const handleBackNavigation = () => {
		setShowPopup(false);
		if (questionIndex.length > 0) {
			const prevQuestionIndex = questionIndex[questionIndex.length - 1];
			setQuestionIndex(questionIndex.slice(0, -1));
			setCurrentQuestionIndex(prevQuestionIndex);
			resetData();
		}
	};

	const handleConfirmation = () => {
		setConfirmationPopUp(false);
		dispatch(setUpdateMyQuestionnairesAPIStatus(API_STATUS_CONSTANT.IDLE));
		dispatch(setIsLastScreenEndUser(true));
		navigate('/');
	};

	const handleSubmit = () => {
		setShowConfirmModal(false);
		const updateMyQuestionnairesObj: UpdateMyQuestionnairesObj = {
			questionnaireId: questionnaireId ? questionnaireId : '',
			questionnaireCode: getQuestionnaireCode(),
			targetAudienceCollection: {
				outScope: [],
				inScope: [],
			},
			responseJSON: getAnswers(),
		};
		const payload: UpdateMyQuestionnairesPayload = {
			updateMyQuestionnairesObj: updateMyQuestionnairesObj,
		};
		dispatch(updateMyQuestionnairesData(payload));
	};

	const handleCancelPopup = () => {
		setShowPopup(false);
	};

	const checkMyQuestionnaireStatus = (
		statusAndString: {
			status: string;
			dispatchObj: AppDispatch;
			navigate: NavigateFunction;
		},
		setLoaderOpen: Dispatch<SetStateAction<boolean>>
	) => {
		if (statusAndString.status === API_STATUS_CONSTANT.SUCCESS) {
			setLoaderOpen(false);
			setConfirmationPopUp(true);
		}
		if (statusAndString.status === API_STATUS_CONSTANT.FAILED) {
			setLoaderOpen(false);
		}
	};
	return (
		<ThemeProvider theme={stepMuiTheme}>
			<Box>
				<Loader isLoading={showLoader} />
				{questions.length > 0 && (
					<Box>
						<Box className={classes.questionTileBox}>
							<Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
								<QuestionNumberTypography>
									{currentQuestionIndex + 1}.{' '}
								</QuestionNumberTypography>
								<QuestionTypography>
									{questions[currentQuestionIndex]?.question}
								</QuestionTypography>
							</Box>
						</Box>
						<Box className={classes.optionBox}>
							<QueInstructionCont>
								{questions[currentQuestionIndex]?.instructions}
							</QueInstructionCont>
							<QuestionOptionsTypography>
								{questions[currentQuestionIndex]?.questionType ===
									QUECONSTANT.QUESTION_TYPE_YES_NO && (
									<RadioGroup
										aria-label="options"
										name="options"
										value={selectedOptions}
										onChange={handleValueChange}
									>
										{questions[currentQuestionIndex].options?.map(
											(option: OptionType, optionIndex: number) => (
												<FormControlLabel
													value={option.options}
													control={<Radio />}
													label={option.options}
													key={optionIndex}
												/>
											)
										)}
									</RadioGroup>
								)}
								{questions[currentQuestionIndex]?.questionType ===
									QUECONSTANT.QUESTION_TYPE_MULTIPLE_OPTION && (
									<RadioGroup
										aria-label="options"
										name="options"
										value={selectedOptions}
										onChange={handleValueChange}
									>
										{questions[currentQuestionIndex].options?.map(
											(option: OptionType, optionIndex: number) => (
												<FormControlLabel
													value={option.options}
													control={<Radio />}
													label={option.options}
													key={optionIndex}
												/>
											)
										)}
									</RadioGroup>
								)}
								{questions[currentQuestionIndex]?.questionType ===
									QUECONSTANT.QUESTION_TYPE_MULTI_SELECT && (
									<Box display="flex" flexDirection="column">
										{questions[currentQuestionIndex].options?.map(
											(option: OptionType, optionIndex: number) => (
												<FormControlLabel
													control={
														<Checkbox
															checked={selectedOption.includes(option.options)}
															value={option.options}
															onChange={handleCheckboxChange}
														/>
													}
													label={option.options}
													key={optionIndex}
												/>
											)
										)}
									</Box>
								)}
							</QuestionOptionsTypography>
							<Box
								sx={{
									position: 'relative',
								}}
							>
								{questions[currentQuestionIndex]?.questionType ===
									QUECONSTANT.QUESTION_TYPE_OPEN && (
									<Box className={classes.openEndedBox}>
										<Box className={classes.textFieldWithCounter}>
											<StyledTextField
												multiline
												rows={3}
												variant="outlined"
												fullWidth
												value={selectedOptions}
												placeholder="Enter Text"
												inputProps={{
													maxLength: Numbers.twoFifty,
												}}
												onKeyPress={handleKeyPress}
												error={!!openEndedError}
												helperText={openEndedError}
												onChange={handleTextFieldChange}
												data-testid="my-input"
											/>
											<Box className={classes.characterBox}>
												<InputCharacterRemaining
													remainingCharacterCount={
														remainingCountForQuestionTitle
													}
												/>
											</Box>
										</Box>
									</Box>
								)}
								{questions[currentQuestionIndex]?.questionType ===
									QUECONSTANT.QUESTION_TYPE_DROPDOWN && (
									<FormControl
										variant="standard"
										className={classes.formControl}
									>
										<InputLabelStyle id="demo-simple-select-standard-label">
											{commonLabels.selectReason}
										</InputLabelStyle>
										<Select
											value={selectedOptions}
											onChange={handleDropDownValueChange}
											MenuProps={{
												PaperProps: {
													sx: {
														maxHeight: Numbers.fourtyEight * Numbers.seven,
													},
												},
											}}
										>
											{questions[currentQuestionIndex].options?.map(
												(option: OptionType, optionIndex: number) => (
													<MenuItem value={option.options} key={optionIndex}>
														{option.options}
													</MenuItem>
												)
											)}
										</Select>
									</FormControl>
								)}
							</Box>
							<Box className={classes.buttonBoxBothButtons}>
								<>
									<Box>
										<StyledBackButton
											variant="outlined"
											color="secondary"
											onClick={handlePrev}
											startIcon={
												currentQuestionIndex === 0 ? (
													<ArrowLeftIconDisabled />
												) : (
													<ArrowLeftIcon />
												)
											}
											disabled={currentQuestionIndex === 0}
										>
											{commonLabels.back}
										</StyledBackButton>
									</Box>
									<Box>
										<StyledNextButton
											variant="contained"
											color="primary"
											endIcon={
												(selectedOptions === '' &&
													selectedOption.length === 0) ||
												openEndedError !== '' ? (
													<ArrowRightIconDisable />
												) : (
													<ArrowRightIcon />
												)
											}
											disabled={
												(selectedOptions === '' &&
													selectedOption.length === 0) ||
												openEndedError !== ''
											}
											label={
												currentQuestionIndex === questions.length - 1
													? commonLabels.submit
													: commonLabels.next
											}
											onClick={handleNext}
										>
											{currentQuestionIndex === questions.length - 1
												? commonLabels.submit
												: commonLabels.next}
										</StyledNextButton>
									</Box>
								</>
							</Box>
						</Box>
					</Box>
				)}
				<GenericConfirmationPopup
					open={showConfirmModal}
					msgBody={questionnaireInfo?.data?.questionnaireName}
					title={EndUserMultiPageSurveyLabels.confirmationPopupHeading}
					rightBtnText={commonLabels.confirm}
					leftBtnText={commonLabels.cancel}
					isRightBtnVisible={true}
					isLeftBtnVisible={true}
					rightBtnHandler={handleSubmit}
					leftBtnHandler={handleClosePopup}
				/>
				<GenericConfirmationPopup
					open={isAnswerChanged}
					msgBody={commonLabels.alertMessageForAnswerChange}
					title={EndUserMultiPageSurveyLabels.confirmationPopupQuestionChange}
					rightBtnText={commonLabels.confirm}
					leftBtnText={commonLabels.cancel}
					isRightBtnVisible={true}
					isLeftBtnVisible={true}
					rightBtnHandler={handleNavigation}
					leftBtnHandler={handleConfirmPopup}
				/>
				<GenericConfirmationPopup
					open={showPopup}
					msgBody={commonLabels.answerWarningMsg}
					title={EndUserMultiPageSurveyLabels.confirmationPopupAnswerChange}
					rightBtnText={commonLabels.confirm}
					leftBtnText={commonLabels.cancel}
					isRightBtnVisible={true}
					isLeftBtnVisible={true}
					rightBtnHandler={handleBackNavigation}
					leftBtnHandler={handleCancelPopup}
				/>
				<GenericConfirmationPopup
					open={confirmationPopUp}
					msgBody={''}
					title={questionnaireResultsLabels.recordSubmittedMsg}
					rightBtnText={commonLabels.ok}
					leftBtnText={commonLabels.cancel}
					isRightBtnVisible={true}
					isLeftBtnVisible={false}
					rightBtnHandler={handleConfirmation}
					leftBtnHandler={handleCancelPopup}
				/>
			</Box>
		</ThemeProvider>
	);
};

export default MultiplePageQuestionnaire;
