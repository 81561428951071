import { styled } from '@mui/material';
import Switch from '@mui/material/Switch';
import { ChangeEvent } from 'react';

interface IOSSwitchProps {
	checked: boolean;
	onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const IOSSwitch = styled((props: IOSSwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 40,
	height: 20,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 1,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(16px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: 'red',
				opacity: 1,
				border: 0,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff',
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 21,
		height: 19,
	},
	'& .MuiSwitch-track': {
		borderRadius: '26px',
		backgroundColor: theme.palette.mode === 'light' ? '#C1BBB3' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}));
