/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled';
import { Box, Paper, Button, AlertColor } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	getQuestionnaireByIdData,
	surveyQuestionnaireData,
} from '../../redux/SurveySlice';
import { ISurveyFormsQuestion } from '../../types/SurveyProps';
import {
	MappingScreenLabels,
	commonLabels,
	questionnaireResultsLabels,
} from '../../utils/CommonLabels';
import {
	addGroup,
	groupSaveRequest,
	groupsList,
	saveGroup,
	saveCompleted,
	deleteGroup,
	getMappingCopyAPILoadingStatus,
	getMappingCreateAPILoadingStatus,
	setMappingCreateAPILoadingStatus,
	setMappingCopyAPILoadingStatus,
	getDeleteAPILoadingStatus,
	setDeleteAPILoadingStatus,
	getGroupsList,
	getSummitSurveyList,
	getSummitInfo,
	clearGroupName,
	getAudienceSubset,
	getAPIErrorType,
	setAPIErrorType,
} from '../../redux/MappingSlice';
import { Numbers } from '../../utils/Enum';
import { STEPTheme } from '../../utils/Theme';
import GenericConfirmationPopup from '../common/GenericConfirmationPopup';
import {
	IGroup,
	IGroupCheckBoxState,
	IGroupSaveReq,
} from '../../types/MappingTypes';
import { API_STATUS_CONSTANT } from '../../constants/StepConstants';
import Loader from '../Loader';
import Toaster from '../common/Toaster';
import { AppDispatch } from '../../app/Store';
import MappingGroupConfig from './MappingGroupConfig';
import MappingGroupList from './MappingGroupList';

const QuestionnaireName = styled(Box)({
	fontFamily: 'JohnsonDisplay-Bold',
	fontSize: '25px',
	paddingTop: '20px',
	lineHeight: '16px',
	fontWeight: '700',
	marginLeft: '20px',
});

const MappingContainer = styled(Box)({
	display: 'flex',
	height: '63vh',
	margin: '2px 20px 10px 20px',
	gap: '20px',
});

const GroupList = styled(Paper)({
	width: '21vw',
	borderRadius: '16px',
	boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
});

const MappingConfiguration = styled(Paper)({
	width: '72vw',
	borderRadius: '16px',
	opacity: '0px',
	overflow: 'auto',
	boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.06)',
});
const Container = styled(Box)({
	display: 'flex',
	overflow: 'hidden',
	height: '10vh',
});
const MappingLandingScreen = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const QuestionnaireInfo = useAppSelector(surveyQuestionnaireData);
	const groups = useAppSelector(groupsList);
	const summitSurveys = useAppSelector(getSummitSurveyList);
	const initialQuestionData: ISurveyFormsQuestion[] =
		QuestionnaireInfo.surveyFormsQuestion;
	const questionnaireName = QuestionnaireInfo.surveyFormsDetails.surveyName;
	//Fetch questionnaireID from route Params
	const { questionnaireID } = useParams<{ questionnaireID: string }>();
	// Convert questionnaireID to string and store in questionnaireId
	const questionnaireId = `${questionnaireID}`;
	const [groupSelected, setGroupSelected] = useState('-1');
	const [groupCopied, setGroupCopied] = useState('');
	const [hasGroupNameErr, setGroupNameErr] = useState(false);
	const [groupCheckBoxState, setGroupCheckBoxState] =
		useState<IGroupCheckBoxState>(
			groups.reduce((acc, group) => {
				acc[group.groupId] = false;
				return acc;
			}, {} as IGroupCheckBoxState)
		);
	const [validateGroupConfig, setValidateGroupConfig] = useState(false);
	const [questionnaireQuestionsData, setQuestionnaireQuestionsData] =
		useState(initialQuestionData);
	window.scrollTo(0, 0);
	const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
	const [confirmPopupAction, setConfirmPopupAction] = useState('');
	const [confirmationPopupMessage, setConfirmationPopupMessage] = useState('');
	const [openToast, setOpenToast] = useState(false);
	const saveGroupReqPayload: IGroupSaveReq = useAppSelector(groupSaveRequest);
	const apiOperationCompleted = useAppSelector(saveCompleted);
	const apiErrorType = useAppSelector(getAPIErrorType);
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
	const [showPopup, setShowPopup] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [toastType, setToastType] = useState('');
	const [toasterStatusMsg, setToasterStatusMsg] = useState('');
	const errorColor: AlertColor = 'error';
	const successColor: AlertColor = 'success';
	const [newGroupCreated, setNewGroupCreated] = useState(false);
	const mappingCopyAPILoadingStatus = useAppSelector(
		getMappingCopyAPILoadingStatus
	);
	const mappingCreateAPILoadingStatus = useAppSelector(
		getMappingCreateAPILoadingStatus
	);
	const mappingDeleteAPILoadingStatus = useAppSelector(
		getDeleteAPILoadingStatus
	);
	//This useEffect will run if page is refreshed
	useEffect(() => {
		dispatch(
			getQuestionnaireByIdData({
				id: `${questionnaireId}`,
			})
		);
		dispatch(
			getGroupsList({
				questionnaireId: `${questionnaireId}`,
			})
		);
		dispatch(getSummitInfo());

		// dispatch to get audience details
		dispatch(
			getAudienceSubset({
				questionnaireId: `${questionnaireId}`,
			})
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questionnaireId]);
	// Post refresh, this will update questionnaireQuestionsData to latest data received from api
	useEffect(() => {
		setQuestionnaireQuestionsData(QuestionnaireInfo?.surveyFormsQuestion);
	}, [QuestionnaireInfo]);

	useEffect(() => {
		if (apiOperationCompleted) {
			// Default view where user can select or create group
			setGroupSelected('-1');
			setGroupCopied('');
			setHasUnsavedChanges(false);
		}
		if (apiErrorType === 'summitGroupFail') {
			// display popup
			setShowConfirmationPopup(true);
			setConfirmationPopupMessage(MappingScreenLabels.summitGroupLoadFailMsg);
			setConfirmPopupAction('summitGroupFail');
		} else if (apiErrorType === 'mappingAudienceFail') {
			// display popup
			setShowConfirmationPopup(true);
			setConfirmationPopupMessage(
				MappingScreenLabels.mappingAudienceLoadFailMsg
			);
			setConfirmPopupAction('mappingAudienceFail');
		}
	}, [apiOperationCompleted, apiErrorType]);

	//This useEffect will run when Create Mapping api is called (on click of save button)
	useEffect(() => {
		const statusAndString = {
			status: mappingCreateAPILoadingStatus,
			dispatchMethod: dispatch,
			dispatchObj: setMappingCreateAPILoadingStatus(API_STATUS_CONSTANT.IDLE),
			successMsg: MappingScreenLabels.groupCreatedMsg,
		};
		processAPILoadingStatus(
			statusAndString,
			setOpenToast,
			setToasterStatusMsg,
			setToastType
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mappingCreateAPILoadingStatus]);
	// This useEffect will run when copy api is called (on click of clone icon)
	useEffect(() => {
		const statusAndString = {
			status: mappingCopyAPILoadingStatus,
			dispatchMethod: dispatch,
			dispatchObj: setMappingCopyAPILoadingStatus(API_STATUS_CONSTANT.IDLE),
			successMsg: MappingScreenLabels.groupCopiedMsg,
		};
		processAPILoadingStatus(
			statusAndString,
			setOpenToast,
			setToasterStatusMsg,
			setToastType
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mappingCopyAPILoadingStatus]);

	// This useEffect will run when delete API is called (on click of delete button)
	useEffect(() => {
		const statusAndString = {
			status: mappingDeleteAPILoadingStatus,
			dispatchMethod: dispatch,
			dispatchObj: setDeleteAPILoadingStatus(API_STATUS_CONSTANT.IDLE),
			successMsg: MappingScreenLabels.groupDeletedMsg,
		};
		processAPILoadingStatus(
			statusAndString,
			setOpenToast,
			setToasterStatusMsg,
			setToastType
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mappingDeleteAPILoadingStatus]);
	//  This is a common function which is being used for showing loader based on API loading status
	//  Also controls visibility of Toaster message with its content
	const processAPILoadingStatus = (
		statusAndString: {
			status: string;
			dispatchMethod: AppDispatch;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			dispatchObj: any; //NOSONAR
			successMsg: string;
		},
		setOpenToast: Dispatch<SetStateAction<boolean>>,
		setToasterStatusMsg: Dispatch<SetStateAction<string>>,
		setToastType: Dispatch<SetStateAction<string>>
	) => {
		// If status is success then loader is closed and a success toaster message is shown
		if (statusAndString.status === API_STATUS_CONSTANT.SUCCESS) {
			setToasterStatusMsg(statusAndString.successMsg);
			setToastType(API_STATUS_CONSTANT.SUCCESS);
			setShowLoader(false);
			setOpenToast(true);
			setTimeout(() => {
				statusAndString.dispatchMethod(statusAndString.dispatchObj);
			}, Numbers.oneThousandFiveHundred);
		} // Else If status is Failed then loader is closed and a failure toaster message is shown
		else if (statusAndString.status === API_STATUS_CONSTANT.FAILED) {
			setToasterStatusMsg(questionnaireResultsLabels.someThingWrongMsg);
			setToastType(API_STATUS_CONSTANT.ERROR);
			setOpenToast(false);
			setShowLoader(false);
		} // Else If status is Loading then loader is shown
		else if (statusAndString.status === API_STATUS_CONSTANT.LOADING) {
			setShowLoader(true);
		} else {
			/* empty */
		}
	};
	// create new group
	const createNewGroup = () => {
		setGroupSelected('0');
		setGroupCopied('');
		dispatch(
			addGroup({
				isDefaulter: false,
				groupId: '0',
				groupName: '',
				includeClause: '',
				excludeClause: '',
				questionnaireJson: '',
				filterJson: '',
			})
		);
		setNewGroupCreated((prev) => !prev);
		setHasUnsavedChanges(false);
	};

	// handle the group selected from group list
	const selectGroupHandler = (groupId: string) => {
		setGroupSelected(groupId);
		setGroupCopied('');
		// Scroll to top of group when a new group opens
		document.getElementById('mappingConfigScrollBar')?.scrollTo(0, 0);
	};
	const checkBoxChangeHandler = (id: string, checked: boolean) => {
		setGroupCheckBoxState((prevState) => ({
			...prevState,
			[id]: checked,
		}));
	};

	// Displays the confirmation pop-up to user
	const openConfirmationPopup = (action = 'delete') => {
		if (action === 'save') {
			setConfirmationPopupMessage(
				MappingScreenLabels.saveGroupConfirmationLabel
			);
			setConfirmPopupAction(action);
			setShowConfirmationPopup(true);
		} else if (action === 'delete') {
			// Finds the count of group to be deleted that need to be shown in popup
			const countOfGroupsToDelete = Object.keys(groupCheckBoxState).filter(
				(group) => groupCheckBoxState[group]
			).length;
			setConfirmationPopupMessage(
				`${countOfGroupsToDelete} ${MappingScreenLabels.deleteGroupConfirm}`
			);
			setConfirmPopupAction(action);
			setShowConfirmationPopup(true);
		} else {
			setShowConfirmationPopup(true);
		}
	};

	const handleResetValidation = () => {
		setValidateGroupConfig(false);
	};

	// Update this state whenever there are changes in the form
	const handleFormChange = () => {
		setHasUnsavedChanges(true);
	};

	const handleGroupNameError = (hasError: boolean) => {
		setGroupNameErr(hasError);
	};
	const handleYesFromConfirmationPopup = () => {
		if (confirmPopupAction === 'save') {
			dispatch(saveGroup(saveGroupReqPayload));
			setConfirmPopupAction('');
			setShowConfirmationPopup(false);
			setValidateGroupConfig(false);
		} else if (confirmPopupAction === 'delete') {
			deleteGroupHandler();
			setShowConfirmationPopup(false);
			setConfirmPopupAction('');
		} else if (confirmPopupAction === 'groupName') {
			setShowConfirmationPopup(false);
			setConfirmPopupAction('');
		} else if (confirmPopupAction === 'summitGroupFail') {
			setShowConfirmationPopup(false);
			dispatch(setAPIErrorType(''));
			setConfirmPopupAction('');
		} else {
			setShowConfirmationPopup(false);
		}
	};

	const handleNoFromConfirmationPopup = () => {
		if (confirmPopupAction === 'save') {
			setConfirmPopupAction('');
			setShowConfirmationPopup(false);
			setValidateGroupConfig(false);
		} else if (confirmPopupAction === 'delete') {
			setShowConfirmationPopup(false);
			setConfirmPopupAction('');
		} else if (confirmPopupAction === 'groupName') {
			setShowConfirmationPopup(false);
			setConfirmPopupAction('');
			dispatch(clearGroupName(true));
		} else {
			setShowConfirmationPopup(false);
		}
	};

	/**
	 * @method
	 * @summary - Receives the groupId and open the form with copied data
	 * @summary - PS: Group name will be empty (when copy-form opens) and this needs to be filled by the user
	 * @parameter - group<IGroup>
	 * @returns void
	 */
	const copyGroupHandler = (groupId: string) => {
		setGroupSelected(groupId);
		setGroupCopied(groupId);
		// Scroll to top of group when a new group opens
		document.getElementById('mappingConfigScrollBar')?.scrollTo(0, 0);
	};

	/** Function is called on popup confirmation,
	 * It finds the group ids that needs to be deleted and dispatches an action to delete them
	 */
	const deleteGroupHandler = () => {
		// Filters the group ids that are selected
		const selectedGroupsToDelete = Object.keys(groupCheckBoxState).filter(
			(group) => groupCheckBoxState[group]
		);
		// Prepares the payload for delete Action/Api
		const deleteGroupPayload = {
			questionnaireId: questionnaireId,
			groupsToDelete: selectedGroupsToDelete,
		};
		dispatch(deleteGroup(deleteGroupPayload));
		// Updates the groupCheckBoxState by removing the deleted group ids
		setGroupCheckBoxState((prevState) => {
			const newState = { ...prevState };
			selectedGroupsToDelete.forEach((group) => {
				delete newState[group];
			});
			return newState;
		});
		// Resets the currently selected group to default
		setGroupSelected('-1');
		setGroupCopied('');
	};

	// will compare current group name with all existing groups to check for duplicacy
	const validateGroupName = (groupName: string) => {
		// Reset validation to allow save next time
		setValidateGroupConfig(false);
		if (groupSelected === groupCopied) {
			return groups.some((group: IGroup) => {
				return group.groupName.toLowerCase() === groupName.toLowerCase();
			});
		} else {
			return groups
				.filter((group: IGroup) => {
					return group.groupId !== groupSelected;
				})
				.some((group: IGroup) => {
					return group.groupName.toLowerCase() === groupName.toLowerCase();
				});
		}
	};

	const compareGrpNameSummit = (groupName: string) => {
		// Extract group names from the Redux store
		const groupNames = groups.map((group) => group.groupName);

		// Check if the group name exists in both the Redux store and the summit group list
		if (
			groupName !== '' &&
			!groupNames.includes(groupName) &&
			!summitSurveys.includes(groupName)
		) {
			setConfirmationPopupMessage(
				MappingScreenLabels.groupNameNotMatchedWithSummit
			);
			setConfirmPopupAction('groupName');
			setShowConfirmationPopup(true);
		}
	};

	const handleGroupSave = () => {
		// read from redux
		setValidateGroupConfig(true);
	};

	/**
	 * @method
	 * @summary - To hide the Toaster
	 * @parameter - Empty
	 * @returns void
	 */
	const handleCloseToast = () => {
		setOpenToast(false);
	};

	const goToHome = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		if (hasUnsavedChanges) {
			setShowPopup(true);
		} else {
			navigate('/');
			navigate(0);
		}
	};
	const handleBackNavigation = () => {
		navigate('/');
		navigate(0);
	};

	const handleCancelPopup = () => {
		setShowPopup(false);
	};

	return (
		<Box>
			<Loader isLoading={showLoader} />
			<Toaster
				onClose={handleCloseToast}
				hideDuration={Numbers.twoThousand}
				message={toasterStatusMsg}
				severity={
					toastType === API_STATUS_CONSTANT.SUCCESS ? successColor : errorColor
				}
				show={openToast}
			></Toaster>
			<Container>
				<QuestionnaireName>
					{MappingScreenLabels.groupMappingFor} {questionnaireName}
				</QuestionnaireName>
			</Container>
			<MappingContainer>
				<GroupList elevation={3}>
					<MappingGroupList
						groupCount={groups?.length}
						addGroup={createNewGroup}
						groups={groups}
						selectGroup={selectGroupHandler}
						deleteGroup={openConfirmationPopup}
						checkBoxState={groupCheckBoxState}
						onCheckBoxChange={checkBoxChangeHandler}
						selectedGroup={groupSelected}
						copiedGroup={groupCopied}
						copyGroup={copyGroupHandler}
					></MappingGroupList>
				</GroupList>
				<MappingConfiguration
					elevation={Numbers.three}
					className="hiddenScrollbarTrack"
					id="mappingConfigScrollBar"
				>
					<MappingGroupConfig
						questionData={questionnaireQuestionsData}
						groupCount={groups?.length}
						groupSelected={groupSelected}
						groupCopied={groupCopied}
						questionnaireId={questionnaireId}
						checkIfNameDuplicate={validateGroupName}
						validateConfiguration={validateGroupConfig}
						showSaveConfirmation={openConfirmationPopup}
						resetValidationState={handleResetValidation}
						checkGroupNameErr={handleGroupNameError}
						summitSurveyList={summitSurveys}
						checkIfNameMatchSummit={compareGrpNameSummit}
						onFormChange={handleFormChange}
						newGroupCreated={newGroupCreated}
					/>
				</MappingConfiguration>
			</MappingContainer>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-end',
					padding: '15px 45px 20px 0',
				}}
			>
				<Button
					variant="contained"
					disableElevation
					disabled={groupSelected === '-1' || hasGroupNameErr}
					sx={{
						backgroundColor: STEPTheme.colors.black,
						color: STEPTheme.colors.white,
						width: '120px',
						textTransform: 'none',
						height: '32px',
						borderRadius: '10px',
						'&:hover': {
							backgroundColor: STEPTheme.colors.black,
							color: STEPTheme.colors.white,
						},
					}}
					onClick={handleGroupSave}
				>
					{MappingScreenLabels.saveLabel}
				</Button>
			</Box>
			<GenericConfirmationPopup
				open={showConfirmationPopup}
				msgBody={''}
				title={confirmationPopupMessage}
				rightBtnText={
					confirmPopupAction === 'summitGroupFail' ||
					confirmPopupAction === 'mappingAudienceFail'
						? commonLabels.ok
						: commonLabels.yes
				}
				leftBtnText={commonLabels.no}
				isRightBtnVisible={true}
				isLeftBtnVisible={
					confirmPopupAction === 'summitGroupFail' ||
					confirmPopupAction === 'mappingAudienceFail'
						? false
						: true
				}
				rightBtnHandler={handleYesFromConfirmationPopup}
				leftBtnHandler={handleNoFromConfirmationPopup}
			></GenericConfirmationPopup>
			<GenericConfirmationPopup
				open={showPopup}
				msgBody={MappingScreenLabels.mappingDataChangeLabel}
				title={MappingScreenLabels.exitWithoutSavingLabel}
				rightBtnText={commonLabels.confirm}
				leftBtnText={commonLabels.cancel}
				isRightBtnVisible={true}
				isLeftBtnVisible={true}
				rightBtnHandler={handleBackNavigation}
				leftBtnHandler={handleCancelPopup}
			/>
		</Box>
	);
};

export default MappingLandingScreen;
