import React from 'react';
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableContainer,
	Paper,
	styled,
	TablePagination,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { audienceSelectionTableHeaders } from '../../utils/AudienceSelectionLabel';
import { AudienceDataProps } from '../../types/CommonTypes';
import { Numbers, PageRows } from '../../utils/Enum';

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		fontWeight: '700',
		fontFamily: '"JohnsonText-Regular"',
		fontSize: '14px',
		lineHeight: '16px',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: '14px',
		fontFamily: '"JohnsonText-Regular"',
		lineHeight: '16px',
	},
}));

const StyledTableContainer = styled(TableContainer)({
	height: '300px',
	margin: 'auto',
});

export const AudienceSelectionTable: React.FC<AudienceDataProps> = ({
	data,
}) => {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(PageRows.firstOption);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};
	return (
		<Paper elevation={0}>
			<StyledTableContainer>
				<Table stickyHeader aria-label="sticky table" size="small">
					<TableHead>
						<TableRow>
							<StyledTableCell>
								{audienceSelectionTableHeaders.wwid}
							</StyledTableCell>
							<StyledTableCell>
								{audienceSelectionTableHeaders.name}
							</StyledTableCell>
							<StyledTableCell>
								{audienceSelectionTableHeaders.emailId}
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data &&
							data
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => (
									<TableRow key={row[Numbers.zero]}>
										<StyledTableCell>{row[Numbers.zero]}</StyledTableCell>
										<StyledTableCell>{row[Numbers.one]}</StyledTableCell>
										<StyledTableCell>{row[Numbers.two]}</StyledTableCell>
									</TableRow>
								))}
					</TableBody>
				</Table>
			</StyledTableContainer>
			<TablePagination
				rowsPerPageOptions={[
					PageRows.firstOption,
					PageRows.secondOption,
					PageRows.thirdOption,
				]}
				component="div"
				data-testid="audience-table-pagination"
				count={data.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
};
export default AudienceSelectionTable;
