export enum PageRows {
	firstOption = 10,
	secondOption = 15,
	thirdOption = 20,
	fourthOption = 25,
	fifthOption = 50,
	sixthOption = 100,
}

export enum Numbers {
	minusTwo = -2,
	minusOne = -1,
	zero = 0,
	zeroAndHalf = 0.5,
	zeroPointFiveFive = 0.55,
	zeroPointSix = 0.6,
	zeroPointSixFive = 0.65,
	zeroPointZeroThree = 0.03,
	zeroPointEight = 0.8,
	zeroPointEightEight = 0.88,
	zeroPointOne = 0.1,
	one = 1,
	two = 2,
	three = 3,
	four = 4,
	fourAndHalf = 4.5,
	five = 5,
	six = 6,
	seven = 7,
	eight = 8,
	ten = 10,
	thirteen = 13,
	fourteen = 14,
	fifteen = 15,
	sixteen = 16,
	twenty = 20,
	twentyThree = 23,
	twentyFour = 24,
	twentyFive = 25,
	twentyEight = 28,
	thirty = 30,
	thritySix = 36,
	forty = 40,
	fourtyEight = 48,
	fifty = 50,
	fiftyNine = 59,
	sixty = 60,
	sixtyFive = 65,
	ninety = 90,
	hundred = 100,
	oneFifty = 150,
	twoHundred = 200,
	twoFifty = 250,
	threeHundred = 300,
	threeThirtyNine = 339,
	threeSixtyFive = 365,
	threeSixtyEight = 368,
	threeHundredEighty = 380,
	fourHundred = 400,
	fourHundredFifty = 450,
	fourHundredEighty = 480,
	fiveHundred = 500,
	sixHundred = 600,
	sixHundredOne = 601,
	sixFifty = 650,
	sevenHundred = 700,
	sevenSixty = 760,
	sevenSixtyEight = 768,
	nineNintyNine = 999,
	thousand = 1000,
	thousandTwentyFive = 1025,
	thirteenHundred = 1300,
	fourteenHundredForty = 1440,
	oneThousandFiveHundred = 1500,
	twoThousand = 2000,
	twoThousandTwentyFour = 2024,
	fiveThousand = 5000,
	tenThousand = 10000,
	sixtyThousand = 60000,
	TIME_ONE_DAY_IN_MILLI_SECS = 86400000,
	TIME_FOURTY_FIVE_IN_MILLI_SECS = 2700000,
	oneLakh = 100000,
	oneLakhOne = 100001,
	twoLakh = 200000,
	zeroxff = 0xff,
}

export enum QUE_JOURNEY_STEPS {
	STEP_BASIC_DETAILS = 1,
	STEP_AUDIENCE = 2,
	STEP_QUESTIONS = 3,
	STEP_PREVIEW = 4,
	STEP_NOTIFICATIONS = 5,
}

export enum COMMON {
	RANDOM_MULTIPLE = 1000000,
	DEFAULT_CHAR_LIMIT = 62000,
}

export enum DURATIONS {
	THIRTY = 30,
	SIXTY = 60,
	NINETY = 90,
}

export enum Percentages {
	Zero = '0%',
	Two = '2%',
	Five = '50%',
	Ten = '100%',
}
