import React from 'react';
import { Avatar } from '@mui/material';

interface KeyMetricsPercentageAvatarProps {
	percentage: string;
	diameterInPixel: string;
	color: string;
}
export const KeyMetricsPercentageAvatar: React.FC<
	KeyMetricsPercentageAvatarProps
> = ({ percentage, diameterInPixel, color }) => {
	return (
		<Avatar
			sx={{
				bgcolor: color,
				width: diameterInPixel,
				height: diameterInPixel,
				fontFamily: '"JohnsonText-Regular"',
				fontWeight: 700,
				fontSize: '16px',
				lineHeight: '16px',
			}}
			data-testid="metrics-percentage-avatar"
		>
			{percentage}
		</Avatar>
	);
};

export default KeyMetricsPercentageAvatar;
