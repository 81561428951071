// Saving all the project level constants here
export const MAX_LENGTH_QUESTIONNAIRE_TITLE = 100;
export const MAX_LENGTH_MAPPING_GROUP_NAME = 250;
export const MAX_LENGTH_QUESTIONNAIRE_DESCRIPTION = 250;
export const DTH_AUTH_DETAILS = 'DTH_Auth_Details';
// eslint-disable-next-line no-useless-escape
export const specialCharacterForAnswers =
	/[<>&%*#@!$^(){}[\]~`_=+|:;?.,'\\/-]/g;

export const VALIDATIONS_RULES = {
	VALIDATION_COMMON_INPUT_FIELDS:
		/^(?!.*([<>&%*#@!$^(){}[\]~`_=+|:;?.,'\\/-])\1)(?!.*<.*<)(?!.*>.*>)(?!.*<.*>)(?!.*["\\])(?!.*<\s*\w+\s*>)(?!.*<\s*\w+\s*\/\s*>)[a-zA-Z0-9\s].*$/,
	VALIDATION_QUESTION_TITLE:
		/^(?!.*([&\-_])\1)(?!.*["\\])[a-zA-Z0-9][a-zA-Z0-9&\-_\s]*$/,
	VALIDATION_QUESTION_DESCRIPTION:
		/^(?!.*([<>&%*#@!$^(){}[\]~`_=+|:;?.,'\\/-])\1)(?!.*<.*<)(?!.*>.*>)(?!.*<.*>)(?!.*["\\])(?!.*<\s*\w+\s*>)(?!.*<\s*\w+\s*\/\s*>)[a-zA-Z0-9\s].*$/s,
	VALIDATION_ONLY_ALPHABET: /[a-zA-Z]+/g,
	VALIDATION_ONLY_ALPHABET_STRING: /^(?!.*([.'])\1)[A-Za-z][A-Za-z.''\s]*$/,
	VALIDATION_NOTIFICATION_MAIL_SUBJECT:
		/^(?!.*([<>&%*#@!$^(){}[\]~`_=+|:;?.,'\\/-])\1)(?!.*<.*<)(?!.*>.*>)(?!.*<.*>)(?!.*["\\])(?!.*<\s*\w+\s*>)(?!.*<\s*\w+\s*\/\s*>)[a-zA-Z0-9\s].*$/,
	VALIDATION_FILTER_WWIDS: /^\d+$/,
	VALIDATION_OPEN_ENDED_END_USER:
		/^(?!.*([<>&%*#@!$^(){}[\]~`_=+|:;?.,'\\/-])\1)(?!.*<.*<)(?!.*>.*>)(?!.*<.*>)(?!.*["\\])(?!.*<\s*\w+\s*>)(?!.*<\s*\w+\s*\/\s*>)[a-zA-Z0-9\s].*$/,
};
export const ADMIN_SCREEN_VALIDATION = {
	VALIDATION_QUESTIONNAIRE_NAME_ID:
		/^(?!.*([&\-_])\1)(?!.*["\\])[a-zA-Z0-9][a-zA-Z0-9&\-_\s]*$/,
	VALIDATION_LOCATION: /^[A-Za-z\s]+$/,
	VALIDATION_AUTHOR: /^(?!.*([.'])\1)[A-Za-z][A-Za-z.''\s]*$/,
	VALIDATION_STATUS: /^[a-zA-Z\s]+( [a-zA-Z\s]+)*$/,
};
export const EVENT_KEYS = {
	BACKSPACE: 'Backspace',
	DELETE: 'Delete',
	CONTROL: 'Control',
	PASTE: 'v',
	SPACE: 32,
};
export const QUECONSTANT = {
	QUESTION_TYPE_YES_NO: 'yesOrNo',
	QUESTION_TYPE_MULTI_SELECT: 'select',
	QUESTION_TYPE_MULTIPLE_OPTION: 'choice',
	QUESTION_TYPE_OPEN: 'open',
	QUESTION_TYPE_DROPDOWN: 'dropdown',
	MAX_LENGTH_QUESTION_TITLE: 250,
	MAX_LENGTH_QUESTION_OPTIONS_TEXT_LENGTH: 250,
	QUESTION_CONDITION_LABEL: 'Add Condition',
};

export const NOTI_CONSTANT = {
	NOTIFICATION_REMINDER_EMAIL: 'reminder-email',
	NOTIFICATION_INITIAL_EMAIL: 'initial-email',
	NOTIFICATIO_OVERDUE_EMAIL: 'overdue-email',
};

export const BASIC_CONSTANT = {
	SELF: 'Self',
	OTHERS: 'others',
	CONTINGENT: 'J&J Contingent Workers',
	DIRECT: 'Direct J&J Employees',
	DURATION: 'duration',
	DUE_DATE: 'dueDate',
	SINGLE_PAGE: 'Single Page',
	MULTIPLE_PAGE: 'Multiple Page',
	HCC: 'HCC',
};

export const AUDIENCE_CONSTANT = {
	SUCCESS: 'success',
};

export const RESPONSE_CODE = {
	HTTP_STATUS_OK: 200,
	HTTP_STATUS_NO_CONTENT: 204,
	HTTP_STATUS_UNAUTHORIZED: 401,
	HTTP_STATUS_PERMISSION_DENIED: 406,
	HTTP_STATUS_BAD_REQUEST: 400,
	HTTP_STATUS_FORBIDDEN: 403,
};

export const PREVIEW_CONSTANT = {
	INITIAL_MAIL: 'initial-email',
	REMINDER_MAIL: 'reminder-email',
	OVERDUE_MAIL: 'overdue-email',
};

export const QUESTION_UPLOAD_CONSTANT = {
	FILE_TYPE:
		'application/vnd.openxmlformates-offficedocument.spreadsheetml.sheet',
};

export const SURVEY_STATUS = {
	COMPLETED: 'Completed',
	PUBLISHED: 'Published',
	ACTIVE: 'Active',
	IN_DRAFT: 'In Draft',
};

export const API_STATUS_CONSTANT = {
	SUCCESS: 'success',
	FAILED: 'failed',
	IDLE: 'idle',
	LOADING: 'loading',
	ERROR: 'error',
	ERROR_BAD_REQUEST: 'ERR_BAD_REQUEST',
	ERROR_PERMISSION_DENIED: 'permission-denied',
	NO_CONTENT: 'NO_CONTENT',
};

export const END_USER_CONSTANT = {
	WWIDS: 'wwiDs',
	LAST_NAME: 'lastName',
	FIRST_NAME: 'firstName',
	PARTNER_COMPANY: 'partnerCompany',
	ASSESSMENT_STATUS: 'assessmentStatus',
	DESCENDING: 'desc',
	ASCENDING: 'asc',
	STATUS: 'status',
	RESPONSE: 'response',
};

export const TARGET_AUDIENCE_TYPE_CONSTANT = {
	SELF: 'self',
	DIRECT: 'direct reports',
};

export const ASSESMENT_STATUS_CONSTANT = {
	OUT_OF_SCOPE: 'Out Of Scope',
	IN_SCOPE: 'In Scope',
	NOT_ASSESSED: 'Not Assessed',
};

export const ROLE_AD_GROUPS = (env: string) => {
	if (env.toLowerCase() === 'production') {
		return {
			STEP_ADMIN: `ITS-DTH-STEPADMIN`,
			LMS_ADMIN: `ITS-DTH-STEPLMSADMIN`,
			CW_USER: `ITS-DTH-STEPCWUSER`,
			CW_MANAGER: 'ITS-DTH-STEPCWMANAGER',
		};
	} else {
		return {
			STEP_ADMIN: `ITS-DTH-STEPADMIN-${env}`,
			LMS_ADMIN: `ITS-DTH-STEPLMSADMIN-${env}`,
			CW_USER: `ITS-DTH-STEPCWUSER-${env}`,
			CW_MANAGER: `ITS-DTH-STEPCWMANAGER-${env}`,
		};
	}
};

export const ROLES = {
	STEP_ADMIN: 'STEP_ADMIN',
	LMS_ADMIN: 'LMS_ADMIN',
	CW_USER: 'CW_USER',
	CW_MANAGER: 'CW_MANAGER',
};
