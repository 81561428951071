import React from 'react';
import { Box, Typography } from '@mui/material';
import { ISurveyFormsQuestion } from '../../../types/SurveyProps';
import { TabPanelProps } from '../../../types/CommonTypes';
import DonutChart from '../../common/DonutChart';
import CustomInfoChart from '../../common/CustomInfoChart';
import { DataItemProps, IDonutGraphData } from '../../../types/ResultsType';
import { questionnaireResultsLabels } from '../../../utils/CommonLabels';
import { Numbers } from '../../../utils/Enum';
import { isDataAvailable } from '../../../utils/Helpers';

interface IMultipleOptionAnswersComponent {
	questionJSONString: ISurveyFormsQuestion;
	activeTabValue: number;
	chartSummary: DataItemProps[];
	graphData: IDonutGraphData[];
	dataDisplayType: string;
	centerText: string;
}

const DropdownAnswersComponent: React.FC<IMultipleOptionAnswersComponent> = ({
	questionJSONString,
	activeTabValue,
	chartSummary,
	graphData,
	dataDisplayType,
	centerText,
}) => {
	// Check if all values in chartSummary are 0
	const dataAvailable = isDataAvailable(chartSummary, Numbers.zero);

	const CustomTabPanel = (props: TabPanelProps) => {
		const { children, value, index, ...other } = props;
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`results-dropdown-tabpanel-${index}`}
				aria-labelledby={`results-dropdown-tab-${index}`}
				{...other}
			>
				{value === index && <Box>{children}</Box>}
			</div>
		);
	};

	return (
		<Box key={questionJSONString?.questionId}>
			<CustomTabPanel value={activeTabValue} index={activeTabValue}>
				{dataAvailable ? (
					<Box
						sx={{
							padding: '20px 0',
							flexDirection: 'column',
						}}
					>
						<Typography
							variant="h2"
							sx={{
								fontFamily: '"JohnsonText-Regular"',
								fontSize: '14px',
								fontWeight: '400',
								lineHeight: '16px',
							}}
						>
							{`Q${Number(activeTabValue + 1)}. ${questionJSONString?.question}`}
						</Typography>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: '20px',
								padding: '20px',
							}}
							data-testid="donut-chart"
						>
							<Box sx={{ display: 'block', width: '38%' }}></Box>
							<DonutChart
								downloadHandler={() => {}}
								id="response-dropdown-chart-svg"
								data={graphData}
								width={210}
								height={210}
								centerText={centerText}
								heading={``}
								summaryData={chartSummary}
								chartType={dataDisplayType}
								summaryPosition="beside"
								showAdditionalInfo={false}
							/>
						</Box>
					</Box>
				) : (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '50vh',
						}}
					>
						<CustomInfoChart
							type="info"
							titleText={questionnaireResultsLabels.noDataFound}
							bodyText={questionnaireResultsLabels.noDataFoundForQuestion}
						/>
					</Box>
				)}
			</CustomTabPanel>
		</Box>
	);
};

const DropdownAnswers = React.memo(DropdownAnswersComponent);
export default DropdownAnswers;
