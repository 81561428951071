import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
} from '@mui/material';
import styled from '@emotion/styled';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { AudienceFilterLabels } from '../../utils/SurveyAudience';
import AudienceFilterPopup from '../AudienceFilterPopup';
import { useAppDispatch, useAppSelector } from '../../app/Hooks';
import {
	dummySelectedFilterRequestPayloadData,
	getSurveyAudienceIncludeFilterData,
	getSurveyAudienceExcludedFilterData,
	setSelectedFilterRequestPayload,
} from '../../redux/SurveySlice';
import { updateGroupFilters } from '../../redux/MappingSlice';
import { peopleLeaderValidation } from '../../utils/CommonMethods';

const StyledButton = styled(Button)({
	width: '224px',
	height: '32px',
	padding: '8px 16px',
	borderRadius: '8px',
	gap: '4px',
});

const AudiencePopup = (props: { closePopup: () => void; groupId?: string }) => {
	const dispatch = useAppDispatch();
	const filterRequestPayload = useAppSelector(
		dummySelectedFilterRequestPayloadData
	);
	const surveyAudienceIncludedFilterList = useAppSelector(
		getSurveyAudienceIncludeFilterData
	);
	const surveyAudienceExcludedFilterList = useAppSelector(
		getSurveyAudienceExcludedFilterData
	);
	const surveyAudienceIncludedFilters = Object.entries(
		surveyAudienceIncludedFilterList
	);
	const surveyAudienceExcludedFilters = Object.entries(
		surveyAudienceExcludedFilterList
	);
	const [hasIncPeopleLeaderError, setHasIncPeopleLeaderError] = useState(false);
	const [hasExcPeopleLeaderError, setHasExcPeopleLeaderError] = useState(false);
	const [includeFilterErrorMessage, setIncludeFilterErrorMessage] = useState<
		string | null
	>(null);
	const [excludeFilterErrorMessage, setExcludeFilterErrorMessage] = useState<
		string | null
	>(null);

	const handleClose = () => {
		props.closePopup();
	};

	const handleAudienceFilter = () => {
		const includedFilters = JSON.parse(filterRequestPayload?.includeFilters);
		const excludedFilters = JSON.parse(filterRequestPayload?.excludeFilters);
		const hasIncludeFilterError = peopleLeaderValidation(
			includedFilters,
			surveyAudienceIncludedFilters
		).includes(false);
		const hasExcludeFilterError = peopleLeaderValidation(
			excludedFilters,
			surveyAudienceExcludedFilters
		).includes(false);
		setHasIncPeopleLeaderError(hasIncludeFilterError);
		setHasExcPeopleLeaderError(hasExcludeFilterError);

		if (!hasIncludeFilterError && !hasExcludeFilterError) {
			if (props.groupId) {
				dispatch(
					updateGroupFilters({
						groupId: props.groupId,
						filters: JSON.stringify(filterRequestPayload),
					})
				);
			}
			dispatch(setSelectedFilterRequestPayload(filterRequestPayload));
			props.closePopup();
		}
	};

	return (
		<Dialog
			open={true}
			fullWidth={true}
			onClose={() => {}}
			maxWidth="md"
			PaperProps={{
				component: 'form',
				onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
					event.preventDefault();
				},
			}}
			data-testid="dialog"
		>
			<IconButton
				aria-label="close"
				onClick={() => {
					handleClose();
				}}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
				}}
				data-testid="iconButton"
			>
				<CloseIcon />
			</IconButton>
			<DialogContent>
				<AudienceFilterPopup
					hasIncPeopleLeaderError={hasIncPeopleLeaderError}
					hasExcPeopleLeaderError={hasExcPeopleLeaderError}
					includeFilterErrorMessage={includeFilterErrorMessage}
					setIncludeFilterErrorMessage={setIncludeFilterErrorMessage}
					excludeFilterErrorMessage={excludeFilterErrorMessage}
					setExcludeFilterErrorMessage={setExcludeFilterErrorMessage}
				/>
			</DialogContent>
			<DialogActions>
				<span>
					<StyledButton
						variant="contained"
						onClick={handleAudienceFilter}
						data-testid="handleAudienceFilter"
						disabled={
							!!includeFilterErrorMessage || !!excludeFilterErrorMessage
						}
					>
						{AudienceFilterLabels.apply}
					</StyledButton>
				</span>
			</DialogActions>
		</Dialog>
	);
};

export default AudiencePopup;
