import { styled } from '@mui/material/styles';
import { Box, Grid, Button, Typography } from '@mui/material';
import { STEPTheme } from '../utils/Theme';
import { filter } from '../utils/Filter';
export const TextContainer = styled('span')`
  display: flex;
  justify-content: space-between;
}
`;

export const ItemSurveyTextAfter = styled('span')`
	&::after {
		content: '|';
	}
	padding: 0 6px;
`;

export const ResultTextContainer = styled('div')({
	fontFamily: "'JohnsonText-Regular'",
	fontSize: '14px',
	fontWeight: '400',
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'left',
	color: STEPTheme.colors.darkGray,
	margin: '12px 0px 38px 0px',
});

export const ItemSurveyText = styled('span')``;
export const ShowingResultText = styled('span')``;

export const StyledBox = styled(Box)({
	maxWidth: '220px',
	height: '48px',
	justifyContent: 'space-between',
	margin: '5px',
	border: `1px ${STEPTheme.colors.text}`,
	'.MuiAutocomplete-inputRoot': {
		backgroundColor: `${STEPTheme.colors.white} !important`,
		padding: '0 !important',
		maxWidth: '220px',
		height: '32px',
		borderRadius: '8px',
		border: `1px solid ${STEPTheme.colors.darkGray}`,
	},
});

export const FilterBox = styled(Box)({
	width: '100%',
	height: '48px',
	alignItems: 'center',
	justifyContent: 'space-between',
	border: '1px',
});

export const StyledTypography = styled(Typography)`
	font-family: JohnsonText-Regular;
	font-size: 14px;
	font-weight: 500;
	line-height: 12px;
	letter-spacing: 0px;
	text-align: left;
	color: #212121;
	margin-bottom: 2px;
	width: 220px;
	height: 12px;
`;

export const ApplyText = styled(Typography)({
	width: '41px',
	height: '16px',
	fontFamily: 'JohnsonText-Regular',
	fontSize: '16px',
	fontWeight: '500',
	lineHeight: '16px',
	letterSpacing: '0px',
	textAlign: 'center',
	background: `${STEPTheme.colors.white}`,
	content: `"${filter.label.APPLY}"`,
});

export const ActionButton = styled(Button)({
	width: '114px',
	height: '32px',
	color: STEPTheme.colors.white,
	background: STEPTheme.colors.text,
	padding: '8px 16px',
	borderRadius: '8px',
	margin: '5px',
	justify: 'space-between',
	border: `1px solid ${STEPTheme.colors.text}`,
	'&:hover': {
		backgroundColor: STEPTheme.colors.text,
		borderColor: STEPTheme.colors.white,
	},
});

export const ResetButton = styled(Button)({
	width: '114px',
	height: '32px',
	color: STEPTheme.colors.text,
	background: 'white',
	padding: '8px 16px',
	borderRadius: '8px',
	margin: '5px',
	justify: 'space-between',
	border: `1px solid ${STEPTheme.colors.text}`,
	'&:hover': {
		backgroundColor: 'white',
		borderColor: 'black',
		color: STEPTheme.colors.text,
	},
});
export const GridContainer = styled(Grid)({
	spacing: 2,
	width: '100%',
	margin: '0px',
	justifyContent: 'space-between',
	marginBottom: '16px',
});
